import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectDelivery =(Comp_Id,ProjectID,Month,Year,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectDelivery/${Comp_Id}/${ProjectID}/${Month}/${Year}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const UpdateDeliveryBlock =(DeliveryID,IsBlock,Callback) =>{
    
  axios.get(`${domain}/Delivery/UpdateDeliveryBlock/${DeliveryID}/${IsBlock}`)
       .then(response=>{
           Callback(response.data.message)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectDeliveries =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Delivery/SelectDeliveries/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const GetMaxDeliveryNo =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Delivery/GetMaxDeliveryNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectDeliveryMaster =(Comp_Id,ProjectID,DeliveryID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectDeliveryMaster/${Comp_Id}/${ProjectID}/${DeliveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectDeliveryDetail =(DeliveryID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectDeliveryDetail/${DeliveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const DeleteDelivery =(DDID,SaleInvoiceID,Callback) =>{
    
  axios.delete(`${domain}/Delivery/DeleteDelivery/${DDID}/${SaleInvoiceID}`)
       .then(response=>{
           Callback(response.data.message)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SaveDelivery = (DeliveryMasterData,DeliveryDetailData,DeletedDeliveryDetailData,isAddNew,callback) =>{
  axios.post(`${domain}/Delivery/SaveDelivery`,{
    DeliveryMasterData,DeliveryDetailData,DeletedDeliveryDetailData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback(false,response.data.DeliveryID)
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
            callback(true,0)
       })
}

export const SelectDeliveryForInvoice =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectDeliveryForInvoice/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectDeliveryForInvoiceLoad =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectDeliveryForInvoiceLoad/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectBookingForInvoiceLoad =(Comp_Id,ProjectID,DeliveryID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectBookingForInvoiceLoad/${Comp_Id}/${ProjectID}/${DeliveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectDeliveryForDD =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectDeliveryForDD/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectRecoveryAgainstDelivery =(Comp_Id,ProjectID,DeliveryID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectRecoveryAgainstDelivery/${Comp_Id}/${ProjectID}/${DeliveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectCityForDelivery =(DeliveryID,Callback) =>{
    
  axios.get(`${domain}/Delivery/SelectCityForDelivery/${DeliveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptDelivery_Summary =(DeliveryID,Callback) =>{
    
  axios.get(`${domain}/Delivery/rptDeliverySummary/${DeliveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptDelivery_SummaryWRTINvoices =(Comp_Id, ProjectID, FromInvNo, ToInvNo, DeliveryID,Callback) =>{
    
  axios.get(`${domain}/Delivery/rptDeliverySummaryWRTInvoiceNo/${Comp_Id}/${ProjectID}/${FromInvNo}/${ToInvNo}/${DeliveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}