import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptAccouontLedger } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'

function AccountLedgerReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj}) {

    const { ProjectID,CGID,CustomerID,FromDate,ToDate} = useParams();

    const [ItemData, setItemData] = useState([]);
    const [Credit, setCredit] = useState(0)
    const [Debit, setDebit] = useState(0)
    const [SumCredit, setSumCredit] = useState(0)
    const [SumDebit, setSumDebit] = useState(0)
    const [OpeneingBalances, setOpeneingBalances] = useState(0)

    const [SelectedProject, setSelectedProject] = useState(null)
    const [Customer, setCustomer] = useState('')
    const [CompanyGroup, setCompanyGroup] = useState('')
    const [Address, setAddress] = useState('')

    useEffect(() => {

        document.title = " Distribution / Account Ledger " 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptAccouontLedger(Comp_Id,ProjectID,CGID,CustomerID,FromDate,ToDate,(mData)=>{
            setCredit(mData[0].Credit)
            setDebit(mData[0].Debit)
            setOpeneingBalances(mData[0].OPBalance)
            
            var openingBalances = mData[0].OPBalance;
            var mArray = mData.shift();
            var SumCredit = 0,SumDebit = 0;
            for(let i=0; i<mData.length; i++){
                if(mData[i].Credit < 0){
                    openingBalances = openingBalances + mData[i].Credit
                }
                else{
                    openingBalances = openingBalances + mData[i].Debit
                    
                }
                mData[i].OPBalance = parseFloat((openingBalances).toFixed(2))
                SumCredit += mData[i].Credit
                SumDebit +=mData[i].Debit

            }
               setItemData(mData)
                setSumDebit(SumDebit)
                setSumCredit(SumCredit)
            var _AccountLedgerRport = JSON.parse(localStorage.getItem("AccountLedgerRport"))
            if(_AccountLedgerRport){
                setCustomer(_AccountLedgerRport.CustomerName)
                setCompanyGroup(_AccountLedgerRport.CompanyGroup)
                setAddress(_AccountLedgerRport.Address)
                
                localStorage.removeItem("AccountLedgerRport")
            }
        })
       
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
        mProjects.map(value=>{
            if(value.Project_ID == ProjectID){
                setSelectedProject(value)
            }
         })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Account Ledger</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Project:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SelectedProject && SelectedProject.ProjectName}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Customer:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{Customer}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Company:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{CompanyGroup}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Address:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{Address}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{FromDate}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{ToDate}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} > Transaction No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} > Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Description</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Debit</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Credit</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Balance</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                           <tr >
                            <td colSpan={3} style={{ width: '10%', fontWeight:'bold',textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Opening Balance</td>
                            <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Debit}</td>
                            <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Credit} </td>
                            <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {OpeneingBalances} </td>
                            </tr>
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.ManufacturedBy}</td>
                                       </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleInvoiceNo} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}    >{Itemdata.InvoiceDate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Description}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Debit}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Credit}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.OPBalance > 0 ? Itemdata.OPBalance + "  Dr" : Itemdata.OPBalance + "  Cr"} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        <tr id='footer_balance'>
                            <td id='' colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  >Total Sum Without Opening :</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{(SumDebit).toFixed(2)}</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{(SumCredit).toFixed(2)}</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                        </tr>
                        <tr >
                            <td id='footer_td' colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  >Total Sum With Opening :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{(SumDebit + Debit).toFixed(2)}</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{(SumCredit + Credit).toFixed(2)}</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                        
                        </tr>
                    </tbody>
                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(AccountLedgerReport)
