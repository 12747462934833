import {  useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import "./App.css";
import MainContents from "./components/Contents/MainContents";
import TopBar from "./components/layouts/TopBar";
import Footer from "./components/layouts/Footer";
import Sidebar from "./components/layouts/sidebar";
import Logins from "./components/Login/Login";
import UOM from "./components/UOM/UOM";
import ItemMainType from "./components/ItemMainType/ItemMainType";
import ItemSubType from "./components/ItemSubType/ItemSubType";
import WareHouse from "./components/WareHouse/WareHouse";
import City from "./components/City/City";
import Bank from "./components/Bank/bank";
import manufacture from "./components/ManufactureBy/manufacture";
import ExpenseClassification from "./components/ExpenseClassification/ExpenseClassification";
import ExpenseType from "./components/ExpenseType/ExpenseType";
import ExpenseList from "./components/Expense/ExpenseList";
import CustomerList from "./components/Customers/CustomerList";
import SuppliersList from "./components/Suppliers/SuppliersList";
import SupplierEntry from "./components/Suppliers/SupplierEntry";
import CustomerEntry from "./components/Customers/CustomerEntry";
import OpeningList from "./components/Opening/OpeningList";
import OpeningEntry from "./components/Opening/OpeningEntry";
import Opening from "./components/Opening/Opening";
import ItemList from "./components/Items/ItemList";
import ItemEntry from "./components/Items/ItemEntry";
import StockTransferList from "./components/StockTransfer/StockTransferList";
import StockTransferEntry from "./components/StockTransfer/StockTransferEntry";
import StockAdjustmentList from "./components/StockAdjustment/StockAdjustmentList";
import StockAdjustmentEntry from "./components/StockAdjustment/StockAdjustmentEntry";
import PaymentsList from "./components/Payments/PaymentsList";
import PaymentEntry from "./components/Payments/PaymentEntry";
import ReceiptsList from "./components/Receipt/ReceiptsList";
import ReceiptEntry from "./components/Receipt/ReceiptEntry";
import PaymentPrint from "./components/Payments/PaymentPrint";
import RecieptPrint from "./components/Receipt/RecieptPrint";
import SaleInvoiceList from "./components/SaleInvoice/SaleInvoiceList";
import PurchaseOrderEntry from "./components/PurchaseOrder/PurchaseOrderEntry";
import PurchaseInvoiceEntry from "./components/PurchaseInvoice/PurchaseInvoiceEntry";
import SaleInvoiceEntry from "./components/SaleInvoice/SaleInvoiceEntry";
import SaleOrderEntry from "./components/SaleOrder/SaleOrderEntry";
import PurchaseReturnList from "./components/PurchaseReturn/PurchaseReturnList";
import SaleReturnList from "./components/SaleReturn/SaleReturnList";
import PurchaseReturn from "./components/PurchaseInvoice/PurchaseReturn";
import SaleReturnEntry from "./components/SaleReturn/SaleReturnEntry";
import PurchaseOrderList from "./components/PurchaseOrder/PurchaseOrderList";
import PurchaseInvoiceList from "./components/PurchaseInvoice/PurchaseInvoiceList";
import SaleOrderList from "./components/SaleOrder/SaleOrderList";
import PurchaseInvoiceReport from "./components/PurchaseInvoice/PurchaseInvoiceReport";
import PurchaseOrderReport from "./components/PurchaseOrder/PurchaseOrderReport";
import PurchaseReturnReport from "./components/PurchaseReturn/PurchaseReturnReport";
import SaleInvoiceReport from "./components/SaleInvoice/SaleInvoiceReport";
import SaleReturnReport from "./components/SaleReturn/SaleReturnReport";
import SaleOrderReport from "./components/SaleOrder/SaleOrderReport";

import CustomerLedger from "./components/Reports/SaleReports/CustomerLedger";
import SaleSummaryCustWise from "./components/Reports/SaleReports/SaleSummaryCustWise";
import SaleSummarySalesManWise from "./components/Reports/SaleReports/SaleSummarySalesManWise";
import SaleLedgerItemWise from "./components/Reports/SaleReports/SaleLedgerItemWise";
import SaleMarginCustomerWise from "./components/Reports/SaleReports/SaleMarginCustomerWise";
import SaleReturnLedgerCustWise from "./components/Reports/SaleReports/SaleReturnLedgerCustWise";
import MostSoldItems from "./components/Reports/SaleReports/MostSoldItems";
import MinimumSoldItems from "./components/Reports/SaleReports/MinimumSoldItems";
import DailySaleOfLastMOnth from "./components/Reports/SaleReports/DailySaleOfLastMOnth";
import Receiveable from "./components/Reports/SaleReports/Receiveable";
import SupplierLedgerReport from "./components/Reports/PurchaseReports/SupplierLedgerReport";
import SupplierWiseLedgerReport from "./components/Reports/PurchaseReports/SupplierWiseLedgerReport";
import PurchaseSummaryItemWiseReport from "./components/Reports/PurchaseReports/PurchaseSummaryItemWiseReport";
import PayablesReport from "./components/Reports/PurchaseReports/PayablesReport";
import StockBalanceReport from "./components/Reports/InventoryReports/StockBalanceReport";
import StockWareHouseWiseReport from "./components/Reports/InventoryReports/StockWareHouseWiseReport";
import StockBatchWiseReport from "./components/Reports/InventoryReports/StockBatchWiseReport";
import ShortInventoryItemsReport from "./components/Reports/InventoryReports/ShortInventoryItemsReport";
import MaxInventoryItemsReport from "./components/Reports/InventoryReports/MaxInventoryItemsReport";
import DailyActivityReport from "./components/Reports/PL/DailyActivityReport";
import PLReport from "./components/Reports/PL/PLReport";
import ExpenseReport from "./components/Reports/ExpenseReports/ExpenseReport";
import ExpenseClassificationReport from "./components/Reports/ExpenseReports/ExpenseClassificationReport";
import ExpenseTypeReport from "./components/Reports/ExpenseReports/ExpenseTypeReport";
import CashInHandReport from "./components/Reports/FinanceReports/CashInHandReport";
import CashInBankReport from "./components/Reports/FinanceReports/CashInBankReport";
import Areas from "./components/Area/Areas";
import District from "./components/District/District";
import OrderBooking from "./components/OrderBooking/OrderBooking";
import DeliveryList from "./components/Delivery/DeliveryList";
import DeliveryEntry from "./components/Delivery/DeliveryEntry";

import ProtectedRoute from "./ProtectedRoute";
import Profile from "./components/Profile/Profile";
import CompanyGroupList from "./components/CompanyGroup/CompanyGroupList";
import ProductGroupList from "./components/ProductGroup/ProductGroupList";
import AGMList from "./components/AssignGroupToMarketingStaff/AGMList";
import Scheme from "./components/Scheme/Scheme";
import SchemeEntry from "./components/Scheme/SchemeEntry";
import Driver from "./components/Driver/Driver";
import Vehicle from "./components/Vehicle/Vehicle";

import socketIOClient from "socket.io-client";
import rptDeliverySummary from "./components/Delivery/rptDeliverySummary";
import RptDeliverySummaryWRTInv from "./components/Delivery/rptSaleSummaryWRTInv";
import SaleInvA4 from "./components/SaleInvoice/SaleInvA4";
import CurrentStockReport from "./components/Reports/DistributionReports/CurrentStockReport";
import BatchWiseSaleReport from "./components/Reports/DistributionReports/BatchWiseSaleReport";
import NearExpiryStockReport from "./components/Reports/DistributionReports/NearExpiryStockReport";
import ProductPurchaseLedgerReport from "./components/Reports/DistributionReports/ProductPurchaseLedgerReport";
import ProductSaleLedgerInvoiceWise from "./components/Reports/DistributionReports/ProductSaleLedgerInvoiceWise";
import SaleSummaryReport from "./components/Reports/DistributionReports/SaleSummaryReport";
import AccountLedgerReport from "./components/Reports/DistributionReports/AccountLedgerReport";
import TownWiseSaleReport from "./components/Reports/DistributionReports/TownWiseSaleReport";
import RecoveryAnalysisReport from "./components/Reports/DistributionReports/RecoveryAnalysisReport";
import SaleRegisterReport from "./components/Reports/DistributionReports/SaleRegisterReport";
import SaleReturnRegisterReport from "./components/Reports/DistributionReports/SaleReturnRegisterReport";
import SuppliersReport from "./components/Suppliers/SuppliersReport";
import CustomersReport from "./components/Customers/CustomersReport";
import ManufacturebyReport from "./components/ManufactureBy/ManufacturebyReport";
import CityReport from "./components/City/CityReport";
import ItemsReport from "./components/Items/ItemsReport";
import CustomerWiseSAleRegisterReport from "./components/Reports/DistributionReports/CustomerWiseSAleRegisterReport";
import SaleAndStockReport from "./components/Reports/DistributionReports/SaleAndStockReport";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import BookOrder from "./components/BookOrder/BookOrder";
import UserAccessList from "./components/UserAccess/UserAccessList";
import UserAccess from "./components/UserAccess/UserAccess";
import UserScreens from "./components/UserAccess/UserScreens";
import AddUser from "./components/Security/AddUser";
import Users from "./components/Security/Users";
import BrancheList from "./components/Branches/BrancheList";
import FinYearList from "./components/FinYear/FinYearList";
import ProjectsList from "./components/Projects/ProjectsList";
import IncomeStatment from "./components/Reports/PL/IncomeStatement";
import Medium from "./components/Medium/Medium";
import Groups from "./components/Groups/Groups";
import PendingPurchaseOrderList from "./components/PurchaseOrder/PendingPurchaseOrderList";
import CompanyPayments from "./components/CompanyPayments/CompanyPayments";
import CompanyPaymentList from "./components/CompanyPayments/CompanyPaymentList";
import RecoveryList from "./components/Recovery/RecoveryList";
import RecoveryEntry from "./components/Recovery/RecoveryEntry";
import AreaWiseSaleReport from "./components/Reports/DistributionReports/AreaWiseSaleReport";
import { rptGroupWiseSale } from "./Services/Distribution_ReportAPI";
import GroupWiseSale from "./components/Reports/DistributionReports/GroupWiseSale";
import SaleComparision from "./components/Reports/ComparisionReports/SaleComparision";
import Reason from "./components/Reasons/Reason";
import DiscountPolicy from "./components/DisPolicyForCG/DiscountPolicy";
import DisPolicyForCG from "./components/DisPolicyForCG/DisPolicyForCG";
import SoftwareInvoice from "./components/Login/SoftwareInvoice";
import DiscountClaimTable from "./components/Claims/DiscountClaimTable";
import DiscountClaim from "./components/Claims/DiscountClaim";
import ChangeRate from "./components/ChageRate/ChangeRate";
import AutoMailer from "./components/AutoMailer/AutoMailer";
import {
  SelectGroupModuleList,
  SelectGroupScreensList,
} from "./Services/SecurityAPI";
import WelcomeComponent from "./components/Welcome/WelcomeComponent";
import MarketTaxReport from "./components/Reports/TaxReports/MarketTaxReport";
import SalesTaxReturnReport from "./components/Reports/TaxReports/SalesTaxReturnReport";
import DiscountClaimList from "./components/Claims/DiscountClaimList";
import Tracking from "./components/LiveTracking/Tracking";
import SaleInvPOS from "./components/SaleInvoice/SaleInvPOS";
import CustomerOpeningBalance from "./components/Opening/CustomerOpeningBalance";
import CompanyOpeningBalance from "./components/Opening/CompanyOpeningBalance";
import BankOpeningBalance from "./components/Opening/BankOpeningBalance";
import RptEssentialMedical from "./components/Reports/DistributionReports/rptEssentialMedical";
import BankOpeningList from "./components/Opening/BankOpeningList";
import CompanyOpeningList from "./components/Opening/CompanyOpeningList";
import CustomerOpeningList from "./components/Opening/CustomerOpeningList";
import RptOpening from "./components/Opening/RptOpening";
import AutoPOList from "./components/PurchaseOrder/AutoPOList";
import AutoPOEntry from "./components/PurchaseOrder/AutoPOEntry";

const ENDPOINT = "http://localhost:1330";

function App({ Group_Id }) {
  const [response, setResponse] = useState("");
  const [DASHBOARD, setDASHBOARD] = useState(false);
  // useEffect(() => {
  //   const socket = socketIOClient(ENDPOINT);
  //   debugger
  //   socket.on("chat message", data => {
  //     debugger
  //     setResponse(data);
  //   });
  // }, []);
  useEffect(() => {
    if (Group_Id != null && Group_Id != undefined) {
      SelectGroupModuleList(Group_Id, (GM_Data) => {
        GM_Data.map(async (MValue, MIndex) => {
          if (MValue.Mod_Id === 1) {
            if (MValue.Allow_Access === 1) {
              setDASHBOARD(true);
            } else {
              setDASHBOARD(false);
            }
          }

          await new Promise((resolve) => {
            SelectGroupScreensList(Group_Id, MValue.Mod_Id, (ScreenData) => {
              ScreenData.map((SValue, SIndex) => {
                if (MValue.Mod_Id === 1) {
                  if (SValue.Screen_Id === 1) {
                    if (SValue.Allow_Access === 1) {
                      setDASHBOARD(true);
                    } else {
                      setDASHBOARD(false);
                    }
                  }
                }
              });

              resolve();
            });
          });
        });
      });
    }
  }, [Group_Id]);

  return (
    <>
      <Router>
        <Sidebar />
        <TopBar />

        <Switch>
          <Route component={Logins} path="/" exact />
          <ProtectedRoute
            component={DASHBOARD ? MainContents : WelcomeComponent}
            path="/MainForm"
            exact
          />
          <ProtectedRoute component={UOM} path="/UOMList" exact />
          <ProtectedRoute component={ItemMainType} path="/ItemMainType" exact />
          <ProtectedRoute component={ItemSubType} path="/ItemSubType" exact />
          <ProtectedRoute component={WareHouse} path="/WareHouse" exact />
          <ProtectedRoute component={City} path="/City" exact />
          <ProtectedRoute component={Bank} path="/Bank" exact />
          <ProtectedRoute component={Reason} path="/Reason" exact />
          <ProtectedRoute component={manufacture} path="/manufacture" exact />
          <ProtectedRoute
            component={ExpenseClassification}
            path="/ExpenseClassification"
            exact
          />
          <ProtectedRoute component={ExpenseType} path="/ExpenseType" exact />
          <ProtectedRoute component={ExpenseList} path="/ExpenseList" exact />
          <ProtectedRoute component={CustomerList} path="/CustomerList" exact />
          <ProtectedRoute
            component={SuppliersList}
            path="/SuppliersList"
            exact
          />
          <ProtectedRoute
            component={SupplierEntry}
            path="/SupplierEntry"
            exact
          />
          <ProtectedRoute
            component={CustomerEntry}
            path="/CustomerEntry"
            exact
          />
          <ProtectedRoute component={OpeningList} path="/OpeningList" exact />
          <ProtectedRoute component={Opening} path="/Opening" exact />
          <ProtectedRoute component={BankOpeningList} path="/bank-opening" exact />
          <ProtectedRoute component={BankOpeningBalance} path="/bank-opening-entry" exact />
          <ProtectedRoute component={CompanyOpeningList} path="/company-opening" exact />
          <ProtectedRoute component={CompanyOpeningBalance} path="/company-opening-entry" exact />
          <ProtectedRoute component={CustomerOpeningList} path="/customer-opening" exact />
          <ProtectedRoute component={RptOpening} path="/opening-invoice/:InventoryMasterID/:Comp_Id/:ProjectID" exact />
          <ProtectedRoute
            component={CustomerOpeningBalance}
            path="/customer-opening-entry"
            exact
          />
          <ProtectedRoute
            component={CompanyOpeningBalance}
            path="/CompanyOpening"
            exact
          />
          <ProtectedRoute
            component={BankOpeningBalance}
            path="/BankOpening"
            exact
          />
          <ProtectedRoute component={OpeningEntry} path="/OpeningEntry" exact />
          <ProtectedRoute component={ItemList} path="/ItemList" exact />
          <ProtectedRoute component={ItemEntry} path="/ItemEntry" exact />
          <ProtectedRoute
            component={StockTransferList}
            path="/StockTransferList"
            exact
          />
          <ProtectedRoute
            component={StockTransferEntry}
            path="/StockTransferEntry"
            exact
          />
          <ProtectedRoute
            component={StockAdjustmentList}
            path="/StockAdjustmentList"
            exact
          />
          <ProtectedRoute
            component={StockAdjustmentEntry}
            path="/StockAdjustmentEntry"
            exact
          />
          <ProtectedRoute component={PaymentsList} path={"/Payments"} exact />
          <ProtectedRoute
            component={PaymentEntry}
            path={"/PaymentEntry"}
            exact
          />
          <ProtectedRoute
            component={PaymentPrint}
            path={"/PaymentPrint"}
            exact
          />
          <ProtectedRoute component={ReceiptsList} path={"/Receipts"} exact />
          <ProtectedRoute
            component={ReceiptEntry}
            path={"/ReceiptEntry"}
            exact
          />
          <ProtectedRoute
            component={RecieptPrint}
            path={"/ReceiptPrint"}
            exact
          />
          <ProtectedRoute component={ChangeRate} path={"/BatchRate"} exact />
          <ProtectedRoute
            component={PurchaseInvoiceEntry}
            path="/PurchaseInvoiceEntry"
            exact
          />
          <ProtectedRoute
            component={PurchaseReturnList}
            path="/PurchaseReturnList"
            exact
          />
          <ProtectedRoute
            component={SaleInvoiceEntry}
            path="/SaleInvoiceEntry"
            exact
          />
          <ProtectedRoute
            component={SaleReturnList}
            path="/SaleReturnList"
            exact
          />
          <ProtectedRoute
            component={PurchaseReturn}
            path="/PurchaseReturn"
            exact
          />
          <ProtectedRoute
            component={SaleReturnEntry}
            path="/SaleReturnEntry"
            exact
          />
          <ProtectedRoute
            component={PurchaseOrderList}
            path="/PurchaseOrderList"
            exact
          />
          <ProtectedRoute
            component={PurchaseOrderEntry}
            path="/PurchaseOrderEntry"
            exact
          />
          <ProtectedRoute
            component={AutoPOList}
            path="/autoPoList"
            exact
          />
          
          <ProtectedRoute
            component={AutoPOEntry}
            path="/AutoPoEntry"
            exact
          />
          <ProtectedRoute
            component={PendingPurchaseOrderList}
            path="/PendingPurchaseOrderList"
            exact
          />
          <ProtectedRoute
            component={PurchaseInvoiceList}
            path="/PurchaseInvoiceList"
            exact
          />
          <ProtectedRoute
            component={SaleOrderList}
            path="/SaleOrderList"
            exact
          />
          <ProtectedRoute
            component={SaleOrderEntry}
            path="/SaleOrderEntry"
            exact
          />
          <ProtectedRoute
            component={SaleInvoiceList}
            path="/SaleInvoiceList"
            exact
          />
          <ProtectedRoute
            component={PurchaseInvoiceReport}
            path="/PurchaseReport"
            exact
          />
          <ProtectedRoute
            component={PurchaseOrderReport}
            path="/PurchaseOrderReport"
            exact
          />
          <ProtectedRoute
            component={PurchaseReturnReport}
            path="/PurchaseReturnReport"
            exact
          />
          <ProtectedRoute
            component={SaleOrderReport}
            path="/SaleOrderReport"
            exact
          />
          <ProtectedRoute
            component={SaleInvoiceReport}
            path="/SaleInvoiceReport"
            exact
          />
          <ProtectedRoute
            component={SaleReturnReport}
            path="/SaleReturnReport"
            exact
          />
          <ProtectedRoute component={Areas} path={"/Areas"} exact />
          <ProtectedRoute component={District} path={"/District"} exact />
          <ProtectedRoute component={Medium} path={"/Medium"} exact />
          <ProtectedRoute
            component={OrderBooking}
            path={"/OrderBooking"}
            exact
          />
          <ProtectedRoute component={DeliveryList} path={"/SetSedule"} exact />
          <ProtectedRoute
            component={DeliveryEntry}
            path={"/DeliveryEntry"}
            exact
          />
          <ProtectedRoute component={Profile} path={"/Profile"} exact />
          <ProtectedRoute
            component={CompanyGroupList}
            path={"/CompanyGroup"}
            exact
          />
          <ProtectedRoute
            component={ProductGroupList}
            path={"/ProductGroup"}
            exact
          />
          <ProtectedRoute component={AGMList} path={"/AGM"} />
          <ProtectedRoute component={Scheme} path="/Scheme" />
          <ProtectedRoute component={SchemeEntry} path="/SchemeEntry" />
          <ProtectedRoute component={Driver} path="/Driver" />
          <ProtectedRoute component={Vehicle} path="/Vehicle" />
          <ProtectedRoute component={ControlPanel} path="/ControlPanel" exact />
          <ProtectedRoute component={BookOrder} path="/BookOrder" exact />
          <ProtectedRoute component={Groups} path="/Groups" exact />
          <ProtectedRoute
            component={DiscountClaim}
            path="/DiscountClaim"
            exact
          />
          <ProtectedRoute
            component={CompanyPayments}
            path="/CompanyPayments"
            exact
          />
          <ProtectedRoute
            component={CompanyPaymentList}
            path="/CompanyPaymentList"
            exact
          />
          <ProtectedRoute component={RecoveryList} path="/RecoveryList" exact />
          <ProtectedRoute
            component={RecoveryEntry}
            path="/RecoveryEntry"
            exact
          />
          <ProtectedRoute
            component={DisPolicyForCG}
            path="/DiscountPolicy"
            exact
          />
          <ProtectedRoute component={AutoMailer} path="/AutoMailer" exact />
          <ProtectedRoute component={Tracking} path="/Tracking" exact />
          <ProtectedRoute
            component={SoftwareInvoice}
            path="/SoftwareInvoice/:Comp_Id/:ProjectID"
            exact
          />

          {/* Reports */}
          {/* Sale Reports */}
          <ProtectedRoute
            component={CustomerLedger}
            path={
              "/CustomerLedgerReport/:ProjectID/:FromDate/:ToDate/:FinYear/:CustomerID"
            }
            exact
          />
          <ProtectedRoute
            component={SaleSummaryCustWise}
            path={"/SaleSummaryCustomerWiseReport/:ProjectID/:FromDate/:ToDate"}
            exact
          />
          <ProtectedRoute
            component={SaleSummarySalesManWise}
            path={
              "/SaleSummarySalesManWise/:ProjectID/:FromDate/:ToDate/:User_Id"
            }
            exact
          />
          <ProtectedRoute
            component={SaleLedgerItemWise}
            path={
              "/SaleLedgerItemWise/:ProjectID/:FromDate/:ToDate/:FromItem/:ToItem"
            }
            exact
          />
          <ProtectedRoute
            component={SaleMarginCustomerWise}
            path={
              "/SaleMarginCustomerWise/:ProjectID/:FromDate/:ToDate/:FromCustomer/:ToCustomer"
            }
            exact
          />
          <ProtectedRoute
            component={SaleReturnLedgerCustWise}
            path={"/SaleReturnLedgerCustWise/:ProjectID/:FromDate/:ToDate"}
            exact
          />
          <ProtectedRoute
            component={MostSoldItems}
            path={"/MostSoldItems/:ProjectID/:FromDate/:ToDate/:Num"}
            exact
          />
          <ProtectedRoute
            component={MinimumSoldItems}
            path={"/MinimumSoldItems/:ProjectID/:FromDate/:ToDate/:Num"}
            exact
          />
          <ProtectedRoute
            component={MinimumSoldItems}
            path={"/MinimumSoldItems/:ProjectID/:FromDate/:ToDate/:Num"}
            exact
          />
          <ProtectedRoute
            component={DailySaleOfLastMOnth}
            path={"/DailySaleOfLastMOnth/:ProjectID"}
            exact
          />
          <ProtectedRoute
            component={Receiveable}
            path={"/Receiveable/:ProjectID/:FromDate"}
            exact
          />
          {/* Tax Reports */}
          <ProtectedRoute
            component={MarketTaxReport}
            path={
              "/CompanyTaxes/:ProjectID/:ManufacturedByID/:FromDate/:ToDate"
            }
            exact
          />

          <ProtectedRoute
            component={SalesTaxReturnReport}
            path={
              "/CustomerWiseTaxes/:ProjectID/:ManufacturedByID/:CityID/:CustomerID/:FromDate/:ToDate/:IsFiler/:IsGST/:IsFurther"
            }
            exact
          />
          {/* Purchase Reports */}
          <ProtectedRoute
            component={SupplierLedgerReport}
            path={
              "/SupplierLedgerReport/:ProjectID/:FromDate/:ToDate/:FinYear/:SupplierID/:CGID"
            }
            exact
          />
          <ProtectedRoute
            component={SupplierWiseLedgerReport}
            path={"/SupplierWiseLedgerReport/:ProjectID/:FromDate/:ToDate"}
            exact
          />
          <ProtectedRoute
            component={PurchaseSummaryItemWiseReport}
            path={
              "/PurchaseSummaryItemWiseReport/:ProjectID/:FromDate/:ToDate/:FromItem/:ToItem"
            }
            exact
          />
          <ProtectedRoute
            component={PayablesReport}
            path={"/Payables/:ProjectID/:FromDate/:ToDate"}
            exact
          />

          {/* Inventory Reports */}
          <ProtectedRoute
            component={StockBalanceReport}
            path={"/StockBalanceReport/:ProjectID"}
            exact
          />
          <ProtectedRoute
            component={StockWareHouseWiseReport}
            path={
              "/StockWareHouseWiseReport/:ProjectID/:WareHouseID/:FromItem/:ToItem"
            }
            exact
          />
          <ProtectedRoute
            component={StockBatchWiseReport}
            path={"/StockBatchWiseReport/:ProjectID"}
            exact
          />
          <ProtectedRoute
            component={ShortInventoryItemsReport}
            path={"/ShortInventoryItemsReport/:ProjectID"}
            exact
          />
          <ProtectedRoute
            component={MaxInventoryItemsReport}
            path={"/MaxInventoryItemsReport/:ProjectID"}
            exact
          />

          {/* PL Reports */}
          <ProtectedRoute
            component={DailyActivityReport}
            path={"/DailyActivityReport/:ProjectID/:FromDate/:ToDate"}
            exact
          />
          {/* <ProtectedRoute component={ PLReport } path={"/PLReport/:ProjectID/:FromDate/:ToDate"} exact /> */}
          <Route
            component={IncomeStatment}
            path={"/PLReport/:ProjectID/:FromDate/:ToDate"}
            exact
          />

          {/* Expense Report */}
          <ProtectedRoute
            component={ExpenseReport}
            path={"/ExpenseReport/:ProjectID/:FromDate/:ToDate"}
            exact
          />
          <ProtectedRoute
            component={ExpenseClassificationReport}
            path={
              "/ExpenseClassificationReport/:ProjectID/:FromDate/:ToDate/:ClassificationID"
            }
            exact
          />
          <ProtectedRoute
            component={ExpenseTypeReport}
            path={"/ExpenseTypeReport/:ProjectID/:FromDate/:ToDate/:TypeID"}
            exact
          />

          {/* Finance Report */}
          <ProtectedRoute
            component={CashInHandReport}
            path={"/CashInHandReport/:ProjectID/:FromDate/:ToDate"}
            exact
          />
          <ProtectedRoute
            component={CashInBankReport}
            path={"/CashInBankReport/:ProjectID/:FromDate/:ToDate/:BankID"}
            exact
          />

          {/* Delivery Reports */}
          <ProtectedRoute
            component={rptDeliverySummary}
            path={"/DeliverySummary/:DeliveryID"}
            exact
          />
          <ProtectedRoute
            component={RptDeliverySummaryWRTInv}
            path={"/DeliverySummary/:Comp_Id/:ProjectID/:FromInvNo/:ToInvNo/:DeliveryID"}
            exact
          />
          <ProtectedRoute
            component={SaleInvA4}
            path="/SaleInvA4/:SaleInvoiceID/:InvoiceType"
            exact
          />
          <ProtectedRoute
            component={SaleInvPOS}
            path="/SaleInvPOS/:SaleInvoiceID/:InvoiceType"
            exact
          />

          {/* Distribution Reports */}
          <ProtectedRoute
            component={RptEssentialMedical}
            path="/rptEssentialMedical/:ProjectID/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={CurrentStockReport}
            path="/CurrentStockReport/:ProjectID/:ManufacturedByID"
            exact
          />
          <ProtectedRoute
            component={BatchWiseSaleReport}
            path="/BatchWiseSaleReport/:ProjectID/:ManufacturedByID/:CityID/:AreaID/:ItemID/:BatchNo"
          />
          <ProtectedRoute
            component={NearExpiryStockReport}
            path="/NearExpiryStock/:ProjectID/:ManufactureByID/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={ProductPurchaseLedgerReport}
            path="/ProductPurchaseLedgerReport/:ProjectID/:ItemID/:ItemName/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={ProductSaleLedgerInvoiceWise}
            path="/ProductSaleLedgerInvoiceWise/:ProjectID/:FromDate/:ToDate/:CityID/:CustomerID/:ItemID"
            exact
          />
          <ProtectedRoute
            component={SaleSummaryReport}
            path="/SaleSummaryReport/:ProjectID/:FromDate/:ToDate/:DeliveryID/:DeliveryName"
            exact
          />
          <ProtectedRoute
            component={AccountLedgerReport}
            path="/AccountLedgerRport/:ProjectID/:CGID/:CustomerID/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={TownWiseSaleReport}
            path="/TownWiseReport/:ProjectID/:ManufacturedByID/:CGID/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={RecoveryAnalysisReport}
            path="/RecoveryAnalysisReport/:ProjectID/:CGID/:CGName/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={SaleRegisterReport}
            path="/SaleRegisterReport/:ProjectID/:SaleInvNoFrom/:SaleInvNoTo/:TransactionType"
            exact
          />
          <ProtectedRoute
            component={SaleReturnRegisterReport}
            path="/SaleReturnRegisterReport/:ProjectID/:SaleInvNoFrom/:SaleInvNoTo/:TransactionType"
            exact
          />
          <ProtectedRoute
            component={CustomerWiseSAleRegisterReport}
            path="/CustomerWiseSAleRegisterReport/:ProjectID/:ManufacturedByID/:CGID/:CustomerID/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={SaleAndStockReport}
            path="/SaleAndStockReport/:ProjectID/:ManufacturedByID/:PGID/:FromDate/:ToDate/:SaleRate"
            exact
          />
          <ProtectedRoute
            component={AreaWiseSaleReport}
            path="/AreaWiseReport/:ProjectID/:ManufacturedByID/:PGID/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={GroupWiseSale}
            path="/GroupWiseSale/:ProjectID/:ManufacturedByID/:PGID/:FromDate/:ToDate"
            exact
          />
          <ProtectedRoute
            component={SaleComparision}
            path="/SaleComparision"
            exact
          />
          {/* Definitions Reports */}
          <ProtectedRoute
            component={SuppliersReport}
            path="/SuppliersReport"
            exact
          />
          <ProtectedRoute
            component={CustomersReport}
            path="/CustomersReport"
            exact
          />
          <ProtectedRoute
            component={ManufacturebyReport}
            path="/ManufacturebyReport"
            exact
          />
          <ProtectedRoute component={CityReport} path="/CityReport" exact />
          <ProtectedRoute component={ItemsReport} path="/ItemsReport" exact />

          {/* SECURITY */}

          <ProtectedRoute
            component={UserAccessList}
            exact
            path="/UserAccessList"
          />
          <ProtectedRoute component={UserAccess} exact path="/UserAccess" />
          <ProtectedRoute component={UserScreens} exact path="/UserScreens" />
          <ProtectedRoute component={Users} exact path="/Users" />
          <ProtectedRoute component={AddUser} exact path="/AddUser" />
          <ProtectedRoute component={BrancheList} exact path="/BranchesList" />
          <ProtectedRoute component={FinYearList} exact path="/FinYearList" />
          <ProtectedRoute component={ProjectsList} exact path="/ProjectList" />
          {/* Claim POLICY */}

          <ProtectedRoute
            component={DiscountClaimList}
            exact
            path="/DiscountClaimList"
          />
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
  Comp_Address:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Address,
  User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  User_Info: state.Login_User && state.Login_User.User_Data,
  Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
  Global_Project_List:
    state.GlobalProjectsList && state.GlobalProjectsList.GlobalProjectsList,
  Global_Selected_Project:
    state.Global_Projects && state.Global_Projects.GlobalProjects,
  ProjectID:
    state.Global_Projects.GlobalProjects &&
    state.Global_Projects.GlobalProjects.Project_ID,
});
export default connect(mapStateToProps)(App);
