import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../Services/Common'
import "./DeliveryReports.css"
import { rptDelivery_Summary, rptDelivery_SummaryWRTINvoices } from '../../Services/DeliveryAPI'
import img from '../Images/AlQuraishLogo.jpeg';

// import { SelectUserProjects } from '../../../Services/Projects'

function RptDeliverySummaryWRTInv({Comp_Name,User_Name,User_Id,ConfigObj}) {

    const { Comp_Id,ProjectID,FromInvNo,ToInvNo,DeliveryID } = useParams();
    const [ItemData, setItemData] = useState([]);
    const [GrandTotal, setGrandTotal] = useState(0);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [DeliveryNo, setDeliveryNo] = useState('')
    const [DeliveryDate, setDeliveryDate] = useState('')
    const [InvoiceNo, setInvoiceNo] = useState('')
    const [Sector, setSector] = useState('')

    useEffect(() => {
        document.title = " Distribution / Delivery Summary Invoice Wise"

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptDelivery_SummaryWRTINvoices(Comp_Id,ProjectID,FromInvNo,ToInvNo,DeliveryID,(mData)=>{
            const DeliveryNOAndDate = mData.splice(1)
            const DetailData = DeliveryNOAndDate.splice(1)
            setDeliveryNo(DeliveryNOAndDate[0].SaleInvoiceID.split(':')[1])
            setDeliveryDate(DeliveryNOAndDate[0].City.split(':')[1])
            // setDeliveryNo(DeliveryNOAndDate[0].DeliveryCode)
            // setDeliveryDate(DeliveryNOAndDate[0].DeliveryDate)
            setSector(mData[0].City.split(':')[1])
            setInvoiceNo(mData[0].SaleInvoiceID.split(':')[1])

            if(DetailData.length > 0){
            var mArray = []
            var mGrandTotal = 0;
            var subtotal = 0;
            var count = 1;
            DetailData.map((value, index) =>{
                if(DetailData[index - 1])
                {
                    if(value.Manufacture !== DetailData[index - 1].Manufacture)
                    {
                        count++;
                        mArray.push({SaleInvoiceID:"Total",VALUE:subtotal})
                        subtotal = 0
                        mArray.push({Manufacture: value.Manufacture, title: true})
                        subtotal +=  parseFloat(value.VALUE)

                    }
                    else{
                        subtotal +=  parseFloat(value.VALUE)
                       
                    }
                }
                else
                {
                    mArray.push({Manufacture: value.Manufacture, title: true})
                    subtotal =  parseFloat(value.VALUE)
                }
               
                mArray.push(value)
                mGrandTotal += parseFloat(value.VALUE)
                if(DetailData.length > 1 && index === DetailData.length-1 ){
                    count++;
                    mArray.push({SaleInvoiceID:"Total",VALUE:subtotal})
                    subtotal = 0
                    }
            })
   
            // mArray.map((mvalue,index1)=>{
            //     if(mArray[index1 - 1]){
            //         if(mvalue.Manufacture !== mArray[index1 - 1].Manufacture){
            //             count++;
            //             mArray.push({SaleInvoiceID:"Total",VALUE:subtotal})
            //             subtotal = 0
            //         }
            //         else{
            //             subtotal +=  parseFloat(mvalue.VALUE)
            //         }
            //     }
            // })
            if(count === 1){  //if there is only 1 manufacturer
                mArray.push({SaleInvoiceID:"Total",VALUE:mGrandTotal})
            }
            setItemData(mArray)
            setGrandTotal(mGrandTotal)
            }
        
        })
     
        
        // SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
        // mProjects.map(value=>{
        //     if(value.Project_ID == ProjectID){
        //         setSelectedProject(value)
        //     }
        // })
        // })
        localStorage.removeItem("token")
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
        <Row className='between-rows-margin between-left-margin between-right-margin'>
        <Col sm={3} md={3} xl={3}><img src={img} width="100" height="100" /></Col>
        <Col sm={6} md={6} xl={6} style={{ textAlign: 'center' }}>
            <h3 style={{ textAlign: 'center pt-4' }}> Delivery Stock Summary</h3>
        </Col>
    </Row>
          
              {/* <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'3%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>Delivery No:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {DeliveryNo}
                        </Col>
                    </Row>
                </Col>

                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'3%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>Delivery Date:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {DeliveryDate}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'3%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>Sector:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >   {Sector} </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'3%', }} >
                    <Row>
                        <Col lg={2} md={2} xl={2} >
                         <b>InvNo:</b>
                        </Col>
                       
                        <Col lg={9} md={9} xl={9} style={{ textAlign: 'left'}}>
                        {InvoiceNo}
                        </Col>
                       
                    </Row>
                </Col>
            </Row> */}
            <div style={{ display: 'flex', flexDirection: 'column',border:'2px solid black' }}>
             <div className='container-fluid'>
             <div className='row' style={{borderBottom: '1px solid black'}}>
             <div className='col-2'> <b> Delivery No:</b> </div>
             <div className= 'col-2'> {DeliveryNo} </div>
             
             <div className='col-2'> <b> Delivery Date:</b> </div>
             <div className= 'col-2'> {DeliveryDate} </div>
             <div className= 'col-2'> <b> Sector:</b> </div>
             <div className= 'col-2'> {Sector} </div>
             </div>
             <div className='row' style={{ display: 'flex', flexDirection: 'column',border:'2px solid black' }}>
             <div className='col-2'> <b> Invoice No:</b> </div>
             <div className= 'col-2' style={{ flexGrow: 1}} > 
             {InvoiceNo} </div>

             </div>
             
             </div>
            
            </div>

            <div>Print Date & Time: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}</div>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }}   >Qty</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Product Name</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Packing</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Batch No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >MRP</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Batch Expiry</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Trade Price</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Value</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={8} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.Manufacture}</td>
                                       </tr>
                                    :
                                    Itemdata.SaleInvoiceID === "Total" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',paddingRight:'10px',fontWeight:'bold',borderRight:'2px solid black' }}  >Company Total :</td>
                                        <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {(Itemdata.VALUE).toFixed(2)} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.SaleInvoiceID}</td> {/* qty */}
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.City}</td> {/* product name */}
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.remarks}</td> {/* PAcking */}
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Batchno} </td> {/* batch no */}
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{parseFloat(Itemdata.MRP).toFixed(2)} </td> {/* batch no */}
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ExpairyDate}</td> {/* ExpairyDate */}
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{parseFloat(Itemdata.PakPurRate).toFixed(2)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {parseFloat(Itemdata.VALUE).toFixed(2)} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > Grand Total :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{GrandTotal.toFixed(2)}</td>
                        </tr>
                   
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})

export default connect(mapStateToProps)(RptDeliverySummaryWRTInv)