import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card, Spinner, Container} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print, Loop, CancelOutlined} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { SelectConfigurationData } from '../../Services/ControlPanelAPI'
import { GetCurrentDate, preventMinus } from '../../Services/Common'
import {SelectcustomersList  } from "../../Services/CustomersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTWareHouse,SelectBatchDetails} from '../../Services/StockTransferAPI'
import { SelectSaleOrderWRTCode , SelectSaleOrderDetailWRTCode} from '../../Services/SaleOrderAPI'
import { GetMaxSaleInvoiceNo,SelectSaleInvoiceMaster,SelectSaleInvoiceDetail,SaveSaleInvoice, } from '../../Services/SaleInvoiceAPI'
import { Selectbank } from '../../Services/bankAPI'
import SaleOrderModal from './../SaleOrder/SaleOrderModal'
import { SelectSchemeFromCust ,SelectSchemeDetail } from '../../Services/SchemeAPI'
import { SelectSchemePolicy } from '../../Services/ItemsAPI'
import OrderBookingModal from './OrderBookingModal'
import Loader from '../Loader';
import './InvoicePrint.css'
import { DownloadDoneOutlined } from '@mui/icons-material'
import { clearSaleInvoiceData, removeSalesInvoiceDetail, setSaleInvoiceMaster, setSalesInvoiceDetails, updateSaleInvoice } from '../../React_Redux/Actions'

export class SaleInvoiceEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        SaleInvoiceNo: '',
          CustomerList: [],
          SelectedCustomer: null,
          BankList:[],
          SelectedBank:null,
          Batch_List:[],
          Selected_Batch:null,
          SelectedPaymentType:null,
          SelectedInvoiceType: null,
          InvoiceDate: GetCurrentDate(),
          CreatedDate:GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Selected_item: null,
          Discription: '',
          SI_Detail_Data: [],
          DeletedSI_Detail_Data:[],
          Supplier_Err: '',
          WareHOuse_Err:'',
          Paymenttype_Err:'',
        UOMID:0,
        Bank_Disable_Prop:false,
        Total_Disable_Prop:false,
        Balance_Disable_Prop:false,
          TotalQuantity:0,
            TotalDiscount:0,
            TotalDiscount1:0,
            TotalTaxAmt:0,
            InvoiceAmount:0,
            GrandTotal:0,
            Balance:0,
            TotalPaid:0,
            Discription:'',
            Cash:0,
            ChangeAmt:0,
        // Modal 
        Stock_Modal : false,
        btn_edit : true,
        IsCancel: false,
        OrderBooking_Modal:false,
        // Hide or Show Columns
        DisAmt_Visible: true,
        DisPer_Visible: true,
        SaleTaxAmt_Visible:true,
        SaleTaxPer_Visible:true,
        DeliveryList:[],
        SelectedDelivery:null,
        AddTaxPer:0,
        AddTaxAmt:0,
        IncomeTaxPer:0,
        IncomeTaxAmt:0,
        WHTPer:0,
        WHTAmt:0,
        VATPer:0,
        VATAmt:0,
        OrderBookingLoad:'',
        DeliveryLoad:'',
        item_ScheduleGP:0, btn_Disabled:false,
        cancel_link:this.props.FormDirection === "OrderBooking" ? '/OrderBooking' : '/SaleInvoiceList',

        PaymentType_Err : '',
        InvoiceType_Err: '',
        Bank_Err:'',
        CustomerSchemeID:0,
        SONo:'',
        IsLoader:false,
        SoldValue: 0,
        AmountLimit: 0,
        StartDate: GetCurrentDate(),
        EndDate: GetCurrentDate(),
        _OurShareDisPer:0,
        _IsSchemeApplied:0,
        _OurShareBonusQty:0,
        schemeData: [],
        TotalGSTOnQty: 0,
        TotalGSTOnBonus:0,
        TotalFTaxOnQty: 0,
        TotalFTaxOnBonus : 0
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
      this.Comp_Id = this.props.Comp_Id
      this.ProjectID = this.props.Project_ID
      this.UserID = this.props.p_userid
       this.IsFlatRate = 0
       this._TypeOptions = [
        {value:'Sale Invoice',title:'Sale Invoice'},
        {value:'Estimate Invoice',title:'Estimate Invoice'}

       ]
      this._Options= [
          {value:'Cash Sale',title:'Cash Sale'},
          {value:'Credit Sale',title:'Credit Sale'},
          {value:'Account Sale',title:'Account Sale'},
          {value:'Cash Credit Sale',title:'Cash Credit Sale'},
          {value:'Account Credit Sale',title:'Account Credit Sale'}
        ]
        
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  setInRedux = (e) => {
    const { name, value } = e.target;
    this.props.setSaleInvoiceMaster(name, value);
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }
    showLoader = () => {
        this.setState({IsLoader:true})
      }
      hideLoader = () => {
        this.setState({IsLoader:false})
      }
    componentDidMount() {

        document.title = " Distribution / Sale Invoice"
        // SelectDeliveryForInvoice(this.Comp_Id, this.ProjectID, (mdelivery) => {
            // this.setState({ DeliveryList: mdelivery })
            this.setState({SelectedInvoiceType:this._TypeOptions[0]})
            const e = {
                target:{
                    name:"SelectedInvoiceType",
                    value: this._TypeOptions[0]
                }
            }
            this.setInRedux(e)
            Selectbank(this.Comp_Id, this.ProjectID, (mBank) => {
                this.setState({ BankList: mBank })
                SelectWareHouseList(this.Comp_Id, this.ProjectID, (mWareHouse) => {
                    var DefaultWarehouse;
                    if(this.props.ConfigObj){
                        mWareHouse.map(x=>{
                            if(x.WareHouseID == this.props.ConfigObj.DefaultWareHouseID){
                                DefaultWarehouse = x;
                            }
                        })
                    }
                    if(!DefaultWarehouse){
                        DefaultWarehouse = mWareHouse[0];
                    }
                    this.setState({ WareHouseList: mWareHouse, SelectedWareHouse: DefaultWarehouse, btn_Disabled:false }, () => {
                        this.Select_Item_Details(DefaultWarehouse.WareHouseID)
                    })
                    SelectcustomersList(this.Comp_Id, this.ProjectID, async(mCustomer) => {
                        if(mCustomer.length > 0 ){
                                this.setState({ CustomerList: mCustomer, SelectedCustomer: mCustomer[0],AddTaxPer:mCustomer[0].AddTaxPer,IncomeTaxPer:mCustomer[0].IncomeTaxPer,WHTPer:mCustomer[0].WHTPer },()=>{
                                        this.Calculate_Taxes()
                                })
                        }
                        
                        

                        if (this.props.isAddNew) 
                        {
                            
                            if(this.props.ConfigObj){
                                this._Options.map(y=>{
                                    if(y.title == this.props.ConfigObj.PaymentTypeForINV){
                                        this.setState({ SelectedPaymentType: y})
                                        const e = {
                                            target:{
                                                name:"SelectedPaymentType",
                                                value: y
                                            }
                                        }
                                        this.setInRedux(e)
                                    }
                                })
                                if(this.props.ConfigObj.PaymentTypeForINV == 'Account Sale'){
                                    this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                }
                                else if (this.props.ConfigObj.PaymentTypeForINV == 'Account Credit Sale'){
                                    this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                }
                                else if (this.props.ConfigObj.PaymentTypeForINV == 'Cash Credit Sale'){
                                    this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false,SelectedBank:null})
                                }
                                else if (this.props.ConfigObj.PaymentTypeForINV == 'Cash Sale'){
                                    this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true,SelectedBank:null})
                                }
                            }
                            if(this.props.FormDirection === "OrderBooking"){
                                var _AddTaxPer = 0,_IncomeTaxPer=0,_WHTPer=0,_SchemeID = 0 ;

                                let _bookingCustomer = mCustomer.find(x=> x.CustomerID == this.props.CustomerFromOrderBooking.CustomerID)

                                for(let i=0; i<this.props.OrderBookingData.length; i++){
                                    
                                  await new Promise (resolveForPolicy =>{
                                    SelectSchemePolicy(this.Comp_Id,this.ProjectID,this.props.CustomerFromOrderBooking.CustomerID,this.props.OrderBookingData[i].ItemID,async(mSchemeData)=>{
                                        
                                        let FlatRateData = mSchemeData[0]
                                        let DiscountScheme = mSchemeData[1]
                                        let BonusPolicy = mSchemeData[2]
                                        
                                        if(FlatRateData[0].FRPolicyFound || DiscountScheme[0].DisPolicyFound || BonusPolicy[0].BonusPolicyFound){
                                            this.props.OrderBookingData[i].IsSchemeApplied = 1
                                        }
                                        // Start Batch Work
                                        await new Promise(resolveForBatch=>{
                                            SelectBatchDetails(this.Comp_Id,this.ProjectID,this.props.OrderBookingData[i].ItemID, DefaultWarehouse.WareHouseID,(mItems)=>{ 
                                                if(mItems.length > 0){
                                                   this.props.OrderBookingData[i].BQty= mItems[0].BQty
                                                   this.props.OrderBookingData[i].ExpairyDate =mItems[0].ExpairyDate ? mItems[0].ExpairyDate : ''
                                                   if(FlatRateData[0].FRPolicyFound){
                                                    const FlatRate = FlatRateData[0].FRData[0].ItemPolicyData[0].FlatRate 
                                                    this.props.OrderBookingData[i].SaleRate = FlatRate
                                                    this.props.OrderBookingData[i].MRP = mItems[0].MRP
                                                   }else{
                                                    this.props.OrderBookingData[i].SaleRate = mItems[0].SaleRate
                                                    this.props.OrderBookingData[i].MRP = mItems[0].MRP
                                                   }
                                                  
                                                   this.props.OrderBookingData[i].BatchNo = mItems[0].batchno
                                                }
                                                else{
                                                    this.props.OrderBookingData[i].BQty= 0
                                                   this.props.OrderBookingData[i].ExpairyDate = ''
                                                   this.props.OrderBookingData[i].SaleRate = 0
                                                   this.props.OrderBookingData[i].BatchNo = ''
                                                }
                                                resolveForBatch()
                                            })
                                        })
                                        // End Batch Work
                                        // Start Discount Work 
                                         let normalizedData = this.normalizeData(DiscountScheme);
                                         let DiscountMainArray =normalizedData.ItemPolicyData
                                         if(DiscountMainArray){
                                            DiscountMainArray.sort(function(a, b) {
                                                return a.TargetFromQty - b.TargetFromQty;
                                            });
                                         }
                                       
                                        if(DiscountScheme[0].DisPolicyFound){
                                            if(DiscountScheme[0].DisData){
                                                let StartDate = DiscountMainArray[0].StartDate ;
                                                let EndDate =  DiscountMainArray[0].EndDate;
                                                let MaxLimit =  DiscountMainArray[0].QtyLimit;
                                                let SoldQty =  normalizedData.SoldQty;
                                                this.props.OrderBookingData[i].SoldValue = normalizedData.SoldValue;
                                                this.props.OrderBookingData[i].AmountLimit =  DiscountMainArray[0].AmountLimit;
                                                this.props.OrderBookingData[i].StartDate = DiscountMainArray[0].StartDate ;
                                                this.props.OrderBookingData[i].EndDate = DiscountMainArray[0].EndDate
                                                this.props.OrderBookingData[i].OurShareDisPer = DiscountMainArray[0].OurSharePer || 0;
                                                let today = GetCurrentDate()
                                                let PolicyArray = []
                                                if(today >= StartDate && today <= EndDate){
                                                     if(SoldQty <= MaxLimit  || MaxLimit === 0 ){
                                                        PolicyArray = DiscountMainArray.find(obj => obj.TargetFromQty <= this.props.OrderBookingData[i].Qty  && obj.Criteria >= this.props.OrderBookingData[i].Qty );
                                                        if(PolicyArray){
                                                            this.props.OrderBookingData[i].DisPer= PolicyArray.Discount1Per
                                                            this.props.OrderBookingData[i].DisPer1 = PolicyArray.Discount2Per   
                                                        }else{
                                                            let maxid =  DiscountMainArray[DiscountMainArray.length-1].SchemeID;
                                                            const minCriteria = DiscountMainArray[0].TargetFromQty;
                                                            let chkmaxCriteria = DiscountMainArray[DiscountMainArray.length-1].Criteria;
                                                            let maxDisQty 
                                                            let maxDis2Qty
                                                            if(DiscountMainArray.length > 1){
                                                                maxDisQty = DiscountMainArray[DiscountMainArray.length-2].Discount1Per; // pick from 2nd last
                                                                maxDis2Qty = DiscountMainArray[DiscountMainArray.length-2].Discount2Per;
                                                            }else{
                                                                maxDisQty = DiscountMainArray[DiscountMainArray.length-1].Discount1Per; // pick from 2nd last
                                                                maxDis2Qty = DiscountMainArray[DiscountMainArray.length-1].Discount2Per;
                                                           }
                                                           PolicyArray =   DiscountMainArray.find(obj => obj.SchemeID === maxid);
                                                           if( this.props.OrderBookingData[i].Qty  <=minCriteria){
                                                            this.props.OrderBookingData[i].DisPer= 0
                                                              this.props.OrderBookingData[i].DisPer1 =0
                                                           }else{
                                                                if(chkmaxCriteria === 0){
                                                                    this.props.OrderBookingData[i].DisPer= maxDisQty
                                                                      this.props.OrderBookingData[i].DisPer1 =maxDis2Qty
                                                                }else if(chkmaxCriteria === 1){
                                                                    var mDisQty = Math.floor(this.props.OrderBookingData[i].Qty  /  PolicyArray.TargetFromQty) 
                                                                    this.props.OrderBookingData[i].DisPer= mDisQty * maxDisQty
                                                                      this.props.OrderBookingData[i].DisPer1 =mDisQty * maxDis2Qty
                                                                }else{
                                                                    this.props.OrderBookingData[i].DisPer= maxDisQty
                                                                      this.props.OrderBookingData[i].DisPer1 =maxDis2Qty
                                                                }
                                                           }
                                                        }
                                                     
                                                     }else{
                                                    this.props.OrderBookingData[i].DisPer= 0
                                                     this.props.OrderBookingData[i].DisPer1 = 0
                                                     }
                                                    
                                                }else{
                                                this.props.OrderBookingData[i].DisPer = 0
                                                 this.props.OrderBookingData[i].DisPer1 =0
                                                }
                                            }else{
                                            this.props.OrderBookingData[i].DisPer = 0
                                             this.props.OrderBookingData[i].DisPer1 = 0
                                            }
                                            
                                        }else{
                                        // this.props.OrderBookingData[i].DisPer = 0
                                        //  this.props.OrderBookingData[i].DisPer1 = 0
                                        }
                                        //End Discount Work
                                        // Start Bonus Work
                                         let normalData = this.normalizeData(BonusPolicy);
                                         let BonusPolicyMain = normalData.ItemPolicyData 
                                      
                                      if(BonusPolicyMain){
                                        BonusPolicyMain.sort(function(a, b) {
                                            return a.TargetFromQty - b.TargetFromQty;
                                        });
                                      }
                                       
                                        if(BonusPolicy[0].BonusPolicyFound){
                                            if(BonusPolicy[0].BonusData){
                                                let StartDate = BonusPolicyMain[0].StartDate ;
                                                let EndDate =  BonusPolicyMain[0].EndDate;
                                                let today = GetCurrentDate()
                                                let PolicyArray = []
                                                this.props.OrderBookingData[i].OurShareBonusQty = BonusPolicyMain[0].OurSharePer
                                                if(today >= StartDate && today <= EndDate){
                                                    PolicyArray = BonusPolicyMain.find(obj => obj.TargetFromQty <= this.props.OrderBookingData[i].Qty   && obj.Criteria >= this.props.OrderBookingData[i].Qty  );
                                                    if(PolicyArray){
                                                        this.props.OrderBookingData[i].BonusQty = PolicyArray.BonusQty
                                                    }else{
                                                        let maxid =  BonusPolicyMain[BonusPolicyMain.length-1].SchemeID;
                                                        const minCriteria = BonusPolicyMain[0].TargetFromQty;
                                                        let chkmaxCriteria = BonusPolicyMain[BonusPolicyMain.length-1].Criteria;
                                                        let maxBonusQty
                                                        let maxBonusCriteria 
                                                        if(BonusPolicyMain.length > 1){
                                                            maxBonusQty = BonusPolicyMain[BonusPolicyMain.length-2].BonusQty; // pick from 2nd last
                                                            maxBonusCriteria = BonusPolicyMain[BonusPolicyMain.length-2].Criteria
                                                        }else{
                                                            maxBonusQty = BonusPolicyMain[BonusPolicyMain.length-1].BonusQty; // pick from 2nd last
                                                            maxBonusCriteria = BonusPolicyMain[BonusPolicyMain.length-1].Criteria
                                                       }  
                                                       PolicyArray =   BonusPolicyMain.find(obj => obj.SchemeID === maxid);
                                                       if(this.props.OrderBookingData[i].Qty  <= minCriteria){ // its means Qty not found in Range
                                                        this.props.OrderBookingData[i].BonusQty =  0
                                                        this.props.OrderBookingData[i].BonusCriteria = 0 
                                                    }else{
                                                        if(chkmaxCriteria === 0){  // means Above false --> Not Duplicate or give extra bonus WRT qty
                                                            this.props.OrderBookingData[i].BonusQty =   maxBonusQty
                                                            this.props.OrderBookingData[i].BonusCriteria = maxBonusCriteria 
                                                        }else if(chkmaxCriteria === 1){ // means Above true --> give extra bonus WRT qty
                                                            var mBonusQty = Math.floor(this.props.OrderBookingData[i].Qty /  PolicyArray.TargetFromQty) 
                                                             this.props.OrderBookingData[i].BonusQty = mBonusQty * maxBonusQty
                                                            this.props.OrderBookingData[i].BonusCriteria = maxBonusCriteria 
                                                        } else {
                                                             this.props.OrderBookingData[i].BonusQty =   maxBonusQty
                                                            this.props.OrderBookingData[i].BonusCriteria = maxBonusCriteria  
                                                        }
            
                                                    }
                                                    }
                                                  
                                                }else{
                                                   this.props.OrderBookingData[i].BonusQty = 0
                                                   this.props.OrderBookingData[i].BonusCriteria = 0 
                                                }
                                            }else{
                                               this.props.OrderBookingData[i].BonusQty = 0
                                               this.props.OrderBookingData[i].BonusCriteria = 0
                                            }
                                        }else{
                                        //    this.props.OrderBookingData[i].BonusQty = 0
                                        //    this.props.OrderBookingData[i].BonusCriteria = 0
                                        }
                                        resolveForPolicy()
                                        //End Bonus Work
                                    })
                                  })
                                 
                                      this.props.OrderBookingData[i].SrNo  = i + 1;
                                      this.props.OrderBookingData[i].DisAmt = parseFloat((((this.props.OrderBookingData[i].Qty * this.props.OrderBookingData[i].SaleRate)/ 100) *  this.props.OrderBookingData[i].DisPer || 0).toFixed(2))
                                    //  this.props.OrderBookingData[i].TaxAmt = parseFloat((((this.props.OrderBookingData[i].Qty * this.props.OrderBookingData[i].SaleRate) / 100) *  this.props.OrderBookingData[i].TaxPer).toFixed(2))
                                      const GSTQty = this.props.OrderBookingData[i].GST > 0 ? this.props.OrderBookingData[i].Qty + this.props.OrderBookingData[i].BonusQty :this.props.OrderBookingData[i].Qty;
                                   
                                      if(_bookingCustomer.SaleTaxType  == 'NOT REGISTER WITH GST' && this.props.OrderBookingData[i].GST > 0 ){
                                      
                                        this.props.OrderBookingData[i].FTaxPer = 3 // hard coding 
                                        this.props.OrderBookingData[i].FTaxAmt = parseFloat((((GSTQty * this.props.OrderBookingData[i].MRP) / 100) *  this.props.OrderBookingData[i].FTaxPer).toFixed(2))

                                      }else{
                                        this.props.OrderBookingData[i].FTaxPer = 0
                                        this.props.OrderBookingData[i].FTaxAmt = 0
                                      }
                                      this.props.OrderBookingData[i].GSTOnQty = parseFloat((((this.props.OrderBookingData[i].Qty  * this.props.OrderBookingData[i].MRP) / 100) *  this.props.OrderBookingData[i].GST).toFixed(2));
                                      this.props.OrderBookingData[i].GSTOnBonus = parseFloat((((this.props.OrderBookingData[i].BonusQty  * this.props.OrderBookingData[i].MRP) / 100) *  this.props.OrderBookingData[i].GST).toFixed(2));
                                      this.props.OrderBookingData[i].FTaxOnQty = parseFloat((((this.props.OrderBookingData[i].Qty  * this.props.OrderBookingData[i].MRP) / 100) *  this.props.OrderBookingData[i].FTaxPer).toFixed(2));
                                      this.props.OrderBookingData[i].FTaxOnBonus = parseFloat((((this.props.OrderBookingData[i].BonusQty  * this.props.OrderBookingData[i].MRP) / 100) *  this.props.OrderBookingData[i].FTaxPer).toFixed(2));

                                      if(this.state.SaleTaxPer_Visible){
                                        this.props.OrderBookingData[i].TaxPer = this.props.OrderBookingData[i].GST
                                        }
                                        else{
                                            this.props.OrderBookingData[i].TaxPer = 0
                                        }

                                      this.props.OrderBookingData[i].TaxAmt = parseFloat((((GSTQty * this.props.OrderBookingData[i].MRP) / 100) *  this.props.OrderBookingData[i].GST).toFixed(2))
                                      this.props.OrderBookingData[i].TaxAmt  = this.props.OrderBookingData[i].TaxAmt  ? this.props.OrderBookingData[i].TaxAmt  : 0
                                      this.props.OrderBookingData[i].Value = parseFloat(((((this.props.OrderBookingData[i].Qty * this.props.OrderBookingData[i].SaleRate) - this.props.OrderBookingData[i].DisAmt) +  this.props.OrderBookingData[i].TaxAmt   + this.props.OrderBookingData[i].FTaxAmt)).toFixed(2))
                                      this.props.OrderBookingData[i].DisAmt1 =  parseFloat((( this.props.OrderBookingData[i].Value / 100 ) * this.props.OrderBookingData[i].DisPer1 || 0 ).toFixed(2))
                                      this.props.OrderBookingData[i].Value = parseFloat((this.props.OrderBookingData[i].Value - this.props.OrderBookingData[i].DisAmt1 ).toFixed(2))
                                      this.props.OrderBookingData[i].action = 'add'
                                      
                                }
                           
                                this.setState({CustomerSchemeID:_SchemeID,SelectedCustomer:_bookingCustomer,SI_Detail_Data:this.props.OrderBookingData,AddTaxPer:_bookingCustomer.AddTaxPer,IncomeTaxPer:_bookingCustomer.IncomeTaxPer,WHTPer:_bookingCustomer.WHTPer},()=>{
                                    this.Calculate_Values()
                                })
                            }
                            
                            this.GetMax_SaleInvoiceNo() 
                            // this.setState({ SelectedPaymentType: {value:'Credit Sale',title:'Credit Sale'}, Bank_Disable_Prop: true, Total_Disable_Prop: true, Balance_Disable_Prop: true })
                        }
                        else // update
                        {
                            this.showLoader()
                            
                            SelectSaleInvoiceMaster(this.Comp_Id, this.ProjectID, this.props.SaleInvoiceID,this.props.InvoiceType, (mData) => {
                               
                                this.hideLoader()
                                this.setState({
                                    SaleInvoiceNo: mData[0].SaleInvoiceNo,
                                    InvoiceDate: mData[0].InvoiceDate,
                                    Discription: mData[0].Discription,
                                    TotalDiscount: mData[0].TotalDiscount,
                                    TotalQuantity: mData[0].TotalQuantity,
                                    InvoiceAmount: mData[0].InvoiceAmount,
                                    GrandTotal: mData[0].GrandTotal,
                                    Cash: mData[0].Cash,
                                    ChangeAmt: mData[0].ChangeAmt,
                                    TotalPaid: mData[0].TotalPaid,
                                    Balance: mData[0].Balance,
                                    SelectedPaymentType: this._Options.find(x => x.value === mData[0].PaymentType),
                                    btn_edit: this.props.show_screen.edit,
                                    AddTaxPer: mData[0].AddTaxPer,
                                    AddTaxAmt: mData[0].AddTaxAmt,
                                    IncomeTaxPer: mData[0].IncomeTaxPer,
                                    IncomeTaxAmt: mData[0].IncomeTaxAmt,
                                    WHTPer: mData[0].WHTPer,
                                    WHTAmt: mData[0].WHTAmt,
                                    TotalTaxAmt: mData[0].TotalGST,
                                    VATPer: mData[0].VATPer,
                                    VATAmt: mData[0].VATAmt,
                                    TotalGSTOnQty: mData[0].TotalGSTOnQty,
                                    TotalGSTOnBonus:mData[0].TotalGSTOnBonus,
                                    TotalFTaxOnQty: mData[0].TotalFTaxOnQty,
                                    TotalFTaxOnBonus : mData[0].TotalFTaxOnBonus
                                })

                                if (mData[0].PaymentType == "Account Credit Sale" || mData[0].PaymentType == "Account Sale" ) {
                                    this.setState({ Bank_Disable_Prop: false, Total_Disable_Prop: false, Balance_Disable_Prop: false })
                                }
                                else {
                                    this.setState({ Bank_Disable_Prop: true, Total_Disable_Prop: true, Balance_Disable_Prop: true })
                                }
                                this._TypeOptions.map(val=>{
                                    if(val.value === mData[0].InvoiceType){
                                        this.setState({SelectedInvoiceType:val})
                                    }
                                })
                                this.Select_Item_Details(mData[0].WareHouseID)
                                mWareHouse.map(val => {
                                    if (val.WareHouseID === mData[0].WareHouseID) {
                                        this.setState({ SelectedWareHouse: val })
                                    }
                                })
                                mBank.map(val => {
                                    if (val.BankID === mData[0].BankID) {
                                        this.setState({ SelectedBank: val })
                                    }
                                })
                                mCustomer.map(async val => {
                                    if (val.CustomerID === mData[0].CustomerID) {
                                    //     var SchemeID = 0;
                                    //     await new Promise(resolve=>{
                                    //     this.Select_SchemeFromCustomer(val.CustomerID,(mSchemeData)=>{
                                    //        if(mSchemeData.length > 0){
                                    //         SchemeID = mSchemeData[0].mSchemeData
                                    //        }
                                    //         resolve()
                                    //     })
                                    // })
                                        // this.setState({ SelectedCustomer: val,CustomerSchemeID:SchemeID })
                                        this.setState({ SelectedCustomer: val })
                                    }
                                })
                                this.showLoader()
                                SelectSaleInvoiceDetail(this.Comp_Id, this.ProjectID, this.props.SaleInvoiceID,this.props.InvoiceType, async(mDetailData) => {
                                    this.hideLoader()
                                    for(let j = 0; j<mDetailData.length; j++){
                                        mDetailData[j].SrNo = j + 1
                                        mDetailData[j].BQty = 0
                                        await new Promise(resolveForBatchQTy=>{
                                            SelectBatchDetails(this.Comp_Id,this.ProjectID,mDetailData[j].ItemID,DefaultWarehouse.WareHouseID,(mItemsBatchQty)=>{ // Api
                                                
                                                for(let i =0; i< mItemsBatchQty.length; i++){
                                                       if((mDetailData[j].BatchNo).trim() === (mItemsBatchQty[i].batchno).trim()){
                                                            mDetailData[j].BQty = mItemsBatchQty[i].BQty
                                                            break
                                                       }
                                                }

                                                   
                                                resolveForBatchQTy()
                                            })
                                        })
                                       
                                        mDetailData[j].BQty += mDetailData[j].Qty + mDetailData[j].BonusQty
                                    }
                                   
                                    
                                    this.setState({ SI_Detail_Data: mDetailData }, () => {
                                        this.Calculate_Values(mData[0].VATAmt)
                                    })
                                })
                            })
                        }

                    })
                })
            })
        // })
        // Sale Tax Column hide
        SelectConfigurationData(this.Comp_Id, (mConfigData) => {
            if (mConfigData[0].ApplyTax_on_SaleInvoice === 1) {
                this.setState({
                    DisAmt_Visible: mConfigData[0].IsDisAmt === 1 ? true : false,
                    DisPer_Visible: mConfigData[0].IsDisPer === 1 ? true : false,
                    SaleTaxAmt_Visible: mConfigData[0].IsSaleTaxAmt === 1 ? true : false,
                    SaleTaxPer_Visible: mConfigData[0].IsSaleTaxPer === 1 ? true : false,
                }, () => {
                    this.grid.refresh()
                })
            }

        })
    }
     normalizeData(data) {
        let normalizedData = {};
        if(data[0].DisData){
            let disData = data[0].DisData;
            if (disData.ItemPolicyData) {
                normalizedData.ItemPolicyData = disData.ItemPolicyData;
                normalizedData.SoldQty = disData.SoldQty;
                normalizedData.SoldValue = disData.SoldValue;
            } else if (Array.isArray(disData) && disData[0].ItemPolicyData) {
                normalizedData.ItemPolicyData = disData[0].ItemPolicyData;
                normalizedData.SoldQty = disData[0].SoldQty;
                normalizedData.SoldValue = disData[0].SoldValue;
            }
        } else if(data[0].BonusData){
            let BonusData = data[0].BonusData;
            if (BonusData.ItemPolicyData) {
                normalizedData.ItemPolicyData = BonusData.ItemPolicyData;
                normalizedData.SoldQty = BonusData.SoldQty;
                normalizedData.SoldValue = BonusData.SoldValue;
                } else if (Array.isArray(BonusData) && BonusData[0].ItemPolicyData){
                    normalizedData.ItemPolicyData = BonusData[0].ItemPolicyData;
                    normalizedData.SoldQty = BonusData[0].SoldQty;
                    normalizedData.SoldValue = BonusData[0].SoldValue;
                }
        }
       
        return normalizedData;
    }
    Select_Item_Details = (WareHouseID) =>{
        SelectItemsWRTWareHouse(this.Comp_Id,this.ProjectID,WareHouseID,(mItems)=>{ // Api
          
            this.setState({Item_List:mItems})
        })
    }
    Select_BatchDetails = (ItemID) =>{
        SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
            this.setState({Batch_List:mItems})
        })
    }
    Show_Min_Stock_Modal = ()=>{
        this.setState({Stock_Modal:true})
    }
    Hide_Min_Stock_Modal = ()=>{
        this.setState({Stock_Modal:false, isCancel: true})
    }
    IsCancel = () =>{
        this.setState({isCancel: false})
    }
    GetMax_SaleInvoiceNo = () => {
            this.showLoader()
            
        GetMaxSaleInvoiceNo(this.Comp_Id,this.ProjectID,'INV',this.state.SelectedInvoiceType.value ,(mSaleInvoiceNo) => { 
            this.hideLoader()
                this.setState({ SaleInvoiceNo: mSaleInvoiceNo })  
                const e = {
                    target: {
                      name: "SaleInvoiceNo",
                      value: mSaleInvoiceNo,
                    },
                  }
                  const e2 = {
                    target: {
                      name: "InvoiceDate",
                      value: GetCurrentDate(),
                    },
                  }
                  const e3 = {
                    target: {
                      name: "SelectedCustomer",
                      value: this.state.CustomerList[0],
                    },
                  }
            
                  this.setInRedux(e)
                  this.setInRedux(e2)
                  this.setInRedux(e3)
            })
    }
     customSort(obj1) {
        if (obj1["DefaultBatchNo"] !== "0" && obj1["DefaultBatchNo"] === obj1["batchno"]) {
            return -1; 
        }
        else {
            return 1; 
        }
    }
    BatchNO_Auto = null
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemID === rowValue.ItemID)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        this.showLoader()
                        this.setState({ Selected_item: value },()=>{
                            document.getElementById('UOM').value = value.uom
                            document.getElementById('TaxPer').value = value.GST
                            document.getElementById('DefaultBatchNo').value = value.DefaultBatchNo
                            this.setState({ Selected_item: value,UOMID:value.UOMID })
                            this.Select_Batch_Detail(value.ItemID,this.state.SelectedWareHouse.WareHouseID,true)
                        }) 
                    }
                    else {
                        var BatchNo_select = document.getElementById('BatchNo')
                        var length = BatchNo_select.options.length;
                        for (let i = length - 1; i >= 0; i--) {
                            BatchNo_select.options[i] = null;
                        } 
                        document.getElementById('BQty').value = 0
                        document.getElementById('SaleRate').value = 0
                        document.getElementById('MRP').value = 0
                        document.getElementById('value').value = 0
                        document.getElementById('DefaultBatchNo').value = 0
                        document.getElementById('ExpairyDate').value = ''
                        this.setState({ Selected_item: null,Batch_List:[],Selected_Batch:null })
                        document.getElementById('UOM').value = ''
                        if(this.state.DisPer_Visible){
                            document.getElementById('DisPer').value = 0
                            document.getElementById('DisPer1').value = 0
                            document.getElementById('DisAmt').value = 0
                            document.getElementById('DisAmt1').value = 0
                        }
                        if(this.state.SaleTaxPer_Visible){
                            document.getElementById('TaxPer').value = 0
                            document.getElementById('TaxAmt').value = 0 
                            document.getElementById('FTaxPer').value = 0
                            document.getElementById('FTaxAmt').value = 0

                        }
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
   

    Select_Batch_Detail = (ItemID, WareHouseID,callScheme) =>{
        SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,WareHouseID,async(mItems)=>{ // Api
            mItems.forEach(obj => {
                for (let key in obj) {
                  if (typeof obj[key] === 'number') {
                    obj[key] = parseFloat(obj[key].toFixed(2));
                  }
                }
              });
              mItems.sort(this.customSort);
         SelectSchemePolicy(this.Comp_Id, this.ProjectID,this.state.SelectedCustomer.CustomerID ,ItemID,(mSchemeData)=>{
           this.setState({schemeData: mSchemeData})
            const FRData = mSchemeData[0]
            var BatchNo_select = document.getElementById('BatchNo')
            var length = BatchNo_select.options.length;
            for (let i = length - 1; i >= 0; i--) {
                BatchNo_select.options[i] = null;
            }
            for (let i = 0; i < mItems.length; i++) {
                BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
            }
            BatchNo_select.selectedIndex = 0
            if(mItems.length > 0){
                
                // mmm.value=  mItems[0].batchno 
                // document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
                document.getElementById('BQty').value = mItems[0].BQty
                //Flat Rate Work
                if(FRData[0].FRPolicyFound){

                   const FlatRate = FRData[0]?.FRData[0]?.ItemPolicyData?.[0]?.FlatRate || FRData[0]?.FRData?.ItemPolicyData?.[0]?.FlatRate;
                    document.getElementById('SaleRate').value = FlatRate
                    document.getElementById('MRP').value = mItems[0].MRP
                    document.getElementById('value').value = FlatRate
                }else{
                    document.getElementById('SaleRate').value = mItems[0].PackSaleRate
                    document.getElementById('MRP').value = mItems[0].MRP
                    document.getElementById('TaxPer').value = mItems[0].GST
                    document.getElementById('value').value = mItems[0].PackSaleRate
                }
               
                document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
                this.setState({Batch_List:mItems,Selected_Batch:mItems[0]})
            }
            else{
                document.getElementById('BQty').value = 0
                document.getElementById('SaleRate').value = 0
                document.getElementById('value').value = 0
                document.getElementById('ExpairyDate').value = 0
               
                this.setState({Batch_List:[],Selected_Batch:null})
            }
            //End Flat Rate Work
            
            const DiscountScheme = mSchemeData[1]
            
            let normalizedData = this.normalizeData(DiscountScheme);
            let DiscountMainArray =normalizedData.ItemPolicyData
           
            if(DiscountMainArray){
                DiscountMainArray.sort(function(a, b) {
                    return a.TargetFromQty - b.TargetFromQty;
                });
            }
           
            if(DiscountScheme[0].DisPolicyFound){
                if(DiscountScheme[0].DisData){
                    let StartDate = DiscountMainArray[0].StartDate ;
                    let EndDate =  DiscountMainArray[0].EndDate;
                    let MaxLimit =  DiscountMainArray[0].QtyLimit;
                    let AmountLimit = DiscountMainArray[0].AmountLimit
                    const OurShareDisPer = DiscountMainArray[0].OurSharePer || 0;
                    let SoldQty =  normalizedData.SoldQty;
                    let SoldValue = normalizedData.SoldValue ? normalizedData.SoldValue : 0;
                    let today = GetCurrentDate()
                    let Qty = document.getElementById('Qty').value;
                    let PolicyArray = []
                    if(today >= StartDate && today <= EndDate){
                        // if(this.state.DisPer_Visible){
                            if(SoldQty <= MaxLimit || MaxLimit === 0){
                                PolicyArray = DiscountMainArray.find(obj => obj.TargetFromQty <= Qty && obj.Criteria >= Qty);
                                if(PolicyArray){
                                    document.getElementById('DisPer').value = PolicyArray.Discount1Per 
                                    document.getElementById('DisPer1').value = PolicyArray.Discount2Per
                                }else{
                                    let maxid =  DiscountMainArray[DiscountMainArray.length-1].SchemeID;
                                        const minCriteria = DiscountMainArray[0].TargetFromQty;
                                        let chkmaxCriteria = DiscountMainArray[DiscountMainArray.length-1].Criteria;
                                        let maxDisQty 
                                        let maxDis2Qty
                                        if(DiscountMainArray.length > 1){
                                            maxDisQty = DiscountMainArray[DiscountMainArray.length-2].Discount1Per; // pick from 2nd last
                                            maxDis2Qty = DiscountMainArray[DiscountMainArray.length-2].Discount2Per;
                                        }else{
                                            maxDisQty = DiscountMainArray[DiscountMainArray.length-1].Discount1Per; // pick from 2nd last
                                            maxDis2Qty = DiscountMainArray[DiscountMainArray.length-1].Discount2Per;
                                       }
                                       PolicyArray =   DiscountMainArray.find(obj => obj.SchemeID === maxid);
                                   
                                       if(Qty <=minCriteria){
                                        document.getElementById('DisPer').value = 0
                                        document.getElementById('DisPer1').value =0
                                       }else{
                                            if(chkmaxCriteria === 0){
                                                document.getElementById('DisPer').value = maxDisQty
                                                document.getElementById('DisPer1').value =maxDis2Qty
                                            }else if(chkmaxCriteria === 1){
                                                var mDisQty = Math.floor(Qty /  PolicyArray.TargetFromQty) 
                                                document.getElementById('DisPer').value = mDisQty * maxDisQty
                                                document.getElementById('DisPer1').value =mDisQty * maxDis2Qty
                                            }else{
                                                document.getElementById('DisPer').value = maxDisQty
                                                document.getElementById('DisPer1').value =maxDis2Qty
                                            }
                                       }
                                }
                              
                            
                            }else{
                                document.getElementById('DisPer').value = 0
                                document.getElementById('DisPer1').value =0
                              
                            }
                        // }
                    
                    }else{
                        document.getElementById('DisPer').value = 0
                        document.getElementById('DisPer1').value =0
                      
                    }
                    this.setState({SoldValue: SoldValue, AmountLimit: AmountLimit, StartDate: StartDate, EndDate: EndDate, _OurShareDisPer:OurShareDisPer,_IsSchemeApplied:1})
                }else{
                    document.getElementById('DisPer').value = 0
                    document.getElementById('DisPer1').value =0
                 
                }
                
            }else{
                // document.getElementById('DisPer').value = 0
                // document.getElementById('DisPer1').value =0 
            }
            
            this.Calculate_Total(mSchemeData)
         })
            
            
            this.hideLoader()
        })
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = {  ( e) => {
                 this.Calculate_Total(this.state.schemeData)
            }}
            min={1}
            onKeyPress={e=>preventMinus(e)}
        />)
    }
    BonusQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            defaultValue={rowValue.BonusQty}
            onChange = { e => {
                this.Calculate_Total()
            }}
            min={0}
            onKeyPress={e=>preventMinus(e)}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
  
    BatchNo = (rowValue) => {
        return (<select id="BatchNo" name="BatchNo" 
            style={{ fontSize: 17, textAlign: "right", width: "100%" }}
            onChange={e => {
                var Qty = 0;
                this.state.Batch_List.find(value => {
                    if (e.target.value == value.batchno) {
                        document.getElementById('ExpairyDate').value = value.ExpairyDate
                        document.getElementById('BQty').value =value.BQty
                        document.getElementById('SaleRate').value =value.PackSaleRate
                        document.getElementById('TaxPer').value = value.GST
                        document.getElementById('MRP').value = value.MRP
                        document.getElementById('value').value = rowValue.Qty * value.PackSaleRate
                        this.setState({ Selected_Batch: value })
                    }
                })
            }}
            className="textbox" ></select>)

    }
    // BatchNO_Auto = null
    // BatchNo = (rowValue) => {
      
    //     this.BatchNO_Auto = 
    //         <Autocomplete
    //             name="BatchNo"
    //             id="BatchNo"
    //             defaultValue={rowValue.BatchNo ? { batchno: rowValue.BatchNo } : ''}
    //             // defaultValue={ _defaultValue }
    //             options={this.state.Batch_List}
    //             getOptionLabel={(option) => option.batchno}
    //             getOptionSelected={(option, value) => {
    //                 //nothing that is put in here will cause the warning to go away
    //                 if (value === "") {
    //                     return true;
    //                 }
    //                 else if (value.BatchNo === option.batchno) {
    //                     return true;
    //                 }
    //             }}
    //             onChange={(e, value) => {
    //                 if (value) {
    //                     this.setState({ Selected_Batch: value })
    //                     document.getElementById('ExpairyDate').value = value.ExpairyDate
    //                     document.getElementById('BQty').value = value.BQty
    //                     document.getElementById('SaleRate').value = value.SaleRate
    //                     document.getElementById('value').value = rowValue.Qty * value.SaleRate
    //                 }
    //                 else {
    //                     this.setState({ Selected_Batch: null })
    //                     document.getElementById('ExpairyDate').value = ''
    //                     document.getElementById('BQty').value = ''
    //                     document.getElementById('SaleRate').value = ''
    //                     document.getElementById('value').value = ''
    //                 }
    //             }}
    //             renderInput={(params) =>
    //                 <TextField  {...params} name="BatchNo" id='BatchNo' />}
    //         />
    //     return (
    //         this.BatchNO_Auto
    //     )
    // }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            readOnly={true}
        />)
    }
    BQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BQty"
            defaultValue={rowValue.BQty}
            className="textbox"
            name="BQty"
            readOnly={true}
            
        />)
    }
    SaleRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="SaleRate"
            defaultValue={rowValue.SaleRate}
            className="textbox"
            name="SaleRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    MRP = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="MRP"
            defaultValue={rowValue.MRP}
            className="textbox"
            name="MRP"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DefaultBatchNo = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="DefaultBatchNo"
            defaultValue={rowValue.DefaultBatchNo}
            className="textbox"
            name="DefaultBatchNo"
            onChange = { e => {
                //  this.Calculate_Total()
             }}
             onInput ={this.toInputUppercase}
             
            //  onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisPer = (rowValue) => {
        
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    DisPer1 = (rowValue) => {
        
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer1"
            defaultValue={rowValue.DisPer1}
            className="textbox"
            name="DisPer1"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisAmt1 = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt1"
            defaultValue={rowValue.DisAmt1}
            className="textbox"
            name="DisAmt1"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    FTaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="FTaxPer"
            defaultValue={rowValue.FTaxPer}
            className="textbox"
            name="FTaxPer"
            readOnly={true}
            // onChange = { e => {
            //      this.Calculate_Total()
            //  }}
        />)
    }
     FTaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="FTaxAmt"
            defaultValue={rowValue.FTaxAmt}
            className="textbox"
            name="FTaxAmt"
            readOnly={true}
            // onChange = { e => {
            //      this.Calculate_Total()
            //  }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }

   
    validate = ()=>{
        if(this.state.SelectedPaymentType.value == 'Account Sale' || this.state.SelectedPaymentType.value == 'Account Credit Sale'){
            if(!this.state.SelectedBank){
                this.setState({Bank_Err: 'required'})
                document.getElementById('BankName').focus()
                return false;
            }
        }
        return true
    }
    SaveHandler = (isFromPrint) => {
        var PrevSrNo, SrNo;
        let resultToReturn = false;
        let _PurchaseAmount = 0;
        if (this.state.SelectedCustomer && this.state.SelectedWareHouse && this.state.SelectedPaymentType) {
            this.setState({ Supplier_Err: '', WareHOuse_Err:'', Paymenttype_Err:'' })
            if(this.validate()){
                if (this.state.SI_Detail_Data.length > 0) {
                   
                    for (let i = 0; i < this.state.SI_Detail_Data.length; i++) {
                        
                        if (typeof this.state.SI_Detail_Data[i].Qty === 'undefined' || this.state.SI_Detail_Data[i].Qty == '' || this.state.SI_Detail_Data[i].Qty == null)
                            this.state.SI_Detail_Data[i].Qty = 0
                        else if (typeof this.state.SI_Detail_Data[i].Qty === 'string')
                            this.state.SI_Detail_Data[i].Qty = parseFloat(this.state.SI_Detail_Data[i].Qty)
    
                        if (typeof this.state.SI_Detail_Data[i].TaxPer === 'undefined' || this.state.SI_Detail_Data[i].TaxPer == '' || this.state.SI_Detail_Data[i].TaxPer == null)
                            this.state.SI_Detail_Data[i].TaxPer = 0
                        else if (typeof this.state.SI_Detail_Data[i].TaxPer === 'string')
                            this.state.SI_Detail_Data[i].TaxPer = parseFloat(this.state.SI_Detail_Data[i].TaxPer)
    
                            if (typeof this.state.SI_Detail_Data[i].FTaxPer === 'undefined' || this.state.SI_Detail_Data[i].FTaxPer == '' || this.state.SI_Detail_Data[i].FTaxPer == null)
                            this.state.SI_Detail_Data[i].FTaxPer = 0
                        else if (typeof this.state.SI_Detail_Data[i].FTaxPer === 'string')
                            this.state.SI_Detail_Data[i].FTaxPer = parseFloat(this.state.SI_Detail_Data[i].FTaxPer)
    
    
                        if (typeof this.state.SI_Detail_Data[i].DisPer === 'undefined' || this.state.SI_Detail_Data[i].DisPer == '' || this.state.SI_Detail_Data[i].DisPer == null)
                            this.state.SI_Detail_Data[i].DisPer = 0
                        else if (typeof this.state.SI_Detail_Data[i].DisPer === 'string')
                            this.state.SI_Detail_Data[i].DisPer = parseFloat(this.state.SI_Detail_Data[i].DisPer)
    
                        if (typeof this.state.SI_Detail_Data[i].DisPer1 === 'undefined' || this.state.SI_Detail_Data[i].DisPer1 == '' || this.state.SI_Detail_Data[i].DisPer1 == null)
                            this.state.SI_Detail_Data[i].DisPer1 = 0
                        else if (typeof this.state.SI_Detail_Data[i].DisPer1 === 'string')
                            this.state.SI_Detail_Data[i].DisPer1 = parseFloat(this.state.SI_Detail_Data[i].DisPer1)
    
                        if (typeof this.state.SI_Detail_Data[i].TaxAmt === 'undefined' || this.state.SI_Detail_Data[i].TaxAmt == '' || this.state.SI_Detail_Data[i].TaxAmt == null)
                            this.state.SI_Detail_Data[i].TaxAmt = 0
                        else if (typeof this.state.SI_Detail_Data[i].TaxAmt === 'string')
                            this.state.SI_Detail_Data[i].TaxAmt = parseFloat(this.state.SI_Detail_Data[i].TaxAmt)
    
                            if (typeof this.state.SI_Detail_Data[i].FTaxAmt === 'undefined' || this.state.SI_Detail_Data[i].FTaxAmt == '' || this.state.SI_Detail_Data[i].FTaxAmt == null)
                            this.state.SI_Detail_Data[i].FTaxAmt = 0
                        else if (typeof this.state.SI_Detail_Data[i].FTaxAmt === 'string')
                            this.state.SI_Detail_Data[i].FTaxAmt = parseFloat(this.state.SI_Detail_Data[i].FTaxAmt)
    
    
                        if (typeof this.state.SI_Detail_Data[i].DisAmt === 'undefined' || this.state.SI_Detail_Data[i].DisAmt == '' || this.state.SI_Detail_Data[i].DisAmt == null)
                            this.state.SI_Detail_Data[i].DisAmt = 0
                        else if (typeof this.state.SI_Detail_Data[i].DisAmt === 'string')
                            this.state.SI_Detail_Data[i].DisAmt = parseFloat(this.state.SI_Detail_Data[i].DisAmt)
    
                        if (typeof this.state.SI_Detail_Data[i].DisAmt1 === 'undefined' || this.state.SI_Detail_Data[i].DisAmt1 == '' || this.state.SI_Detail_Data[i].DisAmt1 == null)
                            this.state.SI_Detail_Data[i].DisAmt1 = 0
                        else if (typeof this.state.SI_Detail_Data[i].DisAmt1 === 'string')
                            this.state.SI_Detail_Data[i].DisAmt1 = parseFloat(this.state.SI_Detail_Data[i].DisAmt1)
    
                        if (typeof this.state.SI_Detail_Data[i].SaleRate === 'undefined' || this.state.SI_Detail_Data[i].SaleRate == '' || this.state.SI_Detail_Data[i].SaleRate == null)
                            this.state.SI_Detail_Data[i].SaleRate = 0
                        else if (typeof this.state.SI_Detail_Data[i].SaleRate === 'string')
                        
                            this.state.SI_Detail_Data[i].SaleRate = parseFloat(this.state.SI_Detail_Data[i].SaleRate)
    
                        if (typeof this.state.SI_Detail_Data[i].Value === 'undefined' || this.state.SI_Detail_Data[i].Value == '' || this.state.SI_Detail_Data[i].Value == null)
                            this.state.SI_Detail_Data[i].Value = 0
                        else if (typeof this.state.SI_Detail_Data[i].Value === 'string')
                            this.state.SI_Detail_Data[i].Value = parseFloat(this.state.SI_Detail_Data[i].Value)
    
                            if (typeof this.state.SI_Detail_Data[i].BonusQty === 'undefined' || this.state.SI_Detail_Data[i].BonusQty == '' || this.state.SI_Detail_Data[i].BonusQty == null)
                            this.state.SI_Detail_Data[i].BonusQty = 0
                        else if (typeof this.state.SI_Detail_Data[i].BonusQty === 'string')
                            this.state.SI_Detail_Data[i].BonusQty = parseFloat(this.state.SI_Detail_Data[i].BonusQty)
    
                            var mQty = this.state.SI_Detail_Data[i].BonusQty + this.state.SI_Detail_Data[i].Qty;
                            if(mQty > this.state.SI_Detail_Data[i].BQty){
                                Swal.fire({
                                    icon: 'info',
                                    text: `Batch Qty is less than Qty + Bonus Qty given at Sr No. ${this.state.SI_Detail_Data[i].SrNo}...`,
                                })
                                return
                            }
                            if(this.state.SI_Detail_Data[i].MaxSaleQty > 0){
                                if(mQty > this.state.SI_Detail_Data[i].MaxSaleQty){
                                    Swal.fire({
                                        icon: 'info',
                                        text: `${this.state.SI_Detail_Data[i].ItemDiscription} given at Sr No. ${this.state.SI_Detail_Data[i].SrNo} can not sale more ${this.state.SI_Detail_Data[i].MaxSaleQty}...`,
                                    })
                                    return
                                }
                            }
                            let today = GetCurrentDate()
                            if(today <= this.state.SI_Detail_Data[i].StartDate && today >= this.state.SI_Detail_Data[i].EndDate){
                                if(this.state.SI_Detail_Data[i].AmountLimit > 0){
                                    let LatestAmt = this.state.SI_Detail_Data[i].SoldValue + this.state.SI_Detail_Data[i].Value
                                    if(LatestAmt > this.state.SI_Detail_Data[i].AmountLimit){
                                        Swal.fire({
                                            icon:'info',
                                            text: `${this.state.SI_Detail_Data[i].ItemDiscription} given at Sr No. ${this.state.SI_Detail_Data[i].SrNo} can not sale more then  ${this.state.SI_Detail_Data[i].AmountLimit}... Amount Limit`
                                        })
                                        return
                                    }
                                }
                            }
                           
                          
                            // related to customer 
                            if(this.state.SelectedCustomer.Lis9 === 0){
                                if( this.state.SI_Detail_Data[i].ScheduleGP === 1){
                                    Swal.fire({
                                        icon: 'info',
                                        text: `Item at Sr No. ${this.state.SI_Detail_Data[i].SrNo} is Not valid for this Customer...`,
                                    })
                                    return
                                }
                            }

                            //related to max sale qty (item definition)
                            this.state.SI_Detail_Data[i].PurchaseValue = this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].PurchaseRate
                            this.state.SI_Detail_Data[i].PurchaseValue = parseFloat((this.state.SI_Detail_Data[i].PurchaseValue).toFixed(2))
                             _PurchaseAmount += this.state.SI_Detail_Data[i].PurchaseValue;
                            //  related to discount claim Amount calculation
                            let totalDis = this.state.SI_Detail_Data[i].DisPer;
                            let ourDis = this.state.SI_Detail_Data[i].OurShareDisPer
                            if(ourDis !== 0  && ourDis){
                                let claimableDis = totalDis - ourDis;
                                this.state.SI_Detail_Data[i].OurShareDisAmt =  parseFloat(((this.state.SI_Detail_Data[i].Value / 100 ) * ourDis).toFixed(2))
                                this.state.SI_Detail_Data[i].ClaimableDisAmt =  parseFloat(((this.state.SI_Detail_Data[i].Value / 100 ) * claimableDis).toFixed(2))
                            }
                           
                            //  related to Bonus claim Amount calculation
                                let totalBonusQty = this.state.SI_Detail_Data[i].BonusQty;
                                let ourBonus = this.state.SI_Detail_Data[i].OurShareBonusQty
                                if(ourBonus !== 0 && ourBonus){
                                    let claimableBonus = totalBonusQty - ourBonus;
                                    // Tp * claimable bonus
                                    this.state.SI_Detail_Data[i].OurShareBonusAmt =  parseFloat((this.state.SI_Detail_Data[i].SaleRate * claimableBonus ).toFixed(2))
                                  }
                            }
    
                    // for (let i = 0; i < this.state.SI_Detail_Data.length; i++) {
                    //     for (let j = 0; j < this.state.SI_Detail_Data.length; j++) {
                    //         if (i !== j) {
                    //                 if (this.state.SI_Detail_Data[i].ItemID === this.state.SI_Detail_Data[j].ItemID) {
                    //                     PrevSrNo = this.state.SI_Detail_Data[i].SrNo;
                    //                     SrNo = this.state.SI_Detail_Data[j].SrNo;
                    //                     resultToReturn = true;
                    //                     break;
                    //                 }
    
                    //         }
                    //     }
                    //     if (resultToReturn) {
                    //         break;
                    //     }
                    // }
    
                    // if (resultToReturn) {
                    //     Swal.fire({ 
                    //         icon: 'info',
                    //         text: `Same Item found at Sr No: ${PrevSrNo} and Sr No: ${SrNo}`,
                    //     })
                    //     return
                    // }
                    // else {
                        var SaleData = {
                            SaleInvoiceID: this.props.SaleInvoiceID,
                            SaleInvoiceNo: this.state.SaleInvoiceNo,
                            WareHouseID: this.state.SelectedWareHouse.WareHouseID,
                            CustomerID: this.state.SelectedCustomer.CustomerID,
                            UserID: this.UserID,
                            CreatedDate: this.state.CreatedDate,
                            InvoiceDate: this.state.InvoiceDate,
                            Discription: this.state.Discription,
                            TotalDiscount: this.state.TotalDiscount + this.state.TotalDiscount1,
                            TotalQuantity: this.state.TotalQuantity,
                            InvoiceAmount: this.state.InvoiceAmount,
                            GrandTotal: this.state.GrandTotal,
                            TransactionType: 'INV',
                            BankID: this.state.SelectedBank ? this.state.SelectedBank.BankID : 0,
                            PaymentType: this.state.SelectedPaymentType.value,
                            TotalPaid: this.state.SelectedPaymentType.value === 'Cash Credit Sale' || this.state.SelectedPaymentType.value === 'Account Credit Sale' || this.state.SelectedPaymentType.value == 'Credit Sale' ? this.state.TotalPaid : this.state.GrandTotal,
                            Balance: this.state.Balance,
                            Comp_Id: this.Comp_Id,
                            ProjectID: this.ProjectID,
                            Cash: this.state.Cash,
                            ChangeAmt: this.state.ChangeAmt,
                            AddTaxPer: this.state.AddTaxPer,
                            AddTaxAmt: this.state.AddTaxAmt,
                            WHTPer: this.state.WHTPer,
                            WHTAmt: this.state.WHTAmt,
                            VATPer: this.state.VATPer,
                            VATAmt: this.state.VATAmt,
                            IncomeTaxPer: this.state.IncomeTaxPer,
                            IncomeTaxAmt: this.state.IncomeTaxAmt,
                            PurchaseAmount: parseFloat(_PurchaseAmount.toFixed(2)),
                            TotalGST: this.state.TotalTaxAmt,
                            SaleInvoiceIDSR: 0,
                            SaleInvoiceIDForSR: 0,
                            InvoiceType: this.state.SelectedInvoiceType.value,
                            TotalGSTOnQty : this.state.TotalGSTOnQty,
                            TotalGSTOnBonus : this.state.TotalGSTOnBonus,
                            TotalFTaxOnQty: this.state.TotalFTaxOnQty,
                            TotalFTaxOnBonus: this.state.TotalFTaxOnBonus,
                            IsDispatched: isFromPrint === 'fromPrint' ? 1 : 0,
                            isWithoutDelivery: isFromPrint === 'fromPrint' ? 1 : 0
                        }
                    
                        this.setState({btn_Disabled:true})
                        
                        SaveSaleInvoice(SaleData, this.state.SI_Detail_Data, this.state.DeletedSI_Detail_Data, this.props.isAddNew, (data) => {
                           if(data.InvoiceType === "Sale Invoice"){
                            data.InvoiceType = "SaleInvoice"
                           }else if(data.InvoiceType === "Estimate Invoice"){
                            data.InvoiceType = "EstimateInvoice"
                           }
                            if(isFromPrint == 'fromPrint'){
                                window.open(`/SaleInvA4/${data.SaleInvoiceID}/${data.InvoiceType}`, "_blank");
                            }else if(isFromPrint == 'fromThermalPrint'){
                                window.open(`/SaleInvPOS/${data.SaleInvoiceID}/${data.InvoiceType}`, "_blank");
                            }
                            this.setState({btn_Disabled:false})
                            if(data.ToList){
                                document.getElementById('btnCancel').click()
                            }
                           
                        })
                    // }
                }
                else {
                    this.setState({btn_Disabled:false})
                    Swal.fire({
                        icon: 'info',
                        text: `Order Details Must be Entered...`,
                    })
                    return
                }
            }
        }
        else if(!this.state.SelectedCustomer){
            this.setState({ Supplier_Err: 'Supplier is required',btn_Disabled:false })
        }
        else if(!this.state.SelectedWareHouse ){
            this.setState({ WareHOuse_Err: 'WareHouse is required' ,btn_Disabled:false})
        }
        else if(!this.state.SelectedPaymentType){
            this.setState({ Paymenttype_Err: 'Payment Type is required',btn_Disabled:false })
        } else if(!this.state.SelectedInvoiceType){
            this.setState({ InvoiceType_Err: 'Invoice Type is required',btn_Disabled:false })
        }
    }

    beforeRowInsert = async(rowValue) => {
    
        if(rowValue.requestType === 'save')
        {
            const qty = +rowValue.data.Qty || 0;
            const mrp = +rowValue.data.MRP || 0;
            const taxPer = +rowValue.data.TaxPer || 0;
            const bonusqty = +rowValue.data.BonusQty || 0;
            const fTax = +rowValue.data.FTaxPer || 0;

            this.calculateTaxes = (qty, tax)=>{
                return parseFloat((qty * mrp / 100) * tax).toFixed(2);
            }

            const gstOnQty    = this.calculateTaxes(qty,taxPer);
            const gstOnBonus  = this.calculateTaxes(bonusqty, taxPer);
            const fTaxOnQty   = this.calculateTaxes(qty, fTax);
            const fTaxOnBonus = this.calculateTaxes(bonusqty, fTax);
          
            rowValue.data.ItemDiscription = this.state.Selected_item?.ItemDiscription
            rowValue.data.ItemID = this.state.Selected_item?.ItemID
            rowValue.data.PurchaseRate = this.state.Selected_item?.PackPurRate
            rowValue.data.ScheduleGP = this.state.Selected_item?.ScheduleGP
            rowValue.data.MaxSaleQty = this.state.Selected_item?.MaxSaleQty
            rowValue.data.FlatRate = this.state.Selected_item?.FlatRate
            rowValue.data.UOMID = this.state.UOMID 
            // rowValue.data.MRP = this.state.Selected_item.MRP
            rowValue.data.SoldValue = this.state.SoldValue
            rowValue.data.AmountLimit = this.state.AmountLimit
            rowValue.data.OurShareDisPer = this.state._OurShareDisPer
            rowValue.data.IsSchemeApplied = this.state._IsSchemeApplied
            rowValue.data.OurShareBonusQty = this.state._OurShareBonusQty
            rowValue.data.GSTOnQty = gstOnQty;
            rowValue.data.GSTOnBonus = gstOnBonus;
            rowValue.data.FTaxOnQty = fTaxOnQty;
            rowValue.data.FTaxOnBonus = fTaxOnBonus;

            if(this.state.Selected_Batch){
                rowValue.data.BatchNo = this.state.Selected_Batch.batchno
            }
          else{
            rowValue.data.BatchNo = ''
          }

            var mQty = parseFloat(rowValue.data.Qty) + parseFloat(rowValue.data.BonusQty)
            if( mQty > parseFloat(rowValue.data.BQty))
            {
                Swal.fire({
                    icon: 'info',
                    text: `Given Qty: ${mQty} must be less than the Batch Qty : ${rowValue.data.BQty} ...`,
                })
                this.grid.closeEdit()
                return
            }
            this.state.SI_Detail_Data.forEach((item) => {
                const isEditing = rowValue.requestType === 'save' && rowValue.action === 'edit';
                if (item.ItemID === rowValue.data.ItemID && item.BatchNo === rowValue.data.BatchNo) {
                    if (isEditing) {
                        return;
                    }
                    Swal.fire({
                        icon: 'info',
                        text: `Given Batch: ${rowValue.data.BatchNo} already exists ...`,
                    });
                    this.grid.closeEdit();
                    return;
                }
            });
            
            if (rowValue.data.SaleInvoiceDetailID === undefined)
            {
                rowValue.data.action = 'add'
            }  
            else{
                rowValue.data.action = rowValue.action
            }
          
            // rowValue.cancel = false;
           
        }
        else if (rowValue.requestType === 'add')
        {
            rowValue.data.SrNo = this.state.SI_Detail_Data.length + 1
            rowValue.data.Qty = 1
            rowValue.data.BonusQty = 0
            rowValue.data.DisPer = 0.00
            rowValue.data.DisAmt = 0.00
            rowValue.data.DisPer1 = 0.00
            rowValue.data.DisAmt1 = 0.00
            rowValue.data.TaxPer = 0.00
            rowValue.data.TaxAmt = 0.00
            rowValue.data.FTaxPer = 0.00
            rowValue.data.FTaxAmt = 0.00
            rowValue.data.GSTOnQty = 0;
            rowValue.data.GSTOnBonus = 0;
            rowValue.data.FTaxOnBonus = 0;
            rowValue.data.FTaxOnQty = 0;
            this.setState({ Selected_item: null,UOMID:0,Selected_Batch : null,_OurShareDisPer:0,_IsSchemeApplied:0,_OurShareBonusQty:0, schemeData:[]})
           
            
        }
        if(rowValue?.action === "add"){
            if(rowValue?.data?.BQty !== undefined){
                const mData = [...this.state.SI_Detail_Data, rowValue.data];
                this.props.setSalesInvoiceDetails(mData)
            }
           }
       if(rowValue?.action === "edit"){
            this.props.updateSaleInvoice(rowValue.data)
           }
          if(rowValue.requestType === "delete"){
            this.props.removeSalesInvoiceDetail(rowValue?.data[0].ItemID, rowValue?.data[0].BatchNo)
          }
        
    }
    afterRowInsert = async(rowValue) => {
        if (rowValue.requestType == "beginEdit") {
            this.setState({ Selected_item: { 
                "ItemDiscription": rowValue.rowData.ItemDiscription, 
                "ItemID": rowValue.rowData.ItemID,
                "ScheduleGP":rowValue.rowData.ScheduleGP ,
                "PackPurRate":rowValue.rowData.PurchaseRate,
                "MaxSaleQty":rowValue.rowData.MaxSaleQty ,
                 "FlatRate" :rowValue.rowData.FlatRate,
                 "GST": rowValue.rowData.TaxPer
                },
                            UOMID: rowValue.rowData.UOMID,
                            _OurShareDisPer: rowValue.rowData.OurShareDisPer,
                            _IsSchemeApplied: rowValue.rowData.IsSchemeApplied,
                            _OurShareBonusQty: rowValue.rowData.OurShareBonusQty
                        },()=>{     // , "MRP": rowValue.rowData.MRP         
                SelectBatchDetails(this.Comp_Id,this.ProjectID,rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
                        var BatchNo_select = document.getElementById('BatchNo')
                            
                        var length = BatchNo_select.options.length;
                        for (let i = length - 1; i >= 0; i--) {
                            BatchNo_select.options[i] = null;
                        }
                        
                        for (let i = 0; i < mItems.length; i++) {
                            if((mItems[i].batchno).trim() == (rowValue.rowData.BatchNo).trim()){
                                BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
                                BatchNo_select.selectedIndex = i
                                this.setState({Selected_Batch:mItems[i]})
                            }
                            else{
                                BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
                            }
                        }
                       
                        
                  this.setState({Batch_List:mItems})
                })
            })
            // this.Select_Batch_Detail(rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID)

        }
        if (rowValue.requestType == "save") {
            
                this.Calculate_Values();
            
        }
        if (rowValue.requestType == "delete") {
            if(rowValue.data[0].SaleInvoiceDetailID)
            {
                this.setState({DeletedSI_Detail_Data:this.state.DeletedSI_Detail_Data.concat(rowValue.data)})
            }
            this.Calculate_Values();
        }
        if(rowValue.requestType == "add")
        {
          document.getElementById('ItemDiscription').focus()
        }
    }
    rowDataBound = (e)=> { 
        //  var mQty = e.data.Qty + e.data.BonusQty;
        // if (e.data.Qty === 10) {
        //     e.row.style.backgroundColor = "#FF6347"
        // }
    }
    Calculate_Total = (mSchemeData) => {
        var Qty=0,  Rate = 0,MRP = 0, GST = 0, DisPer = 0, DisPer1=0,TaxPer = 0, DisAmt = 0,DisAmt1 = 0, TaxAmt = 0, Total_Value = 0,FTaxPer=0,FTaxAmt=0, BonusQty=0, GSTQty = 0;
     
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)

            if (document.getElementById('BonusQty').value)
                BonusQty = typeof (document.getElementById('BonusQty').value) === 'string' ? parseFloat((document.getElementById('BonusQty').value).replace(/,/g, '')) : document.getElementById('BonusQty').value
            else if (document.getElementById('BonusQty').value == "")
                BonusQty = 0
            else
            BonusQty = parseFloat(document.getElementById('BonusQty').value)

            // if(this.state.DisPer_Visible){
            //     document.getElementById('DisPer1').value = DisPer1;
            //     document.getElementById('DisPer').value = DisPer;  
            // }
       
                if(mSchemeData && mSchemeData.length > 0){
                    // Start Bonus Work
                    const BonusPolicy = mSchemeData[2]
                    const DiscountScheme = mSchemeData[1]
                    let normalData = this.normalizeData(BonusPolicy);
                    let BonusPolicyMain = normalData.ItemPolicyData
                    if(BonusPolicyMain){
                        BonusPolicyMain.sort(function(a, b) {
                            return a.TargetFromQty - b.TargetFromQty;
                        });
                    }
                    if(BonusPolicy[0].BonusPolicyFound){
                        if(BonusPolicy[0].BonusData){
                            this.setState({_IsSchemeApplied:1,_OurShareBonusQty: BonusPolicyMain[0].OurSharePer})
                            let StartDate = BonusPolicyMain[0].StartDate ;
                            let EndDate =  BonusPolicyMain[0].EndDate;
                            let today = GetCurrentDate()
                            let PolicyArray = []
                            if(today >= StartDate && today <= EndDate){
                                 PolicyArray = BonusPolicyMain.find(obj => obj.TargetFromQty <= Qty && obj.Criteria >= Qty);
                                if(PolicyArray) {
                                    document.getElementById('BonusQty').value = PolicyArray.BonusQty
                                   
                                }else{
                                        let maxid =  BonusPolicyMain[BonusPolicyMain.length-1].SchemeID;
                                        const minCriteria = BonusPolicyMain[0].TargetFromQty;
                                        let chkmaxCriteria = BonusPolicyMain[BonusPolicyMain.length-1].Criteria;
                                        let maxBonusQty
                                        if(BonusPolicyMain.length > 1){
                                             maxBonusQty = BonusPolicyMain[BonusPolicyMain.length-2].BonusQty; // pick from 2nd last
                                        }else{
                                             maxBonusQty = BonusPolicyMain[BonusPolicyMain.length-1].BonusQty; // pick from 2nd last
                                        }
                                      
                                        PolicyArray =   BonusPolicyMain.find(obj => obj.SchemeID === maxid);

                                        if(Qty <= minCriteria){ // its means Qty not found in Range
                                            document.getElementById('BonusQty').value =  0
                                        }else{
                                            if(chkmaxCriteria === 0){  // means Above false --> Not Duplicate or give extra bonus WRT qty
                                                document.getElementById('BonusQty').value =   maxBonusQty
                                            }else if(chkmaxCriteria === 1){ // means Above true --> give extra bonus WRT qty
                                                var mBonusQty = Math.floor(Qty /  PolicyArray.TargetFromQty) 
                                                document.getElementById('BonusQty').value = mBonusQty * maxBonusQty
                                            } else {
                                                document.getElementById('BonusQty').value =   maxBonusQty 
                                            }

                                        }
                                    
                                }
                               
                             
                            }else{
                                document.getElementById('BonusQty').value = 0
                            }
                        }else{
                            document.getElementById('BonusQty').value = 0
                        }
                       
                    }else{
                        // document.getElementById('BonusQty').value = 0
                    }
                    // End Bouns Work
                    // Start Discount Work
                   
                    let normalizedData = this.normalizeData(DiscountScheme);
                    let DiscountMainArray =normalizedData.ItemPolicyData
                   
                     if(DiscountMainArray){
                        DiscountMainArray.sort(function(a, b) {
                            return a.TargetFromQty - b.TargetFromQty;
                        });
                     }
                    if(DiscountScheme[0].DisPolicyFound){
                        if(DiscountScheme[0].DisData){
                            let StartDate = DiscountMainArray[0].StartDate ;
                            let EndDate =  DiscountMainArray[0].EndDate;
                            let MaxLimit =  DiscountMainArray[0].QtyLimit;
                            const OurShareDisPer = DiscountMainArray[0].OurSharePer || 0;
                            let SoldQty =  normalizedData.SoldQty;
                            let SoldValue =normalizedData.SoldValue;
                            let AmountLimit = DiscountMainArray[0].AmountLimit
                            let today = GetCurrentDate()
                            let PolicyArray = []
                            if(today >= StartDate && today <= EndDate){
                                // if(this.state.DisPer_Visible){
                                    if(SoldQty <= MaxLimit || MaxLimit === 0  ){
                                        PolicyArray = DiscountMainArray.find(obj => obj.TargetFromQty <= Qty && obj.Criteria >= Qty);
                                        if(PolicyArray){
                                            document.getElementById('DisPer').value = PolicyArray.Discount1Per 
                                            document.getElementById('DisPer1').value = PolicyArray.Discount2Per
                                        }else{
                                            let maxid =  DiscountMainArray[DiscountMainArray.length-1].SchemeID;
                                                const minCriteria = DiscountMainArray[0].TargetFromQty;
                                                let chkmaxCriteria = DiscountMainArray[DiscountMainArray.length-1].Criteria;
                                                let maxDisQty 
                                                let maxDis2Qty
                                                if(DiscountMainArray.length > 1){
                                                    maxDisQty = DiscountMainArray[DiscountMainArray.length-2].Discount1Per; // pick from 2nd last
                                                    maxDis2Qty = DiscountMainArray[DiscountMainArray.length-2].Discount2Per;
                                                }else{
                                                    maxDisQty = DiscountMainArray[DiscountMainArray.length-1].Discount1Per; // pick from 2nd last
                                                    maxDis2Qty = DiscountMainArray[DiscountMainArray.length-1].Discount2Per;
                                               }
                                               PolicyArray =   DiscountMainArray.find(obj => obj.SchemeID === maxid);
                                           
                                               if(Qty <=minCriteria){
                                                document.getElementById('DisPer').value = 0
                                                document.getElementById('DisPer1').value =0
                                               }else{
                                                    if(chkmaxCriteria === 0){
                                                        document.getElementById('DisPer').value = maxDisQty
                                                        document.getElementById('DisPer1').value =maxDis2Qty
                                                    }else if(chkmaxCriteria === 1){
                                                        var mDisQty = Math.floor(Qty /  PolicyArray.TargetFromQty) 
                                                        document.getElementById('DisPer').value = mDisQty * maxDisQty
                                                        document.getElementById('DisPer1').value =mDisQty * maxDis2Qty
                                                    }else{
                                                        document.getElementById('DisPer').value = maxDisQty
                                                        document.getElementById('DisPer1').value =maxDis2Qty
                                                    }
                                               }
                                        }
                                      
                                        // this.Calculate_Total()
                                    }else{
                                        document.getElementById('DisPer').value = 0
                                        document.getElementById('DisPer1').value =0
                                        // this.Calculate_Total() 
                                    }
                                // }
                            
                            }else{
                                document.getElementById('DisPer').value = 0
                                document.getElementById('DisPer1').value =0
                                // this.Calculate_Total()
                            }
                            this.setState({SoldValue: SoldValue, AmountLimit: AmountLimit, StartDate: StartDate, EndDate: EndDate, _OurShareDisPer:OurShareDisPer,_IsSchemeApplied:1})
                        }else{
                            document.getElementById('DisPer').value = 0
                            document.getElementById('DisPer1').value =0
                            // this.Calculate_Total()
                        }
                        
                    }else{
                        // Commiting because of direct invoice 
                        // document.getElementById('DisPer').value = 0
                        // document.getElementById('DisPer1').value =0
                        // this.Calculate_Total()
                    }
                }
            
        Rate = document.getElementById('SaleRate').value == ""  ? 0 : parseFloat(document.getElementById('SaleRate').value)
        // MRP = document.getElementById('MRP').value == "" ? 0 : parseFloat(document.getElementById('MRP').value)
        // MRP =  this.state.Selected_item? this.state.Selected_item.MRP : 0
        MRP = document.getElementById('MRP')?.value == "" ? 0 : parseFloat(document.getElementById('MRP')?.value)
        TaxPer = document.getElementById('TaxPer')?.value == "" ? 0 : parseFloat(document.getElementById('TaxPer')?.value)
        GST =  this.state.Selected_item? this.state.Selected_item.GST : 0
        GSTQty = TaxPer > 0 ? Qty + BonusQty : Qty
       
        if(this.state.SaleTaxPer_Visible){
            if(this.state.SelectedCustomer.SaleTaxType == 'NOT REGISTER WITH GST' && GST > 0){
                FTaxPer = 3
            }
            document.getElementById('FTaxPer').value = FTaxPer;
        }
        DisPer1 = document.getElementById('DisPer1').value ;
        DisPer=  document.getElementById('DisPer').value ;  
        DisAmt = parseFloat((( (Qty * Rate)/ 100) * DisPer).toFixed(2))
        TaxAmt = parseFloat((((GSTQty * MRP) / 100) * TaxPer).toFixed(2))
        // GSTOnQty = parseFloat((((Qty * MRP) / 100) * TaxPer).toFixed(2))
        // GSTOnBonus = parseFloat((((BonusQty * MRP) / 100) * TaxPer).toFixed(2))
       //TaxAmt = parseFloat((((Qty * Rate) / 100) * TaxPer).toFixed(2))
        FTaxAmt = parseFloat((((GSTQty * MRP) / 100) * FTaxPer).toFixed(2)) //further tax
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt + FTaxAmt)).toFixed(2))
        
        // DisAmt = parseFloat(((DisPer / 100) * Rate).toFixed(2))
        // TaxAmt = parseFloat(((TaxPer / 100) * Rate).toFixed(2))
        // FTaxAmt = parseFloat(((FTaxPer / 100) * Rate).toFixed(2)) //further tax
        // Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt + FTaxAmt)).toFixed(2))
       DisAmt1 =  parseFloat(((Total_Value / 100 ) * DisPer1 ).toFixed(2))
        if(this.state.DisAmt_Visible){
            document.getElementById('DisAmt').value = DisAmt;
            document.getElementById('DisAmt1').value = DisAmt1;

        }
        if(this.state.SaleTaxAmt_Visible){
            document.getElementById('TaxAmt').value = TaxAmt;
            document.getElementById('FTaxAmt').value = FTaxAmt;
            
            // document.getElementById('GSTOnQty').value = GSTOnQty;
            // document.getElementById('GSTOnBonus').value = GSTOnBonus;
        }
        
        this.hideLoader()
        document.getElementById('value').value = parseFloat((Total_Value - DisAmt1).toFixed(2)) ;

    }


    
    Calculate_Values = (VATAmt = 0) =>{
        
        var _TotalQuantity = 0, _TotalDis = 0, _TotalTax = 0, _TotalPur = 0, _GrandTotal = 0, _TotalDis1=0, Qty=0,_TotalFTax = 0, _TotalGSTOnQty  = 0, _TotalGSTOnBonus = 0,
        _TotalFTaxOnQty = 0,  _TotalFTaxOnBonus = 0;

        for(let i = 0; i< this.state.SI_Detail_Data.length; i++)
        {
           if( typeof this.state.SI_Detail_Data[i].Qty === 'string' && typeof this.state.SI_Detail_Data[i].DisAmt === 'string' && typeof this.state.SI_Detail_Data[i].TaxAmt === 'string' && typeof this.state.SI_Detail_Data[i].SaleRate === 'string' && typeof this.state.SI_Detail_Data[i].value === 'string'  )
           {
            this.state.SI_Detail_Data[i].Qty = parseFloat((this.state.SI_Detail_Data[i].Qty).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].DisAmt = parseFloat((this.state.SI_Detail_Data[i].DisAmt).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].DisAmt1 = parseFloat((this.state.SI_Detail_Data[i].DisAmt1).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].TaxAmt = parseFloat((this.state.SI_Detail_Data[i].MRP).replace(/,/g, ''))
          // this.state.SI_Detail_Data[i].MRP = parseFloat((this.state.SI_Detail_Data[i].MRP).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].SaleRate = parseFloat((this.state.SI_Detail_Data[i].SaleRate).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].Value = parseFloat((this.state.SI_Detail_Data[i].Value).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].FTaxAmt = parseFloat((this.state.SI_Detail_Data[i].FTaxAmt).replace(/,/g, ''))
           }
           else if (this.state.SI_Detail_Data[i].Qty == '' && this.state.SI_Detail_Data[i].DisAmt1 === '' && typeof this.state.SI_Detail_Data[i].DisAmt === '' && typeof this.state.SI_Detail_Data[i].TaxAmt === '' && typeof this.state.SI_Detail_Data[i].SaleRate === '' && typeof this.state.SI_Detail_Data[i].value === '' )
           {
            this.state.SI_Detail_Data[i].Qty = 0 
            this.state.SI_Detail_Data[i].DisAmt = 0
            this.state.SI_Detail_Data[i].DisAmt1 = 0
            //this.state.SI_Detail_Data[i].MRP = 0
            this.state.SI_Detail_Data[i].TaxAmt = 0

            this.state.SI_Detail_Data[i].SaleRate = 0
           this.state.SI_Detail_Data[i].Value = 0
           this.state.SI_Detail_Data[i].FTaxAmt = 0
           }
           
           _TotalGSTOnQty += parseFloat(this.state.SI_Detail_Data[i].GSTOnQty)
           _TotalGSTOnBonus += parseFloat(this.state.SI_Detail_Data[i].GSTOnBonus)
           _TotalFTaxOnQty += parseFloat(this.state.SI_Detail_Data[i].FTaxOnQty)
           _TotalFTaxOnBonus += parseFloat(this.state.SI_Detail_Data[i].FTaxOnBonus)
           _TotalQuantity += parseFloat(this.state.SI_Detail_Data[i].Qty)
           _TotalDis += parseFloat(this.state.SI_Detail_Data[i].DisAmt)
           _TotalDis1 += parseFloat(this.state.SI_Detail_Data[i].DisAmt1)
           //_TotalTax += parseFloat(this.state.SI_Detail_Data[i].MRP)
           _TotalTax += parseFloat(this.state.SI_Detail_Data[i].TaxAmt)

           _TotalPur += parseFloat((this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].SaleRate).toFixed(2))
            _GrandTotal += parseFloat(this.state.SI_Detail_Data[i].Value)
            _TotalFTax +=parseFloat(this.state.SI_Detail_Data[i].FTaxAmt) 
        }
        
        this.setState({
            TotalQuantity:_TotalQuantity,
            TotalDiscount:parseFloat((_TotalDis).toFixed(2)),
            TotalDiscount1:parseFloat((_TotalDis1).toFixed(2)),
            TotalTaxAmt:parseFloat((_TotalTax).toFixed(2)),
            InvoiceAmount:parseFloat((_TotalPur).toFixed(2)),
            GrandTotal:parseFloat((_GrandTotal).toFixed(2)),
            IncomeTaxAmt: parseFloat((_TotalFTax).toFixed(2)),
            TotalGSTOnQty: parseFloat((_TotalGSTOnQty).toFixed(2)),
            TotalGSTOnBonus:parseFloat((_TotalGSTOnBonus).toFixed(2)),
            TotalFTaxOnQty: parseFloat((_TotalFTaxOnQty).toFixed(2)),
            TotalFTaxOnBonus : parseFloat((_TotalFTaxOnBonus).toFixed(2))
        },()=>{
           this.Calculate_Taxes(VATAmt)
        })
        const totQty = {
            target:{
                name:"TotalQuantity",
                value: _TotalQuantity
            }
        }
        const totDis = {
            target:{
                name:"TotalDiscount",
                value: parseFloat((_TotalDis).toFixed(2)),
            }
        }
        const totDis1 = {
            target:{
                name:"TotalDiscount1",
                value: parseFloat((_TotalDis1).toFixed(2)),
            }
        }
        const totTaxAmt = {
            target:{
                name:"TotalTaxAmt",
                value: parseFloat((_TotalTax).toFixed(2)),
            }
        }
        const totInvAmt = {
            target:{
                name:"InvoiceAmount",
                value: parseFloat((_TotalPur).toFixed(2)),
            }
        }
        const totGrandAmt = {
            target:{
                name:"GrandTotal",
                value: parseFloat((_GrandTotal).toFixed(2)),
            }
        }
        const totIncomeAmt = {
            target:{
                name:"IncomeTaxAmt",
                value: parseFloat((_TotalFTax).toFixed(2)),
            }
        }
        const totGSTonQty = {
            target:{
                name:"TotalGSTOnQty",
                value: parseFloat((_TotalGSTOnQty).toFixed(2)),
            }
        }
        const totGSTonBonus = {
            target:{
                name:"TotalGSTOnBonus",
                value: parseFloat((_TotalGSTOnBonus).toFixed(2)),
            }
        }
        const totFTaxOnQty = {
            target:{
                name:"TotalFTaxOnQty",
                value: parseFloat((_TotalFTaxOnQty).toFixed(2)),
            }
        }
        const totFTaxOnBonus = {
            target:{
                name:"TotalFTaxOnBonus",
                value: parseFloat((_TotalFTaxOnBonus).toFixed(2)),
            }
        }
        this.setInRedux(totQty)
        this.setInRedux(totDis)
        this.setInRedux(totDis1)
        this.setInRedux(totTaxAmt)
        this.setInRedux(totInvAmt)
        this.setInRedux(totGrandAmt)
        this.setInRedux(totIncomeAmt)
        this.setInRedux(totGSTonQty)
        this.setInRedux(totGSTonBonus)
        this.setInRedux(totFTaxOnQty)
        this.setInRedux(totFTaxOnBonus)
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    GrandAmount_Calculation = (VATAmt = 0) =>{
        
        var _total = 0.00;
        _total = parseFloat((this.state.AddTaxAmt + this.state.WHTAmt + this.state.IncomeTaxAmt+VATAmt + this.state.TotalTaxAmt+ this.state.InvoiceAmount  - this.state.TotalDiscount - this.state.TotalDiscount1).toFixed(2)) ; 
        // _total = (parseFloat(TotalTaxAmount) + parseFloat(InvoiceAmount)) - parseFloat(TotalDiscount) - parseFloat(TotalDiscount1)
        const totGrand = {
            target:{
                name:"GrandTotal",
                value: parseFloat((_total).toFixed(2)),
            }
        }
        const totcash = {
            target:{
                name:"Cash",
                value: parseFloat((_total).toFixed(2)),
            }
        }
        this.setInRedux(totGrand);
        this.setInRedux(totcash);
        this.setState({GrandTotal : _total,Cash:_total},()=>{
            this.calculate_changeAmt()
        })
    }
    LoadStockToGrid =async mData =>{
        // this function is not using 
     for(let i=0; i<mData.length; i++){
        await new Promise(resolve=>{
        SelectBatchDetails(this.Comp_Id,this.ProjectID,mData[i].ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
            if(mItems.length > 0){
               mData[i].BQty= mItems[0].BQty
               mData[i].ExpairyDate = mItems[0].ExpairyDate
            //    mData[i].SaleRate = mItems[0].SaleRate
               mData[i].BatchNo = mItems[0].batchno
            }
            resolve()
        })
      })
     }    
        var mArray =this.state.SI_Detail_Data.concat(mData)
    
        mArray.map((value,index)=>{
            value.SrNo = index +1
        })
        this.setState({ SI_Detail_Data:mArray },()=>{
            this.Calculate_Values()
            this.hide_OB_Modal()
        })
    }
    Calculate_Taxes = (VATAmt = 0) =>{
        
        var mAddTaxAmt = 0, mWHTAmt = 0, mIncomeTaxAmt = 0;
        mAddTaxAmt =  parseFloat(((this.state.GrandTotal / 100 ) * this.state.AddTaxPer).toFixed(2))
        mWHTAmt = parseFloat(((this.state.GrandTotal / 100 ) * this.state.WHTPer).toFixed(2))
       // mIncomeTaxAmt = parseFloat(((this.state.GrandTotal / 100 ) * this.state.IncomeTaxPer).toFixed(2))
       //,IncomeTaxAmt:mIncomeTaxAmt
       const e = {
        target:{
            name:"AddTaxAmt",
            value: mAddTaxAmt
        }
       }
       const e2 = {
        target:{
            name:"WHTAmt",
            value: mWHTAmt
        }
       }
       this.setInRedux(e)
       this.setInRedux(e2)
        this.setState({AddTaxAmt:mAddTaxAmt,WHTAmt:mWHTAmt},()=>{
            this.GrandAmount_Calculation(VATAmt)
        })
        
    }
    calculate_changeAmt = () =>{
        let balc =   parseFloat((this.state.Cash - this.state.GrandTotal).toFixed(2))
        
        this.setState({ChangeAmt:balc})
        const e = {
            target:{
                name:"ChangeAmt",
                value: balc
            }
        }
        this.setInRedux(e)
    }
    show_OB_Modal = ()=>{
        this.setState({OrderBooking_Modal:true})
    }
    hide_OB_Modal = ()=>{
        this.setState({OrderBooking_Modal:false})
    }
    Select_SchemeFromCustomer = (CustomerID,callback)=>{
        // this function is not useful
            SelectSchemeFromCust(this.Comp_Id,this.ProjectID,CustomerID,(mScheme)=>{
                callback(mScheme)
            })
    }
    Select_SchemeDetail = (CustomerID,ItemID,callback)=>{
        SelectSchemeDetail(this.Comp_Id,this.ProjectID,CustomerID,ItemID,(mschemeDetail)=>{
          callback(mschemeDetail)
        })
    }
    handleFetchAutoSaved = ()=> {
        const { saleMasterData, detailData} = this.props.salesData;
        const {Comp_Id, ProjectID} = saleMasterData?.selectedCustomer;
        if(Comp_Id === this.Comp_Id && ProjectID === this.ProjectID){
            if(detailData.length > 0){
                this.setState({SI_Detail_Data: detailData})
            }
            if(saleMasterData){
                this.setState({
                    SaleInvoiceNo: saleMasterData.SaleInvoiceNo,
                    InvoiceDate: saleMasterData.InvoiceDate,
                    SelectedCustomer: saleMasterData.SelectedCustomer,
                    SelectedPaymentType: saleMasterData.SelectedPaymentType,
                    SelectedBank: saleMasterData.SelectedBank,
                    Discription: saleMasterData.Discription,
                    TotalDiscount: saleMasterData.TotalDiscount,
                    TotalDiscount1: saleMasterData.TotalDiscount1,
                    TotalQuantity: saleMasterData.TotalQuantity,
                    InvoiceAmount: saleMasterData.InvoiceAmount,
                    GrandTotal: saleMasterData.GrandTotal,
                    Cash: saleMasterData.Cash,
                    ChangeAmt: saleMasterData.ChangeAmt,
                    Balance: saleMasterData.Balance,
                    AddTaxPer: saleMasterData.SelectedCustomer.AddTaxPer,
                    AddTaxAmt: saleMasterData.AddTaxAmt,
                    IncomeTaxAmt: saleMasterData.IncomeTaxAmt,
                    WHTAmt: saleMasterData.WHTAmt,
                    TotalTaxAmt: saleMasterData.TotalGST,
                    TotalGSTOnQty: saleMasterData.TotalGSTOnQty,
                    TotalGSTOnBonus:saleMasterData.TotalGSTOnBonus,
                    TotalFTaxOnQty: saleMasterData.TotalFTaxOnQty,
                    TotalFTaxOnBonus : saleMasterData.TotalFTaxOnBonus
                })
            }
        }

    }
    handleClearData = ()=>{
        this.setState({
            SI_Detail_Data: [],
            TotalDiscount: 0,
            TotalDiscount1: 0,
            TotalQuantity: 0,
            InvoiceAmount: 0,
            GrandTotal: 0,
            Cash: 0,
            ChangeAmt:0,
            Balance: 0,
            AddTaxPer:0,
            AddTaxAmt: 0,
            IncomeTaxAmt: 0,
            WHTAmt:0,
            TotalTaxAmt: 0,
            TotalGSTOnQty:0,
            TotalGSTOnBonus:0,
            TotalFTaxOnQty: 0,
            TotalFTaxOnBonus : 0,
            Discription:'',
            selectedCustomer: this.state.CustomerList[0],
            InvoiceDate: GetCurrentDate()
        
        })
    }
 


render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <div className="d-flex flex-column min-vh-100">
            <Container className="flex-grow-1">
          <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> Sale Invoice
                    </h2>
                </div>
            </div>
        </div>
            <Card>
            <Col style={{display:"flex", gap:3,justifyContent:"end", textAlign: "right", marginTop: "1%" }} >
            <button className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.handleClearData} ><CancelOutlined />  <u>Clear Form</u></button>
             <button className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.handleFetchAutoSaved}  ><DownloadDoneOutlined />  <u>Get Auto Saved Data</u></button>
          
        </Col>
                <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="SaleInvoiceNo"
                            label="Sale Invoice No" 
                            value={this.state.SaleInvoiceNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="CustomerName"
                            id="CustomerName"
                            options={this.state.CustomerList}
                            getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                            value={this.state.SelectedCustomer}
                            onChange={async (e, value) => {
                                if (value)
                                {
                                    // var mAddTaxPer = 0;
                                    // if(value.AdvanceTaxType === 'FILER'){
                                    //     mAddTaxPer = 0.5;
                                    // }
                                    // if(value.AdvanceTaxType === 'NON FILER'){
                                    //     mAddTaxPer = 1;
                                    // }
                                    // var _AddTaxPer = 0,_IncomeTaxPer=0,_WHTPer=0,_CustomerSchemeID = 0;
                                    // await new Promise(resolve=>{
                                    //     this.Select_SchemeFromCustomer(value.CustomerID,(mSchemeData)=>{
                                    //         if(mSchemeData.length > 0){
                                    //             _AddTaxPer = mSchemeData[0].AddTaxPer
                                    //             _IncomeTaxPer = mSchemeData[0].IncomeTaxPer
                                    //             _WHTPer = mSchemeData[0].WHTPer
                                    //             _CustomerSchemeID = mSchemeData[0].SchemeID
                                    //         }
                                    //         resolve()
                                    //     })
                                    // })
                                    const persistCustomer = {
                                        target: {
                                          name: "SelectedCustomer",
                                          value: value
                                        }
                                    }
                                    this.setInRedux(persistCustomer)

                                    this.setState({SelectedCustomer:value,AddTaxPer:value.AddTaxPer,IncomeTaxPer:value.IncomeTaxPer,WHTPer:value.WHTPer},()=>{
                                        this.Calculate_Taxes()
                                    })
                                }
                                else
                                {
                                    this.setState({CustomerSchemeID:0,SelectedCustomer:null,AddTaxPer:0,IncomeTaxPer:0,WHTPer:0},()=>{
                                        this.Calculate_Taxes()
                                    })
                                    const persistCustomer = {
                                        target: {
                                          name: "SelectedCustomer",
                                          value: null
                                        }
                                    }
                                    this.setInRedux(persistCustomer)
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="CustomerName" label="Customer"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    {/* <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                                    this.Select_Item_Details(value.WareHouseID)
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.WareHOuse_Err}</span>
                    </Col> */}
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.InvoiceDate}
                            name="InvoiceDate"
                            label="InvoiceDate" 
                            type= 'date'
                            onChange= {e => {
                                this.setState({InvoiceDate:e.target.value});
                                this.setInRedux(e)
                            
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PaymentType"
                            id="PaymentType"
                            options={this._Options}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={this.state.SelectedPaymentType}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedPaymentType:value},()=>{
                                        if(value.value === 'Account Sale'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                        else if (value.value === 'Account Credit Sale'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Credit Sale'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false,SelectedBank:null})
                                        }
                                        else if (value.value === 'Cash Sale'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true,SelectedBank:null})
                                        }
                                    })
                                    const persistPaymentType = {
                                        target: {
                                          name: "SelectedPaymentType",
                                          value: value
                                        }
                                    }
                                    this.setInRedux(persistPaymentType)
                              
                                }
                                else
                                {
                                    this.setState({SelectedPaymentType:null})
                                    const persistPaymentType = {
                                        target: {
                                          name: "SelectedPaymentType",
                                          value: null
                                        }
                                    }
                                    this.setInRedux(persistPaymentType)
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PaymentType" label="Payment Type"
                                />}
                        />
                        <span className="text-danger">{this.state.Paymenttype_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="BankName"
                            id="BankName"
                            options={this.state.BankList}
                            getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                            value={this.state.SelectedBank}
                            disabled={this.state.Bank_Disable_Prop}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedBank:value});
                                    const persistBanks = {
                                        target: {
                                          name: "SelectedBank",
                                          value: value
                                        }
                                    }
                                    this.setInRedux(persistBanks)
                              
                                }
                                else
                                {
                                    this.setState({SelectedBank:null});
                                    const persistBanks = {
                                        target: {
                                          name: "SelectedBank",
                                          value: null
                                        }
                                    }
                                    this.setInRedux(persistBanks)
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="BankName" label="BankName"
                                />}
                        />
                        <span className="text-danger">{this.state.Bank_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalPaid"
                            label="TotalPaid" 
                            value={this.state.TotalPaid}
                            disabled={this.state.Total_Disable_Prop}
                            onInput={this.toInputUppercase}
                            onChange={e=>{
                                this.setState({TotalPaid:e.target.value},()=>{
                                    let bal =   this.state.TotalPaid - this.state.GrandTotal
                                    this.setState({Balance:bal})
                                    this.setInRedux({
                                        target: {
                                          name: "Balance",
                                          value: bal
                                        }
                                    })
                                })
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Balance"
                            label="Balance" 
                            value={this.state.Balance}
                            disabled={this.state.Balance_Disable_Prop}
                            // onInput={this.toInputUppercase}
                            inputProps={
                                {readOnly: true}
                            }
                            onChange={e=>{
                                this.setState({Balance:e.target.value})
                                this.setInRedux(e)
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>{
                                this.setState({Discription:e.target.value})
                                this.setInRedux(e)
                            }}
                            fullWidth
                        />
                    </Col> 
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="SONo"
                            label="Sale Order #" 
                            value={this.state.SONo}
                            onChange={e=>{
                                this.setState({SONo:e.target.value})
                                this.setInRedux(e)
                                if(e.target.value.length != 6 ){
                                    this.setState({SI_Detail_Data:[],
                                                    CustomerSchemeID:0,
                                                    SelectedCustomer:null,
                                                    AddTaxPer:0,
                                                    TotalQuantity:0,
                                                    TotalDiscount:0,
                                                    TotalDiscount1:0,
                                                    TotalTaxAmt:0,
                                                    InvoiceAmount:0,
                                                    GrandTotal:0,
                                                    AddTaxAmt:0,
                                                    WHTPer:0,
                                                    WHTAmt:0,
                                                    VATPer: 0,
                                                    VATAmt:0,
                                                    IncomeTaxPer:0,
                                                    IncomeTaxAmt:0,
                                                    Cash:0,
                                                    ChangeAmt:0
                                                })
                                }
                                if(e.target.value.length == 6){
                                    var _AddTaxPer = 0,_IncomeTaxPer=0,_WHTPer=0,_CustomerSchemeID = 0;
                                    SelectSaleOrderWRTCode(this.Comp_Id,this.ProjectID,e.target.value,async (mData)=>{
                                      if(mData.length > 0) {
                                       const SelectedCust =  this.state.CustomerList.find(x=> x.CustomerID === mData[0].CustomerID)
                                       if(SelectedCust){
                                        
                                        this.setState({CustomerSchemeID:_CustomerSchemeID,SelectedCustomer:SelectedCust,AddTaxPer:SelectedCust.AddTaxPer,IncomeTaxPer:SelectedCust.IncomeTaxPer,WHTPer:SelectedCust.WHTPer},()=>{
                                            this.Calculate_Taxes()
                                        })
                                        
                                       }
                                        SelectSaleOrderDetailWRTCode(this.Comp_Id,this.ProjectID,mData[0].SaleOrderID,async(mDetailData)=>{ 
                                            
                                            for(let i=0; i<mDetailData.length; i++){
                                                await new Promise (resolveForPolicy =>{
                                                    SelectSchemePolicy(this.Comp_Id,this.ProjectID,SelectedCust.CustomerID,mDetailData[i].ItemID,async(mSchemeData)=>{
                                                        let FlatRateData = mSchemeData[0]
                                                        let DiscountScheme = mSchemeData[1]
                                                        let BonusPolicy = mSchemeData[2]
                                                        if(FlatRateData[0].FRPolicyFound || DiscountScheme[0].DisPolicyFound || BonusPolicy[0].BonusPolicyFound){
                                                            mDetailData[i].IsSchemeApplied = 1
                                                        }
                                                        // Start Batch Work
                                                        await new Promise (resolveForBatch =>{
                                                            SelectBatchDetails(this.Comp_Id,this.ProjectID,mDetailData[i].ItemID, this.state.SelectedWareHouse.WareHouseID,(mItems)=>{
                                                                if(mItems.length > 0){
                                                                    mDetailData[i].BQty= mItems[0].BQty
                                                                    mDetailData[i].ExpairyDate =mItems[0].ExpairyDate ? mItems[0].ExpairyDate : ''
                                                                    if(FlatRateData[0].FRPolicyFound){
                                                                        const FlatRate = FlatRateData[0].FRData[0].ItemPolicyData[0].FlatRate 
                                                                        mDetailData[i].SaleRate =FlatRate
                                                                        mDetailData[i].MRP = mItems[0].MRP
                                                                    }else{
                                                                        mDetailData[i].SaleRate = mItems[0].PackSaleRate
                                                                        mDetailData[i].MRP = mItems[0].MRP
                                                                    }
                                                             
                                                                    mDetailData[i].BatchNo = mItems[0].batchno

                                                                }else{
                                                                    mDetailData[i].BQty= 0
                                                                    mDetailData[i].ExpairyDate = ''
                                                                    mDetailData[i].SaleRate = 0
                                                                    mDetailData[i].BatchNo = ''
                                                                    mDetailData[i].MRP = 0
                                                                }
                                                                resolveForBatch()
                                                            })
                                                        })
                                                        //End Batch Work
                                                        //Start Discount Work
                                                       
                                                       let normalizedData = this.normalizeData(DiscountScheme);
                                                       let DiscountMainArray =normalizedData.ItemPolicyData
                                                      if(DiscountMainArray){
                                                        DiscountMainArray.sort(function(a, b) {
                                                            return a.TargetFromQty - b.TargetFromQty;
                                                        });
                                                      }
                                                        
                                                        if(DiscountScheme[0].DisPolicyFound){
                                                            if(DiscountScheme[0].DisData){
                                                                let StartDate = DiscountMainArray[0].StartDate ;
                                                                let EndDate =  DiscountMainArray[0].EndDate;
                                                                let MaxLimit =  DiscountMainArray[0].QtyLimit;
                                                                let SoldQty =  DiscountScheme[0].DisData.SoldQty;
                                                                mDetailData[i].SoldValue = DiscountScheme[0].DisData.SoldValue ? DiscountScheme[0].DisData.SoldValue : 0;
                                                                mDetailData[i].AmountLimit = DiscountMainArray[0].AmountLimit ? DiscountMainArray[0].AmountLimit : 0;
                                                                mDetailData[i].StartDate = DiscountMainArray[0].StartDate ;
                                                                mDetailData[i].EndDate = DiscountMainArray[0].EndDate ;
                                                                mDetailData[i].OurShareDisPer = DiscountMainArray[0].OurSharePer || 0;
                                                               
                                                                let today = GetCurrentDate()
                                                                let PolicyArray = []
                                                                if(today >= StartDate && today <= EndDate){
                                                                     if(SoldQty <= MaxLimit || MaxLimit === 0 ){
                                                                        PolicyArray = DiscountMainArray.find(obj => obj.TargetFromQty <= mDetailData[i].Qty && obj.Criteria >= mDetailData[i].Qty);
                                                                       if(PolicyArray){
                                                                        mDetailData[i].DisPer = PolicyArray.Discount1Per
                                                                        mDetailData[i].DisPer1 = PolicyArray.Discount2Per
                                                                       }else{
                                                                        let maxid =  DiscountMainArray[DiscountMainArray.length-1].SchemeID;
                                                                        const minCriteria = DiscountMainArray[0].TargetFromQty;
                                                                        let chkmaxCriteria = DiscountMainArray[DiscountMainArray.length-1].Criteria;
                                                                        let maxDisQty 
                                                                        let maxDis2Qty
                                                                        if(DiscountMainArray.length > 1){
                                                                            maxDisQty = DiscountMainArray[DiscountMainArray.length-2].Discount1Per; // pick from 2nd last
                                                                            maxDis2Qty = DiscountMainArray[DiscountMainArray.length-2].Discount2Per;
                                                                        }else{
                                                                            maxDisQty = DiscountMainArray[DiscountMainArray.length-1].Discount1Per; // pick from 2nd last
                                                                            maxDis2Qty = DiscountMainArray[DiscountMainArray.length-1].Discount2Per;
                                                                       }
                                                                       PolicyArray =   DiscountMainArray.find(obj => obj.SchemeID === maxid);
                                                                   
                                                                       if( mDetailData[i].Qty <=minCriteria){
                                                                        mDetailData[i].DisPer = 0
                                                                         mDetailData[i].DisPer1 =0
                                                                       }else{
                                                                            if(chkmaxCriteria === 0){
                                                                                mDetailData[i].DisPer = maxDisQty
                                                                                 mDetailData[i].DisPer1 =maxDis2Qty
                                                                            }else if(chkmaxCriteria === 1){
                                                                                var mDisQty = Math.floor(mDetailData[i].Qty /  PolicyArray.TargetFromQty) 
                                                                                mDetailData[i].DisPer = mDisQty * maxDisQty
                                                                                 mDetailData[i].DisPer1 =mDisQty * maxDis2Qty
                                                                            }else{
                                                                                mDetailData[i].DisPer = maxDisQty
                                                                                 mDetailData[i].DisPer1 =maxDis2Qty
                                                                            }
                                                                       }
                                                                       }
                                                                      
                                                                     }else{
                                                                    mDetailData[i].DisPer = 0
                                                                    mDetailData[i].DisPer1 =0
                                                                     }
                                                                      
                                                                }else{
                                                                    mDetailData[i].DisPer = 0
                                                                    mDetailData[i].DisPer1 =0
                                                                }
                                                            }else{
                                                                mDetailData[i].DisPer = 0
                                                                mDetailData[i].DisPer1 = 0
                                                            }
                                                            
                                                        }else{
                                                            // mDetailData[i].DisPer = 0
                                                            // mDetailData[i].DisPer1 = 0
                                                        }
                                                        //End Discount Work
                                                        // Start Bonus Work 
                                                        let normalData = this.normalizeData(BonusPolicy);
                                                         let BonusPolicyMain = normalData.ItemPolicyData
                                                        
                                                         if(BonusPolicyMain){
                                                            BonusPolicyMain.sort(function(a, b) {
                                                                return a.TargetFromQty - b.TargetFromQty;
                                                            });
                                                         }
                                                       
                                                        if(BonusPolicy[0].BonusPolicyFound){
                                                            if(BonusPolicy[0].BonusData){
                                                                let StartDate = BonusPolicyMain[0].StartDate ;
                                                                let EndDate =  BonusPolicyMain[0].EndDate;
                                                                let today = GetCurrentDate()
                                                                let PolicyArray = []
                                                                mDetailData[i].OurShareBonusQty = BonusPolicyMain[0].OurSharePer
                                                                if(today >= StartDate && today <= EndDate){
                                                                    PolicyArray = BonusPolicyMain.find(obj => obj.TargetFromQty <= mDetailData[i].Qty  && obj.Criteria >= mDetailData[i].Qty );
                                                                   if(PolicyArray){
                                                                    mDetailData[i].BonusQty = PolicyArray.BonusQty
                                                                   }else{
                                                                    let maxid =  BonusPolicyMain[BonusPolicyMain.length-1].SchemeID;
                                                                    const minCriteria = BonusPolicyMain[0].TargetFromQty;
                                                                    let chkmaxCriteria = BonusPolicyMain[BonusPolicyMain.length-1].Criteria;
                                                                    let maxBonusQty
                                                                    let maxBonusCriteria 
                                                                    if(BonusPolicyMain.length > 1){
                                                                        maxBonusQty = BonusPolicyMain[BonusPolicyMain.length-2].BonusQty; // pick from 2nd last
                                                                        maxBonusCriteria = BonusPolicyMain[BonusPolicyMain.length-2].Criteria
                                                                    }else{
                                                                        maxBonusQty = BonusPolicyMain[BonusPolicyMain.length-1].BonusQty; // pick from 2nd last
                                                                        maxBonusCriteria = BonusPolicyMain[BonusPolicyMain.length-1].Criteria
                                                                   }
                                                                   PolicyArray =   BonusPolicyMain.find(obj => obj.SchemeID === maxid);
                                                                   if(mDetailData[i].Qty <= minCriteria){ // its means Qty not found in Range
                                                                    mDetailData[i].BonusQty =  0
                                                                    mDetailData[i].BonusCriteria = 0 
                                                                }else{
                                                                    if(chkmaxCriteria === 0){  // means Above false --> Not Duplicate or give extra bonus WRT qty
                                                                        mDetailData[i].BonusQty =   maxBonusQty
                                                                        mDetailData[i].BonusCriteria = maxBonusCriteria 
                                                                    }else if(chkmaxCriteria === 1){ // means Above true --> give extra bonus WRT qty
                                                                        var mBonusQty = Math.floor(mDetailData[i].Qty /  PolicyArray.TargetFromQty) 
                                                                        mDetailData[i].BonusQty = mBonusQty * maxBonusQty
                                                                        mDetailData[i].BonusCriteria = maxBonusCriteria 
                                                                    } else {
                                                                        mDetailData[i].BonusQty =   maxBonusQty
                                                                        mDetailData[i].BonusCriteria = maxBonusCriteria  
                                                                    }
                        
                                                                }
                                                                   }
                                                                 
                                                                }else{
                                                                    mDetailData[i].BonusQty = 0
                                                                    mDetailData[i].BonusCriteria = 0 
                                                                }
                                                            }else{
                                                                mDetailData[i].BonusQty = 0
                                                                mDetailData[i].BonusCriteria = 0
                                                            }
                                                        }else{
                                                            // mDetailData[i].BonusQty = 0
                                                            // mDetailData[i].BonusCriteria = 0
                                                        }
                                                        resolveForPolicy()
                                                        //End Bonus Work
                                                       
                                                    })
                                                })
                                                const GSTQty =  mDetailData[i].GST > 0 ? mDetailData[i].Qty + mDetailData[i].BonusQty : mDetailData[i].Qty;
                                                  mDetailData[i].SrNo  = i + 1;
                                                  mDetailData[i].DisAmt = parseFloat((((mDetailData[i].Qty * mDetailData[i].SaleRate)/ 100) *  mDetailData[i].DisPer).toFixed(2))
                                                 // mDetailData[i].TaxAmt = parseFloat((((mDetailData[i].Qty * mDetailData[i].SaleRate) / 100) *  mDetailData[i].TaxPer).toFixed(2))
                                                  if(SelectedCust.SaleTaxType == 'NOT REGISTER WITH GST' &&  mDetailData[i].GST > 0){
                                                    mDetailData[i].FTaxPer = 3 // hard coding 
                                                    mDetailData[i].FTaxAmt = parseFloat((((GSTQty * mDetailData[i].MRP) / 100) *  mDetailData[i].FTaxPer).toFixed(2))
            
                                                  }else{
                                                    mDetailData[i].FTaxPer = 0
                                                    mDetailData[i].FTaxAmt = 0
                                                  }
                                                  
                                                  mDetailData[i].GSTOnQty = parseFloat((((mDetailData[i].Qty  * mDetailData[i].MRP) / 100) *  mDetailData[i].GST).toFixed(2));
                                                  mDetailData[i].GSTOnBonus = parseFloat((((mDetailData[i].BonusQty  * mDetailData[i].MRP) / 100) *  mDetailData[i].GST).toFixed(2));
                                                  mDetailData[i].FTaxOnQty = parseFloat((((mDetailData[i].Qty  * mDetailData[i].MRP) / 100) *  mDetailData[i].FTaxPer).toFixed(2));
                                                  mDetailData[i].FTaxOnBonus = parseFloat((((mDetailData[i].BonusQty  * mDetailData[i].MRP) / 100) *  mDetailData[i].FTaxPer).toFixed(2));

                                                  mDetailData[i].TaxAmt = parseFloat((((GSTQty * mDetailData[i].MRP) / 100) *  mDetailData[i].GST).toFixed(2))
                                                  
                                                  mDetailData[i].Value = parseFloat(((((mDetailData[i].Qty * mDetailData[i].SaleRate) - mDetailData[i].DisAmt) +  mDetailData[i].TaxAmt + mDetailData[i].FTaxAmt)).toFixed(2))
                                                  mDetailData[i].DisAmt1 =  parseFloat((( mDetailData[i].Value / 100 ) * mDetailData[i].DisPer1 || 0 ).toFixed(2))
                                                  mDetailData[i].Value = parseFloat((mDetailData[i].Value - mDetailData[i].DisAmt1 ).toFixed(2))
                                                

                                                  //mDetailData[i].MRP = parseFloat((((mDetailData[i].Qty * mDetailData[i].MRP) / 100) *  mDetailData[i].TaxPer).toFixed(2))
                                                  mDetailData[i].action = 'add'
                                                  if(this.state.SaleTaxPer_Visible){
                                                    mDetailData[i].TaxPer = mDetailData[i].GST
                                                    }
                                                    else{
                                                        mDetailData[i].TaxPer = 0
                                                    }
                                            }
                                          
                                            this.setState({SI_Detail_Data:mDetailData},()=>{
                                            
                                                this.Calculate_Values()
                                                
                                            })
                                        })
                                      }
                                      else{
                                        Swal.fire({
                                            icon: 'info',
                                            title: 'Oops...',
                                            text: `No Data Found on this SO No`,
                                          })
                                      }
                                      
                                    })
                                }
                            }}
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                    <Autocomplete
                    name="InvoiceType"
                    id="InvoiceType"
                    options={this._TypeOptions}
                    getOptionLabel={(option) => option.title ? option.title : ""}
                    value={this.state.SelectedInvoiceType}
                    disabled = {!this.props.isAddNew}
                    onChange={(e, value) => {
                        if (value)
                        {
                            this.setState({SelectedInvoiceType:value});
                            const persistInvoiceType = {
                                target: {
                                  name: "SelectedInvoiceType",
                                  value: value
                                }
                            }
                            this.setInRedux(persistInvoiceType)  
                      
                        }
                    
                        else
                        {
                            const persistInvoiceType = {
                                target: {
                                  name: "SelectedInvoiceType",
                                  value: null
                                }
                            }
                            this.setInRedux(persistInvoiceType) 
                            this.setState({SelectedInvoiceType:null})
                    
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="InvoiceType" label="Invoice Type"
                        />}
                />
                <span className="text-danger">{this.state.InvoiceType_Err}</span> 
                </Col>
                </Row>
              
                <Row className="row_margin_left row_margin_right pr-4 pl-4" >
                    <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                        <div style={{ flex: "1", width: "100%", height: "100%" }}>
                            <GridComponent
                             gridLines='Both'
                             dataSource={this.state.SI_Detail_Data} 
                             queryCellInfo={(args) => {
                                args.cell.style.padding = '10px 5px';
                            }}
                            dataBound={() => {
                                document.querySelectorAll('.e-filtermenudiv').forEach(icon => icon.style.display = 'none');
                                document.querySelectorAll('.e-headercell').forEach(header => {
                                    header.style.padding = '5px';
                                });
                            }}
                             actionBegin={this.beforeRowInsert} 
                             ref={g => this.grid = g} 
                             rowDataBound={this.rowDataBound} actionComplete={this.afterRowInsert}
                             allowPaging={true} allowFiltering={true} allowSorting={true} 
                             editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: true, newRowPosition: 'Top' }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='30' field='SrNo' headerText='#' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='180' field='ItemDiscription' headerText='Product Name' editType='dropdownedit' editTemplate={this.ItemDiscription} edit={this.cbParams}  textAlign='Left' />
                                    <ColumnDirective width='110' field='BatchNo' headerText='BatchNo' editType='dropdownedit' editTemplate={this.BatchNo} edit={this.cbParams} textAlign='center' />
                                    <ColumnDirective width='80' field='BQty' headerText='B Qty' editTemplate={this.BQty} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='100' field='ExpairyDate' headerText='Expiry' editTemplate={this.ExpairyDate} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='80' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='center' />
                                    <ColumnDirective width='90' field='BonusQty' headerText='Bonus' editTemplate={this.BonusQty} textAlign='center' />
                                    <ColumnDirective width='100' field='SaleRate' headerText='SaleRate' editTemplate={this.SaleRate} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='80' field='Value' headerText='Value' editTemplate={this.Value} textAlign='right' ></ColumnDirective>

                                    <ColumnDirective width='80' field='MRP' headerText='MRP' editTemplate={this.MRP} textAlign='center'/>
                                    <ColumnDirective width='130' field='DefaultBatchNo' headerText='Default Batch' defaultValue={0} editTemplate={this.DefaultBatchNo}  textAlign='center'/>
                                    <ColumnDirective width='90' field='DisPer' headerText='Dis1%' visible={this.state.DisPer_Visible} editTemplate={this.DisPer} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='100' field='DisAmt' headerText='DisAmt1' visible={this.state.DisAmt_Visible} editTemplate={this.DisAmt} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='90' field='DisPer1' headerText='Dis2%' visible={this.state.DisPer_Visible} editTemplate={this.DisPer1} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='100' field='DisAmt1' headerText='DisAmt2' visible={this.state.DisAmt_Visible} editTemplate={this.DisAmt1} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='90' field='TaxPer' headerText={this.Comp_Id === 6 ? 'VAT%' : 'GST%'} visible={this.state.SaleTaxPer_Visible} editTemplate={this.TaxPer} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='100' field='TaxAmt' headerText={this.Comp_Id === 6 ? 'VAT Amt' : 'GST Amt'} visible={this.state.SaleTaxAmt_Visible} editTemplate={this.TaxAmt} textAlign='center'></ColumnDirective>
                                   
                                    <ColumnDirective width='90' field='FTaxPer' headerText='FTax%' visible={this.state.SaleTaxPer_Visible} editTemplate={this.FTaxPer} textAlign='center'></ColumnDirective>
                                    <ColumnDirective width='100' field='FTaxAmt' headerText='FTax Amt' visible={this.state.SaleTaxAmt_Visible} editTemplate={this.FTaxAmt} textAlign='center'></ColumnDirective>
                                    
                                    
                                    <ColumnDirective width='80' field='UOM' headerText='UOM' editTemplate={this.UOM}  />
                                   {/* <ColumnDirective width='130' field='MRP' headerText='MRP' visible={false}/>*/}
                                    <ColumnDirective width='200' field='MBatchNo'  defaultValue='' visible={false} ></ColumnDirective>
                                    <ColumnDirective width='130' field='IsFlatRate' headerText='IsFlatRate' defaultValue={this.IsFlatRate} visible={false}/>
                                    <ColumnDirective field='ScheduleGP' visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='ItemID' visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='StockQty' headerText='StockQty'  visible={false} textAlign='right' />
                                    <ColumnDirective field='MaxSaleQty'  visible={false} textAlign='right' />
                                    <ColumnDirective field='FlatRate'  visible={false} textAlign='right' />
                                    <ColumnDirective field='SaleInvoiceDetailID' headerText='SaleInvoiceDetailID' visible={false}></ColumnDirective>
                                    <ColumnDirective field='SaleInvoiceID' headerText='SaleInvoiceID' width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='SoldValue'  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='AmountLimit'  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='StartDate'  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='OurShareDisPer' defaultValue={0} hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='OurShareDisAmt' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='ClaimableDisAmt' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='IsSchemeApplied' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='IsClaimApplied' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='OurShareBonusQty' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='OurShareBonusAmt' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>

                                    <ColumnDirective width='150' headerText='GST on Qty'    field='GSTOnQty'    defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective width='150' headerText='GST on Bonus'  field='GSTOnBonus'  defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective width='150' headerText='FTax on Qty'   field='FTaxOnQty'   defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective width='150' headerText='FTax on Bonus' field='FTaxOnBonus' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>

                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                            </GridComponent>
                        </div>
                    </div>
                </Row>
                <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalQuantity"
                            label="TotalQuantity"
                            value={this.state.TotalQuantity}
                            inputProps={
                                { readOnly: true }
                            }
                            onChange={e => this.setState({ TotalQuantity: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDiscount"
                            label="TotalDiscount"
                            type='number'
                            value={this.state.TotalDiscount}
                            inputProps={
                                { readOnly: this.state.DisAmt_Visible }
                            }
                            onChange={e => {
                                this.setState({ TotalDiscount: e.target.value })


                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDiscount1"
                            label="TotalDiscount1"
                            type='number'
                            value={this.state.TotalDiscount1}
                            inputProps={
                                { readOnly: this.state.DisAmt_Visible }
                            }
                            onChange={e => {
                                this.setState({ TotalDiscount1: e.target.value })
                                // this.GrandAmount_Calculation(this.state.TotalDiscount1,e.target.value, this.state.TotalTaxAmt, this.state.InvoiceAmount)


                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalGSTAmtQty"
                            label={'GST Amt On Qty'} 
                            type='number'
                            value={this.state.TotalGSTOnQty}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalGSTAmtBonus"
                            label={'GST Amt On Bonus'} 
                            type='number'
                            value={this.state.TotalGSTOnBonus}
                            inputProps={
                                { readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalTaxAmt"
                            label={this.Comp_Id === 6 ? 'Total VAT Amt' : 'Total GST Amt'} 
                            type='number'
                            value={this.state.TotalTaxAmt}
                            inputProps={
                                { readOnly: this.state.DisAmt_Visible }
                            }
                            onChange={e => {
                                this.setState({ TotalTaxAmt: e.target.value })
                                // this.GrandAmount_Calculation(this.state.TotalDiscount,this.state.TotalDiscount1,e.target.value, this.state.InvoiceAmount)
                            }}
                            fullWidth
                        />
                    </Col>

                    <Col sm={12} lg={4} xl={4} md={2} >
                        <TextField
                            name="InvoiceAmount"
                            label="InvoiceAmount"
                            value={this.state.InvoiceAmount}
                            inputProps={
                                { readOnly: true }
                            }
                            onChange={e => this.setState({ InvoiceAmount: e.target.value })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddTaxPer"
                            label="AddTax U/S 236H %"
                            type='number'
                            value={this.state.AddTaxPer}
                            
                            inputProps={
                                { readOnly: true }
                            }
                            // onChange={e=>{
                            //     if(e.target.value){
                            //         this.setState({AddTaxPer:e.target.valueAsNumber},()=>{
                            //             this.Calculate_Taxes()
                            //         })
                            //     }
                            // }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddTaxAmt"
                            label="AddTax U/S 236 H Amt"
                            type='number'
                            value={this.state.AddTaxAmt}
                            
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    {/*<Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="IncomeTaxPer"
                            label="G S T %"
                            type='number'
                            value={this.state.IncomeTaxPer}
                            onChange={e => {
                                    this.setState({ IncomeTaxPer: e.target.valueAsNumber ?  e.target.valueAsNumber  : 0}, () => {
                                        this.Calculate_Taxes()
                                    })
                                
                            }}
                            fullWidth
                        />
                    </Col>*/}
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalFTaxOnQty"
                            label={'FTax Amt On Qty'} 
                            type='number'
                            value={this.state.TotalFTaxOnQty}
                            inputProps={
                                { readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalFTaxOnBonus"
                            label={'FTax Amt On Bonus'} 
                            type='number'
                            value={this.state.TotalFTaxOnBonus}
                            inputProps={
                                { readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="IncomeTaxAmt"
                            label="Further Tax Amt"
                            type='number'
                            value={this.state.IncomeTaxAmt}
                            
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                   {/*<Col sm={12} lg={2} xl={2} md={2} >  // just hide
                        <TextField
                            name="WHTPer"
                            label="WHT %"
                            type='number'
                            value={this.state.WHTPer}
                            onChange={e => {
                                    this.setState({ WHTPer: e.target.valueAsNumber ?  e.target.valueAsNumber  : 0}, () => {
                                        this.Calculate_Taxes()
                                    })
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} > // just hide
                        <TextField
                            name="WHTAmt"
                            label="WHTAmt"
                            type='number'
                            value={this.state.WHTAmt}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>*/} 
                {/*    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATPer"
                            label="VAT %"
                            type='number'
                            value={this.state.VATPer}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e => {
                                const newVATPer = e.target.value;
                                this.setState({ VATPer: newVATPer });
                                const mAmt = (this.state.GrandTotal * newVATPer) / 100;
                                const roundedVATAmt = parseFloat(mAmt.toFixed(2));
                                
                                this.setState({ VATAmt: roundedVATAmt },()=>{
                                    this.GrandAmount_Calculation(roundedVATAmt)
                                });
                                
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATAmt"
                            label="VAT Amount"
                            type='number'
                            value={this.state.VATAmt}
                            disabled={true}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e=>{
                                this.setState({VATAmt:e.target.value})
                            }}
                            fullWidth
                        />
                    </Col>*/} 
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="GrandTotal"
                            label="GrandTotal"
                            value={this.state.GrandTotal}
                            inputProps={{ style: { color: "green", fontWeight: 'bold', fontSize: '20px' } }}
                            fullWidth
                        />
                    </Col>
                </Row>
                <Row className="row_margin_left row_margin_right px-4 py-2" >
                     <Col sm={12} lg={2} xl={2} md={2} >
                         <TextField
                             name="Cash"
                             label="Cash"
                             value={this.state.Cash}
                             type='number'
                             onChange={e => {
                                 if (e.target.value) {
                                     this.setState({ Cash: e.target.valueAsNumber }, () => {
                                         this.calculate_changeAmt()
 
                                     })
                                 }
                             }
 
                             }
                             fullWidth
                         />
                     </Col>
                     <Col sm={12} lg={2} xl={2} md={2} >
                         <TextField
                             name="ChangeAmt"
                             label="ChangeAmt"
                             value={this.state.ChangeAmt}
                             inputProps={
                                 { readOnly: true }
                             }
                             onChange={e => this.setState({ ChangeAmt: e.target.value })}
                             fullWidth
                         />
                     </Col>
                     <Col style={{ textAlign: "right", marginTop: "1%" }} >
                         {/* <Link id="btnGetItems" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'#'} 
                              onClick={e=>{
                                  if(this.state.SelectedCustomer && this.state.SelectedWareHouse){
                                     this.show_OB_Modal()
                                  }
                                  else if(!this.state.SelectedCustomer){
                                     Swal.fire({
                                         icon: 'info',
                                         text: `Please Select Customer...`,
                                     })
                                  }
                                  else if(!this.state.SelectedWareHouse){
                                     Swal.fire({
                                         icon: 'info',
                                         text: `Please Select Warehouse...`,
                                     })
                                  }
                              }}> <ShowChart /><u>Get Items from Order Bookings</u></Link> */}
                         {this.state.btn_edit && (<button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.SaveHandler}  >
                         {
                              this.state.btn_Disabled ?
                                (<><Spinner
                                     as="span"
                                     animation="grow"
                                     size="sm"
                                     role="status"
                                     aria-hidden="true"
                                     /> Saving... </>) : <><Save /> Save</>
                                 }  </button>)}
                        
                         {this.state.btn_edit && ( 
                         <button
                             disabled={this.state.btn_Disabled}
                              className="btn btn-primary rounded-pill" 
                              style={{border: "none"}} 
                             
                              onClick={e =>{
                                 localStorage.setItem('token',sessionStorage.getItem('token'))
                                
                                 this.SaveHandler('fromPrint')
                              }}
                              >
                                  {
                              this.state.btn_Disabled ?
                                (<><Spinner
                                     as="span"
                                     animation="grow"
                                     size="sm"
                                     role="status"
                                     aria-hidden="true"
                                     /> Saving... </>) : <> <Print /> <u> Save & A4 Print</u></>
                                 } 
                                 </button>)}
                                 {/* {this.state.btn_edit && ( 
                         <button
                             disabled={this.state.btn_Disabled}
                              className="btn btn-primary rounded-pill" 
                              style={{border: "none"}} 
                             
                              onClick={e =>{
                                 localStorage.setItem('token',sessionStorage.getItem('token'))
                                
                                 this.SaveHandler('fromThermalPrint')
                              }}
                              >
                                  {
                              this.state.btn_Disabled ?
                                (<><Spinner
                                     as="span"
                                     animation="grow"
                                     size="sm"
                                     role="status"
                                     aria-hidden="true"
                                     /> Saving... </>) : <> <Print /> <u> Save & Thermal Print</u></>
                                 } 
                                 </button>)} */}
 
                         <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to={this.state.cancel_link} > <Cancel /> <u>Close</u></Link>
                     </Col>
                </Row>

            </Card>
            
            <SaleOrderModal 
                    show={this.state.Stock_Modal}
                    onHide = {this.Hide_Min_Stock_Modal}
                    CustomerID={this.state.SelectedCustomer && this.state.SelectedCustomer.CustomerID}
                    projectID={ this.ProjectID }
                    setIsCancel = {this.IsCancel}
                    isCancel={ this.state.IsCancel}
                />
                <OrderBookingModal 
                show={this.state.OrderBooking_Modal}
                onHide={this.hide_OB_Modal}
                customer={this.state.SelectedCustomer && this.state.SelectedCustomer}
                projectID={ this.ProjectID }
                mSaveStock = {this.LoadStockToGrid}
                />
                <Loader
                show={this.state.IsLoader}
                hide={this.hideLoader}
                />
     
           </Container>
          
        </div>, document.getElementById('PageContents')
    )
}
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.SI && state.SI.isAddNew,
    SaleInvoiceID:state.SI && state.SI.SaleInvoiceID,
    InvoiceType: state.SI && state.SI?.InvoiceType?.value || '',
    show_screen: state.SCREEN,
    Project_ID: state.SI && state.SI.Project_ID,
    FormDirection: state.FormDirection && state.FormDirection.formDirection,
    OrderBookingData: state.OB_TO_SI && state.OB_TO_SI.OB_data,
    CustomerFromOrderBooking: state.OB_TO_SI && state.OB_TO_SI.CustomerData,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
    salesData: state?.SaleInvoice 
})
const mapDispatchToProps = {
    setSaleInvoiceMaster,
    setSalesInvoiceDetails,
    removeSalesInvoiceDetail,
    updateSaleInvoice,
    clearSaleInvoiceData
 };
export default connect(mapStateToProps, mapDispatchToProps)(SaleInvoiceEntry)