export const LOGIN_USER = "LOGIN_USER"
export const COMPANIES = "COMPANIES";
export const GLOBAL_VARIABLE = "GLOBAL_VARIABLE";
export const DONOR = "DONOR"
export const RECEIVER = "RECEIVER"
export const EXCHANGER = "EXCHANGER"
export const USERACCESS = "USERACCESS"
export const USERS = "USERS"
export const IPD_REGISTRATION = "IPD_REGISTRATION"

export const INVENTORY_OPENING  = "INVENTORY_OPENING"
export const INVENTORY_DETAIL = 'INVENTORY_DETAIL';
export const REMOVE_INVENTORY_DETAIL = 'REMOVE_INVENTORY_DETAIL';
export const UPDATE_INVENTORY_DETAIL = 'UPDATE_INVENTORY_DETAIL';
export const CLEAR_INVENTORY_DATA = 'CLEAR_INVENTORY_DATA';

export const SALE_INVOICES = 'SALE_INVOICES';
export const SALE_INVOICE_DETAILS = 'SALE_INVOICE_DETAILS';
export const REMOVE_SALE_INVOICE_DETAILS = 'REMOVE_SALE_INVOICE_DETAILS'
export const UPDATE_SALE_INVOICE_DETAILS = 'UPDATE_SALE_INVOICE_DETAILS'
export const CLEAR_SALE_INVOICE_DATA = 'CLEAR_SALE_INVOICE_DATA'




export const TURN_NO = "TURN_NO";
export const SHOW_SCREEN = "SHOW_SCREEN"
// used for new project 
export const SUPPLIER = "SUPPLIER" 
export const CUSTOMER = "CUSTOMER"
export const OPENING = "OPENING"
export const BANK_OPENING = "BANK_OPENING"
export const COMPANY_OPENING = "COMPANY_OPENING"
export const CUSTOMER_OPENING = "CUSTOMER_OPENING"
export const MEDICINE = "MEDICINE"
export const PURCHASE_ORDER = "PURCHASE_ORDER" 
export const SALE_ORDER = "SALE_ORDER" 
export const CONFIGURATION = "CONFIGURATION" 
export const STOCKTRANSFER = "STOCKTRANSFER"
export const STOCKADJUSTMENT = "STOCKADJUSTMENT"
export const PURCHASE_INVOICE = "PURCHASE_INVOICE"
export const SALE_INVOICE = "SALE_INVOICE"
export const PAYMENTS = "PAYMENTS"
export const COMPANY_PAYMENTS = "COMPANY_PAYMENTS"
export const RECEIPTS = "RECEIPTS"
export const GLOBAL_PROJECT = "GLOBAL_PROJECT"
export const GLOBAL_CONFIG = "GLOBAL_CONFIG"
export const GLOBAL_PROJECTS_LIST = "GLOBAL_PROJECTS_LIST"
export const DELIVERY = "DELIVERY"
export const FROM_OB_TO_SI = "FROM_OB_TO_SI"
export const FORM_DIRECTION = "FORM_DIRECTION"
export const SCHEME = "SCHEME"
export const USER = "USER"
export const CLAIM_POLICY = " CLAIM_POLICY"
