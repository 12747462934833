import React,{useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card ,Spinner } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import {SelectManufacturedByList} from '../../Services/manufactureAPI'
import { SelectItemsListWRTManufactured, UpdateBatchRate } from '../../Services/ChangeRateAPI'
import { SelectBatchDetails } from '../../Services/StockTransferAPI'
import { GetCurrentDate } from '../../Services/Common'
import Swal from 'sweetalert2'

const ChangeRate = ({Comp_Id, ProjectID, ConfigObj}) => {
    const [ManufacturedBy_List, setManufacturedBy_List] = useState([])
    const [SelectedManufacturedBy_ListOption, setSelectedManufacturedBy_ListOption] = useState(null)
    const [Item_List, setItem_List] = useState([])
    const [SelectedItem, setSelectedItem] = useState(null)
    const [BatchList, setBatchList] = useState([])
    const [SelectedBatch, setSelectedBatch] = useState(null)
    const [BQty, setBQty] = useState('')
    const [ExpairyDate, setExpairyDate] = useState(GetCurrentDate())
    const [OldRate, setOldRate] = useState('')
    const [NewExpairyDate, setNewExpairyDate] = useState(GetCurrentDate())
    const [NewRate, setNewRate] = useState(0)


    useEffect(()=>{
        document.title = 'Change Rate'
        SelectManufacturedByList(Comp_Id, ProjectID, (mManufacture)=>{
            setManufacturedBy_List(mManufacture)
        })
    }, [])

    const SelectItemsListWRT_Manufactured = (ManufacturedByID) =>{
            SelectItemsListWRTManufactured(Comp_Id, ProjectID, ManufacturedByID, (mItems)=>{
                setItem_List(mItems)
            })
    }
    const Select_BatchDetails = (ItemID) =>{
            SelectBatchDetails(Comp_Id,ProjectID,ItemID,ConfigObj.DefaultWareHouseID,(mItems)=>{ // Api
                alert(JSON.stringify(mItems));
                setBatchList(mItems)
            })
    }
    const SaveHandler = () =>{
        if(SelectedManufacturedBy_ListOption){
            if(SelectedItem){
                if(SelectedBatch){
                    if(NewRate !== 0 && NewRate > 0){
                           var mObj = {
                            SaleRate: NewRate,
                            ExpairyDate: NewExpairyDate,
                            ItemID : SelectedItem.ItemID,
                            BatchNo : SelectedBatch.batchno
                           }
                           UpdateBatchRate(mObj , ()=>{
                            
                           })
                    }else{
                        Swal.fire({
                            title:'Information',
                            icon:'info',
                            text:'Please Enter New rate'
                        })
                    }
                   
                }else{
                    Swal.fire({
                        title:'Information',
                        icon:'info',
                        text:'Please Select Batch No'
                    })
                }
            }else{
                Swal.fire({
                    title:'Info',
                    icon:'info',
                    text:'Please select Item'
                })
            }

        }else{
            Swal.fire({
                title:'Info',
                icon:'info',
                text:'Please select Manufacturer'
            })
        }

    }
  return ReactDOM.createPortal(
    <>
    <Row className="row_margin_top row_margin_left row_margin_right" >
    <Col lg={6} xl={6} md={6} className="header_title" >
        <h2>Change Rate and Expairy</h2>
    </Col>
</Row>
<Card>
<Row className="row_margin_top row_margin_left row_margin_right m-2 mb-4" >
<Col lg={4} xl={4} md={4} >
<Autocomplete
    name="ManufacturedBy"
    id="ManufacturedBy"
    options={ManufacturedBy_List}
    getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""} 
    value={SelectedManufacturedBy_ListOption}
    onChange={(e,value) =>{
    if(value){
        setSelectedManufacturedBy_ListOption(value)
        SelectItemsListWRT_Manufactured(value.ManufacturedByID)
    }
    else{
        setSelectedManufacturedBy_ListOption(null)
        setItem_List([])
        setSelectedItem(null) 
        setBatchList([])
        setSelectedBatch(null)
        setBQty('')
        setOldRate('')
        setExpairyDate(GetCurrentDate())
    }   
    }}
    renderInput={(params) => 
    <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
    />}
/>
</Col>
<Col lg={4} xl={4} md={4} >
<Autocomplete
name="ItemDiscription"
id="ItemDiscription"
options={Item_List}
getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
value={SelectedItem}
onChange={(e, value) => {
    if (value) {
       setSelectedItem(value)
       Select_BatchDetails(value.ItemID)
    }
    else {
        setSelectedItem(null)
        setBatchList([])
        setSelectedBatch(null)
        setBQty('')
        setOldRate('')
        setExpairyDate(GetCurrentDate())
    }
}}
renderInput={(params) => 
    <TextField {...params} name="ItemDiscription" label="Item Discription"
    />}
/>
</Col>
<Col lg={4} xl={4} md={4} >
<Autocomplete
name="batchno"
id="batchno"
options={BatchList}
getOptionLabel={(option) => option.batchno ? option.batchno : ""}
value={SelectedBatch}
onChange={(e, value) => {
    if (value) {
       setSelectedBatch(value)
       setBQty(value.BQty)
       setExpairyDate(value.ExpairyDate)
       setNewExpairyDate(value.ExpairyDate)
       
       setOldRate(value.PackSaleRate)
    }
    else {
        setSelectedBatch(null)
        setBQty('')
        setExpairyDate(GetCurrentDate())
        setOldRate('')
    }
}}
renderInput={(params) => 
    <TextField {...params} name="batchno" label="Batch No"
    />}
/>
</Col>
                <Col lg={2} xl={2} md={2} >
                <TextField
                    value={OldRate}
                    name="OldRate"
                    label="Old Sale Rate"
                    type="text"
                    disabled = {true}
                    fullWidth
                />
                </Col>
            <Col lg={2} xl={2} md={2} >
            <TextField
                value={BQty}
                name="BQty"
                label="Batch Qty"
                type="text"
                disabled = {true}
                fullWidth
            />
            </Col>
            <Col lg={2} xl={2} md={2} >
            <TextField
                value={ExpairyDate}
                name="ExpairyDate"
                label="Expairy Date"
                type="date"
                disabled = {true}
                fullWidth
            />
            </Col>
            <Col lg={2} xl={2} md={2} >
            <TextField
                value={NewRate}
                name="NewRate"
                label="New Sale Rate"
                type="number"
                onChange={e=> setNewRate(e.target.value)}
                fullWidth
            />
            </Col>
            <Col lg={2} xl={2} md={2} >
            <TextField
                value={NewExpairyDate}
                name="NewExpairyDate"
                label="New Expairy Date"
                type="date"
                onChange={e=> setNewExpairyDate(e.target.value)}
                fullWidth
            />
            </Col>
    
        </Row>
        <Row className="row_margin_top row_margin_left row_margin_right m-2 mb-4" >
        <Col style={{textAlign:'right'}} className='mr-4'>
        <button type='button' className="btn btn-primary rounded-pill"  style={{border: "none", width:'150px'}} onClick={SaveHandler}>Save</button>
        <button type='button' className="btn btn-primary rounded-pill"  style={{border: "none", width:'150px'}}>Close</button>
        </Col>
                
        </Row>


</Card>
    </>, document.getElementById('PageContents')
  )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,

  })
  export default connect(mapStateToProps)(ChangeRate)
