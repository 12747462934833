import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptCustomerWiseRegister, rptCustomerWiseRegisterWithDis,rptCustomerWiseRegisterWithBonus } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { SelectManufacturedByList  } from '../../../Services/manufactureAPI'
import { SelectCityWiseAreas } from '../../../Services/AreasAPI'
import { GetUserCG } from '../../../Services/ClaimAPI';

function BatchWiseSaleReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj,UserType}) {

    const { ProjectID,ManufacturedByID,CGID,CustomerID,FromDate,ToDate } = useParams();
    const [ItemData, setItemData] = useState([]);
    const [TotalValue, setTotalValue] = useState(0);
    const [TotalQty, setTotalQty] = useState(0)
    const [SelectedProject, setSelectedProject] = useState(null)
    const [Manufacturer,setManufacturer] = useState('')
    const [CGName, setCGName] = useState("")
    const [CustomerName, setCustomerName] = useState("")
    useEffect(() => {

        document.title = "Distribution / Customer-Wise Sale Report" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
            var mData = localStorage.getItem('CustomerWiseSAleRegisterReport')
            var withDis = JSON.parse(mData).isWithDis
            var withBonus = JSON.parse(mData).isWithBonus
                if(withDis){
                rptCustomerWiseRegisterWithDis(Comp_Id,ProjectID,ManufacturedByID,CGID,CustomerID,FromDate,ToDate,(mDataWithDis)=>{
                    mBody(mDataWithDis)
            })
                }else if(withBonus){
                    rptCustomerWiseRegisterWithBonus(Comp_Id,ProjectID,ManufacturedByID,CGID,CustomerID,FromDate,ToDate,(mDataWithBonus)=>{
                        mBody(mDataWithBonus)
                })
                }
                else{
                rptCustomerWiseRegister(Comp_Id,ProjectID,ManufacturedByID,CGID,CustomerID,FromDate,ToDate,(mArray)=>{
                    mBody(mArray)
            })
            }  
      
            const mBody = async(mData) =>{
                if(UserType === 'Manger Portal'){
                    const res = await GetUserCG(User_Id)
                    const SelectedUserCG = res.data
                    mData = mData.filter(item => SelectedUserCG.some(cg => cg.CGID === item.CGID));
                }
                var mArray = []
                mData.map((value,index)=>{
                    if(mData[index - 1]){
                        if(mData[index - 1].CustomerName === value.CustomerName){
                            if(mData[index - 1].CGName === value.CGName){
                                mArray.push(value)
                            }
                            else{
                                if(value.CustomerName !== null){
                                mArray.push({GroupTitle : value.CGName})
                                }
                                mArray.push(value)
                            }
                           
                        }else{
                            if(value.CustomerName !== null){
                            mArray.push({CustomerTitle : value.CustomerName, CustomerCode: value.CustomerCode, SaleInvoiceNo: value.SaleInvoiceNo, InvoiceDate: value.InvoiceDate})
                            mArray.push({GroupTitle : value.CGName})
                            }
                            mArray.push(value)
                        }
                    }
                    else{
                        if(value.CustomerName !== null){
                            mArray.push({CustomerTitle : value.CustomerName, CustomerCode : value.CustomerCode, SaleInvoiceNo: value.SaleInvoiceNo, InvoiceDate: value.InvoiceDate})
                            mArray.push({GroupTitle : value.CGName})
                        }
                        mArray.push(value)
                    }
                })
                
                setItemData(mArray)
            }
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
            mProjects.map(value=>{
                if(value.Project_ID == ProjectID){
                    setSelectedProject(value)
                }
            })
        })
        if(ManufacturedByID == "0"){
            setManufacturer('ALL COMPANIES')
        }
        else{
            SelectManufacturedByList(Comp_Id,ProjectID,(data) =>{
                data.map(mvalue=>{
                    if(mvalue.ManufacturedByID == ManufacturedByID ){
                        setManufacturer(mvalue.ManufacturedBy)
                    }
                })
            })

        }
       
        var _CustomerWiseSAleRegisterReport = JSON.parse(localStorage.getItem("CustomerWiseSAleRegisterReport"))
        if(_CustomerWiseSAleRegisterReport){
            setCustomerName(_CustomerWiseSAleRegisterReport.CustomerName)
            setCGName(_CustomerWiseSAleRegisterReport.CompanyGroup)
        }
        localStorage.removeItem("token");
    }, [])
    return (
        <>
        
        <div id="main_div_report" style={{margin: "16px"}}>
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2 style={{fontSize:"24px"}}> {Comp_Name} </h2> 
                    <br /> 
                    <h3 style={{fontSize:"20px", marginBottom:"10px"}}>Customer-Wise Sale Register {JSON.parse(localStorage.getItem('CustomerWiseSAleRegisterReport')).isWithDis ? 'With Dis' :
                    JSON.parse(localStorage.getItem('CustomerWiseSAleRegisterReport')).isWithBonus? 'With Bonus' : ''}</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black"}}>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'2%', }} >
                    {/* <Row >
                        <Col lg={3} md={3} xl={3} >
                         <b>Branch:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SelectedProject && SelectedProject.ProjectName}</b>
                        </Col>
                    </Row> */}
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                    <b>Branch:</b>
                    <b>{SelectedProject && SelectedProject.ProjectName}</b>
                    </div>
                    
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'2%', }} >
                    {/* <Row>
                        <Col lg={5} md={5} xl={5} >
                        
                        </Col>
                        <Col lg={7} md={7} xl={7} >
                        <b>{Manufacturer}</b>
                        </Col>
                    </Row> */}
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                    <b>Company:</b>
                    <b>{Manufacturer}</b>
                    </div>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'2%', }} >
                    {/* <Row>
                        <Col lg={5} md={5} xl={5} >
                         <b>Company Group:</b>
                        </Col>
                        <Col lg={7} md={7} xl={7} style={{ textAlign: 'left'}}>
                         <b>{CGName}</b>
                        </Col>
                    </Row> */}
                    <div style={{display:"flex", justifyContent:"space-around"}}>
                    <b>Company Group:</b>
                    <b>{CGName}</b>
                    </div>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'2%', }} >
                    {/* <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Customer:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{CustomerName}</b>
                        </Col>
                    </Row> */}
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                    <b>Customer:</b>
                    <b>{CustomerName}</b>
                    </div>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'2%', }} >
                    {/* <Row>
                        <Col lg={5} md={5} xl={5} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={7} md={7} xl={7} >
                        <b>{FromDate}</b>
                        </Col>
                    </Row> */}
                     <div style={{display:"flex", justifyContent:"space-between"}}>
                    <b>From Date:</b>
                    <b>{FromDate}</b>
                    </div>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'2%', }} >
                    {/* <Row>
                        <Col lg={5} md={5} xl={5} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={7} md={7} xl={7} >
                         <b>{ToDate}</b>
                        </Col>
                    </Row> */}
                     <div style={{display:"flex", justifyContent:"space-around"}}>
                    <b>To Date:</b>
                    <b>{ToDate}</b>
                    </div>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Item Code</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Item Name</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Packing</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Price</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Bonus</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Dis1 %</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Dis2 %</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Gst %</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Value</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            
                            ItemData.map((Itemdata, index) => {
                                if(Itemdata.CustomerTitle){
                                    return(
                                        <>
                                        <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none',textAlign:'left'}}>
                                        <td colSpan={10} style={{ 
                                            width: '10%', 
                                            borderCollapse: "collapse", 
                                            verticalAlign: 'top', 
                                            paddingBottom: '5px', 
                                            paddingTop: '5px',
                                            fontWeight:'bold',
                                            color: "red"
                                            }}  >Customer: {Itemdata.CustomerCode + "/ " + Itemdata.CustomerTitle }</td>
                                       </tr>
                                        <tr key={index} style={{textAlign:'left'}}>
                                        <td colSpan={10} style={{ 
                                            width: '10%', 
                                            borderCollapse: "collapse", 
                                            verticalAlign: 'top', 
                                            paddingBottom: '5px', 
                                            paddingTop: '5px',
                                            fontWeight:'bold',
                                            color: "blue"
                                            }}  >Invoice No: { Itemdata.SaleInvoiceNo} Invoice Date: {Itemdata.InvoiceDate}</td>
                                       </tr>
                                       </>
                                    )
                                }
                                else  if(Itemdata.GroupTitle){
                                    return(
                                        <tr key={index} style={{border:'none',borderLeft:'none',borderRight:'none',borderBottom:'none',textAlign:'left'}}>
                                        <td colSpan={10} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold', }}  >{Itemdata.GroupTitle}</td>
                                       </tr>
                                    )
                                }
                                 else if(Itemdata.ItemID === null && Itemdata.CGID !== null && Itemdata.CustomerName !== null){
                                    return(
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'2px Solid Black',textAlign:'center'}}>
                                    <td colSpan={5} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold',textAlign:'right' }}  >Group Total :</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.BonusQty}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.DisPer}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.DisPer1}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.TaxPer}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.Value}</td>
                                   </tr>)
                                  }
                                    
                                  else if(Itemdata.ItemID === null && Itemdata.CGID === null && Itemdata.CustomerName !== null){
                                    return(
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'2px Solid Black',textAlign:'center'}}>
                                    <td colSpan={5} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold',textAlign:'right'  }}  >Customer Total :</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.BonusQty}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.DisPer}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.DisPer1}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.TaxPer}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.Value}</td>
                                   </tr>)
                                  }
                                  else if(Itemdata.ItemID === null && Itemdata.CGID === null && Itemdata.CustomerName === null){
                                    return(
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'2px Solid Black',textAlign:'center'}}>
                                    <td colSpan={5} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold',textAlign:'right'  }}  >Grand Total :</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.BonusQty}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.DisPer}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.DisPer1}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.TaxPer}</td>
                                    <td style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.Value}</td>
                                   </tr>)
                                  }
                                  else if(Itemdata.ItemID && Itemdata.CGID && Itemdata.CustomerName){
                                      return(
                                        <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.ItemCode}</td>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemDiscription}</td>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Remarks}</td>
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Qty}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleRate !== "" && (parseFloat(Itemdata.SaleRate)).toFixed(2)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BonusQty} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {(Itemdata.DisPer).toFixed(2)} </td>                              
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {(Itemdata.DisPer1).toFixed(2)} </td>                              
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {(Itemdata.TaxPer).toFixed(2)} </td>                              
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {(Itemdata.Value).toFixed(2)} </td>

                                    </tr>
                                      )
                                  }
                               
                            })
                            
                        }
                       
                   
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
    UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})

export default connect(mapStateToProps)(BatchWiseSaleReport)
