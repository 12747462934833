import React, { useEffect, useState } from 'react'
import  './style.css'
import { getOpeningRecordsForEdit } from '../../Services/OpeningAPI';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from '@material-ui/core';

const RptOpening = () => {
  const {InventoryMasterID, Comp_Id, ProjectID} = useParams()
 const [masterData, setMasterData] = useState([])
 const [detailsData, setDetailsData] = useState([])
 const [totalGST, setTotalGST] = useState(0.00)
 const [totalInvoice, setTotalInvoice] = useState(0.00)
  useEffect(() => {
    document.title = "Inventory Opening";


    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('content_Page').hidden = true;
    document.getElementById('RSidebar').hidden = true;

    getOpeningRecordsForEdit(InventoryMasterID, Comp_Id, ProjectID, (res)=>{
      if(res){
        calculateGST(res.detailsData, res.masterData)
        setMasterData(res.masterData)
        setDetailsData(res.detailsData)
      }
    })
  }, [InventoryMasterID, Comp_Id, ProjectID])


  // Calculate total invoice value\
  const calculateGST = (detailsData, masterData)=>{
    let totalGST = 0;
    detailsData.map(item=>{
      if(item.GST && item.GST > 0){
        const value = item.Qty * item.MRP
        totalGST += (parseFloat(value) / 100) * parseFloat(item.GST);
      }
    })
    const total = totalGST + masterData[0]?.Total
    setTotalGST(parseFloat(totalGST).toFixed(2))
    setTotalInvoice(parseFloat(total).toFixed(2))
  }

  return (
    <div className="report-container">
           <div style={{display: 'flex',  justifyContent:'right'}}>
             <button style={{ margin: '20px'}} className="btn btn-primary rounded-pill hide-on-print" onClick={() => window.print()}>Print</button>
             <button style={{ margin: '20px'}} className="btn btn-primary rounded-pill hide-on-print" onClick={() =>{
               window.location.href = '/OpeningList';
              setTimeout(() => {
                window.location.reload();
              }, 100);
              }}>Close</button>
        </div>
      <h1 className="report-header">Inventory Opening Invoice</h1>

      {/* Master Data */}
      <div className="master-data">
        {masterData.map((item) => (
          <div key={item.OpeningNo} className="master-item">
            <div className="left">
              <p><strong>Opening No:</strong> {item.OpeningNo}</p>
              <p><strong>Transaction Date:</strong> {item.TransactionDate}</p>
            </div>
            <div className="right">
              <p><strong>Company Name:</strong> {item.Manufacturedby}</p>
              <p><strong>Company Group:</strong> {item.CGName}</p>
              <p><strong>Total:</strong> {item.Total}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Details Table */}
      <table className="details-table">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Batch No</th>
            <th>Expairy Date</th>
            <th>Qty</th>
            <th>Sale Rate</th>
            <th>Purchase Rate</th>
            <th>MRP</th>
            <th>GST</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {detailsData.map((item, index) => (
            <tr key={index}>
              <td>{item.ItemDiscription}</td>
              <td>{item.BatchNo}</td>
              <td>{item.ExpairyDate}</td>
              <td>{item.Qty}</td>
              <td>{item.SaleRate}</td>
              <td>{item.PurchaseRate}</td>
              <td>{item.MRP}</td>
              <td>{item.GST}</td>
              <td>{item.Amount}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Total Invoice */}
      <div className="total-invoice">
        <div className='row-style'>
          <strong> Invoice Amt:</strong> {masterData[0]?.Total}
        </div>
        <div className='row-style'>
        <strong> GST Amt:</strong> {totalGST}
        </div>
        <div className='row-style'>
        <strong> Total Invoice Amt:</strong> {totalInvoice}
        </div>
      </div>
    </div>
  );
}

export default RptOpening