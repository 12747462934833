import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import './InvoicePrint.css'
import { GetCurrentTimeAM_PM, GetCurrentDate  ,Convert_Date_To_Local_Format} from '../../Services/Common'
function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function PurchaseInvoiceReport({ CompName,User_Name,ConfigObj }) {
    const { Comp_Id, InvID } = useParams()
    const [Header, setHeader] = useState('')
    const [InvoiceTableData, setInvoiceTableData] = useState([])
    const [Inv, setInv] = useState('')
    const [InvDate, setInvDate] = useState('')
    const [Supplier, setSupplier] = useState('')
    const [Godown, setGodown] = useState('')
    const [PaymentType, setPaymentType] = useState('')
    const [BankName, setBankName] = useState('')
    const [CGName, setCGName] = useState(' ')
    var [TotalQty, setTotalQty] = useState(0.00)
    var [TotalNetAmt, setTotalNetAmt] = useState(0.00)
    const [GrandTotal, setGrandTotal] = useState(0.00)
    const [WHTPer, setWHTPer] = useState(0)
    const [WHTAmt, setWHTAmt] = useState(0.00)
    const [PurchaseValue, setPurchaseValue] = useState(0)
    const [AddTaxAmt, setAddTaxAmt] = useState(0)
    const [AddTaxPer, setAddTaxPer] = useState(0)
    const [TotalGST, setTotalGST] = useState(0)
    useEffect(() => {
       
        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
        

       var _Data = JSON.parse(localStorage.getItem("PurchaseInvoice"))
       debugger
       setInv(_Data.InvoiceNo)
       setInvDate(Convert_Date_To_Local_Format(_Data.PurchaseDate))
       setSupplier(_Data.Supplier.SupplierName)
       setGodown(_Data.Warehouse.WareHouse)
       setPaymentType(_Data.PaymentType.value)
       setBankName(_Data.Bank && _Data.Bank.BankName)
       setInvoiceTableData(_Data.Detail)
       setCGName(_Data.CG.CGName)
       setGrandTotal(_Data.GrandTotal)
       setPurchaseValue(_Data.PurchaseValue)
       setWHTAmt(_Data.WHTAmt)
       setWHTPer(_Data.WHT)
       setAddTaxAmt(_Data.AddvTaxAmt)
       setAddTaxPer(_Data.AddvTaxPer)
       setHeader(_Data.StockType.title)
       setTotalGST(_Data.TotalTaxAmt1)
       
    }, [])

    return (
        <div className='size bg-white'  >
             <div style={{display: 'flex',  justifyContent:'right'}}>
                        <button style={{ margin: '20px'}} className="btn btn-primary rounded-pill hide-on-print" onClick={() => window.print()}>Print</button>
                        </div>
            <Row className='between-rows-margin between-left-margin between-right-margin' style={{color:'black'}}>
                <Col sm={3} md={3} xl={3}>
                <img src={ConfigObj.Logo} width="100" height="100" />
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'center' }}>
                    <h3 style={{ textAlign: 'center' }}> {CompName} </h3> <br /> <h4>{Header}</h4>
                </Col>
            </Row>

            <Row className='between-rows-margin between-left-margin between-right-margin' style={{color:'black'}} >

                <Col lg={8} md={8} >
                    <Row lg={12} md={12} xl={12} >
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv.#
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Inv }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv Date:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { InvDate }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Supplier:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Supplier }
                        </Col>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Godown:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Godown }
                        </Col>
                    </Row>
                </Col>
                <Col lg={4} md={4} >

                    <Row sm={12} md={12} xl={12}>
                        
                        <Col sm={5} md={5} xl={5} style={{ fontWeight: "bold" }}>
                            Payment Type:
                        </Col>
                        <Col sm={7} md={7} xl={7}>
                            { PaymentType }
                        </Col>
                        <Col sm={5} md={5} xl={5} style={{ fontWeight: "bold" }}>
                            Bank Name:
                        </Col>
                        <Col sm={7} md={7} xl={7}>
                            { BankName }
                        </Col>
                        <Col sm={5} md={5} xl={5} style={{ fontWeight: "bold" }}>
                            Company Group:
                        </Col>
                        <Col sm={7} md={7} xl={7}>
                            { CGName }
                        </Col>

                    </Row>
                </Col>
            </Row>


            <Row className='between-rows-margin between-left-margin between-right-margin' >
                <Col>
                    <div id='table_sec_invprt'>
                        <table id='main_table_invoice_pr' style={{ marginBottom: "20px" }}>
                    <thead>
                        <tr style={{color:'black'}}>
                            <th style={{ textAlign: 'center' }}> Sr # </th>
                            <th style={{ textAlign: 'left' }}> Item Description </th>
                            <th style={{ textAlign: 'center' }}> Qty </th>
                            <th style={{ textAlign: 'center' }}> UOM </th>
                            <th style={{ textAlign: 'center' }}> Batch No </th>
                            <th style={{ textAlign: 'center' }}> Expairy Date </th>
                            <th style={{ textAlign: 'right' }}> Rate </th>
                            <th style={{ textAlign: 'right' }}> Discount </th>
                            <th style={{ textAlign: 'right' }}>  </th>
                            <th style={{ textAlign: 'right' }}> Tax </th>
                            <th style={{ textAlign: 'right' }}> Amount </th>
                        </tr>
                    </thead>

                    <tbody >
                        {
                            InvoiceTableData.map((invoicetabledata, index) => {
                                TotalQty += parseFloat(invoicetabledata.Qty)
                                TotalNetAmt += invoicetabledata.Value
                                return(                               
                                    <tr key={index} style={{color:'black'}}>
                                        <td style={{ textAlign: 'center' }}> {index + 1} </td>
                                        <td style={{ textAlign: 'left' }}> {invoicetabledata.ItemDiscription} </td>
                                        <td style={{ textAlign: 'center' }}> {invoicetabledata.Qty} </td>
                                        <td style={{ textAlign: 'center' }}> {invoicetabledata.UOM} </td>
                                        <td style={{ textAlign: 'center' }}> {invoicetabledata.BatchNo} </td>
                                        <td style={{ textAlign: 'right' }}> {Convert_Date_To_Local_Format(invoicetabledata.ExpairyDate)} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.PurchaseRate} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.DisAmt} </td>
                                        <td style={{ textAlign: 'right' }}>  </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.TaxAmt1} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.Value} </td>
                                    </tr>                                
                            )
                        })
                        }

                    </tbody>
                    <tfoot>
                        <tr style={{color:'black'}}>
                            <td style={{ textAlign: 'right' }} colSpan='2'  > Total:</td>
                            <td style={{ textAlign: 'center' }}>{TotalQty}</td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}>{TotalGST}</td>
                            <td style={{ textAlign: 'right' }}>{GrandTotal}</td>
                        </tr>
                        
                       
                    </tfoot>
                </table>
            </div>
                </Col>
            </Row >
            <Row className='between-rows-margin between-left-margin between-right-margin' style={{color:'black'}} >

                <Col lg={8} md={8} >
                    <Row lg={12} md={12} xl={12} >
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            WHT %:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { WHTPer }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            WHT Amount:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { WHTAmt }
                        </Col>
                    </Row>
                   {/*<Row lg={12} md={12} xl={12} >
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Add Tax %:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { AddTaxPer }
                        </Col>
                    </Row>*/} 

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Add Tax Amount:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { TotalGST }
                        </Col>
                    </Row>
                  
                </Col>
                <Col lg={4} md={4} >

                    <Row sm={12} md={12} xl={12}>
                        
                       <Col sm={5} md={5} xl={5} style={{ fontWeight: "bold" }}>
                          {/* Purchase Value:*/}
                        </Col>
                        <Col sm={7} md={7} xl={7}>
                          {/*  { PurchaseValue }*/}
                        </Col>
                        <Col sm={5} md={5} xl={5} style={{ fontWeight: "bold" }}>
                            Grand Total:
                        </Col>
                        <Col sm={7} md={7} xl={7}>
                            { GrandTotal }
                        </Col>
                       
                    </Row>
                </Col>
            </Row>

            <Row className="divFooter" style={{ marginTop: "3%" }} >
               <p>{ConfigObj && ConfigObj.PurchaseInvoiceFooterLine1 && ConfigObj.PurchaseInvoiceFooterLine1 !== '' && ConfigObj.PurchaseInvoiceFooterLine1 != 'null' && ConfigObj.PurchaseInvoiceFooterLine1}
               <br />
               {ConfigObj && ConfigObj.PurchaseInvoiceFooterLine2 && ConfigObj.PurchaseInvoiceFooterLine2 !== '' && ConfigObj.PurchaseInvoiceFooterLine2 != 'null' && ConfigObj.PurchaseInvoiceFooterLine2 }
            </p>
            </Row>
           
        </div>

    )
}

const mapStateToProps = state => ({
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    CompName: state.GlobalVariables.Company.Name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
   
})

export default connect(mapStateToProps)(PurchaseInvoiceReport)

// export default InvoicePrint