import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { CustomerwiseTaxes } from '../../../Services/TaxReportAPI';

const SalesTaxReturnReport = ({Comp_Name,Comp_Id,User_Name,ConfigObj}) => {
  const {  ProjectID, ManufacturedByID,CityID, CustomerID, FromDate, ToDate, IsFiler, IsGST, IsFurther } = useParams();
  debugger
  const [CustomerTaxData, setCustomerTaxData] = useState([])
  const [TotalTaxData, setTotalTaxData] = useState([])
  const [UsTotal, setUsTotal] = useState(0)
  const [GsTTotal, setGsTTotal] = useState(0)
  const [FTotal, setFTotal] = useState(0)
  useEffect(()=>{
    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('content_Page').hidden = true;
    document.getElementById('RSidebar').hidden = true;

    fetchData()
  },[])

  const fetchData = async()=>{
    CustomerwiseTaxes(Comp_Id, ProjectID, ManufacturedByID,CityID, CustomerID, FromDate, ToDate, (mData) =>{
        let mTotals = mData[mData.length-1];
        let mUsTotal  = 0
        let mGsTotal = 0
        let mFTotal = 0
        mData.pop()
        mData.map(item=>{
            if(item.InvoiceDate === ''){
             mUsTotal += item.USTaxAmt
             mGsTotal += item.TotalGST
             mFTotal += item.FTax
            }
         })
         setUsTotal(mUsTotal)
         setGsTTotal(mGsTotal)
         setFTotal(mFTotal)
        setCustomerTaxData(mData)
        setTotalTaxData(mTotals)
    })
  }
  return (
    <>  
    <div id="main_div_report" >
        {/* <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '1%' }}>
            <Col sm={12} md={12} xl={12}>
                <h2> {Comp_Name} </h2> <br /> <h3>Customer Ledger</h3>
            </Col>
        </Row> */}
        <Row  style={{ textAlign: 'center', color: "black" }}>
        <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
        <Col sm={6} md={6} xl={6}>
        <h2> {Comp_Name} </h2> <br /> <h3>Customer Wise Sales Taxes</h3>
    </Col>
        </Row>
            
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={5} md={5} xl={5} style={{ textAlign: 'left' }} >
                <Row>
                    <Col lg={3} md={3} xl={3} >
                        <b>From Date:</b>
                    </Col>
                    <Col lg={9} md={9} xl={9} >
                        {ReverseDate(FromDate)}
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={5} md={5} xl={5} style={{ textAlign: 'left' }} >
                <Row>
                    <Col lg={3} md={3} xl={3} >
                    <b>To Date:</b>
                    </Col>
                    <Col lg={9} md={9} xl={9} >
                    {ReverseDate(ToDate)}
                    </Col>
                </Row>
            </Col>
            <Col sm={1} md={1} xl={1}></Col>
        </Row>
        <div id='table_div' className="row-margin-top" >
            <table id='report_table' className='report_table' width="100%" style={{marginLeft:'2%',marginRight:'2%'}}>
                <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                        <th id='date_td' style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }}   >Date</th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} > Registration No</th>
                        <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Buyer Name</th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >Buyer Type</th>
                        <th style={{ width: '8%', textAlign: 'center', fontWeight: "bold" }} >District</th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >Sales Type</th>
                        <th style={{ width: '8%', textAlign: 'center', fontWeight: "bold" }} >Rate</th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                       { IsFiler === "true"   && <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Add Tax U/S 236H</th>}
                       { IsGST   === "true"   && <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >GST</th>}
                       { IsFurther  === "true" && <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Further Tax</th>}
                     
                    </tr>
                </thead>

                <tbody
                    style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                   
                    {
                       
                        CustomerTaxData.map((customerdata, index) => {
                            if(customerdata.InvoiceDate === 'Sale Invoice' || customerdata.InvoiceDate === 'Sale Return'){
                                return <tr key={index}>
                                <td style={{ width: '5%', textAlign: 'center', fontWeight:'bold',fontSize:'20px', borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.InvoiceDate || customerdata.InvoiceDate != '' }</td>
                                <td style={{ width: '5%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}></td>
                                <td style={{ width: '10%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}></td>
                                <td style={{ width: '5%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                <td style={{ width: '8%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > </td>
                                <td style={{ width: '5%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                <td style={{ width: '8%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                <td style={{ width: '5%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                {IsFiler   === "true"  && <td style={{ width: '10%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>}
                                {IsGST     === "true"  && <td style={{ width: '5%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>}
                                {IsFurther === "true" && <td style={{ width: '10%', textAlign: 'center',  borderBottom: "2px solid black",borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>}
                                </tr>
                            }
                            if(customerdata.InvoiceDate === '' ){
                                return <tr key={index}>
                                <td style={{ width: '5%', textAlign: 'center', fontWeight:'bold',fontSize:'20px',borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                <td style={{ width: '5%', textAlign: 'center',borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}></td>
                                <td style={{ width: '10%', textAlign: 'center',borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}></td>
                                <td style={{ width: '5%', textAlign: 'center',borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                <td style={{ width: '8%', textAlign: 'center',borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > </td>
                                <td style={{ width: '5%', textAlign: 'center',borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                <td style={{ width: '8%', textAlign: 'center',borderTop:"2px solid black", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                <td style={{ width: '5%', textAlign: 'center',borderTop:"2px solid black", fontWeight:'bold', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Total</td>
                                {IsFiler   === "true" && <td style={{ width: '10%', textAlign: 'center',borderTop:"2px solid black", fontWeight:'bold', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.USTaxAmt ? customerdata.USTaxAmt  : 0}</td>}
                                {IsGST     === "true" && <td style={{ width: '5%', textAlign: 'center',borderTop:"2px solid black", fontWeight:'bold', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TotalGST ? customerdata.TotalGST  : 0}</td>}
                                {IsFurther === "true" && <td style={{ width: '10%', textAlign: 'center',borderTop:"2px solid black", fontWeight:'bold', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.FTax ? customerdata.FTax  : 0}</td>}
                                </tr>
                            }
                           return (
                            <>
                            <tr key={index}>
                                <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.InvoiceDate || customerdata.InvoiceDate != '' }</td>
                                <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}>{customerdata.SaleInvoiceNo === "" ? "-" : customerdata.SaleInvoiceNo}</td>
                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}>{customerdata.CustomerName}</td>
                                <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.CustomerType}</td>
                                <td style={{ width: '8%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.DistrictName} </td>
                                <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TransactionType === 'INV' ? 'Sale Invoice' : 'Sale Return'}</td>
                                <td style={{ width: '8%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.SaleRate ? parseFloat(customerdata.SaleRate).toFixed(2) : 0}</td>
                                <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.Qty ? customerdata.Qty  : 0} </td>
                                {IsFiler  === "true"  &&  <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.USTaxAmt ? customerdata.USTaxAmt  : 0}</td>}
                                {IsGST     === "true" &&  <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TotalGST ? customerdata.TotalGST  : 0}</td>}
                                {IsFurther  === "true" &&  <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.FTax ? customerdata.FTax  : 0}</td>}
                                </tr>
                        </>
                           )
                           
                        })
                    }
                </tbody>


            </table>
           <div id='table_div' className="row-margin-top" >
            <table id='report_table' className='report_table' width="100%" style={{marginLeft:'2%',marginRight:'2%'}}>
                <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                        <th id='date_td' style={{ width: '5%', textAlign: 'left', fontWeight: "bold" }}   ></th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '8%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '8%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >Totals: </th>
                        {IsFiler   === "true"  &&   <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >{parseFloat(UsTotal).toFixed(2)}</th>}
                        {IsGST     === "true"  &&  <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >{parseFloat(GsTTotal).toFixed(2)}</th>}
                        {IsFurther === "true" &&  <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >{parseFloat(FTotal).toFixed(2)}</th>}
                     
                    </tr>
                </thead>
                </table>
             </div> 
        </div>
        <div className='page-break-ledger'>
          
        </div>
    </div>
    <Row className="divFooter" style={{ marginTop: "3%" }} >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
    </Row>
   
</>
  )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
  Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
  User_Id: state.Login_User && state.Login_User.User_Data.User_id,
  User_Name: state.Login_User && state.Login_User.User_Data.User_name,
  ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})
export default connect(mapStateToProps)(SalesTaxReturnReport)