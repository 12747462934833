import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit, Print } from '@material-ui/icons'
import  { connect } from 'react-redux'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { SelectOpeningList } from '../../Services/OpeningAPI'
  import  { set_OPening, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';

function OpeningList(props) {

    const {  set_OPening,ItemCat , Comp_Id,ProjectID, Group_Id, SHOW_SCREEN } = props;
  
    const [OpeningList_Data, setOpeningList_Data] = useState([])
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(()=>{
    document.title = " Pharmacy / Opening"
    SelectGroupScreenAccess(Group_Id,11,94,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })

Select_OpeningList()
    },[])
    const openInNewTab = (url) => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };
    const column_Opening = [
        {
         name: "InventoryMasterID",
         label: "Inventory ID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "OpeningNo",
            label: "Opening Code",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
         name: "ManufacturedBy",
         label: "Company",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
          name: "CGName",
          label: "Company Group",
          options: {
           filter: true,
           sort: true,
         customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            }, 
           }
         },
        {
          name: "TransactionDate",
          label: "Transaction Date",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
        {
         name: "Total",
         label: "Grand Total",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
        //  {
        //   name: "action",
        //   label: "action",
        //   options: {
        //    filter: true,
        //    sort: true,
        //    customHeadLabelRender: (columnMeta)=>{
        //     return(
        //       <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
        //     )
        //    },
        //    customBodyRender: (dataIndex, rowIndex) => {
             
        //      var InventoryID = OpeningList_Data
        //      if (InventoryID[rowIndex.rowIndex] != null)
        //        return (
        //          <Link to={"/Opening"} onClick={e => edit_opening(OpeningList_Data[rowIndex.rowIndex].InventoryMasterID)}> <Edit /></Link>
        //        );
        //    }
        //   }
        //  }
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var InventoryID = OpeningList_Data
             if (InventoryID[rowIndex.rowIndex] != null)
               return (
                 <div onClick={() => {
                  localStorage.setItem('token', sessionStorage.getItem('token'));
                  window.open(`/opening-invoice/${OpeningList_Data[rowIndex.rowIndex].InventoryMasterID}/${Comp_Id}/${ProjectID}`, '_blank');
                }}>
                  <Print style={{color:"#727CF5"}} />
                </div>
               );
           }
          }
         }
    ];
    // const edit_Supplier = (InventoryID) =>{
    //   // set_Supplier(SupplierID,false)
    // }
    // const add_Supplier = () =>{
    //   // set_Supplier(0,true)
    // }
    const edit_opening = (InventoryID) =>{
        set_OPening(null,InventoryID,false)
     }
     const add_Opening = () =>{
       set_OPening(null,0,true)
     }
   const Select_OpeningList = () =>{
    showLoader()
    SelectOpeningList(Comp_Id,ProjectID,(mData)=>{
      hideLoader() 
      setOpeningList_Data(mData)
    })
   }
   const showLoader = () => {
    setIsLoader(true)
   
  }
const  hideLoader = () => {
  setIsLoader(false)
  }
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title" >
              <h2> Inventory Opening List</h2>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right mt-4 mr-4 ml-4" >
          {/* <Col  style={{ textAlign: "right" }} >
              { btn_add &&(<Link type="button" className="btn btn-primary  rounded-pill" to="/Opening" onClick={add_Opening} style={{ border: "none" }} > <Add />  New (Create New)</Link>)}
            </Col> */}
            <Col  style={{ textAlign: "right" }} >
              { btn_add &&(<Link type="button" className="btn btn-primary  rounded-pill" to="/Opening" onClick={add_Opening} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={OpeningList_Data}
                        columns={column_Opening}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                      </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    Group_Id : state.Login_User.User_Data.Group_Id
  })
 export default connect(mapStateToProps,{set_OPening, SHOW_SCREEN})(OpeningList)
