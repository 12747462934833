import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button  } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import {  Cancel, Print } from "@material-ui/icons"
import { connect } from "react-redux"
import { SelectCityList } from '../../Services/CityAPI'


function SummaryModal({ show, onHide, Delivery_Data,Comp_Id,ProjectID }) {

    const [FromInvNo, setFromInvNo] = useState('')
    const [ToInvNo, setToInvNo] = useState('')
    const [selectedDelivery, setSelectedDelivery] = useState(null)
    // const [CityList, setCityList] = useState([])
    // const [SelectedCity, setSelectedCity] = useState(null)

    const [Error, setError] = useState({
        City: '',
        FromInvNo: '',
        ToInvNo : ''
    })
        // useEffect(() => {

        //     document.title = "Distribution / Expense"
        //     if(show){
        //         SelectCityList(Comp_Id, ProjectID, (mCites)=>{
        //             setCityList(mCites)
        //         })
        //     }
        
        // }, [show])



    const validation = () =>{
        if(!selectedDelivery)
        {
            setError({City: "Must be Required", FromInvNo: "", ToInvNo: ""})
            document.getElementById("City").focus()
            return false
        } else if (!FromInvNo || FromInvNo.length !== 6){
            setError({City: "", FromInvNo: "Must be Required 6 digits", ToInvNo: ""})
            document.getElementById("FromInvNo").focus()
            return false
        } else if (!ToInvNo || ToInvNo.length !== 6){
            setError({City: "", FromInvNo: "", ToInvNo: "Must be Required 6 digits"})
            document.getElementById("ToInvNo").focus()
            return false
        }
            setError({City: "", FromInvNo: "", ToInvNo: ""})
            return true
    }
    const save  = () =>{
        if(validation())
        {
            localStorage.setItem('token', sessionStorage.getItem('token'))
            window.open(`DeliverySummary/${Comp_Id}/${ProjectID}/${FromInvNo}/${ToInvNo}/${selectedDelivery?.DeliveryID}`,'_blank') 
        }
    }

    const clearFields = () => {
        onHide()
        setFromInvNo('')
        setToInvNo('')
        setSelectedDelivery(null)
    }

    return (
        <>
            <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Delivery Stock Summary </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col lg={12} xl={12} md={12} >
                                <Autocomplete
                                    name="Delivery"
                                    id="Delivery"
                                    options={Delivery_Data}
                                    getOptionLabel={(option) => option.DeliveryName ? `${option.DeliveryCode} ~ ${option.DeliveryName}` : ""}
                                    value={selectedDelivery}
                                    onChange={(e, value) => {
                                        setSelectedDelivery(value)
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Delivery" id="Delivery" label="Select Delivery"
                                        />}

                                />
                                <span style={{color: "red"}} >{Error.City}</span>
                            </Col>

                            <Col lg={6} xl={6} md={6} >
                            <TextField
                                    name="FromInvNo"
                                    id="FromInvNo"
                                    label="FromInvNo"
                                    value={ FromInvNo }
                                    onChange = {e => { setFromInvNo(e.target.value) }}
                                    type="text"
                                    fullWidth
                                />
                                 <span style={{color: "red"}} >{Error.FromInvNo}</span>
                            </Col>
                            <Col lg={6} xl={6} md={6} >
                            <TextField
                                    name="ToInvNo"
                                    id="ToInvNo"
                                    label="ToInvNo"
                                    value={ ToInvNo }
                                    onChange = {e => { setToInvNo(e.target.value) }}
                                    type="text"
                                    fullWidth
                                />
                                 <span style={{color: "red"}} >{Error.ToInvNo}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                               <Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save} > <Print /> <u>Load</u> </Button>
                                <Button variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(SummaryModal)
