import React from 'react'
import ReactDOM from 'react-dom'
import { Row, Col, Card, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { TextField, Popper } from '@material-ui/core'
import { Save, Cancel, ShowChart, Print } from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect } from 'react-redux'
import { SelectConfigurationData } from '../../Services/ControlPanelAPI'

import { GetCurrentDate, msg, preventMinus } from '../../Services/Common'
import { SelectcustomersList } from "../../Services/CustomersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTWareHouse, SelectBatchDetails } from '../../Services/StockTransferAPI'
import { GetMaxSaleInvoiceNo, SelectSaleInvoiceMaster, SelectSaleInvoiceDetail, SaveSaleInvoice, SelectSaleInvoiceMasterWRTCode, SelectSaleInvoiceDetailWRTCode } from '../../Services/SaleInvoiceAPI'
import { Selectbank } from '../../Services/bankAPI'
import { SelectSchemeFromCust ,SelectSchemeDetail } from '../../Services/SchemeAPI'
import SaleReturnModal from './SaleReturnModal'
import Loader from '../Loader';
import { chainPropTypes } from '@mui/utils'
export class SaleInvoiceEntry extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            SaleInvoiceNo: '',
            CustomerList: [],
            SelectedCustomer: null,
            BankList: [],
            SelectedBank: null,
            Batch_List: [],
            Selected_Batch: null,
            SelectedPaymentType: null,
            InvoiceDate: GetCurrentDate(),
            WareHouseList: [],
            SelectedWareHouse: null,
            Item_List: [],
            Selected_item: null,
            Discription: '',
            SI_Detail_Data: [],
            DeletedSI_Detail_Data: [],
            Supplier_Err: '',
            UOMID: 0,
            Bank_Disable_Prop: false,
            Total_Disable_Prop: false,
            Balance_Disable_Prop: false,
            TotalQuantity: 0,
            TotalDiscount: 0,
            TotalTaxAmt: 0,
            InvoiceAmount: 0,
            GrandTotal: 0,
            Balance: 0,
            TotalPaid: 0,
            Discription: '',
            btn_Disabled: false,
            // Modal 
            Stock_Modal: false,
            btn_edit: true,
            InvNo: '',
            IsLoader:false,

            CustomerSchemeID:0,
            AddTaxPer:0,
            TotalDiscount1:0,
            AddTaxAmt:0,
            WHTPer:0,
            WHTAmt:0,
            VATPer: 0,
            VATAmt: 0,
            IncomeTaxPer:0,
            IncomeTaxAmt:0,
            // Hide or Show Columns
            DisAmt_Visible: true,
            DisPer_Visible: true,
            SaleTaxAmt_Visible:true,
            SaleTaxPer_Visible:true,
            Cash:0,
            ChangeAmt:0, 
            SaleInvoiceIDSR: 0,
            ActualAmt: 0,
            SaleInvoiceIDForSR: 0,
            btn_Add: false,
            MBatchNo : '',
            TotalGSTOnQty: 0,
            TotalGSTOnBonus:0,
            TotalFTaxOnQty: 0,
            TotalFTaxOnBonus : 0,
            schemeData:[]
        }
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.validationRules = { required: true };
        // this.bg_Ref = React.createRef()
        this.customNameValidation = this.customNameValidation.bind(this)
        this.grid = null
        //   this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
        this.NAmeValidation = {
            required: [this.customNameValidation, 'Name must be selected'],
        };
        this.Comp_Id = this.props.Comp_Id
        this.UserID = this.props.p_userid
        this.ProjectID = this.props.ProjectID
        this.lastColumnField  = 'value'
        this._Options = [
            { value: 'Cash Sale', title: 'Cash Sale' },
            { value: 'Credit Sale', title: 'Credit Sale' },
            { value: 'Account Sale', title: 'Account Sale' },
            { value: 'Cash Credit Sale', title: 'Cash Credit Sale' },
            { value: 'Account Credi tSale', title: 'Account Credit Sale' }
        ]
    }

    sortingOptions = {
        columns: [{ field: 'SrNo', direction: 'Ascending' }]
    };
    customNameValidation(args) {
        args.value = this.state.Selected_item ? this.state.Selected_item.ItemID : ''
        return getValue('value', args) != ''
    }
    showLoader = () => {
        this.setState({IsLoader:true})
        }
        hideLoader = () => {
        this.setState({IsLoader:false})
        }
    componentDidMount() {

        document.title = " Distribution / Sale Return"
        Selectbank(this.Comp_Id, this.ProjectID, (mBank) => {
            this.setState({ BankList: mBank })

            SelectWareHouseList(this.Comp_Id, this.ProjectID, (mWareHouse) => {
                this.setState({ WareHouseList: mWareHouse, SelectedWareHouse: mWareHouse[0], btn_Disabled: false })
                // for hiding warehouse 
                this.Select_Item_Details(mWareHouse[0].WareHouseID)

                SelectcustomersList(this.Comp_Id, this.ProjectID, (mCustomer) => {
                    this.setState({ CustomerList: mCustomer })
                    if (this.props.isAddNew) 
                    {
                        this.GetMax_SaleInvoiceNo() 
                        this.setState({ SelectedPaymentType: { value: 'Cash Sale', title: 'Cash Sale' }, Bank_Disable_Prop: true, Total_Disable_Prop: true, Balance_Disable_Prop: true })
                    }else 
                    {
                        this.showLoader()
                        SelectSaleInvoiceMaster(this.Comp_Id, this.ProjectID, this.props.SaleInvoiceID, 'SaleReturn',(mData) => {
                          
                            this.hideLoader()
                            this.setState({
                                SaleInvoiceNo: mData[0].SaleInvoiceNo,
                                InvoiceDate: mData[0].InvoiceDate,
                                Discription: mData[0].Discription,
                                TotalDiscount: mData[0].TotalDiscount,
                                TotalQuantity: mData[0].TotalQuantity,
                                InvoiceAmount: mData[0].InvoiceAmount,
                                GrandTotal: -(mData[0].GrandTotal),
                                Cash: mData[0].Cash,
                                ChangeAmt: mData[0].ChangeAmt,
                                TotalPaid: -(mData[0].TotalPaid),
                                Balance: mData[0].Balance,
                                SelectedPaymentType: this._Options.find(x => x.value === mData[0].PaymentType),
                                btn_edit: this.props.show_screen.edit,
                                AddTaxPer: mData[0].AddTaxPer,
                                AddTaxAmt: mData[0].AddTaxAmt,
                                IncomeTaxPer: mData[0].IncomeTaxPer,
                                IncomeTaxAmt: mData[0].IncomeTaxAmt,
                                WHTPer: mData[0].WHTPer,
                                WHTAmt: mData[0].WHTAmt,
                                VATPer: mData[0].VATPer,
                                VATAmt: mData[0].VATAmt,
                                TotalTaxAmt: mData[0].TotalGST,
                                ActualAmt: -(mData[0].GrandTotal),
                                SaleInvoiceIDForSR: mData[0].SaleInvoiceIDForSR,
                                TotalGSTOnQty: mData[0].TotalGSTOnQty,
                                TotalGSTOnBonus:mData[0].TotalGSTOnBonus,
                                TotalFTaxOnQty: mData[0].TotalFTaxOnQty,
                                TotalFTaxOnBonus : mData[0].TotalFTaxOnBonus
                            
                            })

                            if ((mData[0].PaymentType).trim() === 'Account Sale') {
                                this.setState({ Bank_Disable_Prop: false, Total_Disable_Prop: true, Balance_Disable_Prop: true })
                            }
                            else if ((mData[0].PaymentType).trim() === 'Account Credit Sale') {
                                this.setState({ Bank_Disable_Prop: false, Total_Disable_Prop: false, Balance_Disable_Prop: false })
                            }
                            else if ((mData[0].PaymentType).trim() === 'Cash Credit Sale') {
                                this.setState({ Bank_Disable_Prop: true, Total_Disable_Prop: false, Balance_Disable_Prop: false })
                            }
                            else if ((mData[0].PaymentType).trim() === 'Cash Sale') {
                                this.setState({ Bank_Disable_Prop: true, Total_Disable_Prop: true, Balance_Disable_Prop: true })
                            }
                            this.Select_Item_Details(mData[0].WareHouseID)
                            mWareHouse.map(val => {
                                if (val.WareHouseID === mData[0].WareHouseID) {
                                    this.setState({ SelectedWareHouse: val })
                                }
                            })
                            mBank.map(val => {
                                if (val.BankID === mData[0].BankID) {
                                    this.setState({ SelectedBank: val })
                                }
                            })
                            mCustomer.map(val => {
                                if (val.CustomerID === mData[0].CustomerID) {
                                    this.setState({ SelectedCustomer: val, btn_Add: true })
                                }
                            })
                            // mWareHouse.map(val=>{
                            //     if(val.WareHouseID === mData[0].WareHouseID)
                            //     {
                            //         this.setState({SelectedWareHouse:val})
                            //     }
                            // })
                            // mSupplier.map(val=>{
                            //     if(val.SupplierID === mData[0].SupplierID)
                            //     {
                            //         this.setState({SelectedCustomer:val})
                            //     }
                            // })
                            this.showLoader()
                            SelectSaleInvoiceDetail(this.Comp_Id, this.ProjectID, this.props.SaleInvoiceID,'SaleReturn', (mDetailData) => {
                                this.hideLoader()
                                mDetailData.map(async(value, index) => {
                                    value.SrNo = index + 1
                                    value.Qty = -(value.Qty)
                                    value.BonusQty = -(value.BonusQty)
                                    await new Promise(resolveForBatchQTy=>{
                                        SelectBatchDetails(this.Comp_Id,this.ProjectID,value.ItemID,mWareHouse[0].WareHouseID,(mItemsBatchQty)=>{ // Api
                                            for(let i =0; i< mItemsBatchQty.length; i++){
                                                   if((value.BatchNo).trim() === (mItemsBatchQty[i].batchno).trim()){
                                                        value.BQty = mItemsBatchQty[i].BQty
                                                   }
                                            }
                                            resolveForBatchQTy()
                                        })
                                    })

                                })
                                this.setState({ SI_Detail_Data: mDetailData }, () => {
                                    this.Calculate_Values(mData[0].VATAmt)})
                            })
                        })
                    }

                })
            })
        })
         // Sale Tax Column hide
         SelectConfigurationData(this.Comp_Id, (mConfigData) => {
            if (mConfigData[0].ApplyTax_on_SaleInvoice === 1) {
                this.setState({
                    DisAmt_Visible: mConfigData[0].IsDisAmt === 1 ? true : false,
                    DisPer_Visible: mConfigData[0].IsDisPer === 1 ? true : false,
                    SaleTaxAmt_Visible: mConfigData[0].IsSaleTaxAmt === 1 ? true : false,
                    SaleTaxPer_Visible: mConfigData[0].IsSaleTaxPer === 1 ? true : false,
                }, () => {
                    this.grid.refresh()
                })
            }

        })
    }
    Select_Item_Details = (WareHouseID) => {
        SelectItemsWRTWareHouse(this.Comp_Id, this.ProjectID, WareHouseID, (mItems) => { // Api
         
            this.setState({ Item_List: mItems })
        })
    }
    Select_BatchDetails = (ItemID) => {
        SelectBatchDetails(this.Comp_Id, this.ProjectID, ItemID, this.state.SelectedWareHouse.WareHouseID, (mItems) => { // Api
            var BatchNo_select = document.getElementById('BatchNo')

            var length = BatchNo_select.options.length;
            for (let i = length - 1; i >= 0; i--) {
                BatchNo_select.options[i] = null;
            }
            for (let i = 0; i < mItems.length; i++) {
                BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
            }
            BatchNo_select.selectedIndex = 0
            if (mItems.length > 0) {
                // mmm.value=  mItems[0].batchno 
                // document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
                document.getElementById('BQty').value = mItems[0].BQty
                document.getElementById('SaleRate').value = mItems[0].SaleRate
                document.getElementById('value').value = mItems[0].SaleRate
                document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
            }
            this.setState({ Batch_List: mItems, Selected_Batch: mItems[0] })
            // this.setState({Batch_List:mItems})
        })
    }
    Show_Min_Stock_Modal = () => {
        this.setState({ Stock_Modal: true })
    }
    Hide_Min_Stock_Modal = () => {
        this.setState({ Stock_Modal: false })
    }
    GetMax_SaleInvoiceNo = () => {
        this.showLoader()
        GetMaxSaleInvoiceNo(this.Comp_Id, this.ProjectID, 'SR','SaleReturn', (mSaleInvoiceNo) => { // API Function
         this.hideLoader()
            this.setState({ SaleInvoiceNo: mSaleInvoiceNo })  // set state here 
        })
    }
    //  handleCellEdit = (args) => {
    //     if (args.column.field === this.lastColumnField) {
    //        args.isLastCell = true;
    //     }
    //  };

    BatchNO_Auto = null
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        this.showLoader()
                        this.setState({ Selected_item: value }, () => {
                            document.getElementById('UOM').value = value.uom
                            document.getElementById('TaxPer').value = value.GST
                            this.setState({ Selected_item: value,UOMID:value.UOMID },()=>{
                                // this.Calculate_Total()
                            })
                            this.Select_Batch_Detail(value.ItemID,this.state.SelectedWareHouse.WareHouseID,true)

                            
                        })

                    }
                    else {
                        var BatchNo_select = document.getElementById('BatchNo')
                        var length = BatchNo_select.options.length;
                        for (let i = length - 1; i >= 0; i--) {
                            BatchNo_select.options[i] = null;
                        }
                        document.getElementById('BQty').value = 0
                        document.getElementById('SaleRate').value = 0
                        document.getElementById('value').value = 0
                        document.getElementById('ExpairyDate').value = ''

                        this.setState({ Selected_item: null, Batch_List: [], Selected_Batch: null })
                        document.getElementById('UOM').value = ''
                        if(this.state.DisPer_Visible){
                            document.getElementById('DisPer').value = 0
                            document.getElementById('DisPer1').value = 0
                            document.getElementById('DisAmt').value = 0
                            document.getElementById('DisAmt1').value = 0
                        }
                        if(this.state.SaleTaxPer_Visible){
                            document.getElementById('TaxPer').value = 0
                            document.getElementById('TaxAmt').value = 0 
                            document.getElementById('FTaxPer').value = 0
                            document.getElementById('FTaxAmt').value = 0


                        }
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Select_Batch_Detail = (ItemID, WareHouseID,callScheme) =>{
      
        SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,WareHouseID,async(mItems)=>{ // Api
            // this.BatchNO_Auto.props.options.splice(0, this.BatchNO_Auto.props.options.length)
            // for (var i = 0; i < mItems.length; i++) {
            //     mItems[i].batchno = typeof mItems[i].batchno === 'number' ? mItems[i].batchno.toString() : mItems[i].batchno
            // this.BatchNO_Auto.props.options.push(mItems[i])
            // }
            
                 var BatchNo_select = document.getElementById('BatchNo')
              
                var length = BatchNo_select.options.length;
                for (let i = length - 1; i >= 0; i--) {
                    BatchNo_select.options[i] = null;
                }
                for (let i = 0; i < mItems.length; i++) {
                    BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
                }
                BatchNo_select.selectedIndex = 0
                if(mItems.length > 0){
                    // mmm.value=  mItems[0].batchno 
                    // document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
                    document.getElementById('BQty').value = mItems[0].BQty
                    document.getElementById('SaleRate').value = mItems[0].PackSaleRate
                    document.getElementById('MRP').value = mItems[0].MRP
                    document.getElementById('value').value = mItems[0].PackSaleRate
                    document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
    
                    this.setState({Batch_List:mItems,Selected_Batch:mItems[0]})
                }
                else{
                    document.getElementById('BQty').value = 0
                    document.getElementById('SaleRate').value = 0
                    document.getElementById('MRP').value = 0
                    document.getElementById('value').value = 0
                    document.getElementById('ExpairyDate').value = 0
                    
                    this.setState({Batch_List:[],Selected_Batch:null})
                }
                if(callScheme){
                    if(this.state.SelectedCustomer){
                        await new Promise(resolve=>{
                            this.Select_SchemeDetail(this.state.SelectedCustomer.CustomerID,ItemID,(mSchemeDeatil)=>{
                                this.setState({schemeData: mSchemeDeatil})
                                if(mSchemeDeatil.length > 0){
                                    // expiry check
                                    var _SchemeExpiryDate =  mSchemeDeatil[0].EndDate.substring(0,10)
                                    _SchemeExpiryDate = new Date(_SchemeExpiryDate)
                                    var _Today = new Date()
                                    const NotExpired = +_Today <= +_SchemeExpiryDate // end date must be greater than today
                                       
                                       if(this.state.DisPer_Visible  && NotExpired){
                                        
                                        document.getElementById('DisPer').value = mSchemeDeatil[0].DisPer
                                        document.getElementById('DisPer1').value = mSchemeDeatil[0].DisPer1
                                        }
                                        if(this.state.SaleTaxPer_Visible  && NotExpired){
                                            document.getElementById('TaxPer').value = mSchemeDeatil[0].TaxPer
                                        }
                                        this.showLoader()
                                        this.Calculate_Total(mSchemeDeatil)
                                }else{
                                    document.getElementById('DisPer').value = 0
                                    document.getElementById('DisPer1').value = 0
                                }
                                resolve()
                            })
                        })
                    }
                }
          
          
            this.hideLoader()
        })
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange={e => {
                this.Calculate_Total(this.state.schemeData)
            }}
            min={1}
            onKeyPress={e => preventMinus(e)}
        />)
    }
    BonusQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            defaultValue={rowValue.BonusQty}
            onChange={e => {
                // this.Calculate_Total()
            }}
            min={0}
            onKeyPress={e => preventMinus(e)}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
   
    BatchNo = (rowValue) => {
        return (<select id="BatchNo" name="BatchNo"
            style={{ fontSize: 17, textAlign: "right", width: "100%" }}
            onChange={e => {
                var Qty = 0;
                this.state.Batch_List.find(value => {
                    if (e.target.value == value.batchno) {
                        document.getElementById('ExpairyDate').value = value.ExpairyDate
                        document.getElementById('BQty').value = value.BQty
                        document.getElementById('SaleRate').value = value.SaleRate
                        document.getElementById('value').value = rowValue.Qty * value.SaleRate
                        this.setState({ Selected_Batch: value })
                    }
                })
            }}
            className="textbox" ></select>)

    }
    MBatchNo = (rowValue) => {
        return (<input
            id="MBatchNo"
            name="MBatchNo"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.MBatchNo}
            className="textbox"
            onChange={e=> this.setState({MBatchNo : e.target.value})}
            onInput={this.toInputUppercase}
            disabled = {!this.props.isAddNew}
        />)
    }
   
//  BatchNo = (rowValue) => {
//         return (
//             <div class="select-editable">
//   <select id="BatchNo" name="BatchNo"
//   style={{ fontSize: 17, textAlign: "right", width: "100%" }}
//   onchange="this.nextElementSibling.value=this.value"
//   onChange={e => {
//     var Qty = 0;
   
//     this.state.Batch_List.find(value => {
//         if (e.target.value == value.batchno) {
//             document.getElementById('ExpairyDate').value = value.ExpairyDate
//             document.getElementById('BQty').value = value.BQty
//             document.getElementById('SaleRate').value = value.SaleRate
//             document.getElementById('value').value = rowValue.Qty * value.SaleRate
//             this.setState({ Selected_Batch: value })
//         }
//     })
// }}
// className="textbox" >
//   </select>
//   <input type="text" name="format" />
// </div>
            
//             )

//     }
 
    // BatchNo = (rowValue) => {
    //     if(rowValue.index === undefined)
    //     {
    //         this.BatchNO_Auto = <Autocomplete
    //                 name="BatchNo"
    //                 id="BatchNo"
    //                 defaultValue={this.state.Batch_List.find(v => v.batchno === rowValue.BatchNo)}
    //                 options={this.state.Batch_List}
    //                 getOptionLabel={(option) => option.batchno ? option.batchno : ""}
    //                 ref={this.bg_Ref}
    //                 onChange={(e, value) => {
    //                     if (value) {

    //                         this.setState({ Selected_Batch: value })
    //                         document.getElementById('ExpairyDate').value = value.ExpairyDate
    //                         document.getElementById('BQty').value = value.BQty
    //                         document.getElementById('SaleRate').value = value.SaleRate
    //                         document.getElementById('value').value = value.SaleRate
    //                     }
    //                     else {
    //                         this.setState({ Selected_Batch: null })
    //                         document.getElementById('ExpairyDate').value = ''
    //                         document.getElementById('BQty').value = ''
    //                         document.getElementById('SaleRate').value =  ''
    //                         document.getElementById('value').value = ''

    //                     }
    //                 }}
    //                 renderInput={(params) =>
    //                     <TextField {...params} name="BatchNo" />}
    //             />
    //     }
    //     else
    //     {
    //         this.BatchNO_Auto = <lable> { rowValue.BatchNo } </lable>
    //     }

    //     return (
    //         this.BatchNO_Auto
    //     )
    // }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="date"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            readOnly={false}
        />)
    }
    BQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BQty"
            defaultValue={rowValue.BQty}
            className="textbox"
            name="BQty"
            readOnly={true}

        />)
    }
    SaleRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="SaleRate"
            defaultValue={rowValue.SaleRate}
            className="textbox"
            name="SaleRate"
            onChange={e => {
                this.Calculate_Total()
            }}
            min={1}
            onKeyPress={e => preventMinus(e)}
        />)
    }
    MRP = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="MRP"
            defaultValue={rowValue.MRP}
            className="textbox"
            name="MRP"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange={e => {
                this.Calculate_Total()
            }}
            min={0}
            onKeyPress={e => preventMinus(e)}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange={e => {
                // this.Calculate_Total()
            }}
        />)
    }
    DisPer1 = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer1"
            defaultValue={rowValue.DisPer1}
            className="textbox"
            name="DisPer1"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisAmt1 = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt1"
            defaultValue={rowValue.DisAmt1}
            className="textbox"
            name="DisAmt1"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange={e => {
                this.Calculate_Total()
            }}
            min={0}
            onKeyPress={e => preventMinus(e)}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange={e => {
                // this.Calculate_Total()
            }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }
    FTaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="FTaxPer"
            defaultValue={rowValue.FTaxPer}
            className="textbox"
            name="FTaxPer"
            readOnly={true}
            // onChange = { e => {
            //      this.Calculate_Total()
            //  }}
        />)
    }
     FTaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="FTaxAmt"
            defaultValue={rowValue.FTaxAmt}
            className="textbox"
            name="FTaxAmt"
            readOnly={true}
            // onChange = { e => {
            //      this.Calculate_Total()
            //  }}
        />)
    }
    SaveHandler = (fromPrint) => {
        let _PurchaseAmount = 0;
        if (this.state.SelectedCustomer) {
            this.setState({ Supplier_Err: '' })
            if (this.state.SI_Detail_Data.length > 0) {
                var _TotalPaid = 0.00;
                var _GrandTotal = parseFloat(this.state.GrandTotal) > 0 ? -(parseFloat(this.state.GrandTotal)) : this.state.GrandTotal
                if (this.state.SelectedPaymentType.value === 'Account Sale') {
                    _TotalPaid = _GrandTotal
                }
                else if (this.state.SelectedPaymentType.value === 'Cash Sale') {
                    _TotalPaid = _GrandTotal
                }
                else {
                    _TotalPaid = this.state.TotalPaid
                }

                for (let i = 0; i < this.state.SI_Detail_Data.length; i++) {
                    
                    if (typeof this.state.SI_Detail_Data[i].Qty === 'undefined' || this.state.SI_Detail_Data[i].Qty == '' || this.state.SI_Detail_Data[i].Qty == null) {
                        this.state.SI_Detail_Data[i].Qty = 0
                        if (typeof this.state.SI_Detail_Data[i].Qty === 'string') {
                            if (parseFloat(this.state.SI_Detail_Data[i].Qty) >= 0) {
                                this.state.SI_Detail_Data[i].Qty = -(parseFloat(this.state.SI_Detail_Data[i].Qty))
                            }
                            else {
                                this.state.SI_Detail_Data[i].Qty = (parseFloat(this.state.SI_Detail_Data[i].Qty))
                            }
                        }
                    }
                    else {
                        if (parseFloat(this.state.SI_Detail_Data[i].Qty) >= 0) {
                            this.state.SI_Detail_Data[i].Qty = -(parseFloat(this.state.SI_Detail_Data[i].Qty))
                        }
                        else {
                            this.state.SI_Detail_Data[i].Qty = (parseFloat(this.state.SI_Detail_Data[i].Qty))
                        }
                    }
                    if(this.state.SI_Detail_Data[i].MBatchNo === '' || this.state.SI_Detail_Data[i].MBatchNo === undefined || this.state.SI_Detail_Data[i].MBatch === null){
                        if (typeof this.state.SI_Detail_Data[i].BatchNo === undefined || typeof this.state.SI_Detail_Data[i].BatchNo === 'undefined' || this.state.SI_Detail_Data[i].BatchNo == '' || this.state.SI_Detail_Data[i].BatchNo == null) {
                            Swal.fire({
                                icon: 'info',
                                text: `Add Batch NO at Sr No. ${this.state.SI_Detail_Data[i].SrNo}...`,
                            })
                            return
                        }
                    }
                   
                    if (typeof this.state.SI_Detail_Data[i].BonusQty === 'undefined' || this.state.SI_Detail_Data[i].BonusQty == '' || this.state.SI_Detail_Data[i].BonusQty == null)
                        this.state.SI_Detail_Data[i].BonusQty = 0
                    else if (typeof this.state.SI_Detail_Data[i].BonusQty === 'string')
                        this.state.SI_Detail_Data[i].BonusQty = parseFloat(this.state.SI_Detail_Data[i].BonusQty)

                    if (this.state.SI_Detail_Data[i].BonusQty > 0) {
                        this.state.SI_Detail_Data[i].BonusQty = -(this.state.SI_Detail_Data[i].BonusQty)
                    }

                    if (typeof this.state.SI_Detail_Data[i].TaxPer === 'undefined' || this.state.SI_Detail_Data[i].TaxPer == '' || this.state.SI_Detail_Data[i].TaxPer == null)
                        this.state.SI_Detail_Data[i].TaxPer = 0
                    else if (typeof this.state.SI_Detail_Data[i].TaxPer === 'string')
                        this.state.SI_Detail_Data[i].TaxPer = parseFloat(this.state.SI_Detail_Data[i].TaxPer)

                    if (typeof this.state.SI_Detail_Data[i].DisPer === 'undefined' || this.state.SI_Detail_Data[i].DisPer == '' || this.state.SI_Detail_Data[i].DisPer == null)
                        this.state.SI_Detail_Data[i].DisPer = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisPer === 'string')
                        this.state.SI_Detail_Data[i].DisPer = parseFloat(this.state.SI_Detail_Data[i].DisPer)

                    if (typeof this.state.SI_Detail_Data[i].FTaxPer === 'undefined' || this.state.SI_Detail_Data[i].FTaxPer == '' || this.state.SI_Detail_Data[i].FTaxPer == null)
                        this.state.SI_Detail_Data[i].FTaxPer = 0
                    else if (typeof this.state.SI_Detail_Data[i].FTaxPer === 'string')
                        this.state.SI_Detail_Data[i].FTaxPer = parseFloat(this.state.SI_Detail_Data[i].FTaxPer)

                    if (typeof this.state.SI_Detail_Data[i].DisPer1 === 'undefined' || this.state.SI_Detail_Data[i].DisPer1 == '' || this.state.SI_Detail_Data[i].DisPer1 == null)
                        this.state.SI_Detail_Data[i].DisPer1 = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisPer1 === 'string')
                        this.state.SI_Detail_Data[i].DisPer1 = parseFloat(this.state.SI_Detail_Data[i].DisPer1)

                    if (typeof this.state.SI_Detail_Data[i].TaxAmt === 'undefined' || this.state.SI_Detail_Data[i].TaxAmt == '' || this.state.SI_Detail_Data[i].TaxAmt == null)
                        this.state.SI_Detail_Data[i].TaxAmt = 0
                    else if (typeof this.state.SI_Detail_Data[i].TaxAmt === 'string')
                        this.state.SI_Detail_Data[i].TaxAmt = parseFloat(this.state.SI_Detail_Data[i].TaxAmt)

                    if (typeof this.state.SI_Detail_Data[i].DisAmt === 'undefined' || this.state.SI_Detail_Data[i].DisAmt == '' || this.state.SI_Detail_Data[i].DisAmt == null)
                        this.state.SI_Detail_Data[i].DisAmt = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisAmt === 'string')
                        this.state.SI_Detail_Data[i].DisAmt = parseFloat(this.state.SI_Detail_Data[i].DisAmt)

                    if (typeof this.state.SI_Detail_Data[i].SaleRate === 'undefined' || this.state.SI_Detail_Data[i].SaleRate == '' || this.state.SI_Detail_Data[i].SaleRate == null)
                        this.state.SI_Detail_Data[i].SaleRate = 0
                    else if (typeof this.state.SI_Detail_Data[i].SaleRate === 'string')
                        this.state.SI_Detail_Data[i].SaleRate = parseFloat(this.state.SI_Detail_Data[i].SaleRate)

                    if (typeof this.state.SI_Detail_Data[i].Value === 'undefined' || this.state.SI_Detail_Data[i].Value == '' || this.state.SI_Detail_Data[i].Value == null)
                        this.state.SI_Detail_Data[i].Value = 0
                    else if (typeof this.state.SI_Detail_Data[i].Value === 'string')
                        this.state.SI_Detail_Data[i].Value = parseFloat(this.state.SI_Detail_Data[i].Value)

                    if (typeof this.state.SI_Detail_Data[i].DisAmt1 === 'undefined' || this.state.SI_Detail_Data[i].DisAmt1 == '' || this.state.SI_Detail_Data[i].DisAmt1 == null)
                        this.state.SI_Detail_Data[i].DisAmt1 = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisAmt1 === 'string')
                        this.state.SI_Detail_Data[i].DisAmt1 = parseFloat(this.state.SI_Detail_Data[i].DisAmt1)

                    if (typeof this.state.SI_Detail_Data[i].FTaxAmt === 'undefined' || this.state.SI_Detail_Data[i].FTaxAmt == '' || this.state.SI_Detail_Data[i].FTaxAmt == null)
                        this.state.SI_Detail_Data[i].FTaxAmt = 0
                    else if (typeof this.state.SI_Detail_Data[i].FTaxAmt === 'string')
                        this.state.SI_Detail_Data[i].FTaxAmt = parseFloat(this.state.SI_Detail_Data[i].FTaxAmt)

                    // this.state.SI_Detail_Data[i].FTaxPer = 0;
                    // this.state.SI_Detail_Data[i].FTaxAmt = 0;

                    this.state.SI_Detail_Data[i].PurchaseValue = -(this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].PurchaseRate)
                    this.state.SI_Detail_Data[i].PurchaseValue = parseFloat((this.state.SI_Detail_Data[i].PurchaseValue).toFixed(2))
                    _PurchaseAmount += this.state.SI_Detail_Data[i].PurchaseValue;

                }
                var OrderData = {
                    SaleInvoiceID: this.props.SaleInvoiceID,
                    SaleInvoiceNo: this.state.SaleInvoiceNo,
                    WareHouseID: this.state.SelectedWareHouse.WareHouseID,
                    CustomerID: this.state.SelectedCustomer.CustomerID,
                    UserID: this.UserID,
                    CreatedDate: GetCurrentDate(),
                    InvoiceDate: this.state.InvoiceDate,
                    Discription: this.state.Discription,
                    TotalDiscount: this.state.TotalDiscount,
                    TotalQuantity: this.state.TotalQuantity,
                    InvoiceAmount: this.state.InvoiceAmount,
                    GrandTotal: _GrandTotal,
                    TransactionType: 'SR',
                    BankID: this.state.SelectedBank ? this.state.SelectedBank.BankID : 0,
                    PaymentType: this.state.SelectedPaymentType.value,
                    TotalPaid: _TotalPaid,
                    Balance: this.state.Balance,
                    Comp_Id: this.Comp_Id,
                    ProjectID: this.ProjectID,
                    Cash: 0.00,
                    ChangeAmt: 0.00,
                    AddTaxPer: this.state.AddTaxPer,
                    AddTaxAmt: this.state.AddTaxAmt,
                    IncomeTaxPer: this.state.IncomeTaxPer,
                    IncomeTaxAmt: this.state.IncomeTaxAmt,
                    WHTPer: this.state.WHTPer,
                    WHTAmt: this.state.WHTAmt,
                    VATPer: this.state.VATPer,
                    VATAmt: this.state.VATAmt,
                    PurchaseAmount: parseFloat(_PurchaseAmount.toFixed(2)),
                    TotalGST: this.state.TotalTaxAmt,
                    SaleInvoiceIDSR: this.state.SaleInvoiceIDSR,
                    ActualAmt:this.state.ActualAmt,
                    SaleInvoiceIDForSR: this.state.SaleInvoiceIDForSR,
                    InvoiceType: 'SaleReturn',
                    TotalGSTOnQty : this.state.TotalGSTOnQty,
                    TotalGSTOnBonus : this.state.TotalGSTOnBonus,
                    TotalFTaxOnQty: this.state.TotalFTaxOnQty,
                    TotalFTaxOnBonus: this.state.TotalFTaxOnBonus,
                    IsDispatched:  0,
                    isWithoutDelivery: fromPrint === true ? 1 : 0
                }
              
                this.setState({ btn_Disabled: true })
                SaveSaleInvoice(OrderData, this.state.SI_Detail_Data, this.state.DeletedSI_Detail_Data, this.props.isAddNew, (mData) => {
                    
                   if(mData.ToList){
                    if (fromPrint === true) {
                        window.open('/SaleReturnReport', '_blank')
                    }
                  
                    document.getElementById('btnCancel').click()
                   }
                   this.setState({ btn_Disabled: false })
              
                })
             }
            else {
                this.setState({ btn_Disabled: false })
                Swal.fire({
                    icon: 'info',
                    text: `Order Details Must be Entered...`,
                })
                return
            }
        }
        else {
            this.setState({ Supplier_Err: 'Supplier is required', btn_Disabled: false })
        }
    }
    beforeRowInsert = (rowValue) => {
        if (rowValue.requestType === 'save') {

            const qty = +rowValue.data.Qty || 0;
            const mrp = +rowValue.data.MRP || 0;
            const taxPer = +rowValue.data.TaxPer || 0;
            const bonusqty = +rowValue.data.BonusQty || 0;
            const fTax = +rowValue.data.FTaxPer || 0;

            this.calculateTaxes = (qty, tax)=>{
                return parseFloat((qty * mrp / 100) * tax).toFixed(2);
            }

            const gstOnQty    = this.calculateTaxes(qty,taxPer);
            const gstOnBonus  = this.calculateTaxes(bonusqty, taxPer);
            const fTaxOnQty   = this.calculateTaxes(qty, fTax);
            const fTaxOnBonus = this.calculateTaxes(bonusqty, fTax);

            rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
            rowValue.data.ItemID = this.state.Selected_item.ItemID
            rowValue.data.PurchaseRate = this.state.Selected_item.PackPurRate
            rowValue.data.UOMID = this.state.UOMID
            rowValue.data.MRP = this.state.Selected_item.MRP
            rowValue.data.MBatchNo = this.state.MBatchNo
            rowValue.data.GSTOnQty = gstOnQty;
            rowValue.data.GSTOnBonus = gstOnBonus;
            rowValue.data.FTaxOnQty = fTaxOnQty;
            rowValue.data.FTaxOnBonus = fTaxOnBonus;
            if (this.state.Selected_Batch) {
                rowValue.data.BatchNo = this.state.Selected_Batch.batchno
            }
            else {
                rowValue.data.BatchNo = ''
            }
            // var mQty = parseFloat(rowValue.data.Qty) + parseFloat(rowValue.data.BonusQty)
            // if (mQty > parseFloat(rowValue.data.BQty)) {
            //     Swal.fire({
            //         icon: 'info',
            //         text: `Qty must be less than the Batch Qty : ${rowValue.data.BQty} Given...`,
            //     })
            //     this.grid.closeEdit()
            // }
            
            if (rowValue.data.SaleInvoiceDetailID  === undefined)
                rowValue.data.action = 'add'
            else
                rowValue.data.action = rowValue.action
        }

        else if (rowValue.requestType === 'add') {
            
            rowValue.data.SrNo = this.state.SI_Detail_Data.length + 1
            rowValue.data.Qty = 1
            rowValue.data.BonusQty = 0
            rowValue.data.DisPer = 0.00
            rowValue.data.DisAmt = 0.00
            rowValue.data.TaxPer = 0.00
            rowValue.data.TaxAmt = 0.00
            rowValue.data.MBatchNo = ''
            rowValue.data.GSTOnQty = 0;
            rowValue.data.GSTOnBonus = 0;
            rowValue.data.FTaxOnBonus = 0;
            rowValue.data.FTaxOnQty = 0;
            this.setState({ Selected_item: null, UOMID: 0, Selected_Batch: null, MBatchNo: '', schemeData:[] })
        }

    }
    afterRowInsert = (rowValue) => {
        if (rowValue.requestType === 'save' && rowValue.rowIndex === this.grid.dataSource.length - 1) {
            this.addNewRow();

            // const isLastCell = rowValue.column && rowValue.column.field === this.lastColumnField;
            
            // if (isLastCell) {
            //     this.addNewRow();
            // }
        }
        if (rowValue.requestType == "beginEdit") {

            this.setState({ Selected_item: { 
                  "ItemDiscription": rowValue.rowData.ItemDiscription,
                  "ItemID": rowValue.rowData.ItemID,
                  "PackPurRate": rowValue.rowData.PurchaseRate,
                   "MRP": rowValue.rowData.MRP , 
                   "GST": rowValue.rowData.TaxPer
                }, UOMID: rowValue.rowData.UOMID, MBatchNo: rowValue.rowData.MBatchNo },()=>{
                SelectBatchDetails(this.Comp_Id,this.ProjectID,rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
          
                    var BatchNo_select = document.getElementById('BatchNo')

                    var length = BatchNo_select.options.length;
                    for (let i = length - 1; i >= 0; i--) {
                        BatchNo_select.options[i] = null;
                    }

                    for (let i = 0; i < mItems.length; i++) {
                        if((mItems[i].batchno).trim() == (rowValue.rowData.BatchNo).trim()){
                            BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
                            BatchNo_select.selectedIndex = i
                            this.setState({Selected_Batch:mItems[i]})
                        }
                        else{
                            BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
                        }
                    }
                    
                    this.setState({Batch_List:mItems})
                })
            })

        }
        if (rowValue.requestType == "save") {
            this.Calculate_Values();
        }
        if (rowValue.requestType == "delete") {
            if (rowValue.data[0].SaleInvoiceDetailID) {
                this.setState({ DeletedSI_Detail_Data: this.state.DeletedSI_Detail_Data.concat(rowValue.data) })
            }
            this.Calculate_Values();
        }
    }
    addNewRow = () => {
        const emptyRow = {
            SrNo: this.state.SI_Detail_Data.length + 1, // Auto-increment SrNo
            // add default values for other fields if necessary
        };

        this.setState((prevState) => ({
            SI_Detail_Data: [...prevState.SI_Detail_Data, emptyRow],
        }), () => {
            // Refresh grid to include the new row
            this.grid.refresh();
            this.grid.startEdit(this.state.SI_Detail_Data.length - 1); // Start editing the new row
        });
    };
    Calculate_Total = async(mSchemeDeatil) => {
        var Qty = 0, Rate = 0,MRP = 0, GST = 0, DisPer = 0,DisPer1=0, TaxPer = 0, DisAmt = 0, DisAmt1 = 0,TaxAmt = 0, Total_Value = 0,FTaxPer=0,FTaxAmt=0, BonusQty=0, GSTQty = 0;
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)

            if (document.getElementById('BonusQty').value)
                BonusQty = typeof (document.getElementById('BonusQty').value) === 'string' ? parseFloat((document.getElementById('BonusQty').value).replace(/,/g, '')) : document.getElementById('BonusQty').value
            else if (document.getElementById('BonusQty').value == "")
                BonusQty = 0
            else
            BonusQty = parseFloat(document.getElementById('BonusQty').value)
            // await new Promise(resolve=>{
            //     this.Select_SchemeDetail(this.state.SelectedCustomer.CustomerID,this.state.Selected_item.ItemID,(mSchemeDeatil)=>{
                   
            //         resolve()
            //     })
            // })
            if(mSchemeDeatil && mSchemeDeatil.length > 0){
                var _SchemeExpiryDate =  mSchemeDeatil[0].EndDate.substring(0,10)
                _SchemeExpiryDate = new Date(_SchemeExpiryDate)
                var _Today = new Date()
                const NotExpired = +_Today <= +_SchemeExpiryDate 
                if(mSchemeDeatil[0].BonusCriteria <= Qty && NotExpired){
                    var mBonusQty = Math.floor(Qty / mSchemeDeatil[0].BonusCriteria) 
                    document.getElementById('BonusQty').value =  mBonusQty * mSchemeDeatil[0].BonusQty
                  }
                  else{
                    document.getElementById('BonusQty').value = 0 
                  }
            }
            // else{
            //     document.getElementById('BonusQty').value = 0 
            //   }

        Rate = document.getElementById('SaleRate').value == "" ? 0 : parseFloat(document.getElementById('SaleRate').value)
        // MRP =  this.state.Selected_item? this.state.Selected_item.MRP : 0
        MRP = document.getElementById('MRP').value == "" ? 0 : parseFloat(document.getElementById('MRP').value)

        GST =  this.state.Selected_item? this.state.Selected_item.GST : 0
        GSTQty = TaxPer > 0 ? Qty + BonusQty : Qty

        if(this.state.DisPer_Visible){
         
            DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)
            DisPer1 = document.getElementById('DisPer1').value == "" ? 0 : parseFloat(document.getElementById('DisPer1').value)

        }
        if(this.state.SaleTaxPer_Visible){
            TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)
            if(this.state.SelectedCustomer.SaleTaxType == 'NOT REGISTER WITH GST'  && GST > 0){
                FTaxPer = 3
            }
            document.getElementById('FTaxPer').value = FTaxPer;
        }
        DisAmt = parseFloat((( (Qty * Rate)/ 100) * DisPer).toFixed(2))
        TaxAmt = parseFloat((((GSTQty * MRP) / 100) * TaxPer).toFixed(2))
        FTaxAmt = parseFloat((((GSTQty * Rate) / 100) * FTaxPer).toFixed(2)) //further tax
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt + FTaxAmt)).toFixed(2))
        
        DisAmt1 =  parseFloat(((Total_Value / 100 ) * DisPer1 ).toFixed(2))
        if(this.state.DisAmt_Visible){
            document.getElementById('DisAmt').value = DisAmt;
            document.getElementById('DisAmt1').value = DisAmt1;

        }
        if(this.state.SaleTaxAmt_Visible){
            document.getElementById('TaxAmt').value = TaxAmt;
            document.getElementById('FTaxAmt').value = FTaxAmt
        }
        this.hideLoader()
        document.getElementById('value').value = parseFloat((Total_Value - DisAmt1).toFixed(2)) ;

    }
    Calculate_Values = (VATAmt = 0) => {
        var _TotalQuantity = 0, _TotalDis = 0, _TotalTax = 0, _TotalPur = 0, _GrandTotal = 0, Rate = 0, Qty = 0,_TotalDis1=0,  _TotalFTax =0, _TotalGSTOnQty  = 0, _TotalGSTOnBonus = 0,
        _TotalFTaxOnQty = 0,  _TotalFTaxOnBonus = 0;
        for (let i = 0; i < this.state.SI_Detail_Data.length; i++) {
            
            if (typeof this.state.SI_Detail_Data[i].Qty === 'string' && typeof this.state.SI_Detail_Data[i].DisAmt === 'string' && typeof this.state.SI_Detail_Data[i].TaxAmt === 'string' && typeof this.state.SI_Detail_Data[i].SaleRate === 'string' && typeof this.state.SI_Detail_Data[i].value === 'string') {
                this.state.SI_Detail_Data[i].Qty = parseFloat((this.state.SI_Detail_Data[i].Qty).replace(/,/g, ''))
                this.state.SI_Detail_Data[i].DisAmt = parseFloat((this.state.SI_Detail_Data[i].DisAmt).replace(/,/g, ''))
                this.state.SI_Detail_Data[i].DisAmt1 = parseFloat((this.state.SI_Detail_Data[i].DisAmt1).replace(/,/g, ''))
                this.state.SI_Detail_Data[i].TaxAmt = parseFloat((this.state.SI_Detail_Data[i].TaxAmt).replace(/,/g, ''))
                this.state.SI_Detail_Data[i].SaleRate = parseFloat((this.state.SI_Detail_Data[i].SaleRate).replace(/,/g, ''))
                this.state.SI_Detail_Data[i].Value = parseFloat((this.state.SI_Detail_Data[i].Value).replace(/,/g, ''))
                this.state.SI_Detail_Data[i].FTaxAmt = parseFloat((this.state.SI_Detail_Data[i].FTaxAmt).replace(/,/g, ''))
            }
            else if (this.state.SI_Detail_Data[i].Qty == '' && typeof this.state.SI_Detail_Data[i].DisAmt === '' && typeof this.state.SI_Detail_Data[i].TaxAmt === '' && typeof this.state.SI_Detail_Data[i].SaleRate === '' && typeof this.state.SI_Detail_Data[i].value === '') {
                this.state.SI_Detail_Data[i].Qty = 0
                this.state.SI_Detail_Data[i].DisAmt = 0
                this.state.SI_Detail_Data[i].DisAmt1 = 0
                this.state.SI_Detail_Data[i].TaxAmt = 0
                this.state.SI_Detail_Data[i].SaleRate = 0
                this.state.SI_Detail_Data[i].Value = 0
                this.state.SI_Detail_Data[i].FTaxAmt = 0
            }
            _TotalGSTOnQty += parseFloat(this.state.SI_Detail_Data[i].GSTOnQty)
            _TotalGSTOnBonus += parseFloat(this.state.SI_Detail_Data[i].GSTOnBonus)
            _TotalFTaxOnQty += parseFloat(this.state.SI_Detail_Data[i].FTaxOnQty)
            _TotalFTaxOnBonus += parseFloat(this.state.SI_Detail_Data[i].FTaxOnBonus)
            _TotalQuantity += parseFloat(this.state.SI_Detail_Data[i].Qty)
            _TotalDis += parseFloat(this.state.SI_Detail_Data[i].DisAmt)
           _TotalDis1 += parseFloat(this.state.SI_Detail_Data[i].DisAmt1 || 0)
           _TotalTax += parseFloat(this.state.SI_Detail_Data[i].TaxAmt)
            _TotalPur += parseFloat((this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].SaleRate).toFixed(2))
            _GrandTotal += parseFloat(this.state.SI_Detail_Data[i].Value)
            _TotalFTax +=parseFloat(this.state.SI_Detail_Data[i].FTaxAmt || 0) 
        }
        this.setState({ TotalQuantity: _TotalQuantity,
            TotalDiscount: parseFloat(_TotalDis).toFixed(2),
            TotalDiscount1:parseFloat((_TotalDis1).toFixed(2)),
            TotalTaxAmt:parseFloat((_TotalTax).toFixed(2)),
            InvoiceAmount:parseFloat((_TotalPur).toFixed(2)),
            GrandTotal:parseFloat((_GrandTotal).toFixed(2)),
            IncomeTaxAmt: parseFloat((_TotalFTax).toFixed(2)),
            VATAmt: parseFloat(VATAmt).toFixed(2),
            TotalGSTOnQty: parseFloat((_TotalGSTOnQty).toFixed(2)),
            TotalGSTOnBonus:parseFloat((_TotalGSTOnBonus).toFixed(2)),
            TotalFTaxOnQty: parseFloat((_TotalFTaxOnQty).toFixed(2)),
            TotalFTaxOnBonus : parseFloat((_TotalFTaxOnBonus).toFixed(2))
        },()=>{
           this.Calculate_Taxes(VATAmt)
        })

       
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData => {
        this.state.CustomerList.map(value => {
            if (mData[0].CustomerID === value.CustomerID) {
                this.setState({ SelectedCustomer: value })
            }
        })

        this.setState({ SI_Detail_Data: this.state.SI_Detail_Data.concat(mData) }, () => {
            var mArray = [...this.state.SI_Detail_Data]
            mArray.map((value, index) => {
                value.SrNo = index + 1
            })
            this.setState({ SI_Detail_Data: mArray }, () => {
                this.Calculate_Values();
            })
        })
        this.Hide_Min_Stock_Modal()
    }
    Calculate_Taxes = (VATAmt = 0) =>{
        var mAddTaxAmt = 0, mWHTAmt = 0, mIncomeTaxAmt = 0;
    
            mAddTaxAmt =  parseFloat(((this.state.GrandTotal / 100 ) * this.state.AddTaxPer).toFixed(2))
     
        mWHTAmt = parseFloat(((this.state.GrandTotal / 100 ) * this.state.WHTPer).toFixed(2))
        // mIncomeTaxAmt = parseFloat(((this.state.GrandTotal / 100 ) * this.state.IncomeTaxPer).toFixed(2))
        // ,IncomeTaxAmt:mIncomeTaxAmt
        this.setState({AddTaxAmt:mAddTaxAmt,WHTAmt:mWHTAmt},()=>{
            this.GrandAmount_Calculation(VATAmt)
        })
    }
    GrandAmount_Calculation = (VATAmt = 0) =>{
        var _total = 0.00;
        _total = parseFloat((this.state.AddTaxAmt + this.state.WHTAmt + this.state.IncomeTaxAmt + this.state.TotalTaxAmt + this.state.InvoiceAmount + VATAmt - this.state.TotalDiscount - this.state.TotalDiscount1).toFixed(2)) ; 
        // _total = (parseFloat(TotalTaxAmount) + parseFloat(InvoiceAmount)) - parseFloat(TotalDiscount) - parseFloat(TotalDiscount1)

        this.setState({GrandTotal : _total,Cash:_total},()=>{
            this.calculate_changeAmt()
        })
    }
    calculate_changeAmt = () =>{
        let balc =   parseFloat((this.state.Cash - this.state.GrandTotal).toFixed(2))
        this.setState({ChangeAmt:balc})
    }
    Select_SchemeFromCustomer = (CustomerID,callback)=>{
        SelectSchemeFromCust(this.Comp_Id,this.ProjectID,CustomerID,(mScheme)=>{
            callback(mScheme)
        })
}
    Select_SchemeDetail = (CustomerID,ItemID,callback)=>{
        SelectSchemeDetail(this.Comp_Id,this.ProjectID,CustomerID,ItemID,(mschemeDetail)=>{
          callback(mschemeDetail)
        })
    }
    render() {
        if (this.grid)
            this.grid.autoFitColumns(['SrNo', 'ItemDescription', 'dd_qty', 'Godown', 'Mnuit']);
        enableRipple(true);
        return ReactDOM.createPortal(
            <>
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <h2> Sale Return
                            </h2>
                        </div>
                    </div>
                </div>
                <Card>

                    <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                        <Col sm={12} lg={2} xl={2} md={3} >
                            <TextField
                                name="SaleInvoiceNo"
                                label="Sale Return No"
                                value={this.state.SaleInvoiceNo}
                                inputProps={
                                    { readOnly: true }
                                }
                                fullWidth
                            />
                        </Col>

                        <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="CustomerName"
                                id="CustomerName"
                                options={this.state.CustomerList}
                                getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                                value={this.state.SelectedCustomer}
                                onChange={async (e, value) => {
                                    debugger
                                    if (value) {
                                        var _AddTaxPer = 0,_IncomeTaxPer=0,_WHTPer=0,_CustomerSchemeID = 0;
                                        // await new Promise(resolve=>{
                                        //     this.Select_SchemeFromCustomer(value.CustomerID,(mSchemeData)=>{
                                        //         if(mSchemeData.length > 0){
                                        //             _AddTaxPer = mSchemeData[0].AddTaxPer
                                        //             _IncomeTaxPer = mSchemeData[0].IncomeTaxPer
                                        //             _WHTPer = mSchemeData[0].WHTPer
                                        //             _CustomerSchemeID = mSchemeData[0].SchemeID
                                        //         }
                                        //         resolve()
                                        //     })
                                        // })
                                        
                                            this.setState({btn_Add: true,CustomerSchemeID:_CustomerSchemeID,SelectedCustomer:value,AddTaxPer:value.AddTaxPer,IncomeTaxPer:value.IncomeTaxPer,WHTPer:value.WHTPer},()=>{
                                                this.Calculate_Taxes()
                                            })
                                        
                                       
                                    }
                                    else {
                                        this.setState({CustomerSchemeID:0,SelectedCustomer:null,AddTaxPer:0,IncomeTaxPer:0,WHTPer:0, btn_Add: false},()=>{
                                            this.Calculate_Taxes()
                                        })
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} name="CustomerName" label="Customer"
                                    />}
                            />
                            <span className="text-danger">{this.state.Supplier_Err}</span>
                        </Col>
                        {/* <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                                    this.Select_Item_Details(value.WareHouseID)
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col> */}
                        <Col sm={12} lg={2} xl={2} md={3} >
                            <TextField
                                value={this.state.InvoiceDate}
                                name="InvoiceDate"
                                label="InvoiceDate"
                                type='date'
                                onChange={e => this.setState({ InvoiceDate: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="PaymentType"
                                id="PaymentType"
                                options={this._Options}
                                getOptionLabel={(option) => option.title ? option.title : ""}
                                value={this.state.SelectedPaymentType}
                                onChange={(e, value) => {
                                    if (value) {
                                        this.setState({ SelectedPaymentType: value }, () => {
                                            if (value.value === 'Account Sale') {
                                                this.setState({ Bank_Disable_Prop: false, Total_Disable_Prop: true, Balance_Disable_Prop: true })
                                            }
                                            else if (value.value === 'Account Credit Sale') {
                                                this.setState({ Bank_Disable_Prop: false, Total_Disable_Prop: false, Balance_Disable_Prop: false })
                                            }
                                            else if (value.value === 'Cash Credit Sale') {
                                                this.setState({ Bank_Disable_Prop: true, Total_Disable_Prop: false, Balance_Disable_Prop: false })
                                            }
                                            else if (value.value === 'Cash Sale') {
                                                this.setState({ Bank_Disable_Prop: true, Total_Disable_Prop: true, Balance_Disable_Prop: true })
                                            }
                                        })

                                    }
                                    else {
                                        this.setState({ SelectedPaymentType: null })

                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} name="PaymentType" label="Payment Type"
                                    />}
                            />
                            {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                        </Col>


                        <Col lg={2} xl={2} md={2} >
                            <Autocomplete
                                name="BankName"
                                id="BankName"
                                options={this.state.BankList}
                                getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                                value={this.state.SelectedBank}
                                disabled={this.state.Bank_Disable_Prop}
                                onChange={(e, value) => {
                                    if (value) {
                                        this.setState({ SelectedBank: value })

                                    }
                                    else {
                                        this.setState({ SelectedBank: null })

                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} name="BankName" label="BankName"
                                    />}
                            />
                            <span className="text-danger">{this.state.Supplier_Err}</span>
                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="TotalPaid"
                                label="TotalPaid"
                                value={this.state.TotalPaid}
                                disabled={this.state.Total_Disable_Prop}
                                onInput={this.toInputUppercase}
                                onChange={e => this.setState({ TotalPaid: e.target.value }, () => {
                                    let bal = this.state.TotalPaid - this.state.GrandTotal
                                    this.setState({ Balance: bal })
                                })}
                                fullWidth
                            />
                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="Balance"
                                label="Balance"
                                value={this.state.Balance}
                                disabled={this.state.Balance_Disable_Prop}
                                onInput={this.toInputUppercase}
                                onChange={e => this.setState({ Balance: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col sm={12} lg={6} xl={6} md={6} >
                            <TextField
                                name="Discription"
                                label="Discription"
                                value={this.state.Discription}
                                onInput={this.toInputUppercase}
                                onChange={e => this.setState({ Discription: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="InvNo"
                                label="Sale Invoice #"
                                value={this.state.InvNo}
                                onChange={e => this.setState({ InvNo: e.target.value }, () => {
                                    if (e.target.value.length != 6) {
                                        this.setState({
                                             SI_Detail_Data: null, 
                                             CustomerSchemeID:0,
                                             AddTaxPer:0,
                                            TotalQuantity: 0,
                                            TotalDiscount: 0,
                                            TotalDiscount1:0,
                                            TotalTaxAmt: 0,
                                            InvoiceAmount: 0,
                                            GrandTotal: 0,
                                            SelectedCustomer: null,
                                            AddTaxAmt:0,
                                            WHTPer:0,
                                            WHTAmt:0,
                                            VATAmt:0,
                                            VATPer: 0,
                                            IncomeTaxPer:0,
                                            IncomeTaxAmt:0,
                                            Cash:0,
                                            ChangeAmt:0
                                        })

                                    }
                                        if (e.target.value.length == 6) {
                                            SelectSaleInvoiceMasterWRTCode(this.Comp_Id, this.ProjectID, e.target.value,async (mData) => {
                                                if (mData.length > 0) {
                                                    const SelectedCust = this.state.CustomerList.find(x => x.CustomerID === mData[0].CustomerID)
                                                    this.setState({SaleInvoiceIDSR: mData[0].SaleInvoiceID, SaleInvoiceIDForSR:mData[0].SaleInvoiceID, VATPer: mData[0].VATPer,
                                                        TotalGSTOnQty: mData[0].TotalGSTOnQty,
                                                        TotalGSTOnBonus:mData[0].TotalGSTOnBonus,
                                                        TotalFTaxOnQty: mData[0].TotalFTaxOnQty,
                                                        TotalFTaxOnBonus : mData[0].TotalFTaxOnBonus
                                                     })
                                                    if (SelectedCust) {
                                                        var _AddTaxPer = 0,_IncomeTaxPer=0,_WHTPer=0,_CustomerSchemeID = 0;
                                                        // await new Promise(resolve=>{
                                                        //     this.Select_SchemeFromCustomer(SelectedCust.CustomerID,(mSchemeData)=>{
                                                        //         if(mSchemeData.length > 0){
                                                        //             _AddTaxPer = mSchemeData[0].AddTaxPer
                                                        //             _IncomeTaxPer = mSchemeData[0].IncomeTaxPer
                                                        //             _WHTPer = mSchemeData[0].WHTPer
                                                        //             _CustomerSchemeID = mSchemeData[0].SchemeID
                                                        //         }
                                                        //         resolve()
                                                        //     })
                                                        // })
                                                        this.setState({
                                                            CustomerSchemeID:_CustomerSchemeID,
                                                            SelectedCustomer:SelectedCust,
                                                            AddTaxPer:SelectedCust.AddTaxPer,
                                                            IncomeTaxPer:SelectedCust.IncomeTaxPer,
                                                            WHTPer:SelectedCust.WHTPer,
                                                        },()=>{
                                                            this.Calculate_Taxes()
                                                        })
                                                        // this.setState({ SelectedCustomer: SelectedCust })
                                                    }
                                                    SelectSaleInvoiceDetailWRTCode(this.Comp_Id, this.ProjectID, mData[0].SaleInvoiceID, (mDetailData) => {
                                                        mDetailData.map((value, index) => {
                                                            value.SrNo = index + 1
                                                            value.action = 'add'
                                                            value.MBatchNo = ''
                                                            mDetailData.TaxPer = mDetailData.TaxPer
                                                        })
                                                        this.setState({ SI_Detail_Data: mDetailData }, () => {
                                                            this.Calculate_Values(mData[0].VATAmt)
                                                        })
                                                    })
                                                }
                                                else {
                                                    Swal.fire({
                                                        icon: 'info',
                                                        title: 'Oops...',
                                                        text: `No Data Found on this Invoice No`,
                                                    })
                                                }
    
                                            })
                                        }
                                    
                                  

                                })}
                                fullWidth
                            />
                        </Col>
                    </Row>


                    <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                        <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                            <div style={{ flex: "1", width: "100%", height: "100%" }}>
                                <GridComponent 
                                   dataSource={this.state.SI_Detail_Data} 
                                   actionBegin={this.beforeRowInsert} 
                                //    cellEdit={this.handleCellEdit}
                                   ref={g => this.grid = g} 
                                   actionComplete={this.afterRowInsert} 
                                   allowPaging={true} 
                                   allowFiltering={true} 
                                   allowSorting={true} 
                                   editSettings={{ 
                                     allowEditing: true, 
                                     allowDeleting: true, 
                                     allowAdding: this.state.btn_Add, 
                                     newRowPosition: 'Bottom',
                                     }} 
                                     sortSettings={this.sortingOptions} 
                                     filterSettings={{ type: 'Menu' }} 
                                     toolbar={this.toolbarOptions}
                                     >
                                    <ColumnsDirective>
                                        <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                     
                                        <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType='dropdownedit' editTemplate={this.ItemDiscription} edit={this.cbParams} textAlign='Left' />
                                        <ColumnDirective width='130' field='UOM' headerText='UOM' editTemplate={this.UOM} />
                                        <ColumnDirective width='250' field='BatchNo' headerText='BatchNo' editType='dropdownedit' editTemplate={this.BatchNo} edit={this.cbParams} textAlign='Left' />
                                        <ColumnDirective width='200' field='MBatchNo'  headerText='Market BatchNo' editTemplate={this.MBatchNo} ></ColumnDirective>
                                        <ColumnDirective width='170' field='ExpairyDate' headerText='Expiry Date' editTemplate={this.ExpairyDate} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='BQty' headerText='Batch Balance' editTemplate={this.BQty} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right' />
                                        <ColumnDirective width='170' field='BonusQty' headerText='Bonus Qty' editTemplate={this.BonusQty} textAlign='right' />

                                        <ColumnDirective width='150' field='SaleRate' headerText='SaleRate' editTemplate={this.SaleRate} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='130' field='MRP' headerText='MRP' editTemplate={this.MRP} textAlign='right'/>
                                        <ColumnDirective width='150' field='DisPer' headerText='Dis%' editTemplate={this.DisPer} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='DisAmt' headerText='DisAmt' editTemplate={this.DisAmt} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='DisPer1' headerText='Dis2%' visible={this.state.DisPer_Visible} editTemplate={this.DisPer1} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='DisAmt1' headerText='DisAmt2' visible={this.state.DisAmt_Visible} editTemplate={this.DisAmt1} textAlign='right'></ColumnDirective>
                                    
                                        <ColumnDirective width='150' field='TaxPer' headerText={this.Comp_Id === 6 ? 'VAT%' : 'GST%'} editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='TaxAmt' headerText={this.Comp_Id === 6 ? 'VAT Amt' : 'GST Amt'} editTemplate={this.TaxAmt} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='FTaxPer' headerText='FurtherTax%' visible={this.state.SaleTaxPer_Visible} editTemplate={this.FTaxPer} textAlign='right'></ColumnDirective>
                                       <ColumnDirective width='150' field='FTaxAmt' headerText='FurtherTaxAmt' visible={this.state.SaleTaxAmt_Visible} editTemplate={this.FTaxAmt} textAlign='right'></ColumnDirective>
                                    
                                        <ColumnDirective width='150' field='Value' headerText='Value' editTemplate={this.Value} textAlign='right'></ColumnDirective>
                                       {/* <ColumnDirective width='130' field='MRP' headerText='MRP' visible={false}/>*/}
                                       <ColumnDirective field='ItemID' visible={false}  ></ColumnDirective>
                                        <ColumnDirective field='SaleInvoiceDetailID' headerText='SaleInvoiceDetailID' visible={false}></ColumnDirective>
                                        <ColumnDirective field='SaleInvoiceID' hideAtMedia={true} ></ColumnDirective>

                                    <ColumnDirective width='150' headerText='GST on Qty' field='GSTOnQty'    defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective width='150' headerText='GST on Bonus'  field='GSTOnBonus'  defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective width='150' headerText='FTax on Qty'   field='FTaxOnQty'   defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective width='150' headerText='FTax on Bonus' field='FTaxOnBonus' defaultValue={0}  hideAtMedia={true} ></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                </GridComponent>
                            </div>
                        </div>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="TotalQuantity"
                                label="TotalQuantity"
                                value={this.state.TotalQuantity}
                                onInput={this.toInputUppercase}
                                onChange={e => this.setState({ TotalQuantity: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="TotalDiscount"
                                label="TotalDiscount"
                                value={this.state.TotalDiscount}
                                onInput={this.toInputUppercase}
                                onChange={e => this.setState({ TotalDiscount: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDiscount1"
                            label="TotalDiscount1"
                            type='number'
                            value={this.state.TotalDiscount1}
                            inputProps={
                                { readOnly: this.state.DisAmt_Visible }
                            }
                            onChange={e => {
                                this.setState({ TotalDiscount1: e.target.value })
                                // this.GrandAmount_Calculation(this.state.TotalDiscount1,e.target.value, this.state.TotalTaxAmt, this.state.InvoiceAmount)


                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalGSTAmtQty"
                            label={'GST Amt On Qty'} 
                            type='number'
                            value={this.state.TotalGSTOnQty}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalGSTAmtBonus"
                            label={'GST Amt On Bonus'} 
                            type='number'
                            value={this.state.TotalGSTOnBonus}
                            inputProps={
                                { readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="TotalTaxAmt"
                                label={this.Comp_Id === 6 ? 'Total VAT Amt' : 'Total GST Amt'} 
                                value={this.state.TotalTaxAmt}
                                onInput={this.toInputUppercase}
                                onChange={e => this.setState({ TotalTaxAmt: e.target.value })}
                                fullWidth
                                inputProps={
                                    { readOnly: this.state.DisAmt_Visible }
                                }
                            />
                        </Col>
                        <Col sm={12} lg={4} xl={4} md={2} >
                            <TextField
                                name="InvoiceAmount"
                                label="InvoiceAmount"
                                value={this.state.InvoiceAmount}
                                onInput={this.toInputUppercase}
                                onChange={e => this.setState({ InvoiceAmount: e.target.value })}
                                fullWidth
                                inputProps={
                                    { readOnly: true }
                                }
                            />
                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddTaxPer"
                            label="AddTax U/S 236H %"
                            type='number'
                            value={this.state.AddTaxPer}
                            inputProps={
                                { readOnly: true }
                            }
                            // onChange={e=>{
                            //     if(e.target.value){
                            //         this.setState({AddTaxPer:e.target.valueAsNumber},()=>{
                            //             this.Calculate_Taxes()
                            //         })
                            //     }
                            // }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddTaxAmt"
                            label="AddTax U/S 236 H Amt"
                            type='number'
                            value={this.state.AddTaxAmt}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    {/*<Col sm={12} lg={2} xl={2} md={2} > // only hide dont remove
                        <TextField
                            name="IncomeTaxPer"
                            label="G S T %"
                            type='number'
                            value={this.state.IncomeTaxPer}
                            onChange={e => {
                                    this.setState({ IncomeTaxPer: e.target.valueAsNumber ?  e.target.valueAsNumber : 0}, () => {
                                        this.Calculate_Taxes()
                                    })
                                
                            }}
                            fullWidth
                        />
                    </Col>*/}
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalFTaxOnQty"
                            label={'FTax Amt On Qty'} 
                            type='number'
                            value={this.state.TotalFTaxOnQty}
                            inputProps={
                                { readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalFTaxOnBonus"
                            label={'FTax Amt On Bonus'} 
                            type='number'
                            value={this.state.TotalFTaxOnBonus}
                            inputProps={
                                { readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="IncomeTaxAmt"
                            label="Further Tax Amt"
                            type='number'
                            value={this.state.IncomeTaxAmt}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    {/*  <Col sm={12} lg={2} xl={2} md={2} >  // only hide dont remove
                        <TextField
                            name="WHTPer"
                            label="WHT %"
                            type='number'
                            value={this.state.WHTPer}
                            onChange={e => {
                                
                                    this.setState({ WHTPer: e.target.valueAsNumber ? e.target.valueAsNumber : 0 }, () => {
                                        this.Calculate_Taxes()
                                    })
                                
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >  // only hide dont remove
                        <TextField
                            name="WHTAmt"
                            label="WHTAmt"
                            type='number'
                            value={this.state.WHTAmt}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                     <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATPer"
                            label="VAT %"
                            type='number'
                            value={this.state.VATPer}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e => {
                                const newVATPer = e.target.value;
                                this.setState({ VATPer: newVATPer });
                                const mAmt = (this.state.GrandTotal * newVATPer) / 100;
                                const roundedVATAmt = parseFloat(mAmt.toFixed(2));
                                
                                this.setState({ VATAmt: roundedVATAmt },()=>{
                                    this.GrandAmount_Calculation(roundedVATAmt)
                                });
                                
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATAmt"
                            label="VAT Amount"
                            type='number'
                            value={this.state.VATAmt}
                            disabled={true}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e=>{
                                this.setState({VATAmt:e.target.value})
                            }}
                            fullWidth
                        />
                    </Col>*/}
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="GrandTotal"
                                label="GrandTotal"
                                value={this.state.GrandTotal}
                                onInput={this.toInputUppercase}
                                inputProps={{ style: { color: "green", fontWeight: 'bold', fontSize: '20px' } }}
                                onChange={e => this.setState({ GrandTotal: e.target.value })}
                                fullWidth
                            />
                        </Col>
                    </Row>

                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col style={{ textAlign: "right", marginTop: "1%" }} >
                            {/* <Link id="btnGetItems" className="btn btn-primary rounded-pill" style={{ border: "none" }} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Items from Sale Invoice</u></Link> */}
                            {this.state.btn_edit && (<button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.SaveHandler} disabled={this.state.btn_Disabled} >
                                {
                                    this.state.btn_Disabled ?
                                        (<><Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to={'/SaleReturnList'} > <Cancel /> <u>Close</u></Link>
                            {this.state.btn_edit && (<button
                                className="btn btn-primary rounded-pill"
                                style={{ border: "none" }}
                                disabled={this.state.btn_Disabled}
                                onClick={e => {

                                    var obj = {
                                        PurchaseInvoiceID: this.state.PurchaseInvoiceID,
                                        PurchaseDate: this.state.InvoiceDate,
                                        Discription: this.state.Discription,
                                        TotalDisAmt: this.state.TotalDisAmt,
                                        TotalTaxAmt: this.state.TotalTaxAmt,
                                        TotalQty: this.state.TotalQty,
                                        AddTaxAmt: this.state.AddTaxAmt,
                                        TotalGSTOnQty: this.state.TotalGSTOnQty,
                                        TotalGSTOnBonus: this.state.TotalGSTOnBonus,
                                        TotalFTaxOnQty: this.state.TotalFTaxOnQty,
                                        TotalFTaxOnBonus: this.state.TotalFTaxOnBonus,
                                        GrandTotal: this.state.GrandTotal,
                                        PurchaseValue: this.state.PurchaseValue,
                                        PurchaseInvoiceNo: this.state.SaleInvoiceNo,
                                        Supplier: this.state.SelectedCustomer,
                                        Warehouse: this.state.SelectedWareHouse,
                                        PaymentType: this.state.SelectedPaymentType,
                                        Bank: this.state.SelectedBank,
                                        GrandTotal: this.state.GrandTotal,
                                        Detail: this.state.SI_Detail_Data
                                    }
                                    localStorage.setItem('token', sessionStorage.getItem('token'))
                                    localStorage.setItem("SaleReturnReport", JSON.stringify(obj))

                                    this.SaveHandler(true)
                                }}
                            >
                                {
                                    this.state.btn_Disabled ?
                                        (<><Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> Saving... </>) : <> <Print /> <u>Save &amp; Print</u></>
                                }
                            </button>)}
                        </Col>
                    </Row>
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                    />
                    <SaleReturnModal
                        show={this.state.Stock_Modal}
                        onHide={this.Hide_Min_Stock_Modal}
                        mSaveStock={this.LoadStockToGrid}
                        project_Id={this.ProjectID}
                        invoiceDate={this.state.InvoiceDate}
                    />
                  
                  
                </Card>

            </>, document.getElementById('PageContents')
        )
    }

}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.SI && state.SI.isAddNew,
    SaleInvoiceID: state.SI && state.SI.SaleInvoiceID,
    ProjectID: state.SI && state.SI.Project_ID,
    show_screen: state.SCREEN
})
export default connect(mapStateToProps)(SaleInvoiceEntry)