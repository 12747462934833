import React,{ useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom';
import { PlayCircle } from '@mui/icons-material';
import { Spinner  } from "react-bootstrap"
import { LockOpenOutlined } from '@material-ui/icons'
import { Login, LoginVerifyToken, VerifySoftwareExpairy } from './../../Services/LoginAPI'
import { connect } from 'react-redux'
import { setLoginData, setSelectedGlobalVariable, setCompanies,setGlobal_Project,setGlobal_Config,setGlobal_Project_List } from "./../../React_Redux/Actions/index"
import { FinYear } from "./../../Services/FinYear"
import storage from 'redux-persist/lib/storage'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { SelectConfigurationData, GetFileFromLocalDirectory } from './../../Services/ControlPanelAPI'
import { SelectUserProjects } from './../../Services/Projects'
import { domain_for_image } from '../../Services/Common';
import Swal from 'sweetalert2';

function Logins(props) {

    const [UserName, setUserName] = useState('')
    const [Password, setPassword] = useState('')
    const [btn_Disabled, setbtn_Disabled] = useState(false)
    const [LoginPath, setLoginPath] = useState({to: '#'})

    useEffect(() => {
            // for all keys defined in your persistConfig(s)
            storage.removeItem('persist:root')
            props.setLoginData(null)
            localStorage.clear()
            sessionStorage.clear()
            document.title = "Distribution / Login"  

    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('content_Page').hidden = true;
    document.getElementById('RSidebar').hidden = true;

    document.getElementById('emailaddress').focus()
    }, [])
    const LoginSubmit = (e) =>{

        e.preventDefault();

        var validate = true;
        var ErrMessage = '';
        if(UserName === ''){
          validate = false; 
          ErrMessage = 'Enter UserName'
        }
        else if (Password === ''){
          validate = false
          ErrMessage = 'Enter Password'
        }
    
        if(validate){
            setbtn_Disabled(true)
            try{
                Login(UserName, Password, props, (token) => {
                    LoginVerifyToken(token, props, (res) => {
                        // props.setHms_User(res.data.user)
                        props.setLoginData(res.data.auth.data[0], token);
                        props.setCompanies(res.data.comp)
                        localStorage.setItem('token', token)
                        FinYear(res.data.comp[0].Branch_Id, res.data.auth.data[0].User_id, (data) => {
                            SelectConfigurationData(res.data.comp[0].Branch_Id, (mConfigData) => {
                                SelectUserProjects(res.data.comp[0].Branch_Id, res.data.auth.data[0].User_id, async(mProjects) => {
                                    props.setGlobal_Project_List(mProjects)
                                    props.setGlobal_Project(mProjects[0])
                                    if(mConfigData[0].Logo){
                                        let marr = mConfigData[0].Logo.split('/')
                                        mConfigData[0].Logo = marr[marr.length - 1]
                                        const _localProfilePic1 = await GetFileFromLocalDirectory(mConfigData[0].Logo)
                                        mConfigData[0].Logo = _localProfilePic1
                                    }
                                    props.setGlobal_Config(mConfigData[0])
                                    props.setSelectedGlobalVariable(data[0], res.data.comp[0])
                                    // setLoginPath({ to: '/MainForm' })
                                    // document.getElementById('btnAdminDashboard').click()
                                    // document.getElementById('topBar').hidden = false;
                                    // document.getElementById('leftside').hidden = false;
                                    // document.getElementById('content_Page').hidden = false;
                                    VerifySoftwareExpairy(res.data.comp[0].Branch_Id,mProjects[0].Project_ID, (ExpairData)=>{
                                        setbtn_Disabled(false)
                                    if(ExpairData.showMsg === false){
                                    setLoginPath({ to: '/MainForm' })
                                    document.getElementById('btnAdminDashboard').click()
                                    document.getElementById('topBar').hidden = false;
                                    document.getElementById('leftside').hidden = false;
                                    document.getElementById('content_Page').hidden = false;
                                    } else if(ExpairData.showMsg === true && ExpairData.status === true){
                                       
                                        Swal.fire({
                                            title: 'Software Expairy Alert',
                                            text:`${ExpairData.message} \n Meezan Account : 02760102126352 \n Please send us receipt of payment \n System will Re-New Automatically \n THANKS`,
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Print Invoice'
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                                window.open(`SoftwareInvoice/${res.data.comp[0].Branch_Id}/${mProjects[0].Project_ID}`,'_blank')
                                            }
                                          })
                                        setLoginPath({ to: '/MainForm' })
                                        document.getElementById('btnAdminDashboard').click()
                                        document.getElementById('topBar').hidden = false;
                                        document.getElementById('leftside').hidden = false;
                                        document.getElementById('content_Page').hidden = false;
                                    } else{
                                        Swal.fire({
                                            title: 'Software Expairy Alert',
                                            text:`${ExpairData.message} \n Meezan Account : 02760102126352 \n Please send us receipt of payment \n System will Re-New Automatically \n THANKS`,
                                            icon: 'error',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Print Invoice'
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                                window.open(`SoftwareInvoice/${res.data.comp[0].Branch_Id}/${mProjects[0].Project_ID}`,'_blank')
                                            }
                                          })
                                        setLoginPath({ to: '/' }) 
                                    }
                                    })
                                  
                                })
                            })
                        })
                    })
                })
            }catch(err){
                setbtn_Disabled(false)
            }
     
        }
        else {
            toast.configure()
            toast.error(ErrMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
      }
    return ReactDOM.createPortal(
        <>
        <Link to={LoginPath.to} id="btnAdminDashboard" ></Link>
            <div className="account-pages pt-sm-5 pb-sm-5"  style={{ backgroundImage: `url('assets/images/loginbg.jpg')`, backgroundRepeat:'none', backgroundPosition:'center', backgroundSize:'cover', height: '100vh'}} >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-4 col-lg-5">
                            <div className="card">
                                <div className="card-header pt-4 pb-3 text-center" style={{backgroundColor: '#313A46'}}>
                                    <a href="#">
                                        <span> <img src="assets/images/sidebar_logo.png" alt = 'Logo' style={{width:'250px', height: '50px'}} /></span>
                                    </a>
                                </div>
                                <div className="card-body p-4">
                                    <div className="text-center w-75 m-auto">
                                        <h4 className="text-dark-50 text-center pb-0 fw-bold">Sign In</h4>
                                        {/* <p className="text-muted mb-4">Enter your email address and password to access admin panel.</p> */}
                                    </div>
                                    <form action="#">
                                        <div className="mb-3">
                                            <label htmlFor="emailaddress" className="form-label">User Name</label>
                                            <input className="form-control" type="text"  value={UserName}   onChange={e =>{
                                                    setUserName(e.target.value)
                                                }} id="emailaddress" required placeholder="Enter your user name" />
                                        </div>
                                        <div className="mb-3">
                                            {/* <a href="pages-recoverpw.html" className="text-muted float-end"><small>Forgot your password?</small></a> */}
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <div className="input-group input-group-merge">
                                                <input type="password" id="password" className="form-control"  value={Password}  onChange={e =>{
                                                    setPassword(e.target.value)
                                                }} placeholder="Enter your password" />
                                                <div className="input-group-text" data-password="false">
                                                    <span className="password-eye" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="mb-3 mb-3">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="checkbox-signin" defaultChecked />
                                                <label className="form-check-label" htmlFor="checkbox-signin">Remember me</label>
                                            </div>
                                        </div> */}
                                        <div className="mb-3 mb-0 text-center">
                                        <button className="btn text-white bold" disabled={btn_Disabled} style={{backgroundColor: '#313A46', width: '280px'}} onClick={LoginSubmit} >
                                            {
                                            btn_Disabled ?
                                              (<><Spinner
                                                   as="span"
                                                   animation="grow"
                                                   size="sm"
                                                   role="status"
                                                   color='white'
                                                   aria-hidden="true"
                                                   /> Loging... </>) : <> Log In <LockOpenOutlined /></>
                                               } 
                                          </button>
                                        
                                            {/* <button className="btn btn-primary" type="submit"> Log In </button> */}
                                        </div>
                                        <div className="mb-3 mb-0 text-center">
                                        
                                        <a href='https://youtu.be/3LROKvU5SBQ' target='_blank' style={{cursor:'pointer', fontWeight:'bold'}}>Overview Vedio <PlayCircle /></a>
                                            {/* <button className="btn btn-primary" type="submit"> Log In </button> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <p className="text-muted">Don't have an account? <a href="pages-register.html" className="text-muted ms-1"><b>Sign Up</b></a></p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>, document.getElementById('login')
    )   
}
export default connect(null, { setLoginData, 
    setSelectedGlobalVariable, setCompanies,setGlobal_Project,setGlobal_Config,setGlobal_Project_List })(Logins)
