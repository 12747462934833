import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import { SelectUserProjects } from '../../../Services/Projects'
import { rptPL,DirectExpenseForPL,OperativeExpenseForPL,OtherExpenseForPL,OtherIncomeForPL } from '../../../Services/PL_ReportsAPI';
import "./PL.css"

const IncomeStatment = ({Comp_Name,Comp_Id,User_Name,PhoneNo,Comp_Address,ConfigObj}) => {
  const { ProjectID, FromDate, ToDate } = useParams();
  const [Opening, setOpening] = useState(0.00)
  const [Purchase, setPurchase] = useState(0.00)
  const [PurchaseReturn, setPurchaseReturn] = useState(0.00)
  const [NetPurchase, setNetPurchase] = useState(0.00)
  const [Sale, setSale] = useState(0.00)
  const [SaleReturn, setSaleReturn] = useState(0.00)
  const [NetSale, setNetSale] = useState(0.00)
  const [ADJI, setADJI] = useState(0.00)
  const [ADJD, setADJD] = useState(0.00)
  const [Closing, setClosing] = useState(0.00)
  const [DirectExpenseData, setDirectExpenseData] = useState([])
  const [TotalDirectExpense, setTotalDirectExpense] = useState(0.00)
  const [OperativeExpenseData, setOperativeExpenseData] = useState([])
  const [TotalOperativeExpense, setTotalOperativeExpense] = useState(0.00)
  const [CGAS , setCGAS] = useState(0.00)
  const [CGS, setCGS] = useState(0.00)
  const [GP, setGP] = useState(0.00)
  const [NetProfit, setNetProfit] = useState(0.00)
  const [OtherIncomeData, setOtherIncomeData] = useState([])
  const [TotalOtherIncome, setTotalOtherIncome] = useState(0.00)
  const [TotalIncome, setTotalIncome] = useState(0.00)
  const [OtherExpenseData, setOtherExpenseData] = useState([])
  const [TotalOtherExpense, setTotalOtherExpense] = useState(0.00)
  const [NetIncome, setNetIncome] = useState(0.00)
  useEffect(()=>{

    document.title = "Distribution / Profit & Loss Report" 

    document.getElementById('topBar').hidden = true;
   document.getElementById('leftside').hidden = true;
   document.getElementById('content_Page').hidden = true;
   document.getElementById('RSidebar').hidden = true;

    rptPL(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
      setOpening(mData[0].GrandTotal)
      setPurchase(mData[1].GrandTotal)
      setPurchaseReturn(mData[2].GrandTotal)
      setNetPurchase(mData[3].GrandTotal)
      setSale(mData[4].GrandTotal)
      setSaleReturn(mData[5].GrandTotal)
      setNetSale(mData[6].GrandTotal)
      setADJD(mData[7].GrandTotal)
      setADJI(mData[8].GrandTotal)
      setClosing(mData[9].GrandTotal)
      OperativeExpenseForPL(Comp_Id,ProjectID,FromDate,ToDate,(mOperative)=>{

        if(mOperative.length > 0){
          var OperativeTotal = mOperative.splice(-1)
          setTotalOperativeExpense(OperativeTotal[0].GrandTotal)
          setOperativeExpenseData(mOperative)

        }
        DirectExpenseForPL(Comp_Id,ProjectID,FromDate,ToDate,(MDirectExpense)=>{
          if(MDirectExpense.length > 0){
           var DirectTotal = MDirectExpense.splice(-1)
            setTotalDirectExpense(DirectTotal[0].GrandTotal)
            setCGAS(parseFloat(mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal).toFixed(2))
            setCGS(parseFloat((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)- mData[9].GrandTotal).toFixed(2) )
            setGP(parseFloat(((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal)).toFixed(2))
            setNetProfit(parseFloat((((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal))-(OperativeTotal[0].GrandTotal)).toFixed(2))
            setDirectExpenseData(MDirectExpense)
            
          }
          OtherIncomeForPL(Comp_Id,ProjectID,FromDate,ToDate,(mOtherIncome)=>{
           
            if(mOtherIncome.length > 0){
              var TotalOtherIncome = mOtherIncome.splice(-1)
              setTotalOtherIncome(TotalOtherIncome[0].GrandTotal)
              setTotalIncome(parseFloat(((((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal))-(OperativeTotal[0].GrandTotal))+(TotalOtherIncome[0].GrandTotal)).toFixed(2))
              setOtherIncomeData(mOtherIncome)
            }
            OtherExpenseForPL(Comp_Id,ProjectID,FromDate,ToDate,(mOtherExpenses)=>{
              if(mOtherExpenses.length > 0){
                var TotalOtherExpenses = mOtherExpenses.splice(-1)
                setTotalOtherExpense(TotalOtherExpenses[0].GrandTotal)
                setOtherExpenseData(mOtherExpenses)
                setNetIncome(parseFloat((((((mData[0].GrandTotal + mData[2].GrandTotal + DirectTotal[0].GrandTotal)-(mData[9].GrandTotal))-(mData[4].GrandTotal))-(OperativeTotal[0].GrandTotal))+(TotalOtherIncome[0].GrandTotal))-(TotalOtherExpenses[0].GrandTotal)).toFixed(2))
              }
            })
          })
        })
 

      })
    })

  
  },[])
 
  return (
   <>
 <div className="container">
  <div className="row">
  <div className="col-md-3 head"> <img src={ConfigObj.Logo} width="100" height="100" /></div>
    <div className="col-md-6 head">
      <h1><strong>{Comp_Name}</strong></h1>
      <h4 className="font"><strong>Address:</strong>{Comp_Address}</h4>
      <h4 className="font"><strong>PNO:</strong> {PhoneNo}</h4>
    </div>
  </div>
  <div className="row">
    <div className="col-md-12 bck mt-3">
      <h3 style={{  textTransform: 'uppercase',
        color: '#fff',
        textAlign: 'center'   }}>profit and loss statment / income statment</h3>
      <div className="row">
        <div className="col-md-4"> <h4 className="mt-3"><strong>From Date:</strong>{FromDate}</h4></div>
        <div className="col-md-4" />
        <div className="col-md-4"><h4 className="mt-3"><strong>To Date:</strong>{ToDate}</h4></div>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="row subheads mt-3">Revenue From Sales</div>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Sales</strong></td>
          <td style={{width: 120}}>{parseFloat(Sale).toFixed(2)}</td>
          <td style={{width: 120}} />
        </tr>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Less Sales Return and Allownces</strong></td>
          <td style={{width: 120}}>({parseFloat(SaleReturn).toFixed(2)})</td>
          <td style={{width: 120}} />
        </tr>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Net Sales</strong></td>
          <td style={{width: 120}} />
          <td style={{width: 120}} id='NetSale'>{parseFloat(NetSale).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
    <div className="row subheads">Cost of Goods Sold(CGS)</div>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Opening Inventory</strong></td>
          <td style={{width: 120}}>{parseFloat(Opening).toFixed(2)}</td>
          <td style={{width: 120}} />
        </tr>
      </tbody>
    </table>
    <div className="row subheads mt-3">Add Purchases</div>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 750}}> <strong>Purchases</strong></td>
          <td style={{width: 120}}>{parseFloat(Purchase).toFixed(2)}</td>
          <td style={{width: 120}} />
          <td style={{width: 120}} />
        </tr>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Less Purchases Return and Allownces</strong></td>
          <td style={{width: 120}}>({parseFloat(PurchaseReturn).toFixed(2)})</td>
          <td style={{width: 120}} />
          <td style={{width: 120}} />
        </tr>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Net Purchases</strong></td>
          <td style={{width: 120}} />
          <td style={{width: 120}} id='NetPurchase'>{parseFloat(NetPurchase).toFixed(2)}</td>
          <td style={{width: 120}} />
        </tr>
      </tbody>
    </table>
    <div className="row subheads mt-3">Add Direct Expenses</div>
    <table className="table table-bordered">
      <tbody>
       {
        DirectExpenseData.map((DirectExpenseData,index)=>(
     
          <>
          <tr key={index}>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>{DirectExpenseData.ExpenseType}</strong></td>
          <td style={{width: 120}}>{DirectExpenseData.GrandTotal}</td>
          <td style={{width: 120}} />
          <td style={{width: 120}} />
        </tr>
          </>
        ))
      }
        
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Total Direct Expenses</strong></td>
          <td style={{width: 120}} />
          <td style={{width: 120}}>{parseFloat(TotalDirectExpense).toFixed(2)}</td>
          <td style={{width: 120}} />
        </tr>
      </tbody>
    </table>
    <div className="row subheads">Cost of Goods Available for Sales</div>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Cost of Goods Available for Sales </strong></td>
          <td style={{width: 120}}>{parseFloat(CGAS).toFixed(2)}</td>
          <td style={{width: 120}} />
        </tr>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Less Closing Inventory</strong></td>
          <td style={{width: 120}}>({parseFloat(Closing).toFixed(2)})</td>
          <td style={{width: 120}} />
        </tr>
      </tbody>
    </table>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Cost Of Goods Sold (CGS)</strong></td>
          <td style={{width: 120}} />
          <td style={{width: 120}}>{parseFloat(CGS).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
    <div className="row subheads">Gross Profit/Loss</div>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Gross Profit/Loss (Net Sales - CGS)</strong></td>
          <td style={{width: 120}} />
          <td style={{width: 120}}>{parseFloat(GP).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
    <div className="row subheads mt-5">Less Operative Expenses</div>
    <table className="table table-bordered">
      <tbody>
      {
        OperativeExpenseData.map((OperativeExpenseData,index)=>(
     
          <>
          <tr key={index}>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>{OperativeExpenseData.ExpenseType}</strong></td>
          <td style={{width: 120}}>{OperativeExpenseData.GrandTotal}</td>
          <td style={{width: 120}} />
          
        </tr>
          </>
        ))
      }
       
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong /> Total Operative Expenses</td>
          <td style={{width: 120}} />
          <td style={{width: 120}}>{parseFloat(TotalOperativeExpense).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
    <div className='page-break-ledger'>
              
</div> 
    <div className="row subheads" />
    <table className="table table-bordered">
      <tbody>
        <tr >
          <th scope="row" style={{width: 50}}  />
          <td style={{width: 870}} ><strong>Net Profit/Loss</strong></td>
          <td style={{width: 120}} />
          <td style={{width: 120}} >{NetProfit}</td>
        </tr>
      </tbody>
    </table>
    <div className="row subheads mt-3">Add Other Income</div>
    <table className="table table-bordered">
    <tbody>
    {
      OtherIncomeData.map((OtherIncomeData,index)=>(
   
        <>
        <tr key={index}>
        <th scope="row" style={{width: 50}} />
        <td style={{width: 870}}><strong>{OtherIncomeData.ExpenseType}</strong></td>
        <td style={{width: 120}}></td>
        <td style={{width: 120}} >{OtherIncomeData.GrandTotal}</td>
        <td style={{width: 120}} ></td>
      </tr>
        </>
      ))
    }
     
      <tr>
        <th scope="row" style={{width: 50}} />
        <td style={{width: 870}}><strong>Total Other Income</strong></td>
        <td style={{width: 120}} />
        <td style={{width: 120}}></td>
        <td style={{width: 120}} >{parseFloat(TotalOtherIncome).toFixed(2)}</td>
      </tr>
      <tr>
      <th scope="row" style={{width: 50}} />
      <td style={{width: 870}}><strong>Total  Income</strong></td>
      <td style={{width: 120}} />
      <td style={{width: 120}}></td>
      <td style={{width: 120}} >{TotalIncome}</td>
    </tr>
    </tbody>
  </table>
 
<div className="row subheads mt-3">Less Other Expenses</div>
<table className="table table-bordered">
<tbody>
{
  OtherExpenseData.map((OtherExpenseData,index)=>(

    <>
    <tr key={index}>
    <th scope="row" style={{width: 50}} />
    <td style={{width: 870}}><strong>{OtherExpenseData.ExpenseType}</strong></td>
    <td style={{width: 120}}></td>
    <td style={{width: 120}} >{OtherExpenseData.GrandTotal}</td>
    <td style={{width: 120}} />
  </tr>
    </>
  ))
}

  <tr>
    <th scope="row" style={{width: 50}} />
    <td style={{width: 870}}><strong>Total Other Expenses</strong></td>
    <td style={{width: 120}} />
    <td style={{width: 120}}></td>
    <td style={{width: 120}} >{parseFloat(TotalOtherExpense).toFixed(2)}</td>
  </tr>
</tbody>
</table>
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th scope="row" style={{width: 50}} />
          <td style={{width: 870}}><strong>Net Income</strong></td>
          <td style={{width: 120}} />
          <td style={{width: 120}}>{NetIncome}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


   </>
  )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
  Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
  User_Id: state.Login_User && state.Login_User.User_Data.User_id,
  User_Name: state.Login_User && state.Login_User.User_Data.User_name,
  PhoneNo: state.Login_User && state.Login_User.User_Data.PhoneNo,
  ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})
export default connect(mapStateToProps)(IncomeStatment)

