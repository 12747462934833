import React,{useEffect,useState} from 'react'
import  ReactDOM  from 'react-dom'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { domain_for_image } from '../../Services/Common'
import  ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal'
import { GetFileFromLocalDirectory } from '../../Services/ControlPanelAPI'
import socketIOClient from "socket.io-client";
import { InstallDesktop } from '@mui/icons-material'

function TopBar ({UserName,Image})  {
  const [ImageSrc, setImageSrc] = useState(Image && domain_for_image+"/"+ Image.split('/')[5]);
  const [ShowChangePassword_modal, setShowChangePassword_modal] = useState(false);
  const [ShowNotificationBadge, setShowNotificationBadge] = useState(false)
  const [ShowOrder, setShowOrder] = useState(false)
  const [OrdersData, setOrdersData] = useState([])
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const fetchData = async() =>{
      if(Image)
      {
        
        let marr =Image.split('/')
       Image = marr[marr.length - 1]
        const _localProfilePic1 = await GetFileFromLocalDirectory(Image)
        setImageSrc(_localProfilePic1)
      }
      else{
        setImageSrc('https://cdn-icons-png.flaticon.com/512/149/149071.png')
      }
    }
    fetchData()
 
    // const socket = socketIOClient(domain_for_live_Notifications);
    // socket.on("chat message", data => {
    //   setShowNotificationBadge(true)
    //   setOrdersData(prevState=>[...prevState,data])
    // });
  
  }, [Image])
  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('App installation accepted.');
      }
      setDeferredPrompt(null); // Clear the event after prompting
      setIsInstallable(false); // Optionally hide the install button after installation
    }
  };
 
  const Show_ChangePassword_Modal = ()=>{
    setShowChangePassword_modal(true)
  }
  const Hide_ChangePassword_Modal = ()=>{
    setShowChangePassword_modal(false)
  }
 const Clear_Data = () =>{
  setShowNotificationBadge(false)
  setOrdersData([])
 }
  return ReactDOM.createPortal(
    <>
     <div className="navbar-custom">
        <ul className="list-unstyled topbar-menu float-end mb-0">
          {/* <li className="dropdown notification-list d-lg-none">
            <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <i className="dripicons-search noti-icon" />
            </a>
             <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
              <form className="p-3">
                <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
              </form>
            </div> 
          </li> */}
          {/* <li className="dropdown notification-list topbar-dropdown">
            <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <img src="assets/images/flags/us.jpg" alt="user-image" className="me-0 me-sm-1" height={12} />
              <span className="align-middle d-none d-sm-inline-block">English</span> <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle" />
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu">
              <a href="javascript:void(0);" className="dropdown-item notify-item">
                <img src="assets/images/flags/germany.jpg" alt="user-image" className="me-1" height={12} /> <span className="align-middle">German</span>
              </a>
              <a href="javascript:void(0);" className="dropdown-item notify-item">
                <img src="assets/images/flags/italy.jpg" alt="user-image" className="me-1" height={12} /> <span className="align-middle">Italian</span>
              </a>
              <a href="javascript:void(0);" className="dropdown-item notify-item">
                <img src="assets/images/flags/spain.jpg" alt="user-image" className="me-1" height={12} /> <span className="align-middle">Spanish</span>
              </a>
              <a href="javascript:void(0);" className="dropdown-item notify-item">
                <img src="assets/images/flags/russia.jpg" alt="user-image" className="me-1" height={12} /> <span className="align-middle">Russian</span>
              </a>
            </div>
          </li> */}
 
           <li className="dropdown notification-list">
            <a className="nav-link dropdown-toggle arrow-none" onClick={e=>{setShowOrder(!ShowOrder)}} data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <i className="dripicons-bell noti-icon" />
              {ShowNotificationBadge && <span className="noti-icon-badge" /> }
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg">
              <div className="dropdown-item noti-title">
                <h5 className="m-0">
                  <span className="float-end">
                    <a href="javascript: void(0);" onClick={Clear_Data} className="text-dark">
                      <small>Clear All</small>
                    </a>
                  </span>Orders 
                </h5>
              </div>
              
              {/* <div style={{ overflow:'scroll',maxHeight:'200px'}} data-simplebar> */}
              {
              OrdersData &&  OrdersData.map((value,index)=>(
                  <div key={index} style={{ maxHeight:'230px' }} data-simplebar>
                    <a  href="javascript:void(0);" className="dropdown-item notify-item">
                      <div className="notify-icon bg-info">
                        <i className="mdi mdi-heart" />
                      </div>
                      <p className="notify-details">Booker Name  :  {value.UserName}</p>
                      <p className="text-muted mb-0 user-msg">
                        <small>New Order Having Booking Code :{value.BookingCode} </small>
                      </p>
                    </a>
                    </div>
                  ))
              }
           
           {/* </div> */}
              {/* <a href="javascript:void(0);" className="dropdown-item text-center text-primary notify-item notify-all">
                View All
              </a> */}
            </div>
          </li> 
          {/* <li className="dropdown notification-list d-none d-sm-inline-block">
            <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <i className="dripicons-view-apps noti-icon" onClick={handleInstallClick}/>
            
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg p-0">
              <div className="p-2">
                <div className="row g-0">
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/slack.png" alt="slack" />
                      <span>Slack</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/github.png" alt="Github" />
                      <span>GitHub</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/dribbble.png" alt="dribbble" />
                      <span>Dribbble</span>
                    </a>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/bitbucket.png" alt="bitbucket" />
                      <span>Bitbucket</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/dropbox.png" alt="dropbox" />
                      <span>Dropbox</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="assets/images/brands/g-suite.png" alt="G Suite" />
                      <span>G Suite</span>
                    </a>
                  </div>
                </div> 
              </div>
            </div>
          </li> */}
          <li className="notification-list">
            <a className="nav-link end-bar-toggle" href="javascript: void(0);">
              <i className="dripicons-gear noti-icon" />
            </a>
          </li>
          <li className="dropdown notification-list">
            <a className="nav-link dropdown-toggle nav-user arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <span className="account-user-avatar">
                <img src={ImageSrc} alt="user-image" className="rounded-circle" />
              </span>
              <span>
                <span className="account-user-name">{UserName && UserName}</span>
                {/* <span className="account-position">Founder</span> */}
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
              <div className=" dropdown-header noti-title">
                <h6 className="text-overflow m-0">Welcome !</h6>
              </div>
              <Link to={"/Profile"} className="dropdown-item notify-item">
                <i className="mdi mdi-account-circle me-1" />
                <span>My Account</span>
              </Link>
              <a href="javascript:void(0);" onClick={Show_ChangePassword_Modal} className="dropdown-item notify-item">
                <i className="mdi mdi-account-edit me-1" />
                <span>Change Password</span>
              </a>
              <a href="javascript:void(0);" onClick={handleInstallClick} className="dropdown-item notify-item">
                <InstallDesktop className='me-1' sx={{fontSize:"14px"}} />
                <span>Install App</span>
              </a>
              {/* <a href="javascript:void(0);" className="dropdown-item notify-item">
                <i className="mdi mdi-lifebuoy me-1" />
                <span>Support</span>
              </a>
              <a href="javascript:void(0);" className="dropdown-item notify-item">
                <i className="mdi mdi-lock-outline me-1" />
                <span>Lock Screen</span>
              </a> */}
              <Link to="/" onClick={e=>{
                setTimeout(() => {
                  window.location.reload()
                }, 200);
                }} className="dropdown-item notify-item">
              {/* <a href="javascript:void(0);" className="dropdown-item notify-item"> */}
                <i className="mdi mdi-logout me-1" />
                <span>Logout</span>
              {/* </a> */}
              </Link>
            </div>
          </li>
        </ul>
        <button className="button-menu-mobile open-left">
          <i className="mdi mdi-menu" />
        </button>
        {/* <div className="app-search dropdown d-none d-lg-block">
          <form>
            <div className="input-group">
              <input type="text" className="form-control dropdown-toggle" placeholder="Search..." id="top-search" />
              <span className="mdi mdi-magnify search-icon" />
              <button className="input-group-text btn-primary" type="submit">Search</button>
            </div>
          </form>
          <div className="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
            <div className="dropdown-header noti-title">
              <h5 className="text-overflow mb-2">Found <span className="text-danger">17</span> results</h5>
            </div>
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <i className="uil-notes font-16 me-1" />
              <span>Analytics Report</span>
            </a>
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <i className="uil-life-ring font-16 me-1" />
              <span>How can I help you?</span>
            </a>
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <i className="uil-cog font-16 me-1" />
              <span>User profile settings</span>
            </a>
            <div className="dropdown-header noti-title">
              <h6 className="text-overflow mb-2 text-uppercase">Users</h6>
            </div>
            <div className="notification-list">
              <a href="javascript:void(0);" className="dropdown-item notify-item">
                <div className="d-flex">
                  <img className="d-flex me-2 rounded-circle" src="assets/images/users/avatar-2.jpg" alt="Generic placeholder image" height={32} />
                  <div className="w-100">
                    <h5 className="m-0 font-14">Erwin Brown</h5>
                    <span className="font-12 mb-0">UI Designer</span>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0);" className="dropdown-item notify-item">
                <div className="d-flex">
                  <img className="d-flex me-2 rounded-circle" src="assets/images/users/avatar-5.jpg" alt="Generic placeholder image" height={32} />
                  <div className="w-100">
                    <h5 className="m-0 font-14">Jacob Deo</h5>
                    <span className="font-12 mb-0">Developer</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <ChangePasswordModal 
          show={ShowChangePassword_modal}
          hide={Hide_ChangePassword_Modal}
        />
      </>, document.getElementById('topBar')
  )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Group_Id : state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
  Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  UserName: state.Login_User.User_Data && state.Login_User.User_Data.User_name,
  Image:state.Login_User.User_Data && state.Login_User.User_Data.UserPic
})
export default connect(mapStateToProps)(TopBar)