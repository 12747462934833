import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import "./InvoicePrint.css";
import { GetCurrentDate, GetCurrentTimeAM_PM } from "../../Services/Common";
import InvoiceFooter from "../Reports/Footer";

function setPageSize(cssPageSize) {
  const style = document.createElement("style");
  style.innerHTML = `@page {size: ${cssPageSize}}`;
  style.id = "page-orientation";
  document.head.appendChild(style);
}

function PurchaseInvoiceReport({ CompName, ConfigObj }) {
  const { Comp_Id, InvID } = useParams();
  const [InvoiceTableData, setInvoiceTableData] = useState([]);
  const [Inv, setInv] = useState("");
  const [InvDate, setInvDate] = useState("");
  const [Supplier, setSupplier] = useState("");
  const [Godown, setGodown] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [BankName, setBankName] = useState("");
  const [TotalGST, setTotalGST] = useState(0);
  const [TotalGSTOnQty, setTotalGSTOnQty] = useState(0);
  const [TotalGSTOnBonus, setTotalGSTOnBonus] = useState(0);
  const [TotalAddTax, setTotalAddTax] = useState(0);
  const [TotalFTaxOnQty, setTotalFTaxOnQty] = useState(0);
  const [TotalFTaxOnBonus, setTotalFTaxOnBonus] = useState(0);
  const [GrandTotal, setGrandTotal] = useState(0);
  var [TotalQty, setTotalQty] = useState(0.0);
  var [TotalNetAmt, setTotalNetAmt] = useState(0.0);

  useEffect(() => {
    document.getElementById("topBar").hidden = true;
    document.getElementById("leftside").hidden = true;
    document.getElementById("footer").hidden = true;
    document.getElementById("content_Page").hidden = true;
    document.getElementById("RSidebar").hidden = true;

    var _Data = JSON.parse(localStorage.getItem("SaleReturnReport"));

    setTotalFTaxOnQty(_Data.TotalFTaxOnQty);
    setTotalFTaxOnBonus(_Data.TotalFTaxOnBonus);
    setTotalGSTOnBonus(_Data.TotalGSTOnBonus);
    setTotalGSTOnQty(_Data.TotalGSTOnQty);
    setGrandTotal(_Data.GrandTotal);
    setInv(_Data.PurchaseInvoiceNo);
    setTotalGST(_Data.TotalTaxAmt);
    setInvDate(_Data.PurchaseDate);
    setTotalAddTax(_Data.AddTaxAmt);
    setSupplier(_Data.Supplier.CustomerName);
    setGodown(_Data.Warehouse.WareHouse);
    setPaymentType(_Data.PaymentType.value);
    setBankName(_Data.Bank && _Data.Bank.BankName);
    setInvoiceTableData(_Data.Detail);
  }, []);

  return (
    <>
    <div className="size bg-white" style={{ color: "black" }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <button
          style={{ margin: "20px" }}
          className="btn btn-primary rounded-pill hide-on-print"
          onClick={() => window.print()}
        >
          Print
        </button>
      </div>
      <Row className="between-rows-margin between-left-margin between-right-margin">
        <Col sm={3} md={3} xl={3}>
          <img src={ConfigObj.Logo} width="100" height="100" />
        </Col>
        <Col sm={6} md={6} xl={6} style={{ textAlign: "center" }}>
          <h3 style={{ textAlign: "center" }}> {CompName} </h3>
        </Col>
      </Row>

      <Row className="between-rows-margin between-left-margin between-right-margin">
        <Col lg={8} md={8}>
          <Row lg={12} md={12} xl={12}>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Inv.#
            </Col>
            <Col sm={9} md={9} xl={9}>
              {Inv}
            </Col>
          </Row>

          <Row lg={12} md={12} xl={12}>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Inv Date:
            </Col>
            <Col sm={9} md={9} xl={9}>
              {InvDate}
            </Col>
          </Row>

          <Row lg={12} md={12} xl={12}>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Supplier:
            </Col>
            <Col sm={9} md={9} xl={9}>
              {Supplier}
            </Col>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Godown:
            </Col>
            <Col sm={9} md={9} xl={9}>
              {Godown}
            </Col>
          </Row>
        </Col>

        <Col lg={4} md={4}>
          <Row sm={12} md={12} xl={12}>
            <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
              Payment Type:
            </Col>
            <Col sm={8} md={8} xl={8}>
              {PaymentType}
            </Col>
            <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
              Bank Name:
            </Col>
            <Col sm={8} md={8} xl={8}>
              {BankName}
            </Col>
            <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
              Print Date & Time:
            </Col>
            <Col sm={8} md={8} xl={8}>
            {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
            </Col> 
          </Row>
        </Col>
      </Row>

      <Row className="between-rows-margin between-left-margin between-right-margin">
        <Col>
          <div id="table_sec_invprt">
            <table id="main_table_invoicepr" style={{ marginBottom: "20px" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}> Sr # </th>
                  <th style={{ textAlign: "left" }}> Item Description </th>
                  <th style={{ textAlign: "center" }}> Qty </th>
                  <th style={{ textAlign: "center" }}> Bonus Qty </th>
                  <th style={{ textAlign: "center" }}> UOM </th>
                  <th style={{ textAlign: "center" }}> Batch No </th>
                  <th style={{ textAlign: "center" }}> Expairy Date </th>
                  <th style={{ textAlign: "right" }}> Rate </th>
                  <th style={{ textAlign: "right" }}> Discount </th>
                  <th style={{ textAlign: "right" }}> Tax </th>
                  <th style={{ textAlign: "right" }}> Amount </th>
                </tr>
              </thead>

              <tbody>
                {InvoiceTableData.map((invoicetabledata, index) => {
                  TotalQty += invoicetabledata.Qty;
                  TotalNetAmt += invoicetabledata.Value;
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}> {index + 1} </td>
                      <td style={{ textAlign: "left" }}>
                        {" "}
                        {invoicetabledata.ItemDiscription}{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        {invoicetabledata.Qty}{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        {invoicetabledata.BonusQty}{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        {invoicetabledata.UOM}{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        {invoicetabledata.BatchNo}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.ExpairyDate}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.PurchaseRate}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.DisAmt}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.TaxAmt}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.Value}{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ textAlign: "right" }} colSpan="2">
                    {" "}
                    Total:
                  </td>
                  <td style={{ textAlign: "center" }}>{TotalQty}</td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}>{TotalNetAmt}</td>
                </tr>
              </tfoot>                      
            </table>
            <div style={{ display:"flex", justifyContent:"flex-end"}}>
              <div>
            <tr style={{ borderTop: '2px solid black' }}>
                                           
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Gross Value:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{TotalNetAmt}</td>  
                                           
                                           </tr>
                                            <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Adjust Cr Note:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{0}</td>
                                           </tr>
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Less Dis 1:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{0}</td>
                                           </tr>
                                           <tr >
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left' }}  >Less Dis 2:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left' }}  >{0}</td>
                                           </tr> 
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  > Gst on Qty:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{TotalGSTOnQty}</td>
                                           </tr>
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  > Gst on Bonus:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >{TotalGSTOnBonus}</td>
                                           </tr>
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >Total Gst:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >{TotalGST}</td>
                                           </tr>
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  > Further GST Tax on Qty:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{TotalFTaxOnQty}</td>
                                           </tr>
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  > Further GST Tax on Bonus:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >{TotalFTaxOnBonus}</td>
                                           </tr>
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >Total Further GST Tax:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >{0}</td>
                                           </tr>
                                           <tr>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >Advance Tax U/s 236H:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', borderBottom:"1px solid black" }}  >{TotalAddTax}</td>
                                           </tr>
   
                                           
                                           <tr style={{marginTop:"20px"}}>
                                           <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', fontSize:"20px"}}  >Invoice Payable:</td>
                                           <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', fontSize:"20px"}}  >{GrandTotal}</td>
                                           </tr>
                </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    {/* <InvoiceFooter /> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  CompName: state.GlobalVariables.Company.Name,
  ConfigObj: state.Global_Configs && state.Global_Configs.GlobalConfig,
});

export default connect(mapStateToProps)(PurchaseInvoiceReport);

// export default InvoicePrint
