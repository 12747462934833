import * as actiontypes from "./types"

// ################################# persit start ##################################
export const setInventoryOpening = (fieldName, value) => {
    return {
        type: actiontypes.INVENTORY_OPENING,
        payload: {
            fieldName,
            value,
        },
    };
};

export const setInventoryOpeningDetails = (updatedRow) => {
    return {
      type: actiontypes.INVENTORY_DETAIL,
      payload: {
        updatedRow,
      },
    };
  };
  export const removeInventoryDetail = (ItemID) => {
    return {
        type: actiontypes.REMOVE_INVENTORY_DETAIL,
        payload: {
            ItemID,
        },
    };
};
export const updateInventoryDetail = (updatedRow) => {
    return {
        type: actiontypes.UPDATE_INVENTORY_DETAIL,
        payload: {
            updatedRow,
        },
    };
};
export const clearInventoryData = () => {
    return {
        type: actiontypes.CLEAR_INVENTORY_DATA,
    };
};

export const setSaleInvoiceMaster = (fieldName, value) => {
    return {
        type: actiontypes.SALE_INVOICES,
        payload: {
            fieldName,
            value,
        },
    };
};

export const setSalesInvoiceDetails = (updatedRow) => {
    return {
      type: actiontypes.SALE_INVOICE_DETAILS,
      payload: {
        updatedRow,
      },
    };
  };
  export const removeSalesInvoiceDetail = (ItemID, BatchNo) => {
    return {
        type: actiontypes.REMOVE_SALE_INVOICE_DETAILS,
        payload: {
            ItemID,
            BatchNo
        },
    };
};
export const updateSaleInvoice = (updatedRow) => {
    return {
        type: actiontypes.UPDATE_SALE_INVOICE_DETAILS,
        payload: {
            updatedRow,
        },
    };
};
export const clearSaleInvoiceData = () => {
    return {
        type: actiontypes.CLEAR_SALE_INVOICE_DATA,
    };
};
// ################################# persit end ##################################
export const setLoginData = (loginData,token)=>{
    return {
        type: actiontypes.LOGIN_USER,
        payload:{
            loginData,
            token
        }
    }
}

export const setCompanies = (Companies) =>{

    return {
        type: actiontypes.COMPANIES,
        payload: {
            Companies: Companies
        }
    }
}

export const setSelectedGlobalVariable = (Gl, Company) =>{

    return{
        type: actiontypes.GLOBAL_VARIABLE,
        payload: {
            Gl_Year: Gl,
            Company: Company
        }
    }
}


export const setDonorData = (DonorData,d_id,isAddNew) =>{
    return {
        type: actiontypes.DONOR,
        payload:{
            DonorData,
            d_id,
            isAddNew
        }
    }
}

export const setReceiverData = (ReceiverData,p_id,isAddNew) =>{
    return {
        type: actiontypes.RECEIVER,
        payload:{
            ReceiverData,
            p_id,
            isAddNew
        }
    }
}


export const setExchangerData = (ExchangerData,e_id,isAddNew) =>{
    return {
        type: actiontypes.EXCHANGER,
        payload:{
            ExchangerData,
            e_id,
            isAddNew
        }
    }
}
export const setUserAccessData = (AccessData,Group_Id,Frm_Add) =>{
    return { 
        type: actiontypes.USERACCESS,
        payload:{
            AccessData:AccessData,
            Group_Id:Group_Id,
            FrmAdd:Frm_Add
        }
    }
}

export const setUsers = (UsersData,UserID,Frm_Add)=>{
    return {
        type: actiontypes.USERS ,
        payload:{
            UsersData,
            UserID,
            Frm_Add
        }
    }
}

export const setUserData = (User_Id,Frm_Add) =>{
    return {
        type: actiontypes.USER,
        payload: {
            User_Id:User_Id,
            FrmAdd:Frm_Add
        }
    }
}

// ################################  --- IPD ---- ################################ //   
export const setIPD_Regstration = (RegData,pt_id,Frm_Add)=>{
    return {
        type: actiontypes.IPD_REGISTRATION ,
        payload:{
            RegData,
            pt_id,
            Frm_Add
        }
    }
}

// ################################  --- Pharmacy ---- ################################ // 

export const set_Medicine = (ItemData,ItemID,Frm_Add, ManufacturedByData) =>{
    return { 
        type : actiontypes.MEDICINE , 
        payload:{
            ItemData,
            ItemID,
            Frm_Add,
            ManufacturedByData
        }
    }
}

export const set_Supplier = (SupplierID,Frm_Add) =>{
    return { 
        type : actiontypes.SUPPLIER , 
        payload:{
            SupplierID,
            Frm_Add
        }
    }
}
export const set_Customer = (CustomerID,Frm_Add) =>{
    return { 
        type : actiontypes.CUSTOMER, 
        payload:{
            CustomerID,
            Frm_Add
        }
    }
}
export const set_Configuration = (ConfigurationID,Frm_Add) =>{
    return { 
        type : actiontypes.CONFIGURATION, 
        payload:{
            ConfigurationID,
            Frm_Add
        }
    }
}
export const set_StockTransfer = (StockTransferID,Frm_Add) =>{
    return { 
        type : actiontypes.STOCKTRANSFER, 
        payload:{
            StockTransferID,
            Frm_Add
        }
    }
}
export const set_StockAdjustment = (StockAdjustmentID,Frm_Add) =>{
    return { 
        type : actiontypes.STOCKADJUSTMENT, 
        payload:{
            StockAdjustmentID,
            Frm_Add
        }
    }
}

export const set_PO = (PO_data,PurchaseOrderID,Frm_Add, Project_ID) =>{
    return { 
        type : actiontypes.PURCHASE_ORDER , 
        payload:{
            PO_data,
            PurchaseOrderID,
            Frm_Add,
            Project_ID
        }
    }
}
export const set_SO = (SO_data,SaleOrderID,Frm_Add, ProjectID) =>{
    return { 
        type : actiontypes.SALE_ORDER , 
        payload:{
            SO_data,
            SaleOrderID,
            Frm_Add,
            ProjectID: ProjectID
        }
    }
}

export const set_PI = (PI_data,PurchaseInvoiceID,Frm_Add, Project_ID) =>{
    return { 
        type : actiontypes.PURCHASE_INVOICE , 
        payload:{
            PI_data,
            PurchaseInvoiceID,
            Frm_Add,
            Project_ID
        }
    }
}

export const setTurnNo = (turn_no) =>{

    return{
        type: actiontypes.TURN_NO,
        payload: {
            turn_no
        }
    }
}

export const set_OPening = (OPening_data,OPening_ID,Frm_Add) =>{
    return { 
        type : actiontypes.OPENING , 
        payload:{
            OPening_data,
            OPening_ID,
            Frm_Add
        }
    }
}
export const setBankOpening = (OPening_data,OPening_ID,Frm_Add) =>{
    return { 
        type : actiontypes.BANK_OPENING , 
        payload:{
            OPening_data,
            OPening_ID,
            Frm_Add
        }
    }
}
export const setCompanyOpening = (OPening_data,OPening_ID,Frm_Add) =>{
    return { 
        type : actiontypes.COMPANY_OPENING, 
        payload:{
            OPening_data,
            OPening_ID,
            Frm_Add
        }
    }
}
export const setCustomerOpening = (OPening_data,OPening_ID,Frm_Add) =>{
    return { 
        type : actiontypes.CUSTOMER_OPENING, 
        payload:{
            OPening_data,
            OPening_ID,
            Frm_Add
        }
    }
}
export const set_SI = (SI_data,SaleInvoiceID,Frm_Add, Project_ID,InvoiceType_Data) =>{
    
    return { 
        type : actiontypes.SALE_INVOICE , 
        payload:{

            SI_data,
            SaleInvoiceID,
            Frm_Add,
            Project_ID,
            InvoiceType_Data

        }
    }
}

export const SHOW_SCREEN = (add, edit, del, others) =>{
    return { 
        type : actiontypes.SHOW_SCREEN , 
        payload:{
            add,
            edit,
            del,
            others
        }
    }
}

export const set_Payment = (Payment_data,PaymentID,Frm_Add) =>{
    return { 
        type : actiontypes.PAYMENTS , 
        payload:{
            Payment_data,
            PaymentID,
            Frm_Add
        }
    }
}
export const set_CompanyPayment = (Payment_data,PaymentID,Frm_Add) =>{
    return { 
        type : actiontypes.COMPANY_PAYMENTS , 
        payload:{
            Payment_data,
            PaymentID,
            Frm_Add
        }
    }
}
export const set_Receipt = (Receipt_data,ReceiptID,Frm_Add) =>{
    return { 
        type : actiontypes.RECEIPTS , 
        payload:{
            Receipt_data,
            ReceiptID,
            Frm_Add
        }
    }
}

export const setGlobal_Project = Projects =>{
    return {
        type: actiontypes.GLOBAL_PROJECT,
        payload: {
            GlobalProjects: Projects
        }
    }
}

export const setGlobal_Config = Config =>{
    return {
        type: actiontypes.GLOBAL_CONFIG,
        payload: {
            Global_Config:Config
        }
    }
}

export const setGlobal_Project_List = Projects =>{
    return {
        type: actiontypes.GLOBAL_PROJECTS_LIST,
        payload: {
            GlobalProjectsList: Projects
        }
    }
}

export const set_Delivery = (Delivery_data,DeliveryID,Frm_Add) =>{
    return { 
        type : actiontypes.DELIVERY , 
        payload:{
            Delivery_data,
            DeliveryID,
            Frm_Add
        }
    }
}
export const set_OB_TO_SI = (OB_data,CustomerData) =>{
    return { 
        type : actiontypes.FROM_OB_TO_SI , 
        payload:{
            OB_data,
            CustomerData
        }
    }
}
export const set_FormDirection = (formDirection)=>{
    return{
        type: actiontypes.FORM_DIRECTION,
        payload:{
            formDirection
        }
    }
}
export const set_Scheme = (mData,SchemeID,isAddNew)=>{
    return{
        type: actiontypes.SCHEME,
        payload:{
            mData,
            SchemeID,
            isAddNew
        }
    }
}
export const set_ClaimPolicyType = (ClaimID,Year,Month,SelectedProject,type,isAddNew)=>{
    return{
        type: actiontypes.CLAIM_POLICY,
        payload:{
            ClaimID,
            Year,
            Month,
            SelectedProject,
            type,
            isAddNew
        }
    }
}