import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import { CompanywiseTaxes } from '../../../Services/TaxReportAPI';
import "../SaleReports/Table.css"

const MarketTaxReport = ({Comp_Name,Comp_Id,User_Name,ConfigObj}) => {
  const { ProjectID,FromDate,ToDate,ManufacturedByID } = useParams();
  const [CompanyTaxData, setCompanyTaxData] = useState([])
  const [CompanyTotalData, setCompanyTotalData] = useState([])

  useEffect(()=>{
    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('content_Page').hidden = true;
    document.getElementById('RSidebar').hidden = true;
    fetchData()
  },[])
  const fetchData = ()=>{
    CompanywiseTaxes(Comp_Id, ProjectID, ManufacturedByID, FromDate, ToDate , (mData)=>{
        let mTotals = mData[mData.length-1];
        mData.pop()
        setCompanyTaxData(mData)
        setCompanyTotalData(mTotals)
    })
  }
  return (
    <>
        
    <div id="main_div_report" >
        {/* <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '1%' }}>
            <Col sm={12} md={12} xl={12}>
                <h2> {Comp_Name} </h2> <br /> <h3>Customer Ledger</h3>
            </Col>
        </Row> */}
        <Row  style={{ textAlign: 'center', color: "black" }}>
        <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
        <Col sm={6} md={6} xl={6}>
        <h2> {Comp_Name} </h2> <br /> <h3>Company Advance and WHT</h3>
    </Col>
        </Row>
            
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={5} md={5} xl={5} style={{ textAlign: 'left' }} >
                <Row>
                    <Col lg={3} md={3} xl={3} >
                        <b>From Date:</b>
                    </Col>
                    <Col lg={9} md={9} xl={9} >
                        {ReverseDate(FromDate)}
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={5} md={5} xl={5} style={{ textAlign: 'left' }} >
                <Row>
                    <Col lg={3} md={3} xl={3} >
                    <b>To Date:</b>
                    </Col>
                    <Col lg={9} md={9} xl={9} >
                    {ReverseDate(ToDate)}
                    </Col>
                </Row>
            </Col>
            <Col sm={1} md={1} xl={1}></Col>
        </Row>
        <div id='table_div' className="row-margin-top" >
            <table id='report_table' className='report_table' width="100%" style={{marginLeft:'2%',marginRight:'2%'}}>
                <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                        <th id='date_td' style={{ width: '5%', textAlign: 'left', fontWeight: "bold" }}   >Purchase Date</th>
                        <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} > Purchase Invoice No</th>
                        <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} >Transaction Type</th>
                        <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Manufactured By</th>
                        <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Advance Tax Amt</th>
                        <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >WHT Amt</th>
                     
                    </tr>
                </thead>
                <tbody
                    style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                    {
                        CompanyTaxData.length > 0 ?  
                      CompanyTaxData.map((customerdata, index) => (
                            <>
                                <tr key={index}>
                                    <td style={{ width: '5%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{customerdata.PurchaseDate || customerdata.PurchaseDate != '' }</td>
                                    <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.PurchaseInvoiceNo === "" ? "-" : customerdata.PurchaseInvoiceNo}</td>
                                    <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TransactionType === 'PUR' ? 'Purchase Invoice' : 'Purchase Return'}</td>
                                    <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.ManufacturedBy}</td>
                                    <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.AddvTaxAmt ? parseFloat(customerdata.AddvTaxAmt).toFixed(2)  : 0} </td>
                                    <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.WHTAmt ? parseFloat(customerdata.WHTAmt).toFixed(2)  : 0}</td>
                                      
                                    </tr>
                              
                            </>
                        )) 
                        : 
                        <>
                        <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{  textAlign: 'left', fontWeight:'bold', fontSize:'24px'}}> No Record Found</td>
                        </tr>
                        </>
                    }
                 
                </tbody>
            </table>
           <div id='table_div' className="row-margin-top" >
            <table id='report_table' className='report_table' width="100%" style={{marginLeft:'2%',marginRight:'2%'}}>
                <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                        <th id='date_td' style={{ width: '5%', textAlign: 'left', fontWeight: "bold" }}   ></th>
                        <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Totals</th>
                        <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{CompanyTotalData.AddvTaxAmt ? CompanyTotalData.AddvTaxAmt : 0}</th>
                        <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{CompanyTotalData.WHTAmt ? CompanyTotalData.WHTAmt : 0}</th>
                     
                    </tr>
                   
                </thead>
                </table>
             </div>
        </div>
        <div className='page-break-ledger'>
          
        </div>
    </div>
    <Row className="divFooter" style={{ marginTop: "3%" }} >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
    </Row>
   
</>
  )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
  Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
  User_Id: state.Login_User && state.Login_User.User_Data.User_id,
  User_Name: state.Login_User && state.Login_User.User_Data.User_name,
  ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})
export default connect(mapStateToProps)(MarketTaxReport)