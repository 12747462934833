import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { SelectSaleInvoiceDetail, SelectSaleInvoiceMaster } from '../../Services/SaleInvoiceAPI';
import { GetCurrentDate } from '../../Services/Common';
import  './POSStyle.css';

const SaleInvPOS = ({Comp_Name,Comp_Id,ProjectID,User_Name,User_Id,Comp_Phone,Comp_Address}) => {
    const [InvoiceNo, setInvoiceNo] = useState("")
    const [InvoiceDate, setInvoiceDate] = useState(GetCurrentDate())
    const [VATPer, setVATPer] = useState(0)
    const [VATAmt, setVATAmt] = useState(0)
    const [GrandTotal, setGrandTotal] = useState(0)
    const [CustomerName, setCustomerName] = useState("")
    const [PhoneNo, setPhoneNo] = useState("")
    const [SaleInvoiceDetails, setSaleInvoiceDetails] = useState([])
    const [NTN,setNTN] = useState("")
    var { SaleInvoiceID, InvoiceType } = useParams();
     if(InvoiceType === "SaleInvoice"){
             InvoiceType = "Sale Invoice"
         }else if(InvoiceType === "EstimateInvoice"){
             InvoiceType = "Estimate Invoice"
         }
  useEffect(() => {
    document.title = " Distribution / Sale Invoice";
    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('content_Page').hidden = true;
    document.getElementById('RSidebar').hidden = true;
    SelectSaleInvoiceMaster(Comp_Id, ProjectID,SaleInvoiceID,InvoiceType, (mData)=>{
      
        setInvoiceNo(mData[0].SaleInvoiceNo)
        const formattedDate = new Date(mData[0].InvoiceDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
        setInvoiceDate(formattedDate)
        setNTN(mData[0].NTN)
        setVATAmt(mData[0].TotalGST)
        setGrandTotal(mData[0].GrandTotal)
        setCustomerName(mData[0].CustomerName)
        setPhoneNo(mData[0].PhoneNo)
    })
    SelectSaleInvoiceDetail(Comp_Id, ProjectID,SaleInvoiceID,InvoiceType,(mDetailsData)=>{
        setVATPer(mDetailsData[0].TaxPer)
        setSaleInvoiceDetails(mDetailsData)
    })
  }, []);

  const styles = {
    
    receipt: {
      maxWidth: '80mm',
      margin: 40,
      padding: '10px',
      //border: '1px solid #ccc',
      color:'black',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
      textAlign: 'center',
      fontSize: '1em',
      fontWeight: 'bold',
      whiteSpace: 'pre-line',
    },
    dottedLine: {
      border: '1px dotted #ccc',
      margin: '10px 0',
      padding: 0
    },
    heading: {
      textAlign: 'center',
      fontSize: '1.5em',
      fontWeight: 'bold',
    },
    info: {
      marginTop: '10px',
      fontSize: '0.9em',
    },
    TaxSection:{
        marginTop: '10px',
        fontSize: '0.9em',
    },
    itemsTable: {
      width: '100%',
      marginTop: '15px',
      borderCollapse: 'collapse',
    },
    tableHeader: {
      borderBottom: '1px solid #000',
      background: '#f2f2f2',
    },
    tableCell: {
        border: 'none',
        padding: '8px',
        maxWidth: '100px', // Set a maximum width for ellipsis effect
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal', // Ensure the text doesn't wrap to the next line
      },
    total: {
      marginTop: '15px',
      fontSize: '1.2em',
      fontWeight: 'bold',
     display:'flex', 
     flexDirection:'row', 
     justifyContent:'space-around'
    },
    solidLine: {
      border: '1px solid #000',
      margin: '10px 0',
    },
    paymentTerms: {
      marginTop: '10px',
      fontSize: '0.9em',
      fontStyle: 'italic',
    },
  };

  return (
    <div style={styles.receipt}>
     <div style={styles.header}>
  {Comp_Id === 6 ? (
    <>
      بن سلمان للتجارة العامة {'\n'}
      {Comp_Name} {'\n'}
      United Arab Emirates {'\n'}
      الامارات العربية المتحدة {'\n'}
      {NTN}
    </> 
  ) : (
    <>
     {Comp_Name} {'\n'}
    </>
  )}
   
</div>

      <div style={styles.dottedLine}></div>
      <div style={styles.heading}>TAX INVOICE</div>
      <div style={styles.dottedLine}></div>
      <div style={styles.info}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1, fontWeight:'bold', fontSize: '18px' }}>Invoice No:</div>
            <div style={{ textAlign: 'right', fontWeight:'bold', fontSize: '16px'}}>{InvoiceNo}</div>
        </div>
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{ flex: 1, fontWeight:'bold', fontSize: '18px' }}>Invoice Date:</div>
        <div style={{ textAlign: 'right', fontWeight:'bold', fontSize: '16px'}}>{InvoiceDate}</div>
        </div>
        
      </div>
      <div style={styles.dottedLine}></div>
      <div style={styles.info}>
        <div style={{fontSize:'14px'}}>Bill To,</div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1,  fontSize: '14px' }}>Customer No:</div>
            <div style={{ textAlign: 'right',  fontSize: '14px'}}>{PhoneNo}</div>
        </div>
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{ flex: 1,  fontSize: '14px' }}>Customer:</div>
        <div style={{ textAlign: 'right',  fontSize: '14px'}}>{CustomerName}</div>
        </div>
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{ flex: 1,  fontSize: '14px' }}>TRN:</div>
        <div style={{ textAlign: 'right',  fontSize: '14px'}}>{NTN}</div>
        </div>
      </div>
      <table style={styles.itemsTable}>
        <thead style={styles.tableHeader}>
          <tr>
            <th style={styles.tableCell}>Item Name</th>
            <th style={styles.tableCell}>Qty</th>
            <th style={styles.tableCell}>Rate</th>
            <th style={styles.tableCell}>Amount</th>
          </tr>
        </thead>
        <tbody>
            {
                SaleInvoiceDetails.map((val, i)=>(
                             <tr key={i}>
                                <td style={styles.tableCell}>{val.ItemName}</td>
                                <td style={styles.tableCell}>{val.Qty}</td>
                                <td style={styles.tableCell}>{val.SaleRate}</td>
                                <td style={styles.tableCell}>{parseFloat(val.Qty * val.SaleRate).toFixed(2)}</td>
                            </tr>
                ))
            }
          
         
        </tbody>
      </table>
      <div style={styles.dottedLine}></div>
      <div style={styles.TaxSection}>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
         <div style={{ fontSize:'14px'}}>Total Tax Before VAT:</div>  
         <div  style={{fontWeight:'bold', fontSize:'14px'}}>  {GrandTotal - VATAmt}</div>
        </div>
        <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
            <div  style={{fontSize:'14px'}}>  Standard VAT({VATPer}%): </div>
            <div  style={{fontWeight:'bold', fontSize:'14px'}}>   {VATAmt}</div>
        
        </div>
      </div>
      <div style={styles.solidLine}></div>
      <div style={styles.total}>
       <div>Grand Total:</div>
       <div> AED {GrandTotal} </div>
       </div>
      <div style={styles.solidLine}></div>
      <div style={styles.paymentTerms}>
             <b>Payment Methods:</b> 
             <ul>
                <li>Cash on delivery</li>
                <li>Cheques payable to <b> Bin Sulman General Trading </b>Fze </li>
                <li>Bank Name: Mashreq Bank</li>
                <li>Account number: 019101308725</li>
                <li>IBAN Number: AE490330000019101308725</li>
                <li>SWIFT Code: BOMLAEAD</li>
                <li><b> Credit Card Payments By POS Terminal</b></li>
                <li><b>Pay By NFC & Link ( Apple Pay , G Pay )</b></li>

             </ul>
      </div>
      <div style={styles.paymentTerms}>
        <ul>
            <li>No Returns Because Of Food Products Quality Assurance.</li>
            <li>Free Delivery</li>
        </ul>
        Note:- This is System Generated invoice Doesn't Require
             Company Stamp and Signature
      </div>
    </div>
  );
};
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    ProjectID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    Comp_Phone:state.GlobalVariables &&  state.GlobalVariables.Company.PhoneNo,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
})
export default connect(mapStateToProps)(SaleInvPOS)
