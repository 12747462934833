import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "./Table.css"
import { rptCustomerLedger,rptCustomersLedger } from '../../../Services/Sale_ReportAPI'
import { SelectcustomersList } from '../../../Services/CustomersAPI'

function CustomerLedger({Comp_Name,Comp_Id,User_Name,ConfigObj}) {

    const { ProjectID,FromDate,ToDate,FinYear,CustomerID } = useParams();
    const [CustomerData, setCustomerData] = useState([])
    const [Customer, setCustomer] = useState(null)
    const [TotalBalance, setTotalBalance] = useState(0)
    const [TotalPaid, setTotalPaid] = useState(0)
    const [GrandTotal, setGrandTotal] = useState(0)
    const [CustomerLedger, setCustomerLedger] = useState([])
    const [sumDebit, setSumDebit] = useState(0)
    const [sumDebitOP, setSumDebitOP] = useState(0)
    const [sumCredit, setSumCredit] = useState(0)
    const [sumCreditOP, setSumCreditOP] = useState(0)
    const [sumBalance, setSumBalance] = useState(0)
    const [sumBalanceOP, setSumBalanceOP] = useState(0)
    useEffect(() => {
        document.title = "Distribution / Customer Ledger" 
       
        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
        rptCustomersLedger(Comp_Id,ProjectID,FromDate,ToDate,CustomerID,(mCusData)=>{
            var mArray = mCusData.splice(1) // mCusData -> openingData, mArray -> except Opening
            var PBalnce = mArray.length ? mArray[0].debit - mArray[0].credit : 0
            var sumofDebitWithOP = 0
            var sumofDebitWithoutOP = 0
            var sumofCreditWithOP = 0
            var sumofCreditWithoutOP = 0
            var sumofBalanceWithOP = 0
            var sumofBalanceWithoutOP = 0
            for(let i =0; i<mArray.length; i++){
                if(i === 0){
                    mArray[i].balance=  (mArray[i].balance + mArray[i].debit) - mArray[i].credit
                }else{
                    mArray[i].balance= (PBalnce+ mArray[i].debit) - mArray[i].credit 
                }
                PBalnce = mArray[i].balance
                sumofDebitWithoutOP+=   mArray[i].debit    
                sumofCreditWithoutOP += mArray[i].credit
                sumofBalanceWithoutOP += mArray[i].balance
            }
            setSumDebit(sumofDebitWithoutOP)
            setSumCredit(sumofCreditWithoutOP)
            setSumBalance(sumofBalanceWithoutOP)
             mArray.unshift(mCusData[0])
           for(let i=0; i<mArray.length; i++){
            sumofDebitWithOP +=  mArray[i].debit ? mArray[i].debit : 0  
            sumofCreditWithOP += mArray[i].credit ? mArray[i].credit : 0
            sumofBalanceWithOP += mArray[i].balance ? mArray[i].balance : 0
           } 
           setSumDebitOP(sumofDebitWithOP)
           setSumCreditOP(sumofCreditWithOP)
           setSumBalanceOP(sumofBalanceWithOP)
           setCustomerLedger(mArray)  
        })
       
        SelectcustomersList(Comp_Id,ProjectID,(mCuctomerData)=>{
            mCuctomerData.map(value=>{
                if(value.CustomerID == CustomerID){
                    setCustomer(value)
                }
            })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            {/* <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black", marginTop: '1%' }}>
                <Col sm={12} md={12} xl={12}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Customer Ledger</h3>
                </Col>
            </Row> */}
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
            <h2> {Comp_Name} </h2> <br /> <h3>Customer Ledger</h3>
        </Col>
            </Row>
                
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={5} md={5} xl={5} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={8} md={8} xl={8} >
                        <b>Financial Year:</b>
                        </Col>
                        <Col lg={4} md={4} xl={4}  style={{ textAlign: 'left' }}>
                        {FinYear}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={5} md={5} xl={5} style={{ textAlign: 'left' }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                        <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }} >
                    <Row>
                        <Col lg={8} md={8} xl={8} >
                        <b>Customer:</b>
                        </Col>
                        <Col lg={4} md={4} xl={4} style={{textAlign:'left'}}>
                        {Customer && Customer.CustomerName}
                        </Col>
                    </Row>
                </Col>
                <Col sm={1} md={1} xl={1}></Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%" style={{marginLeft:'2%',marginRight:'2%'}}>
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Transaction No.</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Discription</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Debit</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Credit</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Balance</th>
                         
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                       
                        {
                            CustomerLedger.map((customerdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{customerdata.InvoiceDate || customerdata.InvoiceDate != '' }</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.SaleInvoiceNo === "" ? "-" : customerdata.SaleInvoiceNo}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.Discription}</td>
                                       
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.debit ? parseFloat(customerdata.debit).toFixed(2) : 0}</td>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.credit ? parseFloat(customerdata.credit).toFixed(2)  : 0} </td>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{parseFloat(customerdata.balance).toFixed(2) > 0 ? customerdata.balance? parseFloat(customerdata.balance).toFixed(2) + " DR" : 0 : customerdata.balance? parseFloat(customerdata.balance).toFixed(2) + " CR" : 0}</td>
                                    </tr>
                                  
                                </>
                            ))

                        }
                     
                    </tbody>


                </table>
                <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%" style={{marginLeft:'2%',marginRight:'2%'}}>
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   ></th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} ></th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Totals Without Opening</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{parseFloat(sumDebit).toFixed(2)}</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{parseFloat(sumCredit).toFixed(2)}</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{parseFloat(sumDebit - sumCredit).toFixed(2)}</th>
                         
                        </tr>
                        <tr style={{ border: "none" }}>
                        <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   ></th>
                        <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} ></th>
                        <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Totals With Opening</th>
                        <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{(parseFloat(sumDebitOP).toFixed(2))}</th>
                        <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{(parseFloat(sumCreditOP).toFixed(2))}</th>
                        <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >{(parseFloat(sumDebitOP - sumCreditOP).toFixed(2))}</th>
                     
                    </tr>
                    </thead>
                    </table>
                 </div> 
            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})
export default connect(mapStateToProps)(CustomerLedger)
