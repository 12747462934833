import React, { useEffect, useState } from 'react';
import './rptEssentialMedical.css';
import { rptEssentialProductsSale } from '../../../Services/Distribution_ReportAPI';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'

const RptEssentialMedical = ({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj,UserType}) => {

  const { ProjectID,FromDate, ToDate } = useParams();
  const [reportData, setReportData] = useState([])
  useEffect(() => {
    document.title = "Essential Items Sales Report";

    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('content_Page').hidden = true;
    document.getElementById('RSidebar').hidden = true;

    rptEssentialProductsSale(Comp_Id, ProjectID, FromDate, ToDate, (mData)=>{
      setReportData(mData)
    })
  }, [])

  return (
    <div className="report-container">
      <h1>Essential Medical  Report</h1>
      {reportData.map((invoice, index) => (
        <div key={index} >
          <div className="invoice-header">
              <strong>Invoice No:{invoice.invoiceNo}</strong> 
              <strong>Date: {invoice.date}</strong>
              <strong>Lis No: {invoice.LisNo}</strong>
              <strong>Customer:{invoice.customer}</strong>
          </div>
          <table className="invoice-table">
            {/* <thead>
              <tr>
                <th>Item Description</th>
                <th>Batch No</th>
                <th>Expiry Date</th>
                <th>Rate</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead> */}
            <tbody>
              {invoice.items.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.description}</td>
                  <td>{item.batch}</td>
                  <td>{item.expiry}</td>
                  <td>{item.rate.toFixed(2)}</td>
                  <td>{item.qty}</td>
                  <td>{item.total.toFixed(2)}</td>
                </tr>
              ))}
              <tr className="invoice-total">
                <td colSpan="4"></td>
                <td><strong>Total Invoice</strong></td>
                <td>{invoice.total.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
      <div>

      </div>
    </div>
  );
};

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
  Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
  User_Id: state.Login_User && state.Login_User.User_Data.User_id,
  User_Name: state.Login_User && state.Login_User.User_Data.User_name,
  ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
  UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})

export default connect(mapStateToProps)(RptEssentialMedical)