import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptStockBalance } from '../../../Services/Inventory_ReportAPI'

function StockBalanceReport({Comp_Name,Comp_Id,User_Name,ConfigObj}) {

    const { ProjectID } = useParams();
    const [StockData, setStockData] = useState([])
    const [TotalPurchase, setTotalPurchase] = useState(0)
    const [ExpectedProfit, setExpectedProfit] = useState(0)
    const [TotalSaleExpected, setTotalSaleExpected] = useState(0)
    const [TotalQty, setTotalQty] = useState(0)
    useEffect(() => {
        document.title = "Distribution / Stock Ledger" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptStockBalance(Comp_Id,ProjectID,(mData)=>{
            if(mData.length > 0){
            var Totals = mData.splice(-1);
            setTotalPurchase(Totals[0].PurchaseRate.split('~')[1])
            setExpectedProfit(Totals[0].Remarks.split('~')[1])
            setTotalSaleExpected(Totals[0].SaleRate.split('~')[1])
            setTotalQty(Totals[0].StockQty)
                
            setStockData(mData)
            }
        
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Stock Ledger</h3>
            </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >ItemCode</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >ItemDiscription</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Purchase Rate</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Sale Rate</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Remarks</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >StockQty</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            StockData.map((Stockdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Stockdata.ItemCode}</td>
                                         <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Stockdata.ItemDiscription}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Stockdata.PurchaseRate && parseFloat(Stockdata.PurchaseRate).toFixed(2)}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Stockdata.SaleRate && parseFloat(Stockdata.SaleRate).toFixed(2)} </td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Stockdata.Remarks}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Stockdata.StockQty}</td>
                                    </tr>
                                  
                                </>
                            ))
                        }
                        <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total Qty:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  >{parseFloat(TotalQty).toFixed(2)}</td>
                        </tr>
                        <tr >
                            <td id='' colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Total Purchase:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {parseFloat(TotalPurchase).toFixed(2)}</td>
                        </tr>
                        <tr >
                            <td id='' colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Expected Profit:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {parseFloat(ExpectedProfit).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td id='' colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total Expected Sale:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {parseFloat(TotalSaleExpected).toFixed(2)}</td>
                        </tr> 
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})
export default connect (mapStateToProps)(StockBalanceReport)
