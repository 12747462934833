import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import { GetCurrentDate, ReverseDate, GetCurrentTimeAM_PM } from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptSaleAndStock } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { SelectManufacturedByList } from '../../../Services/manufactureAPI'
import { GetUserCG, GetUserPG } from '../../../Services/ClaimAPI';

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}
function SaleAndStockReport({ Comp_Name, Comp_Id, User_Name, User_Id, ConfigObj, UserType }) {

    const { ProjectID, ManufacturedByID, PGID, FromDate, ToDate, SaleRate } = useParams();
    const [ItemData, setItemData] = useState([]);
    const [TotalValue, setTotalValue] = useState(0);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [Manufacturer, setManufacturer] = useState('')
    const [NetSaleValue, setNetSaleValue] = useState(0.00)
    const [NetpurValue, setNetpurValue] = useState(0.00)
    const [NetClosingValue, setNetClosingValue] = useState(0.00)
    const [GrandTotalSale, setGrandTotalSale] = useState(0.00)
    const [GrandTotalpur, setGrandTotalpur] = useState(0.00)
    const [CGName, setCGName] = useState('')
    useEffect(() => {
        document.title = "Distribution / Sale And Stock Report"

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptSaleAndStock(Comp_Id, ProjectID, ManufacturedByID, PGID, FromDate, ToDate, SaleRate, async(mData) => {
            if(UserType === 'Manger Portal'){
                const res = await GetUserPG(User_Id)
                const SelectedUserPG = res.data
                mData = mData.filter(item => SelectedUserPG.some(pg => pg.PGID === item.PGID));
            }
            var _NetSaleValue = 0, _NetSaleRetValue = 0, _NetpurValue = 0, _NetpurRetValue = 0, _NetClosingValue = 0, _GrandTotalSale = 0, GrandTotalpur = 0;
            let _Sub_ttl_NetSalevalue = 0, _Sub_ttl_ClosingValue = 0,_Sub_ttl_PurchaseValue = 0, mArray = []
            mData.map((value, index) => {
                _NetSaleValue += value.NETSALEVALUE
                _NetClosingValue += value.CLOSINGVALUE
                _NetpurValue += value.NetPurchaseValue
                if (mData[index - 1]) {
                    if (value.PGID === mData[index - 1].PGID) {
                        mArray.push(value)
                    }
                    else {
                        mArray.push({ PGName: mData[index - 1].PGName, "SubTotal": "SubTotal", _Sub_ttl_NetSalevalue, _Sub_ttl_ClosingValue ,_Sub_ttl_PurchaseValue})
                        mArray.push(value)
                        _Sub_ttl_NetSalevalue = 0
                        _Sub_ttl_ClosingValue = 0
                        _Sub_ttl_PurchaseValue = 0
                    }
                    _Sub_ttl_NetSalevalue += value.NETSALEVALUE
                    _Sub_ttl_ClosingValue += value.CLOSINGVALUE
                    _Sub_ttl_PurchaseValue += value.NetPurchaseValue
                }
                else {
                    _Sub_ttl_NetSalevalue += value.NETSALEVALUE
                    _Sub_ttl_ClosingValue += value.CLOSINGVALUE
                    _Sub_ttl_PurchaseValue += value.NetPurchaseValue
                    mArray.push(value)
                }
                if (mData.length === index + 1) {
                    mArray.push({ PGName: mData[index - 1].PGName, "SubTotal": "SubTotal", _Sub_ttl_NetSalevalue, _Sub_ttl_ClosingValue ,_Sub_ttl_PurchaseValue})
                    _Sub_ttl_NetSalevalue = 0
                    _Sub_ttl_ClosingValue = 0
                    _Sub_ttl_PurchaseValue = 0
                }
            })
            setItemData(mArray)
            setNetSaleValue(_NetSaleValue.toFixed(2))
            setNetpurValue(_NetpurValue.toFixed(2))
            setNetClosingValue(_NetClosingValue.toFixed(2))
            // setGrandTotalSale((_NetSaleValue - _NetSaleRetValue).toFixed(2))
            // setGrandTotalpur((_NetpurValue - _NetpurRetValue).toFixed(2))


        })


        SelectUserProjects(Comp_Id, User_Id, (mProjects) => {
            mProjects.map(value => {
                if (value.Project_ID == ProjectID) {
                    setSelectedProject(value)
                }
            })
        })
        if (ManufacturedByID == "0") {
            setManufacturer('ALL COMPANIES')
        }
        else {
            SelectManufacturedByList(Comp_Id, ProjectID, (data) => {
                data.map(mvalue => {
                    if (mvalue.ManufacturedByID == ManufacturedByID) {
                        setManufacturer(mvalue.ManufacturedBy)
                    }
                })
            })

        }
      
        var _SaleAndStockReport = JSON.parse(localStorage.getItem("SaleAndStockReport"))
        if (_SaleAndStockReport) {
            setCGName(_SaleAndStockReport.CompanyGroup)
        }
        localStorage.removeItem("token");
    }, [])
    return (
        <>

            <div id="main_div_report" style={{color:'black', margin: "16px"}}>
                <Row style={{ textAlign: 'center', color: "black" }}>
                    <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
                    <Col sm={6} md={6} xl={6}>
                        <h2> {Comp_Name} </h2> <br /> <h3>Sale And Stock Statement</h3>
                    </Col>
                </Row>
                <Row sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', borderLeft: '2px solid black', height: '20px', left: '2%', }} >
                        <Row>
                            <Col lg={4} md={4} xl={4} >
                                <b>Branch:</b>
                            </Col>
                            <Col lg={8} md={8} xl={8} >
                                {SelectedProject && SelectedProject.ProjectName}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', borderLeft: '2px solid black', height: '20px', left: '2%', }} >
                        <Row>
                            <Col lg={3} md={3} xl={3} >
                                <b>Company:</b>
                            </Col>
                            <Col lg={9} md={9} xl={9} >
                                {Manufacturer}
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', borderLeft: '2px solid black', height: '20px', left: '2%', }} >
                        <Row>
                            <Col lg={4} md={4} xl={4} >
                                <b>From Date:</b>
                            </Col>
                            <Col lg={8} md={8} xl={8} >
                                {FromDate}
                            </Col>
                        </Row>
                    </Col>  <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', borderLeft: '2px solid black', height: '20px', left: '2%', }} >
                        <Row>
                            <Col lg={3} md={3} xl={3} >
                                <b>To Date:</b>
                            </Col>
                            <Col lg={9} md={9} xl={9} >
                                {ToDate}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div id='table_div' className="row-margin-top" style={{color:'black'}}>
                    <table id='report_table' className='report_table' width="100%">
                        <thead id="thead" style={{ color: "black" }}>
                            <tr style={{  border:'1px solid  black', borderStyle:'solid' }}>
                                <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >DESCRIPTIONS</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >OPENING</th>
                                <th colSpan={2} style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >PURCHASE</th>
                                <th colSpan={2} style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >P-RETURN</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >SALES</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >S-RET</th>
                                <th colSpan={3} style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >NET SALES</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >TODAYSALE</th>
                                <th colSpan={2}style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >OTHER</th>
                                <th colSpan={2} style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >CLOSING STOCK</th>
                            </tr>
                            <tr style={{color:'black',border:'1px solid  black', borderStyle:'solid' }}>
                                <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}>Product Name</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >BNS</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >BNS</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY+BNS</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY+BNS</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >BNS</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >VALUE</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >IN</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >OUT</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >QTY</th>
                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >VALUE</th>
                            </tr>
                        </thead>

                        <tbody
                            style={{ border: "#FFFFFF", borderCollapse: "collapse",color:'black' }}>

                            {
                                ItemData.map((Itemdata, index) => {
                                  
                                    if (Itemdata.SubTotal === "SubTotal") {
                                        return (
                                            <tr key={index} style={{ borderTop: '2px Solid Black', borderLeft: 'none', borderRight: 'none', borderBottom: '2px Solid Black' }}>
                                                <td id='footer_td' colSpan="3" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Net Pur Value :</td>
                                                <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >{parseFloat(Itemdata._Sub_ttl_PurchaseValue).toFixed(2)}</td>
                                                <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Net Sale Value :</td>
                                                <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >{parseFloat(Itemdata._Sub_ttl_NetSalevalue).toFixed(2)}</td>
                                                <td id='footer_td' colSpan="3" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Closing Stock Value:</td>
                                                <td id='footer_td' colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >{parseFloat(Itemdata._Sub_ttl_ClosingValue).toFixed(2)}</td>

                                            </tr>
                                        )
                                    }
                                    else {
                                        if (ItemData[index - 1]) {
                                            if(Itemdata.PGName === ItemData[index - 1].PGName){
                                                return (
                                                        <tr key={index} style={{ borderBottom: '1px solid black' }}>
                                                            <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.ItemDiscription}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OpeningQTy}</td>
    
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Qty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BonusQty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PRQty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PRBonusQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.INVQty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.SRQty } </td>
    
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETSALEQTY } </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETBONUSSALEQTY} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETSALEVALUE} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TSQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OtherInQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OtherOutQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ClosingQTy} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CLOSINGVALUE} </td>
                                                        </tr>
                                                )
                                            }
                                            else{
                                                return (
                                                    <>
                                                        <tr style={{ borderBottom: '1px solid black' }} >
                                                            <td colSpan={16}><h4><b>Group Name : {Itemdata.PGName}</b></h4></td>
                                                        </tr>
                                                        <tr key={index}  style={{ borderBottom: '1px solid black' }} >
                                                            <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.ItemDiscription}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OpeningQTy}</td>
    
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Qty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BonusQty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PRQty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PRBonusQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.INVQty}</td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.SRQty } </td>
    
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETSALEQTY } </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETBONUSSALEQTY} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETSALEVALUE} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TSQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OtherInQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OtherOutQty} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ClosingQTy} </td>
                                                            <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CLOSINGVALUE} </td>
                                                        </tr>
                                                    </>
                                                )
                                             
                                            }
                                        }
                                        else {
                                            return (
                                                <>
                                                    <tr style={{ borderBottom: '1px solid black' }} >
                                                    <td colSpan={16}><h4><b>Group Name : {Itemdata.PGName}</b></h4></td>
                                                    </tr>
                                                    <tr key={index}  style={{ borderBottom: '1px solid black' }} >
                                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.ItemDiscription}</td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OpeningQTy}</td>

                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Qty}</td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BonusQty}</td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PRQty}</td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PRBonusQty} </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.INVQty}</td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.SRQty } </td>

                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETSALEQTY } </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETBONUSSALEQTY} </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.NETSALEVALUE} </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TSQty} </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OtherInQty} </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OtherOutQty} </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ClosingQTy} </td>
                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CLOSINGVALUE} </td>
                                                    </tr>
                                                </>
                                            )
                                         
                                        }
                                    }
                                }
                                )
                            }
                           
                            <tr id='footer_balance' style={{ borderTop: '2px Solid Black', borderLeft: 'none', borderRight: 'none', borderBottom: '2px Solid Black' }}>
                            <td id='footer_td' colSpan="3" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Grand Total Pur Value :</td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >{NetpurValue}</td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Grand Total  Sale Value :</td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >{NetSaleValue}</td>
                            <td id='footer_td' colSpan="3" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Grand Closing Stock Value:</td>
                            <td id='footer_td' colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >{NetClosingValue}</td>

                        </tr>
                      

                        </tbody>


                    </table>

                </div>
                <div className='page-break-ledger'>

                </div>
            </div>
            <Row className="divFooter" style={{ marginTop: "3%" }} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
            </Row>

        </>
    )
}
const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name: state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address: state.GlobalVariables && state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj: state.Global_Configs && state.Global_Configs.GlobalConfig,
    UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})

export default connect(mapStateToProps)(SaleAndStockReport)
