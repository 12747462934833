import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptSaleRegister } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { GetUserPG } from '../../../Services/ClaimAPI';
import img from '../../Images/AlQuraishLogo.jpeg';

function SaleReturnRegisterReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj,UserType}) {

    const { ProjectID,SaleInvNoFrom,SaleInvNoTo,TransactionType} = useParams();
    const [ItemData, setItemData] = useState([]);
    const [MasterData, setMasterData] = useState([])
    const [TotalValue, setTotalValue] = useState(0);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [TotalWithTax, setTotalWithTax] = useState(0)
    useEffect(() => {
            document.title = `Distribution / Sale Return Register Report`

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptSaleRegister(Comp_Id,ProjectID,SaleInvNoFrom,SaleInvNoTo,TransactionType,async(mData)=>{
            
            if(UserType === 'Manger Portal'){
                const res = await GetUserPG(User_Id)
                const SelectedUserPG = res.data
                mData = mData.filter(item => SelectedUserPG.some(pg => pg.PGID === item.PGID));
            }
            var unique_Array = []
            mData.map(value=>{
                var _IsFind = unique_Array.find(x => x.SaleInvoiceID == value.SaleInvoiceID)
                if (!_IsFind) {
                    unique_Array.push(value)
                }
            })

            var sumAmt = 0;
            var sumGrand = 0;
                unique_Array.map(value=>{
                    sumAmt += value.InvoiceAmount
                    sumGrand += value.GrandTotal
                })
                setTotalValue(sumAmt)
                setTotalWithTax(sumGrand)
                setItemData(mData)
                setMasterData(unique_Array)
        
        })
     
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
            mProjects.map(value=>{
                if(value.Project_ID == ProjectID){
                    setSelectedProject(value)
                }
            })
        })
      
        localStorage.removeItem("token");
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={img} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Sale Return Register</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Branch:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SelectedProject && SelectedProject.ProjectName}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                    
                        <Col lg={3} md={3} xl={3} >
                         <b>Date & Time:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}</b>

                        </Col>
                    </Row>
                   
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>From Sale Ret No:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SaleInvNoFrom}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>To  Sale Ret No:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SaleInvNoTo}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Product Code</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Product Name</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Packing</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Batch</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Batch Expiary</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th> 
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Bns</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Price</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Dis1 %</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Dis2%</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Gst%</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Value</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                                MasterData.map((MainData, MainIndex) => {
                                    MainData.SumDis1 = 0
                                    MainData.SumDis2 = 0
                                    MainData.SumTax  =0
                                    MainData.SumBonusQty = 0
                                   return(
                                       <>
                                           <tr key={MainIndex} style={{ border: '2px Solid Black', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontWeight: 'bold' }}>
                                               <td colSpan={12} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >
                                                  
                                                   <Row>
                                                   <Col lg={2} md={2}></Col>
                                                       <Col lg={2} md={2}>
                                                          SaleRet#: {MainData.SaleInvoiceNo}
                                                       </Col>
                                                       <Col lg={2} md={2}></Col>
                                                       <Col lg={2} md={2}>
                                                          Date: {MainData.InvoiceDate}
                                                       </Col>
                                                       <Col lg={4} md={4}>
                                                           City : {MainData.City}
                                                       </Col>
                                                       <Col lg={6} md={6}>
                                                          Customer:  {MainData.CustomerName}
                                                       </Col>
                                                       <Col lg={2} md={2}>
                                                          Sale Man:  {MainData.SalesMan}
                                                       </Col>
                                                   </Row>
                                               </td>
                                           </tr>
                                           {
                                               ItemData.map((Itemdata, index) => {
                                               
                                                if(MainData.SaleInvoiceID === Itemdata.SaleInvoiceID){
                                                    MainData.SumDis1 += Itemdata.DisPer
                                                    MainData.SumDis2 += Itemdata.DisPer1
                                                    MainData.SumTax += Itemdata.TaxPer
                                                    MainData.SumBonusQty += Itemdata.BonusQty
                                                    return (
        
                                                        <>
                                                            {
            
                                                                Itemdata.CityID === "Totals" ?
                                                                    <tr key={index} style={{ border: '2px Solid Black', borderLeft: 'none', borderRight: 'none', fontWeight: 'bold' }}>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Total :</td>
            
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PreBalance}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CurrentSale}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TotalBalance}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Recoveries} </td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Recovery}</td>
                                                                    </tr>
                                                                    :
                                                                    <tr key={index} >
                                                                    <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemCode}</td>
                                                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemDiscription}</td>
                                                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Remarks}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BatchNo}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ExpairyDate}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Qty} </td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BonusQty}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleRate}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.DisPer}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.DisPer1}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TaxPer}</td>
                                                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Value}</td>
                                                                    </tr>
                                                            }
                                                        </>
            
                                                    )
                                                }
                                            })

                                           }
                                            <tr key={MainIndex} style={{ border: '2px Solid Black', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', fontWeight: 'bold' }}>
                                               <td   style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                                <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Net Value + Tax :</td>
                                                <td colSpan={2}  style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{MainData.GrandTotal}</td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Totals : </td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{MainData.TotalQuantity} </td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{MainData.SumBonusQty}</td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  ></td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{MainData.SumDis1}</td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{MainData.SumDis2}</td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{MainData.SumTax}</td>
                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{MainData.InvoiceAmount}</td>
                                                                    
                                                  
                                           </tr>
                                       </>
                                   )
                                    
                                        
                                    
                                   
                                    
                                    })  
                               
                        }
                       
                       <tr id='footer_balance'>
                       <td id='footer_td' colSpan="2" style={{ textAlign: 'center', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  >Total Net Value + Tax:</td>
                       <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > {(TotalWithTax).toFixed(2)}</td>
                        <td id='footer_td' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                        <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total :</td>
                        <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{(TotalValue).toFixed(2)}</td>
                        </tr>
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
    UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})

export default connect(mapStateToProps)(SaleReturnRegisterReport)
