import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Autocomplete } from '@material-ui/lab'
import MUIDataTable from "mui-datatables";
import { Icon } from '@iconify/react';
import CanvasJSReact from '../../assets/canvasjs.react'

import { TodaysSale,WeekSale,MonthSale,YearSale,TodaysExpense, ExpiredItems,BeforeExpiryItem, TodaysSaleChart, WeakSaleChart, MonthSaleChart, YearSaleChart} from '../../Services/DashboardAPI'
import { rptPayables } from '../../Services/Purchase_ReportAPI'
import { rptReciveables ,rptReciveablesssss } from '../../Services/Sale_ReportAPI';
import { SelectUserProjects } from '../../Services/Projects'
import { GetCurrentDate , numFormatter} from '../../Services/Common'
import { rptMostSoldItems,rptMinimumSoldItems } from '../../Services/Sale_ReportAPI'
import { rptPL } from '../../Services/PL_ReportsAPI';
import { SelectItemsWRTMinStock,SelectItemsWRTMaxStock } from '../../Services/PurchaseOrderAPI';
import { rptCashInBank, rptCashInHand } from '../../Services/FinanceReport_API';


var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function MainContents({Comp_Id,ProjectID,User_Id}) {
  
  const [_TodaysSale, set_TodaysSale] = useState(0);
  const [thisWeekSale, setThisWeekSale] = useState(0);
  const [thisMonthSale, setThisMonthSale] = useState(0);
  const [thisYearSale, setThisYearSale] = useState(0);
  const [Project_List, setProject_List] = useState([])
  var [SelectedProject, setSelectedProject] = useState([])
  const [FromDate, setFromDate] = useState(GetCurrentDate())
  const [ToDate, setToDate] = useState(GetCurrentDate())
const [_Payables, set_Payables] = useState(0);
const [_Receivables, set_Receivables] = useState(0);
const [MostSoldItemData, setMostSoldItemData] = useState([]);
const [MinimunSoldItemData, setMinimunSoldItemData] = useState([]);
const [TotalMinQty, setTotalMinQty] = useState(0);
const [TotalMaxQty, setTotalMaxQty] = useState(0);
const [PL_Narration, setPL_Narration] = useState('Profit');
const [PL_Value, setPL_Value] = useState(0);
const [Min_Stock_Data, setMin_Stock_Data] = useState([])
const [Max_Stock_Data, setMax_Stock_Data] = useState([]);
const [Total_Cash_In_Hand, setTotal_Cash_In_Hand] = useState(0);
const [Total_Cash_In_Banks, setTotal_Cash_In_Banks] = useState(0);
const [Total_Todays_Expense, setTotal_Todays_Expense] = useState(0);
const [ExpiredItemsData, setExpiredItemsData] = useState([]);
const [BeforeExpiredItemsData, setBeforeExpiredItemsData] = useState([]);
const [TodayDataPoints, setTodayDataPoints] = useState([]);
const [WeakDataPoints, setWeakDataPoints] = useState([]);
const [MonthDataPoints, setMonthDataPoints] = useState([]);
const [YearDataPoints, setYearDataPoints] = useState([]);



  useEffect(() => {
    document.getElementById('topBar').hidden = false;
    document.getElementById('leftside').hidden = false;
    document.getElementById('content_Page').hidden = false;
    document.getElementById('RSidebar').hidden = false;

    TodaysSale(Comp_Id,ProjectID,(mTodaySale)=>{
      var _todaySale = numFormatter(mTodaySale[0].todaysale)
      set_TodaysSale(_todaySale)
    })
    TodaysSaleChart(Comp_Id,ProjectID, (mData)=>{
      setTodayDataPoints(mData)
    })
    WeakSaleChart(Comp_Id,ProjectID, (mData)=>{
      setWeakDataPoints(mData)
    })
    MonthSaleChart(Comp_Id,ProjectID, (mData)=>{
      
      setMonthDataPoints(mData)
    })
    YearSaleChart(Comp_Id,ProjectID, (mData)=>{
      setYearDataPoints(mData)
    })
    
    WeekSale(Comp_Id,ProjectID,(mWeekSale)=>{
      var _weekSale = numFormatter(mWeekSale[0].thisweaksale)

      setThisWeekSale(_weekSale)
    })
    MonthSale(Comp_Id,ProjectID,(mMonthSale)=>{
      var _monthSale = numFormatter(mMonthSale[0].thismonthsale)

      setThisMonthSale(_monthSale)
    })
    YearSale(Comp_Id,ProjectID,(mYearSale)=>{
      var _yearSale = numFormatter(mYearSale[0].thisyearsale)

      setThisYearSale(_yearSale)
    })
    SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
     setProject_List(mProjects)
     setSelectedProject(mProjects[0])
     Payables(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
     Receivables(mProjects[0].Project_ID,GetCurrentDate())
     MostSoldItems(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
     MinimunSoldItems(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
     ProfitAndLoss(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
     MinimumStock(mProjects[0].Project_ID)
     MaxStock(mProjects[0].Project_ID)
     Cash_In_Hand(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
     Cash_In_Banks(mProjects[0].Project_ID,GetCurrentDate(),GetCurrentDate())
     Todays_Expense(mProjects[0].Project_ID)
     Expired_Items(mProjects[0].Project_ID)
     Items_to_be_Expired(mProjects[0].Project_ID)
    //  Receivablesss(mProjects[0].Project_ID)
     })

    
  }, [])
    
  const Payables = (Project_ID,FromDate,ToDate)=>{
    rptPayables(Comp_Id,Project_ID,FromDate,ToDate,(mPayables)=>{
      if(mPayables.length > 0){
        var Totals = mPayables.splice(-1);
        var _Payables = numFormatter(parseFloat(Totals[0].Balance.split('~')[1]))
          set_Payables(_Payables)
      }
    })
  }
  const Receivables = (Project_ID,FromDate) =>{
    
    rptReciveables(Comp_Id,Project_ID,FromDate,(mReceivables)=>{
      
      if(mReceivables.length > 0){
        var mCompanyTotals = mReceivables.splice(-1);
        //var _receivables =  numFormatter(parseFloat(mCompanyTotals[0].Balance))
        const CompanyTotal_Debit = parseFloat(mCompanyTotals[0].DEBIT).toFixed(2)
        const CompanyTotal_Credit = parseFloat(mCompanyTotals[0].CREDIT).toFixed(2)
        const CompanyTotal_Balance = parseFloat(mCompanyTotals[0].BALANCE).toFixed(2)

      var mCustomerTotal =   mReceivables.find(x=> x.CUSTOMERLEDGERSSUMMARY === 'TOTAL CUSTOMERS LEDGER SUMMARY')

      const CustomerTotal_Debit =  parseFloat(mCustomerTotal.DEBIT).toFixed(2)
      const CustomerTotal_Credit =  parseFloat(mCustomerTotal.CREDIT).toFixed(2)
      const CustomerTotal_Balance =  parseFloat(mCustomerTotal.BALANCE).toFixed(2)

      const mGrandDebitTotal = parseFloat(CompanyTotal_Debit) + parseFloat(CustomerTotal_Debit)
      const mGrandCreditTotal =  parseFloat(CompanyTotal_Credit) + parseFloat(CustomerTotal_Credit)
      const mGrandBalanceTotal =  numFormatter(parseFloat(CompanyTotal_Balance) + parseFloat(CustomerTotal_Balance))
      
        set_Receivables(mGrandBalanceTotal)
      }
    })
  }
  // const Receivablesss = (Project_ID) =>{
  //   rptReciveablesssss(Comp_Id,Project_ID,(mReceivables)=>{
     
  //     if(mReceivables.length > 0){
  //       var Totals = mReceivables.splice(-1);
  //       var _receivables =  numFormatter(parseFloat(Totals[0].Balance))
  //       set_Receivables(_receivables)
  //     }
  //   })
  // }
  const MostSoldItems = (ProjectID,FromDate,ToDate)=>{
    rptMostSoldItems(Comp_Id,ProjectID,FromDate,ToDate,5,(mData)=>{
     if(mData.length > 0){
      var Totals = mData.splice(-1);
      setTotalMaxQty(Totals[0].Qty.split('-')[1])
      setMostSoldItemData(mData)
     }
  })
  }
  const MinimunSoldItems = (ProjectID,FromDate,ToDate)=>{
    rptMinimumSoldItems(Comp_Id,ProjectID,FromDate,ToDate,5,(mData)=>{
     if(mData.length > 0){
      var Totals = mData.splice(-1);
      setTotalMinQty(Totals[0].Qty.split('-')[1])
      setMinimunSoldItemData(mData)
     }
  })
  }
  const ProfitAndLoss  = (ProjectID,FromDate,ToDate)=>{
    rptPL(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
      if(mData[0].length > 0)
      {
        setPL_Narration(mData[0][8].Naration)
        setPL_Value(mData[0][8].Total)
      }
   })
  }

  const MinimumStock = (ProjectID)=>{
    SelectItemsWRTMinStock(Comp_Id,ProjectID,0,(mStockData)=>{
      setMin_Stock_Data(mStockData)
  })
  }
  const MaxStock = (ProjectID)=>{
    SelectItemsWRTMaxStock(Comp_Id,ProjectID,(mStockData)=>{
      setMax_Stock_Data(mStockData)
  })
  }
  const Cash_In_Hand = (ProjectID,FromDate,ToDate) =>{
    rptCashInHand(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
      if(mData.length > 0){
          var Totals = mData.splice(-1)
          if(Totals[0].PaidAmt != null)
          {
            var _cashinHand = numFormatter(Totals[0].PaidAmt)
            setTotal_Cash_In_Hand(_cashinHand)
          }
      }
   
   })
  }
  const Cash_In_Banks = (ProjectID,FromDate,ToDate) =>{
    rptCashInBank(Comp_Id,ProjectID,FromDate,ToDate,0,(mData)=>{
      if(mData.length > 0){
          var Totals = mData.splice(-1)
          if(Totals[0].PaidAmt != null){
              var cashInBank = numFormatter(Totals[0].PaidAmt)
            setTotal_Cash_In_Banks(cashInBank)
          }
      }
   
   })
  }

  const Todays_Expense = (ProjectID)=>{
    TodaysExpense(Comp_Id,ProjectID,(mData)=>{
      if(mData.length > 0){
        var _expense = numFormatter(mData[0].TodayExpences)
        setTotal_Todays_Expense(_expense)
      }
    
    })
  }

  const Expired_Items = (ProjectID) =>{
    ExpiredItems(Comp_Id,ProjectID,(mData)=>{
      let uniqueArray = mData.filter((item, index) => {
        return index === mData.findIndex(obj => {
          return obj.ItemID === item.ItemID;
        });
      });
      setExpiredItemsData(uniqueArray)
    })
  }
  const Items_to_be_Expired = (ProjectID) =>{
    BeforeExpiryItem(Comp_Id,ProjectID,(mData)=>{
      let uniqueArray = mData.filter((item, index) => {
        return index === mData.findIndex(obj => {
          return obj.ItemID === item.ItemID;
        });
      });
      setBeforeExpiredItemsData(uniqueArray)
    })
  }
  const column_MinStock = [
    {
     name: "ItemDiscription",
     label: "Item Discription",
     options: {
      filter: true,
      sort: true,
      customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       },  
      
     }
    },
    {
        name: "UOM",
        label: "UOM",
        options: {
         filter: true,
         sort: true,
         customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          },
        }
       },
    {
     name: "StockQty",
     label: "Stock Qty",
     options: {
      filter: true,
      sort: true,
    customHeadLabelRender: (columnMeta)=>{
        return(
          <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
        )
       }, 
      }
    }
  
];
const column_ExpairedItems = [
  {
   name: "ItemCode",
   label: "Item Code",
   options: {
    filter: true,
    sort: true,
    customHeadLabelRender: (columnMeta)=>{
      return(
        <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
      )
     },  
    
   }
  },
  {
      name: "ItemDiscription",
      label: "Item Discription",
      options: {
       filter: true,
       sort: true,
       customHeadLabelRender: (columnMeta)=>{
         return(
           <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
         )
        },
      }
     },
  {
   name: "BatchNo",
   label: "Batch No",
   options: {
    filter: true,
    sort: true,
  customHeadLabelRender: (columnMeta)=>{
      return(
        <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
      )
     }, 
    }
  },
  {
    name: "StockQty",
    label: "Stock Qty",
    options: {
     filter: true,
     sort: true,
   customHeadLabelRender: (columnMeta)=>{
       return(
         <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
       )
      }, 
     }
   },
   {
    name: "Expairy_Date",
    label: "Expairy Date",
    options: {
     filter: true,
     sort: true,
   customHeadLabelRender: (columnMeta)=>{
       return(
         <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
       )
      }, 
     }
   }

];
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      }
    }
  }
});

const Yearly_options = {
  theme: "light1",
  backgroundColor: "transparent",
  title: {
    text: ""
  },
  animationEnabled: true,
  height: 120,
  axisX: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the horizontal ticks
    lineThickness: 0, // remove the horizontal axis line
    gridThickness: 0 // remove the vertical grid lines
  },
  axisY: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the vertical ticks
    lineThickness: 0, // remove the vertical axis line
    gridThickness: 0 // remove the horizontal grid lines
  },
  legend: {
    enabled: false
  },
  toolTip: {
    enabled: true,
   
  },
  data: [{
    type: "spline",
    xValueFormatString: "MMM YYYY",
    yValueFormatString: "#,##0.00",
    dataPoints: YearDataPoints
  }]
}
const Today_options = {
  theme: "light4",
  backgroundColor: "transparent",
  animationEnabled: true,
  title: {
    text: ""
  },
  height: 120,
  axisX: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the horizontal ticks
    lineThickness: 0, // remove the horizontal axis line
    gridThickness: 0 // remove the vertical grid lines
  },
  axisY: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the vertical ticks
    lineThickness: 0, // remove the vertical axis line
    gridThickness: 0 // remove the horizontal grid lines
  },
  legend: {
    enabled: false
  },
  toolTip: {
    enabled: true,
   
  },
  data: [{
		type: "pie",
		startAngle: 240,
    indexLabel: "{label} - #percent%",
    toolTipContent: "<b>{label}:</b> {y} (#percent%)",
    indexLabelFontColor: "white",
		dataPoints: TodayDataPoints

  
	}]
}
const Monthly_options = {
  theme: "light2",
  backgroundColor: "transparent",
  animationEnabled: true,
  title: {
    text: ""
  },
  height: 120,
  axisX: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the horizontal ticks
    lineThickness: 0, // remove the horizontal axis line
    gridThickness: 0 // remove the vertical grid lines
  },
  axisY: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the vertical ticks
    lineThickness: 0, // remove the vertical axis line
    gridThickness: 0 // remove the horizontal grid lines
  },
  legend: {
    enabled: false
  },
  toolTip: {
    enabled: true,
   
  },
  data: [{
		type: "doughnut",
		startAngle: 60,
		//innerRadius: 60,
		indexLabelFontSize: 12,
    indexLabelFontColor: "white",
		indexLabel: "{label} - #percent%",
		toolTipContent: "<b>{label}:</b> {y} (#percent%)",
		dataPoints: MonthDataPoints
	}]
}
const Weakly_options = {
  theme: "light3",
  backgroundColor: "transparent",
  animationEnabled: true,
  title: {
    text: ""
  },
  height: 120,
  axisX: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the horizontal ticks
    lineThickness: 0, // remove the horizontal axis line
    gridThickness: 0 // remove the vertical grid lines
  },
  axisY: {
    labelFormatter: function () {
      return "";
    },
    tickLength: 0, // remove the vertical ticks
    lineThickness: 0, // remove the vertical axis line
    gridThickness: 0 // remove the horizontal grid lines
  },
  legend: {
    enabled: false
  },
  toolTip: {
    enabled: true,
   
  },
  data: [{
    type: "column",
    xValueFormatString: "MMM YYYY",
    yValueFormatString: "#,##0.00",
   
    dataPoints:WeakDataPoints
  }]
}




  return ReactDOM.createPortal(
    <>
      <div className="container-fluid">
        
          <div className='card mt-3'>
            <div className="card-body">
            <div className="row ">
              <div className='col-xl-4 col-lg-4 col-md-4'>
              <TextField
                    name="FromDate"
                    label="From Date"
                    type='date'
                    value={FromDate}
                    onChange={e => {
                      setFromDate(e.target.value)
                      Payables(SelectedProject.Project_ID,e.target.value,ToDate)
                      Receivables(SelectedProject.Project_ID,e.target.value,ToDate)
                      MostSoldItems(SelectedProject.Project_ID,e.target.value,ToDate)
                      MinimunSoldItems(SelectedProject.Project_ID,e.target.value,ToDate)
                      ProfitAndLoss(SelectedProject.Project_ID,e.target.value,ToDate)
                      Cash_In_Hand(SelectedProject.Project_ID,e.target.value,ToDate)
                      Cash_In_Banks(SelectedProject.Project_ID,e.target.value,ToDate)
                    }}
                  />
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4'>
              <TextField
                    name="ToDate"
                    label="To Date"
                    type='date'
                    value={ToDate}
                    onChange={e => {
                      setToDate(e.target.value)
                      Payables(SelectedProject.Project_ID,FromDate,e.target.value)
                      Receivables(SelectedProject.Project_ID,FromDate,e.target.value)
                      MostSoldItems(SelectedProject.Project_ID,FromDate,e.target.value)
                      MinimunSoldItems(SelectedProject.Project_ID,FromDate,e.target.value)
                      ProfitAndLoss(SelectedProject.Project_ID,FromDate,e.target.value)
                      Cash_In_Hand(SelectedProject.Project_ID,FromDate,e.target.value)
                      Cash_In_Banks(SelectedProject.Project_ID,FromDate,e.target.value)
                    }}
                  />
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4'>
              <Autocomplete
                    name="Project"
                    id="Project"
                    options={Project_List}
                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                    value={SelectedProject}
                    onChange={(e, value) => {
                        if (value) {
                            setSelectedProject(value)
                            Payables(value.Project_ID,FromDate,ToDate)
                            Receivables(value.Project_ID,FromDate,ToDate)
                            MostSoldItems(value.Project_ID,FromDate,ToDate)
                            MinimunSoldItems(value.Project_ID,FromDate,ToDate)
                            ProfitAndLoss(value.Project_ID,FromDate,ToDate)
                            MinimumStock(value.Project_ID)
                            MaxStock(value.Project_ID)
                            Cash_In_Hand(value.Project_ID,FromDate,ToDate)
                            Cash_In_Banks(value.Project_ID,FromDate,ToDate)
                            Todays_Expense(value.Project_ID)
                            Expired_Items(value.Project_ID)
                            Items_to_be_Expired(value.Project_ID)
                        }
                        else{
                            setSelectedProject(null)
                        }
                    }}
                    renderInput={(params) =>
                      <TextField {...params} name="Project" label="Project"
                      />
                    }
                  /> 
                   

              </div>
            </div>
          </div>
        </div>
       <div className="row">
       <div className="col-xl-3 col-lg-3">
       <div className="card tilebox-one">
         <div className="card-body" style={{backgroundColor:'#9FCC2E', color:'white'}}>
        <Icon icon="uil:analysis" className='float-end' color="white" style={{fontSize:'40px', fontWeight:'bold'}} />
        
           <h6 className="text-uppercase mt-0 text-white" style={{fontSize:'20px'}}>Today Sale</h6>
           <h6 className="my-2 text-white" style={{fontSize:'28px'}} >
            {Comp_Id === 6 ? "AED "  : "PKR "}
                 {_TodaysSale} 
                </h6>
         </div> 
         <div className='card-footer' style={{backgroundColor:'#9FCC2E', color:'white', height: '145px'}}>
        { TodayDataPoints.length > 0 ?  <CanvasJSChart  options = {Today_options}  /> : <h3 style={{textAlign:'center', marginTop: '40px'}}>No Sales Found</h3>}   
         </div>
       </div>
     </div> 
     <div className="col-xl-3 col-lg-3">
       <div className="card tilebox-one">
         <div className="card-body" style={{backgroundColor:'#03A9F4', color:'white'}}>
         <Icon icon="uil:chart-growth" className='float-end' color="white" style={{fontSize:'40px', fontWeight:'bold'}} />
           
           <h6 className="text-uppercase mt-0 text-white" style={{fontSize:'20px'}}>This Week's Sale </h6>
           <h6 className="my-2" style={{fontSize:'28px'}} >
           {Comp_Id === 6 ? "AED "  : "PKR "} 
           {thisWeekSale}</h6>
         </div> 
         <div className='card-footer' style={{backgroundColor:'#03A9F4', color:'white', height: '145px'}}>
         { WeakDataPoints.length > 0 ?  <CanvasJSChart  options = {Weakly_options}  /> : <h3 style={{textAlign:'center', marginTop: '40px'}}>No Sales Found</h3>}
      
       
         </div>
       </div>
     </div> 
     <div className="col-xl-3 col-lg-3">
       <div className="card tilebox-one">
         <div className="card-body" style={{backgroundColor:'#AB47BC', color:'white'}}>
         <Icon icon="uil:chart-line" className='float-end' color="white" style={{fontSize:'40px', fontWeight:'bold'}} />
           <h6 className="text-uppercase mt-0 text-white" style={{fontSize:'20px'}}> This Month's Sale </h6>
           <h6 className="my-2" style={{fontSize:'28px'}} >
           {Comp_Id === 6 ? "AED "  : "PKR "}
            {thisMonthSale}</h6>
         </div> 
         <div className='card-footer' style={{backgroundColor:'#AB47BC', color:'white', height: '145px'}}>
         { MonthDataPoints.length > 0 ?  <CanvasJSChart  options = {Monthly_options}  /> : <h3 style={{textAlign:'center', marginTop: '40px'}}>No Sales Found</h3>}
   
         </div>
       </div>
     </div> 
     <div className="col-xl-3 col-lg-3" >
       <div className="card tilebox-one">
         <div className="card-body" style={{backgroundColor:'#FA9F1B', color:'white'}}>
         <Icon icon="uil:graph-bar" className='float-end' color="white" style={{fontSize:'40px', fontWeight:'bold'}} />
           <h6 className="text-uppercase mt-0 text-white" style={{fontSize:'20px'}}> This Year's Sale</h6>
           <h6 className="my-2" style={{fontSize:'28px'}} >
           {Comp_Id === 6 ? "AED "  : "PKR "}
            {thisYearSale}</h6>
         </div> 
         <div className='card-footer' style={{backgroundColor:'#FA9F1B', color:'white', height: '145px'}}>
         { YearDataPoints.length > 0 ?  <CanvasJSChart  options = {Yearly_options}  /> : <h3 style={{textAlign:'center', marginTop: '40px'}}>No Sales Found</h3>}

      
         </div>
       </div>
     </div> 

          <div className="col-xl-4 col-lg-4">
            <div className="card tilebox-one">
              <div className="card-body">
                <i className="uil uil-bill float-end text-danger" />
                <h6 className="text-uppercase mt-0 text-success">Total Payables </h6>
                <Link target={"_blank"} onClick={e=>{
                   localStorage.setItem('token', sessionStorage.getItem('token'))
                }} to={`/Payables/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}`}>
                  <h2 className="my-2" ><u>{_Payables}</u></h2>
                  </Link>
              </div> 
            </div>
            </div>
            <div className="col-xl-4 col-lg-4">
            <div className="card tilebox-one">
              <div className="card-body">
                <i className="uil uil-usd-circle float-end text-danger" />
                <h6 className="text-uppercase mt-0 text-success">Total Recievables</h6>
                <Link target={"_blank"} onClick={e=>{
                   localStorage.setItem('token', sessionStorage.getItem('token'))
                }} to={`/Receiveable/${SelectedProject && SelectedProject.Project_ID}/${FromDate}`}>
                <h2 className="my-2">
                    <u>{_Receivables}</u></h2></Link>
              </div> 
            </div>
          </div> 
          <div className="col-xl-4 col-lg-4">
            <div className="card tilebox-one">
              <div className="card-body">
                <i className="uil uil-dollar-alt float-end text-danger" />
                <h6 className="text-uppercase mt-0 text-success">Total {PL_Narration}</h6>
                <Link target={"_blank"} onClick={e=>{
                   localStorage.setItem('token', sessionStorage.getItem('token'))
                }} to={`/PLReport/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}`}>
                <h2 className="my-2" >
                     <u>{PL_Value}</u></h2></Link>
              </div> 
            </div>
          </div> 
        
        
          <div className="col-xl-4 col-lg-4">
            <div className="card tilebox-one">
              <div className="card-body">
                <i className="uil uil-graph-bar float-end text-danger" />
                <h6 className="text-uppercase mt-0 panel-add text-success"> Cash in Hand</h6>
                <Link target={"_blank"} onClick={e=>{
                   localStorage.setItem('token', sessionStorage.getItem('token'))
                }} to={`/CashInHandReport/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}`}>
                  <div className="number" ><u>{Total_Cash_In_Hand}</u></div>
                  </Link>
                {/* <h6 className="my-2" >
                     PKR {thisYearSale}</h6> */}
              </div> 
            </div>
          </div> 

          <div className="col-xl-4 col-lg-4">
            <div className="card tilebox-one">
              <div className="card-body">
                <i className="uil uil-store float-end text-danger" />
                <h6 className="text-uppercase mt-0 text-success"> Cash in Banks</h6>
                <Link target={"_blank"} onClick={e=>{
                   localStorage.setItem('token', sessionStorage.getItem('token'))
                }} to={`/CashInBankReport/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}/0`}>
                  <div className="number"><u>{Total_Cash_In_Banks}</u></div></Link>
                {/* <h6 className="my-2" >
                     PKR {thisYearSale}</h6> */}
              </div> 
            </div>
          </div> 

          <div className="col-xl-4 col-lg-4">
            <div className="card tilebox-one">
              <div className="card-body">
                <i className="uil uil-chart-down float-end text-danger" />
                <h6 className="text-uppercase mt-0 text-success ">Today's Expense</h6>
                <div className="number">{Total_Todays_Expense}</div>
                {/* <h6 className="my-2" >
                     PKR {thisYearSale}</h6> */}
              </div> 
            </div>
          </div> 
        </div> 
        {/* <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="dropdown float-end">
                  <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="javascript:void(0);" className="dropdown-item">Refresh Report</a>

                    <a href="javascript:void(0);" className="dropdown-item">Export Report</a>
                  </div>
                </div>
                <h4 className="header-title">Sessions by country</h4>
                <div className="row">
                  <div className="col-lg-8">
                    <div id="world-map-markers" className="mt-3 mb-3" style={{ height: 300 }}>
                    </div>
                  </div>
                  <div className="col-lg-4" dir="ltr">
                    <div id="country-chart" className="apex-charts" data-colors="#727cf5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="card">
              <div className="card-body">
              <a href="#" className="p-0 float-end">Top 5 Qty wise<i className="mdi mdi-pill ms-1" /></a>
                <h4 className="header-title">Most Sold Items</h4>
                <div className="info col-7">		
                <Link onClick={e=>{
                   localStorage.setItem('token', sessionStorage.getItem('token'))
                }} to={`/MostSoldItems/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}/5`} target={"_blank"}> <div className="temp float-end"><h4><u>{TotalMaxQty}</u></h4></div></Link> 						
              </div>
                {/* <div id="views-min" className="apex-charts mt-2" data-colors="#0acf97" /> */}
                <div className="table-responsive mt-3">
                  <table className="table table-sm mb-0 font-13">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        MostSoldItemData.length > 0 ?
                        MostSoldItemData.map((value,index)=>(
                        <>
                          <tr key={index}>
                          <td>{value.ItemDiscription}</td>
                          <td className="font-medium">{value.Qty}</td>
                        </tr>
                        </>
                          ))
                          :
                          <>
                          <tr>
                          <td>No Items Found</td>
                        </tr>
                          </>
                      }
            </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12">
            <div className="card">
              <div className="card-body">
              <a href="#" className="p-0 float-end">Top 5 Qty wise<i className="mdi mdi-pill ms-1" /></a>
                <h4 className="header-title">Minimum Sold Items</h4>
                <div className="info col-7">		
                <Link onClick={e=>{
                   localStorage.setItem('token', sessionStorage.getItem('token'))
                }} to={`/MinimumSoldItems/${SelectedProject && SelectedProject.Project_ID}/${FromDate}/${ToDate}/5`} target={"_blank"}> <div className="temp float-end"><h4><u>{TotalMinQty}</u></h4></div></Link> 						
              </div>
                <div className="table-responsive mt-3">
                  <table className="table table-sm mb-0 font-13">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
            {
                MinimunSoldItemData.length > 0 ?
                MinimunSoldItemData.map((value,index)=>(
                 <>
                  <tr key={index}>
                  <td>{value.ItemDiscription}</td>
                  <td className="font-medium">{value.Qty}</td>
                </tr>
                </>
                  ))
                  :
                  <>
                  <tr>
                  <td style={{backgroundColor:'white'}}>No Items Found</td>
                </tr>
                  </>
              }
            </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-body">
                <a href="#" className="p-0 float-end"> <i className="mdi mdi-arrow-bottom-left-bold-outline" style={{fontSize:'36px'}} /></a>
                <h4 className="header-title mt-1 mb-3">Minimum Stock</h4>
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable 
                        data={Min_Stock_Data}
                        columns={column_MinStock}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        
                        }}
                        className="custom-table"
                    />
                      </MuiThemeProvider>
                </div>
              {/*  <div className="table-responsive">
                  <table className="table table-sm table-centered mb-0 font-14">
                    <thead className="table-light">
                      <tr>
                      <th style={{ width: '40%' }}> Item </th>
                      <th> UOM </th>
                      <th> Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                {
                  Min_Stock_Data.length > 0 ?
                  Min_Stock_Data.map((value,index)=>(
                    <>
                      <tr key={index}>
                        <td>{value.ItemDiscription}</td>
                        <td>{value.UOM}</td>
                        <td>{value.StockQty}</td>
                      </tr>
                    </>
                  ))
                  :
                      <tr >
                        <td >No Data Found</td>
                      </tr>
                }
               
               
              </tbody>
                  </table>
                </div>*/}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-body">
                <a href="#" className="p-0 float-end"> <i className="mdi mdi-arrow-top-right-bold-outline" style={{fontSize:'36px'}} /></a>
                <h4 className="header-title mt-1 mb-3">Maximum Stock</h4>
                <div className="table-responsive">
                <MuiThemeProvider theme={theme}>
                <MUIDataTable
                    data={Max_Stock_Data}
                    columns={column_MinStock}
                    options={{
                    selectableRows: 'none',
                    print: false,
                    search: true,
                    download: false,
                    viewColumns: false,
                    filter: false,
                    }}
                    className="custom-table"
                />
                  </MuiThemeProvider>
                 {/*<table className="table table-sm table-centered mb-0 font-14">
                    <thead className="table-light">
                      <tr>
                      <th style={{ width: '40%' }}> Item </th>
                      <th> UOM </th>
                      <th> Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                {
                  Max_Stock_Data.length > 0 ?
                  Max_Stock_Data.map((value,index)=>(
                    <>
                      <tr key={index}>
                        <td>{value.ItemDiscription}</td>
                        <td>{value.UOM}</td>
                        <td>{value.StockQty}</td>
                      </tr>
                    </>
                  ))
                  :
                      <tr >
                        <td style={{backgroundColor:'white'}}>No Data Found</td>
                      </tr>
                }
               
               
              </tbody>
                  </table>*/} 
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-body">
                <a href="#" className="p-0 float-end"> <i className="mdi mdi-hospital-box-outline" style={{fontSize:'36px'}} /></a>
                <h4 className="header-title mt-1 mb-3">Expired Items</h4>
                <div className="table-responsive">
                {/*  <table className="table table-sm table-centered mb-0 font-14">
                    <thead className="table-light">
                      <tr>
                      <th>Code</th>
                      <th >Item</th>
                      <th >Batch #</th>
                      <th>StockQty</th>
                      <th>Expiry Date </th> 
                      </tr>
                    </thead>
              <tbody>
                {
                  ExpiredItemsData.length > 0 ?
                  ExpiredItemsData.map((value,index)=>(
                  <>
                   <tr key={index}>
                    <td>{value.ItemCode}</td>
                    <td>{value.ItemDiscription}</td>
                    <td>{value.BatchNo}</td>
                    <td>{value.StockQty}</td>                                   
                    <td>{value.Expairy_Date} </td>
                </tr>
                </>
                  ))
                  :
                  <tr>
                    <td>No Data Found</td>
                  </tr>
                }
               
               
              </tbody>
                  </table>*/}
                  <MuiThemeProvider theme={theme}>
                <MUIDataTable
                    data={ExpiredItemsData}
                    columns={column_ExpairedItems}
                    options={{
                    selectableRows: 'none',
                    print: false,
                    search: true,
                    download: false,
                    viewColumns: false,
                    filter: false,
                    }}
                    className="custom-table"
                />
                  </MuiThemeProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-body">
                <a href="#" className="p-0 float-end"> <i className="mdi mdi-hospital-box-outline" style={{fontSize:'36px'}} /></a>
                <h4 className="header-title mt-1 mb-3">Near Expiry Items </h4>
                <div className="table-responsive">
                <MuiThemeProvider theme={theme}>
                <MUIDataTable
                    data={BeforeExpiredItemsData}
                    columns={column_ExpairedItems}
                    options={{
                    selectableRows: 'none',
                    print: false,
                    search: true,
                    download: false,
                    viewColumns: false,
                    filter: false,
                    }}
                    className="custom-table"
                />
                  </MuiThemeProvider>
                 {/*<table className="table table-sm table-centered mb-0 font-14">
                    <thead className="table-light">
                      <tr>
                      <th>Code</th>
                  <th >Item</th>
                  <th>BatchNo</th>
                  <th>StockQty</th>
                  <th>Expiry Date </th> 
                      </tr>
                    </thead>
                    <tbody>
                {
                  BeforeExpiredItemsData.length > 0 ?
                  BeforeExpiredItemsData.map((value,index)=>(
                  <>
                   <tr key={index}>
                    <td>{value.ItemCode}</td>
                    <td>{value.ItemDiscription}</td>
                    <td>{value.BatchNo}</td>
                    <td>{value.StockQty}</td>                                   
                    <td>{value.Expairy_Date} </td>
                </tr>
                </>
                  ))
                  :
                  <tr>
                    <td>No Data Found</td>
                  </tr>
                }
               
               
              </tbody>
                  </table>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>, document.getElementById('PageContents')
  )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  Group_Id : state.Login_User.User_Data.Group_Id,
  User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id
})
export default connect(mapStateToProps)(MainContents)